export const insurances = [
  {
    image: "vsk",
    title: "ВСК",
    link: "https://shop.vsk.ru/mortgage",
  },
  {
    image: "maks",
    title: "Макс",
    link: "https://makc.ru/calculator/gorodskie-ogni.php",
  },
  {
    image: "ingos",
    title: "Ингосстрах",
    link: "https://www.ingos.ru/mortgage",
  },
  {
    image: "sogaz",
    title: "Согаз",
    link: "https://www.sogaz.ru/corporate/property/",
  },
  {
    image: "alpha",
    title: "Альфа",
    link: "https://www.alfastrah.ru/individuals/housing/",
  },
  {
    image: "absolut",
    title: "Абсолют",
    link: "https://www.absolutins.ru/fizicheskie-lica/strahovanie-pri-ipoteke/ipoteka/",
  },
  {
    image: "homecredit",
    title: "Хоум Кредит",
    link: "https://www.hcinsurance.ru/",
  },
];
