import styled, { css } from 'styled-components';
import { getTransition } from '../../Styles/utilites';

type ContentProps = {
  $isShow: boolean;
  $width?: number;
  $overVisible?: boolean;
};
export const Content = styled.div<ContentProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.25em;
  min-height: 30vh;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  max-width: ${({ $width }) => ($width ? $width : 900)}px;
  max-height: 90vh;
  margin: 0 1rem 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.darkBrand};
  opacity: 0;
  transform: rotateY(90deg) translate3d(0, -100px, 0);
  overflow: ${({ $overVisible }) => ($overVisible ? 'initial' : 'hidden')};
  ${({ $isShow }) =>
    $isShow &&
    css`
      opacity: 1;
      transform: rotateY(0) translate3d(0, 0, 0);
    `}
  ${getTransition(300, ['opacity', 'transform'], 'ease-out')}
  @media screen and ${({ theme }) => theme.media.sm} {
    max-height: 100vh;
    margin: 0 3rem 0;
    padding: 1em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    min-height: 50vh;
    padding: 1em 2em;
  }
  @media screen and (min-width: 2100px) {
    min-height: 40vh;
  }
`;

export const Title = styled.div`
  position: absolute;
  left: 12px;
  top: 22px;
  z-index: ${({ theme }) => theme.order.two};
  color: ${({ theme }) => theme.colors.revert};
  font-size: 1.2rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1.5rem;
    top: 1%;
    left: 16px;
  }
`;
