import { PropsWithChildren, useEffect, useState } from 'react';
import { createContainer, Portal } from '../Portal/Portal';

import * as S from './Styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/modal/close.svg';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { Close, FullScreenModalWrapper } from '../../Styles/components';

export const MODAL_CONTAINER_ID = 'modal-container-id';

export type ModalProps = {
  onClose?: () => void;
  isShow: boolean;
  isSpecial?: boolean;
  width?: number;
  overVisible?: boolean;
};
export type ModalContainerProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};
export const Modal = ({ onClose, isShow, isSpecial, width, overVisible, children }: PropsWithChildren<ModalProps>) => {
  const [isMounted, setMounted] = useState(false);

  useLockBodyScroll();

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID });
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, [onClose]);

  return isMounted ? (
    <Portal id={MODAL_CONTAINER_ID}>
      <FullScreenModalWrapper onClick={isSpecial ? () => {} : onClose} $isShow={isShow} data-testid={'wrap'}>
        <S.Content
          onClick={(e) => e.stopPropagation()}
          $isShow={isShow}
          $isSpecial={isSpecial}
          $width={width}
          $overVisible={overVisible}
          data-testid={'modal-content'}
        >
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
          <>{children}</>
        </S.Content>
      </FullScreenModalWrapper>
    </Portal>
  ) : null;
};
