import axios from 'axios';
import apiRoutes from './config/apiRoutes';

export async function getNormalNews(page = 1, limit = 20, newsFilter) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_ARTICLES_ACTIONS}`, {
      page,
      limit,
      orderBy: 'desc',
      newsFilter,
      isOnlyNormal:true,
    });
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function getArticle(slug) {
  try {
    const response2 = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_ARTICLES_ACTIONS}/${slug}`, {
      slug,
    });
    return response2.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function getRandomArticle(page = '1', limit = 5, currentTitleId) {
  try {
    const response3 = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_ARTICLES_RANDOM}/${currentTitleId}`, {
      page,
      limit,
    });
    return response3.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function getTopNews() {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_ARTICLES_TOP}`);
    return res.data.body;
  } catch (error) {
    console.error(error);
  }
}
