import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import mapCenterActions from '../store/actions/mapCenter';
import defineMapCenter from '../API/defineMapCenter';
import { YMapsApi } from 'react-yandex-maps';
import { RootState } from '../store/store';

const useMapCenter = (ymaps: YMapsApi) => {
  const dispatch = useDispatch();
  const { setMapCenter } = bindActionCreators(mapCenterActions, dispatch);
  const [coords, setCoords] = useState<null | string[]>(null);
  const city = useSelector((state: RootState) => state.selectedCity);
  const localStorageMapCenter = !!localStorage.getItem('mapCenter') && localStorage.getItem('mapCenter')!.split(',');

  useEffect(() => {
    if (localStorageMapCenter) {
      setCoords(localStorageMapCenter);
    } else {
      if (ymaps && city) {
        defineMapCenter(ymaps, city).then((coords) => {
          setCoords(coords);
          localStorage.setItem('mapCenter', coords);
        });
      }
    }
  }, [ymaps, city]);

  useEffect(() => {
    if (coords) {
      setMapCenter(coords);
    }
  }, [coords]);

  return { mapCenter: coords, setMapCenter: setCoords };
};

export default useMapCenter;
