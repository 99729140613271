type IconCalendarProps = {};
export const IconCalendar = ({}: IconCalendarProps) => {
  return (
    <svg width="22" height="25" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 563">
        <path
          id="Vector"
          d="M12.4444 18V15H15.5556V18H12.4444ZM6.22222 18V15H9.33333V18H6.22222ZM18.6667 18V15H21.7778V18H18.6667ZM12.4444 24V21H15.5556V24H12.4444ZM6.22222 24V21H9.33333V24H6.22222ZM18.6667 24V21H21.7778V24H18.6667ZM0 30V3H4.66667V0H7.77778V3H20.2222V0H23.3333V3H28V30H0ZM3.11111 27H24.8889V12H3.11111V27Z"
          fill={'#198ACB'}
        />
        <rect id="Rectangle 519" x="3" y="12" width="22" height="15" fill="white"></rect>
        <text x={new Date().getDate().toString().length > 1 ? '4' : '9'} y="25" fontWeight="bold" fill={'#198ACB'}>
          {new Date().getDate()}
        </text>
      </g>
    </svg>
  );
};
