import React, { useEffect, useState } from 'react';
import { ReactComponent as PartnerIcon } from '../../assets/icons/profile/parther.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as FilesIcon } from '../../assets/icons/files.svg';
import { ReactComponent as CalendarIcon } from '../../assets/styles/bg-imgs/calendar.svg';
import * as S from '../../layouts/styles';
import useIsMobile from '../../hooks/useIsMobile';
import PhoneInput from 'react-phone-input-2';
import { useAccessToken, useCurrentUser } from '../../store/reducers';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDropzone } from 'react-dropzone';
import { createUserPartnerOrder } from '../../API/userPartnerORder';
import { bindActionCreators } from 'redux';
import alertActions from '../../store/actions/alert';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function UserPartner() {
  const navigate = useNavigate();

  const isMobile = useIsMobile(768);

  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const token = useAccessToken();
  const currentUser = useCurrentUser();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [passportSeries, setPassportSeries] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportDateOfIssue, setPassportDateOfIssue] = useState('');
  const [passportWhoIssued, setPassportWhoIssued] = useState('');
  const [comment, setComment] = useState('');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const data = {
    userId: currentUser?.id,
    firstName,
    lastName,
    birthday,
    phone,
    email,
    passportSeries,
    passportNumber,
    passportDateOfIssue,
    passportWhoIssued,
    comment,
    token,
  };

  const fields = {
    isInValidFirstName: false,
    isInValidLastName: false,
    isInValidBirthday: false,
    isInValidPhone: false,
    isInValidEmail: false,
    isInValidPassportSeries: false,
    isInValidPassportNumber: false,
    isInValidPassporDateOfIssue: false,
    isInValidPassportWhoIssued: false,
  };

  const [valid, setValid] = useState(fields);

  const onSumbit = (e) => {
    e.preventDefault();

    const validMail = email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

    const isInValidFirstName = firstName === undefined || firstName?.length <= 0;
    const isInValidLastName = lastName === undefined || lastName?.length <= 0;
    const isInValidBirthday = !(birthday && true);
    const isInValidPhone = phone === undefined || phone?.length > 11 || phone?.length < 11 || phone === null;
    const isInValidEmail = email === undefined || validMail === undefined || validMail === null;
    const isInValidPassportSeries = passportSeries === undefined || passportSeries?.length <= 0;
    const isInValidPassportNumber = passportSeries === undefined || passportSeries?.length <= 0;
    const isInValidPassporDateOfIssue = passportSeries === undefined || passportSeries?.length <= 0;
    const isInValidPassportWhoIssued = passportSeries === undefined || passportSeries?.length <= 0;

    console.log({ lastName, isInValidFirstName });

    const newValid = structuredClone(valid);

    if (isInValidFirstName) {
      newValid['isInValidFirstName'] = true;
    }
    if (isInValidLastName) {
      newValid['isInValidLastName'] = true;
    }
    if (isInValidBirthday) {
      newValid['isInValidBirthday'] = true;
    }
    if (isInValidPhone) {
      newValid['isInValidPhone'] = true;
    }
    if (isInValidEmail) {
      newValid['isInValidEmail'] = true;
    }
    if (isInValidPassportSeries) {
      newValid['isInValidPassportSeries'] = true;
    }
    if (isInValidPassportNumber) {
      newValid['isInValidPassportNumber'] = true;
    }
    if (isInValidPassporDateOfIssue) {
      newValid['isInValidPassporDateOfIssue'] = true;
    }
    if (isInValidPassportWhoIssued) {
      newValid['isInValidPassportWhoIssued'] = true;
    }
    setValid({ ...valid, ...newValid });

    if (!(isInValidFirstName || isInValidLastName || isInValidPhone || isInValidEmail)) {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      acceptedFiles.forEach((i, index) => {
        formData.append('files[]', i);
      });

      createUserPartnerOrder(formData, axiosPrivate)
        .then((response) => {
          setAlert('success', true, 'Заявка на партнерство успешно отправлена');
            navigate('/personal-account')
        })
        .catch((errors) => {
          setAlert('danger', true, 'Произошла ошибка сервера');
        });
    }
  };

  const resetFieldVal = (newState, field) => {
    setValid({ ...valid, [field]: false });
  };

  useEffect(() => {
    console.log({ valid });
  }, [valid]);

  return (
    <div style={{ maxWidth: 980, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
      <div className="frame" style={{ borderRadius: 10, padding: '30px 40px' }}>
        <S.CustomPTeg>Стать партнером</S.CustomPTeg>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <PartnerIcon
            style={{
              width: isMobile ? '' : 320,
              height: isMobile ? '' : 160,
            }}
          />
          <span
            style={{
              color: '#797979',
              fontWeight: 400,
              fontSize: isMobile ? 14 : 24,
              lineHeight: isMobile ? '21px' : '36px',
            }}
          >
            Мы создаем взаимовыгодные отношения для партнерства и приглашаем Вас стать членом нашей Команды
          </span>
        </div>
      </div>
      <form className="form-profile" style={{ maxWidth: 668, width: '100%', marginBottom: 20 }}>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{ fontWeight: 700, color: valid.isInValidFirstName ? '#DA1E2A' : '' }}
          >
            Имя:
          </div>
          <div className="col-sm-8">
            <input
              name="firstName"
              type="text"
              value={firstName}
              placeholder="Имя"
              className="fs-11"
              onChange={(e) => {
                e.target.value.length < 256 && setFirstName(e.target.value);
                resetFieldVal(e, 'isInValidFirstName');
              }}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{ fontWeight: 700, color: valid.isInValidLastName ? '#DA1E2A' : '' }}
          >
            Фамилия:
          </div>
          <div className="col-sm-8">
            <input
              name="lastName"
              type="text"
              value={lastName}
              placeholder="Фамилия"
              className="fs-11"
              onChange={(e) => {
                e.target.value.length < 256 && setLastName(e.target.value);
                resetFieldVal(e, 'isInValidLastName');
              }}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{ fontWeight: 700, color: valid.isInValidBirthday ? '#DA1E2A' : '' }}
          >
            Дата рождения:
          </div>
          <div className="col-sm-8" style={{ position: 'relative' }}>
            {/* <CalendarIcon style={{ left: 30, position: 'absolute', top: isMobile ? '20%' : 15, zIndex: 1 }} /> */}
            <input
              name="birthday"
              type="date"
              value={birthday}
              placeholder="Дата рождения"
              className="fs-11"
              onChange={(e) => {
                setBirthday(e.target.value);
                resetFieldVal(e, 'isInValidBirthday');
              }}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{ fontWeight: 700, color: valid.isInValidPhone ? '#DA1E2A' : '' }}
          >
            Телефон:
          </div>
          <div className="col-sm-8 position-relative">
            <div className={'phone-container'}>
              <PhoneInput
                inputStyle={{ backgroundColor: '#fff', fontSize: '1em', lineHeight: 'inherit' }}
                specialLabel={''}
                country={'ru'}
                countryCodeEditable={false}
                disableDropdown={true}
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                  resetFieldVal(phone, 'isInValidPhone');
                }}
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{ fontWeight: 700, color: valid.isInValidEmail ? '#DA1E2A' : '' }}
          >
            Email:
          </div>
          <div className="col-sm-8">
            <input
              name="email"
              type="text"
              value={email}
              placeholder="Email@mail.com"
              className="fs-11"
              onChange={(e) => {
                e.target.value.length < 256 && setEmail(e.target.value);
                resetFieldVal(e, 'isInValidEmail');
              }}
            />
          </div>
        </div>
        <div className="row mb-3 mb-sm-4 mb-xxl-5">
          <div
            className="col-sm-4 fs-11 mb-1 mb-sm-0"
            style={{
              fontWeight: 700,
              color:
                valid.isInValidPassportSeries ||
                valid.isInValidPassportNumber ||
                valid.isInValidPassporDateOfIssue ||
                valid.isInValidPassportWhoIssued
                  ? '#DA1E2A'
                  : '',
            }}
          >
            Паспортные данные:
          </div>
          <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div style={{ display: 'flex', gap: '5%' }}>
              <input
                name="passportSeries"
                type="number"
                value={passportSeries}
                placeholder="Серия"
                className="fs-11"
                style={{ width: '25%' }}
                onChange={(e) => {
                  e.target.value.length < 5 && setPassportSeries(e.target.value);
                  resetFieldVal(e, 'isInValidPassportSeries');
                }}
                max={9999}
              />
              <input
                name="passportNumber"
                type="number"
                value={passportNumber}
                placeholder="Номер"
                className="fs-11"
                style={{ width: '70%' }}
                onChange={(e) => {
                  e.target.value.length < 7 && setPassportNumber(e.target.value);
                  resetFieldVal(e, 'isInValidPassportNumber');
                }}
                max={999999}
              />
            </div>
            <div className="col-sm-8" style={{ position: 'relative' }}>
              {/* <CalendarIcon style={{ left: 20, position: 'absolute', top: isMobile ? 10 : 15, zIndex: 1 }} /> */}
              <input
                name="passportDateOfIssue"
                type="date"
                value={passportDateOfIssue}
                placeholder="Дата выдачи"
                className="fs-11"
                onChange={(e) => {
                  setPassportDateOfIssue(e.target.value);
                  resetFieldVal(e, 'isInValidPassporDateOfIssue');
                }}
              />
            </div>
            <input
              name="passportWhoIssued"
              type="text"
              value={passportWhoIssued}
              placeholder="Кем выдан"
              className="fs-11"
              onChange={(e) => {
                e.target.value.length < 256 && setPassportWhoIssued(e.target.value);
                resetFieldVal(e, 'isInValidPassportWhoIssued');
              }}
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          {!isMobile ? (
            <div className="col-sm-4 fs-11 mb-1 mb-sm-0" style={{ fontWeight: 700 }}>
              Прикрепить файлы:
            </div>
          ) : null}
          <div className="col-sm-8">
            {isMobile ? (
              <div {...getRootProps({ className: 'dropzone' })}>
                <FilesIcon /> Прикрепить файлы:
                <input {...getInputProps()} />
              </div>
            ) : (
              <>
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{ border: '2px dashed rgb(200,200,200)', padding: 20, borderRadius: 3 }}
                >
                  <input {...getInputProps()} />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                    <UploadIcon />
                    <span>Перетащите ваши файлы сюда</span>
                  </div>
                </div>
              </>
            )}

            <aside style={{ paddingTop: 20 }}>
              <ul>{files}</ul>
            </aside>
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
          <div className="col-sm-4 fs-11 mb-1 mb-sm-0" style={{ fontWeight: 700 }}>
            Комментарий:
          </div>
          <div className="col-sm-8">
            <textarea
              name="comment"
              value={comment}
              placeholder="Комментарий"
              className="fs-11"
              onChange={(e) => {
                e.target.value.length < 256 && setComment(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            className="btn btn-1 fs-11 text-uppercase"
            onClick={(e) => {
              onSumbit(e);
            }}
          >
            Стать партнером
          </button>
        </div>
      </form>
    </div>
  );
}
