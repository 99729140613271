'use client';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useLockBodyScroll = (deps?: ReadonlyArray<any>) => {
  const mob = useRef(false);
  //  const [isMob, setIsMob] = useState(false);
  const [originalStyle, setOriginalStyle] = useState('');
  // const [dontHaveEffect, setDontHaveEffect] = useState(false);
  const dontHaveEffect = useRef(false);
  useLayoutEffect(() => {
    if (typeof window === 'undefined') return;
    const isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // Get original body overflow
    const originalStyle: string = window.getComputedStyle(document.body).overflow;
    const dontHaveEffectState = dontHaveEffect.current || originalStyle === 'hidden';
    //setIsMob(isMob);
    mob.current = isMob;
    setOriginalStyle(originalStyle);
    //setDontHaveEffect(dontHaveEffectState);
    dontHaveEffect.current = dontHaveEffectState;
  }, []);
  useLayoutEffect(
    () => {
      if (dontHaveEffect.current) return;
      if (deps) {
        if (deps.filter((d) => d === true).length) {
          if (!mob.current) document.body.style.paddingRight = '7px';
          document.body.style.overflow = 'hidden';
        } else {
          if (!mob.current) document.body.style.paddingRight = '0';
          document.body.style.overflow = originalStyle;
        }
      } else {
        // Prevent scrolling on mount
        if (!mob.current) document.body.style.paddingRight = '7px';
        document.body.style.overflow = 'hidden';
      }
    },
    deps ? [...deps, mob.current, dontHaveEffect.current] : [mob.current, dontHaveEffect.current]
  );
  useEffect(() => {
    return () => {
      if (dontHaveEffect.current) return;
      if (!mob.current) document.body.style.paddingRight = '0';
      document.body.style.overflow = originalStyle;
    };
  }, []);
};
