import styled from 'styled-components';
import { getTransition } from '../../Styles/utilites';

type ButtonProps = {
  $isShow?: boolean;
};
export const Button = styled.button<ButtonProps>`
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  > div {
    line-height: 1;
    font-size: 0.85rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    color: ${({ theme }) => theme.colors.brandColor};
  }
  svg {
    margin-left: 0.5rem;
    padding-bottom: 1px;
    height: 14px;
    width: auto;
    transform: rotateZ(${({ $isShow }) => ($isShow ? 90 : 0)}deg);
    line {
      fill: ${({ theme }) => theme.colors.brandColor};
      stroke: ${({ theme }) => theme.colors.brandColor};
    }
    ${getTransition(300, 'transform')}
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    > div {
      font-size: 1rem;
    }
  }
`;
