import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 1rem 0;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 2rem 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  button {
    color: ${({ theme }) => theme.colors.brandColor};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.brandColor};
      }
    }
    span {
      text-transform: uppercase;
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-bottom: 1rem;
  }
`;
