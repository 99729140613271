"use client";
import { useState, useLayoutEffect, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  id: string;
};

export const PORTAL_ERROR_MSG =
  "There is no portal container in markup. Please add portal container with proper id attribute.";

export const Portal = ({ children, id }: PropsWithChildren<PortalProps>) => {
  const [wrapperElement, setWrapperElement] = useState<null | HTMLElement>(
    null
  );

  useLayoutEffect(() => {
    let element = document.getElementById(id);

    if (!element) {
      throw new Error(PORTAL_ERROR_MSG);
    }
    setWrapperElement(element);
  }, [id]);

  return wrapperElement ? createPortal(children, wrapperElement) : null;
};

type containerOptions = { id: string; mountNode?: HTMLElement };

export const createContainer = ({
  id,
  mountNode = document.body
}: containerOptions) => {
  if (document.getElementById(id)) {
    return;
  }

  const portalContainer = document.createElement("div");

  portalContainer.setAttribute("id", id);
  portalContainer.setAttribute("data-testid", `portalContainer-${id}`);
  mountNode.appendChild(portalContainer);
};
