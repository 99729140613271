import styled from 'styled-components';
import search from '../../../assets/styles/bg-imgs/search.svg';
import { Link } from 'react-router-dom';
import { getTransition } from '../../../Styles/utilites';

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.bgColor};
`;
export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
`;
export const Title = styled.div`
  display: none;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: block;
    color: ${({ theme }) => theme.colors.brandColor};
    font-size: 1.5rem;
    font-weight: 900;
  }
`;

export const Input = styled.input`
  background: url(${search}) no-repeat 0.4em center;
  background-size: 1.5em;
  padding: 0.5em 1.2em 0.25em 2em;
  width: 100%;
  color: ${({ theme }) => theme.colors.brandColor};
  font-weight: 600;
  border: none;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.revert};

  margin-right: 1rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.brandColor};
    font-weight: 600;
    font-size: 0.85rem;
  }
  font-size: 0.85rem;
  &:not(:placeholder-shown),
  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    border: none;
    outline: none;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    &::placeholder {
      font-size: 1rem;
    }
    margin: 0 1rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 0 2rem;
  }
`;

export const AllLink = styled(Link)`
  color: ${({ theme }) => theme.colors.brandColor};
  font-weight: 600;
  padding: 0.25em 0.5em;
  border: none;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.revert};
  white-space: nowrap;
  font-size: 0.85rem;
  svg {
    height: 14px;
    width: auto;
    margin-left: 0.3rem;
    path {
      ${getTransition(300, 'fill')}
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.darkBrand};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.darkBrand};
      }
    }
  }
  ${getTransition()}
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1rem;
  }
`;
