import { useEffect, useState } from 'react';
import { getNormalNews, getTopNews } from '../../../API/news';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';
import Loader from '../../Loader';
import ArticleTiles from './ArticleTiles';
import { Slider } from './Slider/Slider';
import * as S from './styles';
import useDebounce from '../../../hooks/debounce';
import { useCallback } from 'react';
import useIsMobile from '../../../hooks/useIsMobile';

export const Articles = () => {
  const [newsFilter, setNewsFilter] = useState('');
  const delayNewsFilter = useDebounce(newsFilter, 500);
  const isMobile = useIsMobile(768);
  //TOP
  const [isLoadingTop, setIsLoadingTop] = useState(true);
  const [allTopArticles, setAllTopArticles] = useState<any>();
  const [filteredTopArticles, setFilteredTopArticles] = useState();
  useEffect(() => {
    getTopNews().then((result) => {
      setAllTopArticles(result);
      setFilteredTopArticles(result);
      setIsLoadingTop(false);
    });
  }, []);
  useEffect(() => {
    if (delayNewsFilter)
      setFilteredTopArticles(
        allTopArticles?.filter(
          (article) =>
            article.title.toLowerCase().includes(delayNewsFilter.toLowerCase()) ||
            article.description.toLowerCase().includes(delayNewsFilter.toLowerCase())
        )
      );
    else setFilteredTopArticles(allTopArticles);
  }, [delayNewsFilter]);

  //NORMAL
  const [normarArticles, setNormarArticles] = useState<any>();
  const [isLoadingNormal, setIsLoadingNormal] = useState(true);
  useEffect(() => {
    getNormalNews(1, isMobile ? 3 : 5, newsFilter).then((result) => {
      setNormarArticles({ catalog: result?.data, last_page: result?.meta?.last_page });
      setIsLoadingNormal(false);
    });
  }, [isMobile]);

  const [nextPage, setNextPage] = useState(2);
  const getFetchData = useCallback(
    async (nPage = nextPage) => {
      getNormalNews(nPage, isMobile ? 3 : 5, delayNewsFilter)
        .then((response) => {
          console.log({ response });
          setNextPage(nPage + 1);
          setNormarArticles({
            isLoaded: true,
            meta: response.meta,
            last_page: response.meta.last_page,
            catalog: [...normarArticles.catalog, ...response.data],
            foundCount: response.meta.total,
          });
        })
        .catch((err) => {
          return err.message;
        });
    },
    [delayNewsFilter, normarArticles, isMobile]
  );

  useEffect(() => {
    setIsLoadingNormal(true);
    getNormalNews(1, isMobile ? 3 : 5, newsFilter).then((result) => {
      setNormarArticles({ catalog: result?.data, last_page: result?.meta?.last_page });
      setIsLoadingNormal(false);
    });
  }, [delayNewsFilter, isMobile]);

  return (
    <S.Section>
      <div className="container catalog">
        <S.Wrapper className="container">
          <S.Header>
            <S.Title>Статьи</S.Title>
            <S.Input value={newsFilter} onChange={(event) => setNewsFilter(event.target.value)} placeholder="Поиск" />
            <S.AllLink to="/articles">
              Смотреть все <Arrow />
            </S.AllLink>
          </S.Header>
          {isLoadingTop ? (
            <div className="p-5 w-100 d-flex justify-content-center">
              <Loader color="#146492" />
            </div>
          ) : allTopArticles.length ? (
            <div className="position-relative">
              <Slider data={filteredTopArticles} />
            </div>
          ) : null}
        </S.Wrapper>
        <ArticleTiles
          isLoading={isLoadingNormal}
          catalogData={normarArticles}
          getFetchData={getFetchData}
          nextPage={nextPage}
          view={'tiled'}
        />
      </div>
    </S.Section>
  );
};
