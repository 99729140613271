import { useSelector } from 'react-redux';
import { ReactComponent as PartnerIcon } from '../../../assets/icons/profile/parther.svg';
import ava from '../../../assets/imgs/ava.png';
import { RootState } from '../../../store/store';
import { RatingStar } from '../../RatingStar/RatingStar';
import * as S from './styles';

type MiniProfileProps = {};
export const MiniProfile = ({}: MiniProfileProps) => {
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));
  return (
    <S.Wrapper className={'container'}>
      <S.Profile>
        <S.User to="/personal-account/profile">
          <span style={{position:'relative'}}>
            <img
              src={
                !!currentUser && currentUser.avatar
                  ? `${process.env.REACT_APP_PHOTO_URL}/uploads/${currentUser.avatar}`
                  : ava
              }
              alt="Аватар"
            />
            {currentUser?.isPartner ? (
              <PartnerIcon
                style={{
                  width: '35%',
                  height: '35%',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translate(0%, 0%)',
                }}
              />
            ) : null}
          </span>

          <div style={{marginLeft:'0.75rem'}}>{currentUser ? currentUser.firstName + ' ' + currentUser.lastName : 'Личный кабинет'}</div>
        </S.User>
        <RatingStar rating={!!currentUser ? currentUser.rating : '0'} />
      </S.Profile>
    </S.Wrapper>
  );
};
