import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { baseTheme } from './Styles/theme';

ReactDOM.render(
  <Provider store={store}>
    <StyleSheetManager enableVendorPrefixes={true}>
      <ThemeProvider theme={baseTheme}>
        <App />
      </ThemeProvider>
    </StyleSheetManager>
  </Provider>,
  document.getElementById('root')
);
