import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../store/actions/alert';

export default function ShowPhone(props) {
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const [visible, setVisibility] = useState(false);
  return (
    <div className={'show-phone ' + props.className}>
      <div className="phone fw-5">
        <a href={'tel:' + props.phone}>{props?.phone?.length === 11 ? `+${props?.phone}` : 'отсутствует'}</a>
      </div>

      <button
        type="button"
        onClick={() => {
          setVisibility(true);
          // if (props.userId !== 'notExist') {
          //   setVisibility(true);
          // } else {
          //   setAlert('danger', true, 'Функция доступна авторизованным пользователям');
          // }
        }}
        className={visible ? 'invisible btn btn-1 px-1' : 'btn btn-1 px-1'}
        ref={(node) => {
          if (node) {
            node.style.setProperty('background', '#D0E0E9', 'important');
            node.style.setProperty('color', '#146492', 'important');
          }
        }}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: '10px' }}
        >
          <path
            d="M17.9895 13.5971C17.829 14.8167 17.2301 15.9361 16.3046 16.7463C15.3791 17.5566 14.1903 18.0022 12.9602 18C5.81411 18 8.14392e-06 12.1859 8.14392e-06 5.03987C-0.00219688 3.80982 0.443434 2.62102 1.25367 1.69552C2.06391 0.770017 3.18334 0.171096 4.40288 0.010618C4.71128 -0.0270376 5.02358 0.0360539 5.29317 0.190474C5.56276 0.344895 5.77517 0.582362 5.89871 0.867426L7.79954 5.11097V5.12177C7.89412 5.33998 7.93318 5.57822 7.91324 5.8152C7.89329 6.05219 7.81496 6.28055 7.68524 6.47988C7.66904 6.50418 7.65194 6.52668 7.63394 6.54918L5.76011 8.7704C6.43422 10.1402 7.86704 11.5604 9.25487 12.2363L11.4455 10.3724C11.467 10.3543 11.4896 10.3375 11.513 10.322C11.7122 10.1892 11.9413 10.1081 12.1797 10.0861C12.4181 10.0641 12.6582 10.1019 12.8783 10.196L12.89 10.2014L17.13 12.1013C17.4156 12.2244 17.6536 12.4367 17.8086 12.7063C17.9635 12.9759 18.027 13.2884 17.9895 13.5971Z"
            fill="#146492"
          />
        </svg>
        {/* Показать телефон */}
        +7(XXX)-XX-XX
      </button>
    </div>
  );
}
