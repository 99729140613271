import { ITheme } from '../interfaces/styled';

export const baseTheme: ITheme = {
  colors: {
    brandColor: 'hsl(202,78%,45%)', //#198ACB
    brandColorValue: '202,78%,45%',
    darkBrand: 'hsl(202, 76%, 38%)', //#146492
    secondary: 'hsl(25,38%,63%)', //#C59C7E
    secondaryValue: '25,38%,63%',
    lightBlue: 'hsl(202,36%,86%)', //#D0E0E9
    lightBlueValue: '202,36%,86%',

    red: 'hsl(351,100%,54%)', //#FF1337
    brown: 'hsl(15,35%,23%)', //#4F3026
    brightOrange: 'hsl(17,99%,55%)', //#FE5819
    orange: 'hsl(29,88%,56%)', //#F28C2C
    orangeValue: '29,88%,56%',
    yellow: 'hsl(43,91%,57%)', //#F5BC2D
    yellowValue: '43,91%,57%',
    gems: 'hsl(84,100%,71%)', //#C4FF6C
    border: 'hsl(162,100%,50%)', //#00FFB2
    greenGrad: 'hsl(119,99%,35%)', //#04B401
    fillBtn: 'hsl(183,61%,26%)', //#1A696D
    tgColor: 'hsl(209,72%,62%)', //#57A0E4

    grey33: 'hsl(0, 0%, 33%)', //#545454
    grey33Value: '0, 0%, 33%',
    grey45: 'hsl(0,0%,45%)', //#737373
    grey58: 'hsl(0,0%,58%)', //#959595
    grey85: 'hsl(0,0%,85%)', //#D9D9D9
    bgLayout: 'hsl(225,25%,9%)', //#1E222F новый - #12151E
    bgLayoutValue: '225,25%,9%', //#1E222F
    bgColor: 'hsl(0,0%,95%)', //#F3F3F3
    font: 'hsl(0, 0%, 2%)',
    fontValue: '0, 0%, 2%',
    revert: 'hsl(0, 0%, 100%)',
    revertValue: '0, 0%, 100%',
  },
  media: {
    xxxl: '(min-width: 1660px)',
    xxl: '(min-width: 1400px)',
    xl: '(min-width: 1200px)',
    lg: '(min-width: 992px)',
    md: '(min-width: 768px)',
    sm: '(min-width: 576px)',
    xs: '(min-width: 420px)',
    minAnimate: '(prefers-reduced-motion: reduce)',
  },
  durations: {},
  order: {
    minus: -1,
    one: 1,
    two: 2,
    three: 3,
    fixed: 20,
    height: 1000,
    action: 1100,
    top: 2000,
  },
  opacity: {
    main: 0.66,
  },
};
