import fingerprint from '@fingerprintjs/fingerprintjs';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { YMaps } from 'react-yandex-maps';
import { bindActionCreators } from 'redux';
import 'swiper/css';
import apiRoutes from './API/config/apiRoutes';
import { setSocketConnection } from './API/socketInstance';
import './assets/fonts/fonts.css';
import './assets/styles/style.css';
import WelcomeScreen from './components/WelcomeScreen';
import env from './config/env';
import useInitialAuth from './hooks/initialAuth';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import useSelectedCity from './hooks/useSelectedCity';
import { useTimeout } from './hooks/useTimeout';
import AppRouter from './routes/AppRouter';
import accessTokenActions from './store/actions/accessToken';
import currentUserActions from './store/actions/currentUser';
import favoriteCount from './store/actions/favoriteCount';
import { RootState } from './store/store';

function App() {
  const isLoading = useInitialAuth();
  const dispatch = useDispatch();
  const { resetToken } = bindActionCreators(accessTokenActions, dispatch);
  const { resetCurrentUser } = bindActionCreators(currentUserActions, dispatch);
  const { setFavoriteCount } = bindActionCreators(favoriteCount, dispatch);

  const axiosPrivate = useAxiosPrivate();

  const [visitor, setVisitor] = useState('');
  const user = useSelector((state: RootState) => state.currentUser);
  const [show, setShow] = useState(true);
  const [set] = useTimeout(() => setShow(false), 1600);
  useSelectedCity();

  const handleLogout = async () => {
    const response = await axiosPrivate?.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.LOGOUT}`);
    if (response && response?.status === 200 && localStorage.getItem('fingerprint')) {
      resetToken();
      resetCurrentUser();
      setFavoriteCount({ adsCount: 0, servicesCount: 0 });
    }
  };

  const onUnloadHandler = () => {
    const isNotRemember = localStorage.getItem('isNotRemember');
    if (isNotRemember === 'true') {
      handleLogout();
      localStorage.removeItem('isNotRemember');
    }
  };

  useEffect(() => {
    user && user?.id && setSocketConnection(user?.id || 0);
  }, [user]);

  useEffect(() => {
    fingerprint
      .load()
      .then((fp) => fp.get())
      .then((result) => {
        setVisitor(result.visitorId);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('fingerprint', visitor);
  }, [visitor]);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnloadHandler);
  }, []);

  useEffect(() => {
    if (!isLoading) set();
  }, [isLoading]);

  if (isLoading) return <></>;
  return (
    <>
      {show && <WelcomeScreen />}
      <BrowserRouter>
        <YMaps query={{ apikey: env.YMAPS_TOKEN, load: 'package.full' }}>
          <AppRouter />
        </YMaps>
      </BrowserRouter>
    </>
  );
}

export default App;
