import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GeolocationControl, Map, ObjectManager, ZoomControl } from 'react-yandex-maps';
import { useSelector } from 'react-redux';

const calcOffset = (zoom) => {
  if (zoom === 21) return [200, 400];
  if (zoom === 20) return [100, 200];
  if (zoom === 19) return [30, 60];
  if (zoom === 18) return [0, 10];
  if (zoom === 17) return [-14, -20];
  if (zoom === 16) return [-22, -32];
  if (zoom === 15) return [-26, -40];
  if (zoom === 14) return [-28, -44];
  if (zoom === 13) return [-30, -46];
  if (zoom === 12) return [-31, -47];
  return [-31, -47];
};
const YMap = (props) => {
  const [ymaps, setYmaps] = useState(null);
  const mapRef = useRef();
  const [objectManager, setObjectManager] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const mapCenter = useSelector((state) => state.mapCenter);
  const { items, favId, setFavs } = props;

  useEffect(() => {
    objectManager && objectManager.removeAll() && initObjectManager();
  }, [items, objectManager]);

  useEffect(() => {
    if (props.callback && selectedCluster) {
      props.callback(selectedCluster.features?.map((item) => item.properties?.data?.id));
    }
  }, [selectedCluster]);

  useEffect(() => {
    if (props.callback && selectedPoint) {
      props.callback([selectedPoint.properties?.data?.id]);
    }
  }, [selectedPoint]);

  useEffect(() => {
    if (!favId) return;
    onLikeObject(favId);
    setFavs();
  }, [favId]);

  const onClusterEvent = (e) => {
    // initial reset
    setSelectedCluster(null);

    const objectId = e.get('objectId');
    const object = objectManager.clusters.getById(objectId);

    setSelectedCluster(object);
  };

  const onObjectEvent = (e) => {
    setSelectedPoint(null);

    const objectId = e.get('objectId');
    const object = objectManager.objects.getById(objectId);

    // Если событие произошло на метке
    if (object.geometry.type === 'Point') {
      setSelectedPoint(object);
    }
  };

  const setCluster = (cluster, offsets) => {
    const count = cluster.properties.geoObjects.length;
    const minPrice = cluster.properties.geoObjects
      .map(({ properties }) => properties.data.price)
      .reduce((last, curr) => (last > curr ? curr : last), 999999999999);
    const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
      `<div style="font-size: 0.65rem; color: #fff; font-weight: bold; margin-top: 1.2rem;">${count} от ${minPrice < 1_000_000 ? (minPrice / 1_000).toFixed(1) + ' тыс' : (minPrice / 1_000_000).toFixed(1) + ' млн'
      }</div>`
    );

    console.log(cluster.properties.geoObjects);
    const options = cluster.properties.geoObjects.filter(({ properties }) => properties.data.wishlistStatus).length
      ? {
        clusterIconContentLayout: MyIconContentLayout,
        clusterIcons: [
          {
            href: '/img/icons/Ymaps/cluster-price-wishlist.svg',
            size: [100, 60],
            offset: offsets,
          },
        ],
      }
      : cluster.properties.geoObjects.filter(({ properties }) => properties.data.isBlackMarker).length ?
        {
          clusterIconContentLayout: MyIconContentLayout,
          clusterIcons: [
            {
              href: '/img/icons/Ymaps/cluster-blackMarker.svg',
              // href: '/img/icons/Ymaps/cluster-price.svg',
              size: [100, 60],
              offset: offsets,
            },
          ],
        } :
        {
          clusterIconContentLayout: MyIconContentLayout,
          clusterIcons: [
            {
              href: '/img/icons/Ymaps/cluster-price.svg',
              // href: '/img/icons/Ymaps/cluster-price.svg',
              size: [100, 60],
              offset: offsets,
            },
          ],
        };
    objectManager.clusters.setClusterOptions(cluster.id, {
      ...options,
    });
  };

  const getZoom = () => {
    if (!mapRef.current) return;
    return mapRef.current.getZoom();
  };

  const getOffsets = () => {
    if (!mapRef.current) return;
    return calcOffset(getZoom());
  };
  const onAddCluster = (e) => {
    const cluster = objectManager.clusters.getById(e.get('objectId'));
    setCluster(cluster, getOffsets());
  };

  const setClusterize = () => {
    const clusterZoom = 10;
    if (getZoom() < clusterZoom && objectManager.options._options.clusterize) {
      objectManager.options.set({
        gridSize: 1,
        clusterize: getZoom() > clusterZoom - 1,
        clusterDisableClickZoom: true,
      });
    } else if (getZoom() > clusterZoom - 1 && !objectManager.options._options.clusterize) {
      objectManager.options.set({
        gridSize: 1,
        clusterize: true,
        clusterDisableClickZoom: true,
      });
    }
  };

  const setPointIcon = (object) => {
    const blackMarker = object.properties.data.isBlackMarker;
    const wishlistStatus = object.properties.data.wishlistStatus;
    if (wishlistStatus) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/point-wish.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/point-wish.svg',
        iconImageSize: [18, 18],
        iconImageOffset: [-9, -9],
      });
    } if (blackMarker) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/blackMarker.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/blackMarker.svg',
        iconImageSize: [18, 18],
        iconImageOffset: [-9, -9],
      });
    }
    else {
      if (object.options.iconImageHref === '/img/icons/Ymaps/point.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/point.svg',
        iconImageSize: [18, 18],
        iconImageOffset: [-9, -9],
      });
    }
  };
  const setPlacemarkIcon = (object) => {
    const wishlistStatus = object.properties.data.wishlistStatus;
    const blackMarker = object.properties.data.isBlackMarker;
    const isHot = object.properties.data.isHot;
    const isVip = object.properties.data.isVip;
    if (wishlistStatus) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/wishlist-placemark.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/wishlist-placemark.svg',
        iconImageSize: [36, 55],
        iconImageOffset: [-5, -38],
      });
    } else if (blackMarker) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/blackMarker.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/blackMarker.svg',
        iconImageSize: [36, 55],
        iconImageOffset: [-5, -38],
      });
    } else if (isHot) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/hot-placemark.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/hot-placemark.svg',
        iconImageSize: [43, 60],
        iconImageOffset: [-5, -38],
      });
    } else if (isVip) {
      if (object.options.iconImageHref === '/img/icons/Ymaps/vip-placemark.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/vip-placemark.svg',
        iconImageSize: [46, 47],
        iconImageOffset: [-5, -38],
      });
    } else {
      if (object.options.iconImageHref === '/img/icons/Ymaps/default-placemark.svg') {
        return;
      }
      objectManager.objects.setObjectOptions(object.id, {
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/Ymaps/default-placemark.svg',
        iconImageSize: [36, 55],
        iconImageOffset: [-5, -38],
      });
    }
  };

  const setObjectsIcon = (isSolo) => {
    objectManager.objects.each((object) => {
      if (getZoom() > 14 || isSolo) setPlacemarkIcon(object);
      else setPointIcon(object);
    });
  };

  const onLikeObject = (id) => {
    const object = objectManager.objects.getById(id);
    const wishlistStatus = object.properties.data.wishlistStatus;
    const blackMarker = object.properties.data.isBlackMarker;
    const isHot = object.properties.data.isHot;
    const isVip = object.properties.data.isVip;
    if (items.length === 1 || getZoom() > 14) {
      if (!wishlistStatus) {
        if (object.options.iconImageHref !== '/img/icons/Ymaps/wishlist-placemark.svg') {
          return;
        }
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/wishlist-placemark.svg',
          iconImageSize: [36, 55],
          iconImageOffset: [-5, -38],
        });
      } if (blackMarker) {
        if (object.options.iconImageHref !== '/img/icons/Ymaps/blackMarker.svg') {
          return;
        }
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/blackMarker.svg',
          iconImageSize: [36, 55],
          iconImageOffset: [-5, -38],
        });
      } else if (isHot) {
        if (object.options.iconImageHref !== '/img/icons/Ymaps/hot-placemark.svg') {
          return;
        }
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/hot-placemark.svg',
          iconImageSize: [43, 60],
          iconImageOffset: [-5, -38],
        });
      } else if (isVip) {
        if (object.options.iconImageHref !== '/img/icons/Ymaps/vip-placemark.svg') {
          return;
        }
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/vip-placemark.svg',
          iconImageSize: [46, 47],
          iconImageOffset: [-5, -38],
        });
      } else {
        if (object.options.iconImageHref !== '/img/icons/Ymaps/default-placemark.svg') {
          return;
        }
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/default-placemark.svg',
          iconImageSize: [36, 55],
          iconImageOffset: [-5, -38],
        });
      }
    } else {
      if (!wishlistStatus) {
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/point-wish.svg',
          iconImageSize: [18, 18],
          iconImageOffset: [-9, -9],
        });
      } else {
        objectManager.objects.setObjectOptions(object.id, {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/point.svg',
          iconImageSize: [18, 18],
          iconImageOffset: [-9, -9],
        });
      }
    }
  };

  const feature = useMemo(() => {
    if (!items || !items.length) return;
    return items.map((item) => ({
      type: 'Feature',
      id: item.id,
      geometry: {
        type: 'Point',
        coordinates: [+item.latitude, +item.longitude],
      },
      properties: {
        data: {
          id: item.id,
          isHot: item.isHot,
          isVip: item.isVip,
          wishlistStatus: item.wishlistStatus,
          isBlackMarker: item.isBlackMarker,
          price: item.price,
        },
      },
    }));
  }, [items]);
  const features = useMemo(() => {
    if (!feature) return [];
    return feature.map((item) => {
      const wishlistStatus = item.properties.data.wishlistStatus;
      const blackMarker = item.properties.data.isBlackMarker;

      if (wishlistStatus) {
        return {
          ...item,
          options: {
            iconLayout: 'default#image',
            iconImageHref: '/img/icons/Ymaps/point-wish.svg',
            iconImageSize: [18, 18],
            iconImageOffset: [-9, -9],
          },
        };
      }
      if (blackMarker) {
        return {
          ...item,
          options: {
            iconLayout: 'default#image',
            iconImageHref: '/img/icons/Ymaps/blackMarker.svg',
            iconImageSize: [18, 18],
            iconImageOffset: [-9, -9],
          },
        };
      }
      return {
        ...item,
        options: {
          iconLayout: 'default#image',
          iconImageHref: '/img/icons/Ymaps/point.svg',
          iconImageSize: [18, 18],
          iconImageOffset: [-9, -9],
        },
      };
    });
  }, [feature]);

  const initObjectManager = () => {
    if (!ymaps || !features.length) return;
    setClusterize();
    objectManager.add(JSON.stringify(features));
    objectManager.clusters.each(function (cluster) {
      setCluster(cluster, getOffsets());
    });

    //предотвращает ререндер иконок объектов при смене стейта
    setObjectsIcon(features.length === 1);

    //objectManager.objects.events.add('add', onAddObject);
    objectManager.clusters.events.add('add', onAddCluster);

    // Назначаем обработчик событий на коллекцию кластеров менеджера.
    objectManager.clusters.events.add('click', onClusterEvent);

    // Назначаем обработчик событий на коллекцию объектов менеджера.
    objectManager.objects.events.add(['click'], onObjectEvent);

    if (mapRef.current)
      mapRef.current.events.add('boundschange', () => {
        setObjectsIcon(features.length === 1);
        setClusterize();
      });
  };

  const mapCoordinates = () => {
    if (items?.length === 1) {
      return [items[0]?.latitude, items[0]?.longitude];
    } else {
      return mapCenter;
    }
  };

  return (
    <div className={`y-maps-container ${props.className || ''}`}>
      <Map
        className="y-maps"
        state={{
          center: mapCoordinates() || [0, 0],
          zoom: 10,
          controls: [],
        }}
        modules={['geolocation', 'geocode']}
        onLoad={setYmaps}
        instanceRef={(ref) => {
          if (ref) mapRef.current = ref;
        }}
        style={props.isAdList && {
          "border-radius": 0
        }}
      >
        <ZoomControl
          options={{
            size: 'small',
            position: {
              top: props.isAdList ? 60 : '28px',
              right: '20px',
            },
          }}
        />
        <GeolocationControl
          options={{
            position: {
              top: props.isAdList ? 134 : '94px',
              right: '20px',
            },
          }}
        />
        <ObjectManager
          options={{
            gridSize: 1,
            //   clusterize: true,
            clusterDisableClickZoom: true,
          }}
          objects={{
            openBalloonOnClick: false,
            preset: 'islands#greenDotIcon',
            iconLayout: 'default#image',
            iconImageHref: '/img/icons/Ymaps/point.svg',
            iconImageSize: [18, 18],
            iconImageOffset: [-9, -9],
          }}
          clusters={{
            groupByCoordinates: false,
            openBalloonOnClick: false,
            clusterDisableClickZoom: true,

            clusterIcons: [
              {
                href: '/img/icons/Ymaps/cluster-blackMarker.svg',
                // href: '/img/icons/Ymaps/cluster-price.svg',
                size: [100, 60],
                offset: [-30, -62],
              },
            ],
          }}
          instanceRef={(ref) => setObjectManager(ref)}
        />
      </Map>
    </div>
  );
};

export default YMap;
