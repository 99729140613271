import * as S from './styles';
import { MutableRefObject, PropsWithChildren, ReactElement, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { useToggle } from '../../hooks/useToggle';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useTimeout } from '../../hooks/useTimeout';
import { Arrow } from './styles';

type PopoverProps = {
  tooltip?: string | ReactElement;
  title?: string;
  widthInRem?: number;
  direction?: Placement;
  action?: 'click' | 'hover';
};

export const Popover = ({
  title,
  tooltip,
  widthInRem,
  children,
  direction = 'auto',
  action = 'hover',
  toogleZidx,
  isLast
}: PropsWithChildren<PopoverProps>) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<null | HTMLElement>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: direction,
    strategy: 'absolute',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'preventOverflow',
        enabled: false,
      },
      {
        name: 'hide',
        enabled: false,
      },
      {
        name: 'offset',
        options: {
          offset: direction.startsWith('left') || direction.startsWith('right') ? [isLast ? -50 : 0, 12] : [6, 0],
        },
      },
    ],
    // TODO разобраться в документации, как лучше сделать динамический отступ от элемента
    // modifiers: [
    //   {
    //     name: "offset",
    //     options: {
    //       offset:
    //         direction.startsWith("left") || direction.startsWith("right")
    //           ? [0, 6]
    //           : [6, 0]
    //     }
    //   }
    // ]
  });
  const [entered, setEntered] = useToggle();
  useClickOutside(() => {toogleZidx(false);setEntered(false)()}, ref);
  const [setTimer, clearTimer] = useTimeout(setEntered(true), 500);


  return (
    <div ref={ref}>
      <S.Wrapper
        onMouseEnter={action === 'hover' ? setTimer : () => {}}
        onMouseLeave={
          action === 'hover'
            ? () => {
                clearTimer();
                if (entered) {
                  setEntered(false)();
                }
              }
            : () => {}
        }
        onClick={
          action === 'hover'
            ? () => {}
            : () => {
                setEntered()();
                toogleZidx()
              }
        }
        ref={setReferenceElement}
      >
        {children}
        {entered && (
          <S.Tooltip
            $widthInRem={widthInRem}
            ref={setPopperElement}
            style={styles.popper}
            $direction={direction}
            {...attributes.popper}
            role={'tooltip'}
          >
            <S.Arrow ref={setArrowElement} style={styles.arrow} data-placement={direction} />
            {title && <S.Heading>{title}</S.Heading>}
            {typeof tooltip === 'string' && <S.Paragraph>{tooltip}</S.Paragraph>}
            {!!tooltip && typeof tooltip !== 'string' && <>{tooltip}</>}
          </S.Tooltip>
        )}
      </S.Wrapper>
    </div>
  );
};
