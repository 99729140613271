import styled, { css } from 'styled-components';
import linkImage from './assets/link.svg';
import fullwidth from './assets/fullwidth.svg';
export const StyledCardWrapper = styled.div`
  max-height: 620px;
  height: 620px;
  width: 100%;
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  //margin-left: calc(-.5* var(--bs-gutter-x));
  @media screen and ${({ theme }) => theme.media.sm} {
    max-height: 650px;
    //height: 650px;
    gap: 10px;
    margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    max-height: 650px;
    height: 650px;
    margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    max-height: 650px;
    height: 650px;
    margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 100%;
    max-height: 680px;
    height: 680px;
    position: relative;
    background-color: #fff;
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
    border-radius: 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    // max-height: 550px;
  }
`;

export const StyledWrapper = styled.div`
  width: 398px;
  height: 270px;
  display: flex;
  flex-direction: column;
`;
export const StyledWrapperImg = styled.div`
  width: 396px;
  height: 89px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 785px) {
    img {
      width: 100px;
    }
  }
`;

export const ImgPreviewWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 785px) {
    gap: 5px;
  }
`;

export const TextUpper = styled.div`
  position: absolute;
  color: #fff;
  margin-top: -50px;
  margin-left: 35px;
  font-size: 0.8rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 7px;
    margin-top: -50px;
    margin-left: 7px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 8px;
    margin-top: -50px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 0.8em;
    margin-top: -52px;
    margin-left: 15px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 0.65em;
    margin-top: -52px;
    margin-left: 15px;
  }
  @media screen and ${({ theme }) => theme.media.xxxl} {
    position: absolute;
    font-size: 14px;
    margin-top: -52px;
    margin-left: 15px;
    color: #fff;
  }
`;

export const BlackSq = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 89px;
  width: 110px;
  margin-top: -98px;
  border-radius: 10px;
  @media screen and (max-width: 785px) {
    width: 100px;
    height: 55px;
    margin-top: -55px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.xl} {
    display: flex;
    flex-direction: row;
  }
`;

export const UnderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

export const CardNamer = styled.p`
  font-size: 1.6em;
  display: flex;
  align-items: flex-start;
  color: #198acb;
  font-family: Montserrat;
  font-weight: bold;
  margin-top: 10px;
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: 0px;
    display: flex;
    align-items: flex-start;
    font-size: 1.8em;
    color: #198acb;
    font-family: Montserrat;
    font-weight: bold;
  }
`;

export const CardServ = styled.p`
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 10px;
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    align-items: flex-start;
    font-size: 24px;
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 10px;
  }
`;
export const CardServ2 = styled.p`
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: -20px;
  padding-bottom: 10px;
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    align-items: flex-start;
    font-size: 24px;
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    margin-top: -20px;
    padding-bottom: 10px;
  }
`;
// export const DescriptUnder = styled.span`
//   display: inline-block;
//   font-family: Montserrat;
//   font-weight: 600;
//   color: #6f6f6f;
//   max-width: 92%;
//   height: 74px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-size: 0.9rem;
//   //white-space: nowrap;
//   margin-bottom: 5px;
//   @media screen and ${({ theme }) => theme.media.xxl} {
//     font-size: 16px;
//     display: inline-block;
//     font-family: Montserrat;
//     font-weight: 600;
//     color: #6f6f6f;
//     max-width: 574px;
//     height: 78px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     //white-space: nowrap;
//   }
// `;
interface DescriptUnderProps {
  isExpanded: boolean;
  contentHeight?: number; 
}
export const DescriptUnder = styled.span<DescriptUnderProps>`
  display: block;
  font-family: Montserrat;
  font-weight: 600;
  color: #6f6f6f;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  max-width: 92%;
  height: ${({ isExpanded, contentHeight }) => isExpanded ? `${contentHeight}px` : '49px'};
  @media screen and ${({ theme }) => theme.media.md} {
    height: ${({ isExpanded, contentHeight }) => isExpanded ? `${contentHeight}px` : '60px'};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    
    max-width: 574px;
    font-size: 16px;
    display: inline-block;
    font-family: Montserrat;
    font-weight: 600;
    color: #6f6f6f;
    max-width: 574px;
  }
`;

export const ReadMoreButton = styled.button`
  display: flex;
  border-radius: 15px;
  background: #198ACB;
  color: #fff;
  align-self: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 0.9em;
  padding: 3px 8px 3px 8px;
  border: 1px solid #198ACB;
  transition: color 300ms, background 300ms;
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1.2em;
    padding: 5px 10px 5px 10px;
    border: 1px solid #198ACB;
  }
  &:hover {
    background: #fff;
    color: #198ACB;
  }
`;
export const OrangeGroup = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: 50px;
`;
export const Eye = styled.div`
  margin-right: 0.25rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 24px;
    height: 17px;
  }
  svg {
    width: 24px;
    height: 17px;
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    margin-right: 0.45rem;
  }
`;

export const EditButton = styled.div`
    width: 140px;
    height: 25px;
    border-radius: 50px;
    background: #198ACB;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    @media screen and ${({ theme }) => theme.media.md} {
        width: 200px;
        height: 31px;
        font-size: 20px;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        width: 260px;
        height: 41px;
        border-radius: 50px;
        background: #198ACB;
        align-items: center;
        display: flex;
        justify-content: center;
        align-content: center;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 20px;
    }
}
`;

export const RemoveButton = styled.button`
  width: 140px;
  height: 25px;
  border-radius: 50px;
  border: 1px solid #198acb;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #198acb;
  font-family: Montserrat;
  font-size: 12px;
  align-items: center;
  background: transparent;
  transition: 0.3s;
  &:hover {
    border: 1px solid #fe6060;
    background: #fe6060;
    color: #fff;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 200px;
    height: 31px;
    font-size: 20px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 260px;
    height: 41px;
    border-radius: 50px;
    border: 1px solid #198acb;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #198acb;
    font-family: Montserrat;
    font-size: 20px;
    align-items: center;
    background: transparent;
  }
`;

export const ServShield = styled.div`
  background: #d0e0e9;
  color: #165d85;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 8px 3px 8px;
  border-radius: 20px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  @media screen and ${({ theme }) => theme.media.xxl} {
    background: #d0e0e9;
    color: #165d85;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    padding: 3px 10px 3px 10px;
  }
`;

export const TextUnderShield = styled.span`
  color: #6f6f6f;
  font-family: Montserrat;
  font-style: normal;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  margin-right: 0.35rem;
  margin-bottom: 0.5rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    color: #6f6f6f;
    font-family: Montserrat;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%;
    margin-right: 0.35rem;
    margin-bottom: 0.5rem;
  }
`;

export const ShieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // align-self: flex-start;
  //justify-items: start;
  justify-items: start;
  align-items: start;
  justify-content: start;
  align-content: normal;
  @media screen and ${({ theme }) => theme.media.xl} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const GridCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NormalizedCol = styled.div`
  flex: 1 0;
  @media screen and ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 100%;
  }
`;

export const ImgResizer = styled.div`
  margin-top: 10px;
  width: 100%;
  object-fit: cover;
  img {
    width: 100%;
    height: 89px;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  //padding: 10px 0;

  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;
export const WrapperRow2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    flex-direction: row;
    width: 100%;
    //height: 100%;
  }
`;
export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 1em 0;
`;
export const ColOne = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    flex-direction: column;
    width: 33%;
  }
`;
export const ColTwo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  padding-left: 10px;
  // margin-top: 70px;
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    flex-direction: column;
    width: 67%;
    height: 100%;
    padding-left: 0px;
    margin-left: 22px;
    // margin-top: 70px;
  }
`;
export const WrapperCols = styled.div`
  // display: flex;
  // flex-direction: row;
  width: 100%;
  // padding-left: 20px;
  // padding-right: 20px;
`;

export const CustomRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  img {
    width: 56px;
    height: 54px;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      width: 44px;
      height: 43px;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      width: 56px;
      height: 54px;
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    img {
      width: 86px;
      height: 84px;
    }
  }
`;
export const CustomRatingSpan = styled.span`
  position: absolute;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 36px;
  }
`;

export const AuthorSkeletonBetweemFav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
`;
export const AuthorSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-left: 0px;
    display: flex;
    flex-direction: row;
  }
`;
export const ImgHolder = styled.div`
  width: fit-content;
  margin-top: 1.5rem !important;
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
  border-radius: 50%;
  object-fit: cover;
  img {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    img {
      width: 105px;
      height: 105px;
      border-radius: 50%;
    }
  }
`;
export const ImgHolder2 = styled.div`
  width: fit-content;
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
  border-radius: 50%;
  object-fit: cover;
  img {
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    img {
      width: 105px;
      height: 105px;
      border-radius: 50%;
    }
  }
`;
export const AuthorSkeletonCunstruct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
`;
export const NameText = styled.div`
  margin-top: 0.5rem !important;
  font-family: Montserrat;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
`;
export const NameTextFav = styled.div`
  font-size: 1.2em;
  font-family: Montserrat;
  color: #000000;
  font-weight: 700;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 0.9em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 1.3em;
  }
`;

export const FavCardTitle = styled.div`
  font-size: 1.5em;
  font-weight: 800;
  color: #146492;
  font-family: Montserrat;
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1.3em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 1.5em;
  }
`;
export const DataRegistrationText = styled.div`
  margin-top: 0.5rem !important;
  font-family: Montserrat;
  color: #7da3b9;
  font-size: 22px;
  font-weight: 400;
`;
export const DataRegistrationTextFav = styled.div`
  font-size: 1.2em;
  font-family: Montserrat;
  color: #7da3b9;
  font-weight: 400;
  margin-top: 10px;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 0.9em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 1em;
  }
`;
export const CardTilteColorizer = styled.div`
  color: #198acb;
`;

export const OrangeTextD = styled.div`
  color: #c59c7e;
  font-size: 1em;
  font-weight: 500;
  @media screen and ${({ theme }) => theme.media.xl} {
    color: #c59c7e;
    font-size: 1.4em;
    font-weight: 500;
  }
`;
export const OrangeTextS = styled.span`
  color: #c59c7e;
  font-size: 1em;
  font-weight: 500;
  @media screen and ${({ theme }) => theme.media.xl} {
    color: #c59c7e;
    font-size: 1.4em;
    font-weight: 500;
  }
`;

export const SidePanelWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const SidePanelWrapper2 = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.sm} {
    bottom: 7rem;
  }
`;

type SideEltProps = {
  $isRating?: boolean;
};
export const SideElt = styled.div<SideEltProps>`
  margin-bottom: 0.5rem;
  svg {
    width: ${({ $isRating }) => ($isRating ? 26 : 18)}px;
    height: auto;
  }
  ${({ $isRating }) =>
    !$isRating &&
    css`
      cursor: pointer;
      svg {
        circle,
        path {
          fill: ${({ theme }) => theme.colors.brandColor};
        }
      }
    `}
  @media screen and ${({ theme }) => theme.media.xs} {
    margin-bottom: 0.75rem;
    svg {
      width: ${({ $isRating }) => ($isRating ? 32 : 22)}px;
    }
  }
`;

export const CopyLinkButton2 = styled.button`
  z-index: 110;
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url(${linkImage});
  background-size: cover;
  background-position: center;

  @media screen and ${({ theme }) => theme.media.xl} {
    width: 40px;
    height: 40px;
  }
`;
export const ItemContainerTotal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: auto;
  justify-content: center;
`;
export const FullScreenImgButt = styled.button`
  //position: absolute;
  z-index: 120;
  display: flex;
  //left: 87%;
  //top: 1%;
  //margin-top: 20px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  //background-color: #FFFFFF;
  background-image: url(${fullwidth});
  background-size: cover;
  // background-size: 24px 24px;
  // background-repeat: no-repeat;
  background-position: center;
  @media screen and ${({ theme }) => theme.media.sm} {
    //left: 87%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    //left: 87%;
    width: 33px;
    height: 33px;
  }
  @media screen and ${({ theme }) => theme.media.xxxl} {
    //left: 85%;
    width: 33px;
    height: 33px;
  }
`;
export const CustomButtons2 = styled.div`
  z-index: 110;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 10px;
  padding: 0 10px;
`;
export const ScrollWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  max-width: 100vw;
  overflow-x: scroll;
  padding: 0 0.75rem 0px;
  gap: 20px;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0.5rem 0.75rem 0px;
  }
`
