import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getQuestion } from '../../API/question';
import { conversationListeners } from '../../API/socketConversations';
import { socketInstance } from '../../API/socketInstance';
import useSocket from '../../hooks/socket';
import alertActions from '../../store/actions/alert';
import conversationCountActions from '../../store/actions/conversationsCount';
import { CallRieltorModal } from '../CallRieltorModal';
import CustomModal from '../CustomModal';
import { HeaderComponent } from './HeaderComponent/HeaderComponent';

const Header = () => {
  const { isConnected } = useSocket();

  const [isShowMenu, setIsShowMenu] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const { setConversationCount } = bindActionCreators(conversationCountActions, dispatch);
  const initialData = {
    name: '',
    email: '',
    question: '',
  };
  const [data, setData] = useState({ ...initialData });
  const [isShowQuestionModal, setIsShowQuestionModal] = useState(false);
  const fields = {
    isInValidName: false,
    isInValidEmail: false,
    isInValidQuestions: false,
  };

  const [valid, setValid] = useState(fields);
  const [showRieltorModal, setIsShowRieltorModal] = useState(false);

  const mailSample = Object.values(data).find((i) => i?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isInValidName = data?.name === undefined || data.name?.length < 1 || data.name?.length > 55;
    const isInValidEmail = data?.email === undefined || mailSample === undefined;
    const { question } = data;
    const isInValidQuestions = question?.length === undefined || question?.length < 5 || question?.length > 1024;

    if (isInValidName) {
      setValid({ ...valid, isInValidName: true });
    } else if (isInValidEmail) {
      setValid({ ...valid, isInValidEmail: true });
    } else if (isInValidQuestions) {
      setValid({ ...valid, isInValidQuestions: true });
    } else {
      const formData = new FormData();
      const req = { ...data };
      for (let key in req) {
        formData.append(key, req[key as keyof typeof req]);
      }
      getQuestion(formData)
        .then(() => {
          setData(initialData);
          setIsShowQuestionModal(false);
          setAlert('success', true, 'Вопрос успешно отправлен нашим консультантам, ждите ответа');
        })
        .catch(() => {
          setAlert('danger', true, 'Произошла ошибка сервера');
        });
    }
  };

  const resetFieldVal = (e: React.ChangeEvent, field: string) => {
    setValid({ ...valid, [field]: false });
  };

  const closeCanvas = () => {
    setIsShowMenu((prevIsShowMenu) => !prevIsShowMenu);
  };

  useEffect(() => {
    if (isConnected) {
      socketInstance.on(conversationListeners.countNewMessages, (count: number) => setConversationCount(+count));
    }
  }, [isConnected]);

  return (
    <>
      <HeaderComponent />
        <CustomModal isShow={isShowQuestionModal} setIsShow={setIsShowQuestionModal} closeButton={true} size="lg">
        <div>
          <h3 className="text-center">Задать вопрос</h3>
          <form className="message-form">
            <div className="text-center">
              <div>
                <div className="fs-11 fw-5">Вам ответит администратор</div>
              </div>
            </div>
            <div className="row align-items-center fs-11 mt-3">
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="name"
                  style={{
                    color: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                >
                  Ваше имя:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Имя"
                  value={data.name}
                  id="name"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    resetFieldVal(e, 'isInValidName');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="email"
                  style={{
                    color: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                >
                  Ваш Email:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Email"
                  value={data.email}
                  id="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    resetFieldVal(e, 'isInValidEmail');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="question"
                  style={{
                    color: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                >
                  Ваш вопрос:
                </label>
                <span className="fs-08 gray-3 mt-2">От 5 символов</span>
              </div>
              <div className="col-sm-9 mb-sm-3">
                <textarea
                  style={{
                    borderColor: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                  placeholder="Вопрос"
                  value={data.question}
                  id="question"
                  onChange={(e) => {
                    setData({ ...data, question: e.target.value });
                    resetFieldVal(e, 'isInValidQuestions');
                  }}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </CustomModal>

      <CallRieltorModal showRieltorModal={showRieltorModal} setIsShowRieltorModal={setIsShowRieltorModal} />
    </>
  );
};

export default Header;
