const setToken = 'SET_TOKEN';
const resetToken = 'RESET_TOKEN';

const setCurrentUser = 'SET_CURRENT_USER';
const resetCurrentUser = 'RESET_CURRENT_USER';

const setSelectedCity = 'SET_SELECTED_CITY';

const setMapCenter = 'SET_MAP_CENTER';

const setAlert = 'SET_ALERT';
const resetAlert = 'RESET_ALERT';

const setConversationsCount = 'SET_CONVERSATIONS_COUNT';

const setFavoriteCount = 'SET_FAVORITE_COUNT';

const setTabAds = 'SET_ADS'
const setTabServ = 'SET_SERVICES'

const setTab = (tab) => {
  return {
    type: 'SET_TAB',
    payload: tab,
  };
};

export default {
  setToken,
  resetToken,
  setCurrentUser,
  resetCurrentUser,
  setSelectedCity,
  setMapCenter,
  setAlert,
  resetAlert,
  setConversationsCount,
  setFavoriteCount,
  setTabAds,
  setTabServ,
  setTab,
};
