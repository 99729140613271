import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { getTransition } from '../../Styles/utilites';

export const Wrapper = styled.div`
  overflow: hidden;
  > .swiper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    > .swiper {
      position: initial;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.font};
  overflow: hidden;
  position: relative;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 500px;
    height: 90vh;
    border-radius: 1rem;
  }
`;

export const SlideContentDivider = styled.div`
  width: 100%;
  height: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.font};
  .swiper {
    height: 100%;
  }
  .swiper-wrapper {
    height: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      border-radius: calc(0.5rem + 10px);
    }
  }
`;

export const SlideWithContent = styled(SwiperSlide)`
  width: 100%;
  height: 100%;
`;
