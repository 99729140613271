import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi';

//import Success from '/public/img/icons/success.svg';
import InputPassword from '../components/InputPassword';
import FormErrorMessage from '../components/FormErrorMessage';
import { registration } from '../API/auths';
import alertActions from '../../src/store/actions/alert';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/CustomModal';

const formValueDefault = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  remember: true,
  ownerType: '0',
};

const formErrorDefault = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  companyName: '',
  taxIdentificationNumber: '',
};

const baseSchema = {
  firstName: Joi.string().min(4).max(20).required().messages({
    'string.empty': 'Имя не может быть пустым',
    'string.min': `Имя не может быть короче 4 символов`,
    'string.max': `Имя не может быть длиннее 20 символов`,
  }),
  lastName: Joi.string().min(4).max(20).required().messages({
    'string.empty': 'Фамилия не может быть пустой',
    'string.min': `Фамилия не может быть короче 4 символов`,
    'string.max': `Фамилия не может быть длиннее 20 символов`,
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .min(4)
    .required()
    .messages({
      'string.empty': 'Email адрес не может быть пустым',
      'string.min': `Email адрес не может быть короче 4 символов`,
      'string.max': `Email адрес не может быть длиннее 20 символов`,
      'string.email': `Введите Email адрес корректного формата`,
    }),
  password: Joi.string()
    .pattern(/.*[A-Z].*/)
    .pattern(/.*[0-9].*/)
    .min(8)
    .required()
    .messages({
      'string.empty': 'Пароль не может быть пустым',
      'string.pattern.base': 'Пароль должен содержать одну заглавную букву и одну цифру',
      'string.min': `Пароль не может быть короче 8 символов`,
      'string.max': `Пароль не может быть длиннее 20 символов`,
    }),
  passwordConfirm: Joi.string().required().messages({
    'string.empty': 'Подтверждение пароля не может быть пустым',
  }),
  ownerType: Joi.number(),
  remember: Joi.boolean(),
};

const individualEntitySchema = Joi.object({
  ...baseSchema,
});

const legalEntitySchema = Joi.object({
  ...baseSchema,
  taxIdentificationNumber: Joi.string()
    .pattern(/.*[0-9].*/)
    .min(10)
    .max(12)
    .required()
    .messages({
      'string.empty': 'Заполните ИНН',
      'string.pattern.base': 'ИНН состоит только из цифр',
      'string.min': 'Минимальная длина 10 символов',
      'string.max': 'Максимальная длина 10 символов',
    }),
  companyName: Joi.string().required().messages({
    'string.empty': 'Заполните название организации',
  }),
});

export default function Entrance() {
  const [isShowSuccessModal, setShowSuccessModal] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [formValue, setFormValue] = useState(formValueDefault);
  const [ownerType, setOwnerType] = useState(formValueDefault.ownerType);
  const [formErrors, setFormErrors] = useState(formErrorDefault);
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  useEffect(() => {
    if (formValue?.ownerType === '0') {
      delete formValue.companyName;
      delete formValue.taxIdentificationNumber;
    } else {
      setFormValue((prevState) => ({
        ...prevState,
        companyName: '',
        taxIdentificationNumber: '',
      }));
    }
  }, [formValue?.ownerType]);

  const handleFormChange = (e) => {
    setFormValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      };
    });
    setFormErrors((prev) => {
      return { ...prev, [e.target.name]: '' };
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let result;
    if (formValue.ownerType === '0') {
      result = individualEntitySchema.validate(formValue, { abortEarly: false });
    } else {
      result = legalEntitySchema.validate(formValue, { abortEarly: false });
    }
    if (result.error) {
      handleFormErrors(result.error.details);
      return;
    }

    if (formValue.password !== formValue.passwordConfirm) {
      return setFormErrors((prev) => {
        return { ...prev, passwordConfirm: 'Пароли не совпадают' };
      });
    }

    registration(formValue)
      .then(() => {
        setSuccess(true);
        setShowSuccessModal(true);
      })
      .catch((errors) => {
        const serverErrors = {};
        errors?.response?.data?.body?.errors?.forEach((error) => {
          serverErrors[error.field] = error.message;
        });
        if (serverErrors) {
          if (serverErrors.email) {
            setFormErrors((prev) => ({
              ...prev,
              email: 'Пользователь с текущим адресом эл.почты уже существует',
            }));
          }
          if (serverErrors.taxIdentificationNumber) {
            setFormErrors((prev) => ({
              ...prev,
              taxIdentificationNumber: 'Пользователь с текущим ИНН уже существует',
            }));
          }
        }
        if (errors?.response?.data?.status === 400) {
          setAlert('danger', true, 'Произошла ошибка сервера');
        }
      });
  };

  const handleFormErrors = (errors) => {
    errors.forEach((formField) => {
      setFormErrors((prev) => {
        return { ...prev, [formField.path[0]]: formField.message };
      });
    });
  };

  return (
    <main className="account py-sm-3 py-md-4 py-lg-5">
      <section className="container">
        <CustomModal isShow={isShowSuccessModal} setIsShow={setShowSuccessModal} closeButton={true} size="lg">
          <div>
            <h3 className="text-center">Регистрация почти завершена</h3>
            <p className="text-center mb-5">
              Просим вас подтвердить регистрацию на вашей электронной почте. Ссылка с подтверждением отправлена в
              сообщении письма.
            </p>
            <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={() => setShowSuccessModal(false)}>
              Закрыть
            </button>
          </div>
        </CustomModal>
        <Link to="/" className="d-flex align-items-center mt-3 mb-4 mb-sm-5">
          <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line
              y1="-1"
              x2="14.5309"
              y2="-1"
              transform="matrix(-0.660679 -0.750669 0.709114 -0.705094 11.6006 21)"
              stroke="#C59C7E"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="14.5309"
              y2="-1"
              transform="matrix(-0.660679 0.750669 0.709114 0.705094 11.6006 2)"
              stroke="#C59C7E"
              strokeWidth="2"
            />
          </svg>
          <span className="fs-09 ms-1 ms-md-3">На главную</span>
        </Link>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            {isSuccess ? (
              <div className={'text-center'}>
                <h1 className="text-center">Регистрация</h1>
                <div>
                  <img src={'/img/icons/success.svg'} alt={'Галочка успешной регистрации'} className={'success-img'} />
                </div>
                <p className="fw-bold">Регистрация успешно завершена!</p>
                <div className={'mt-3'}>
                  <Link to={'/'} className={'single-link'}>
                    На главную
                  </Link>
                </div>
              </div>
            ) : (
              <form className="entrance">
                <h1 className="text-center mb-4 mb-xxl-5">Регистрация</h1>
                <div className="row">
                  <div className="col-md-3 fs-11 title-req mt-4 mt-sm-5 mb-3 m-md-0">
                    <span data-for="owner" data-status={false}>
                      Владелец объявления*:
                    </span>
                  </div>
                  <div className="col-md-9 mb-5">
                    <div className="d-flex align-items-center">
                      <div className="me-5">
                        <label>
                          <input
                            type="radio"
                            name="ownerType"
                            value="0"
                            defaultChecked={true}
                            onChange={handleFormChange}
                          />
                          <span className="fs-11 ms-2">Физическое лицо</span>
                        </label>
                      </div>
                      <div>
                        <label>
                          <input type="radio" name="ownerType" value="1" onChange={handleFormChange} />
                          <span className="fs-11 ms-2">Юридическое лицо</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                  <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Имя:</div>
                  <div className="col-sm-9">
                    <input
                      placeholder="Ирина"
                      className="fs-11"
                      name="firstName"
                      value={formValue.firstName}
                      onChange={handleFormChange}
                    />
                    <FormErrorMessage>{formErrors.firstName}</FormErrorMessage>
                  </div>
                </div>
                <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                  <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Фамилия:</div>
                  <div className="col-sm-9">
                    <input
                      placeholder="Колесникова"
                      className="fs-11"
                      name="lastName"
                      value={formValue.lastName}
                      onChange={handleFormChange}
                    />
                    <FormErrorMessage>{formErrors.lastName}</FormErrorMessage>
                  </div>
                </div>
                {formValue?.ownerType === '1' && (
                  <>
                    <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                      <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Название организации:</div>
                      <div className="col-sm-9">
                        <input
                          placeholder="Название организации"
                          className="fs-11"
                          name="companyName"
                          value={formValue.companyName || ''}
                          onChange={handleFormChange}
                        />
                        <FormErrorMessage>{formErrors.companyName}</FormErrorMessage>
                      </div>
                    </div>
                    <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                      <div className="col-sm-3 fs-11 mb-1 mb-sm-0">ИНН:</div>
                      <div className="col-sm-9">
                        <input
                          placeholder="ИНН"
                          className="fs-11"
                          name="taxIdentificationNumber"
                          value={formValue.taxIdentificationNumber || ''}
                          onChange={handleFormChange}
                        />
                        <FormErrorMessage>{formErrors.taxIdentificationNumber}</FormErrorMessage>
                      </div>
                    </div>
                  </>
                )}
                <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                  <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Email:</div>
                  <div className="col-sm-9">
                    <input
                      placeholder="mail@mail.ru"
                      className="fs-11"
                      type="email"
                      name="email"
                      value={formValue.email}
                      onChange={handleFormChange}
                    />
                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                  </div>
                </div>
                <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                  <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Пароль:</div>
                  <div className="col-sm-9">
                    <InputPassword
                      name="password"
                      visible={false}
                      onChange={handleFormChange}
                      value={formValue.password}
                    />
                    <FormErrorMessage>{formErrors.password}</FormErrorMessage>
                  </div>
                </div>
                <div className="row align-items-center mb-3 mb-sm-4 mb-xxl-5">
                  <div className="col-sm-3 fs-11 mb-1 mb-sm-0">Подтверждение пароля:</div>
                  <div className="col-sm-9">
                    <InputPassword
                      name="passwordConfirm"
                      visible={false}
                      onChange={handleFormChange}
                      value={formValue.passwordConfirm}
                    />
                    <FormErrorMessage>{formErrors.passwordConfirm}</FormErrorMessage>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-sm-8 col-lg-6">
                    <button className="btn btn-1 fs-11 w-100 text-uppercase mb-4" onClick={handleFormSubmit}>
                      Зарегистрироваться
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="fs-09 text-center gray-3 mb-4">
                      Нажимая на кнопку “Зарегистрироваться”, вы принимаете условия пользовательского сглашения
                    </div>
                    <div className="fs-11 text-center">
                      У Вас уже аккаунт?{' '}
                      <Link to="/login" className="color-1 bb-1">
                        Войти
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}
