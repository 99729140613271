import fingerprint from '@fingerprintjs/fingerprintjs';
import axios from 'axios';

import store from '../store/store';
import apiRoutes from './config/apiRoutes';

const baseURL = process.env.REACT_APP_BASE_URL;

export const loadFingerPrint = async () => {
  const fp = await fingerprint.load();
  const result = await fp.get();
  localStorage.setItem('fingerprint', result.visitorId);
};

export const getAxiosPrivate = () => {
  const httpClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
      'User-Fingerprint': localStorage.getItem('fingerprint'),
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
  });

  const currentUserToken = store.getState().accessToken;
  const refreshToken = async () => {
    const response = await httpClient.get(`${baseURL}${apiRoutes.REFRESH}`);
    const { token } = response.data.body;
    return token;
  };

  httpClient?.interceptors?.request?.use(
    (request) => {
      return loadFingerPrint().then(() => {
        if (!request.headers['Authorization']) {
          request.headers['Authorization'] = `Bearer ${currentUserToken}`;
          request.headers['User-Fingerprint'] = localStorage.getItem('fingerprint');
        }
        return request;
      });
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  httpClient?.interceptors?.response?.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (( [400,401,403].includes(error?.response?.status)) && !prevRequest.isSent) {
        prevRequest.isSent = true;
        const newAccessToken = await refreshToken();
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return httpClient(prevRequest);
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};
export default getAxiosPrivate;
