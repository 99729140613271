import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers/index';
import favoriteCount from './reducers/favoriteCount';

const initialState = {
  favoriteCount: {
    servicesCount: 0,
    adsCount: 0,
  },
};

const store = configureStore({
  reducer: reducers,
  preloadedState: initialState,
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
