import styled from 'styled-components';

export const RoundedBottomRemove = styled.span`
  // padding: 5px 70px 5px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 24px;
  border: 1px solid #198acb;
  border-radius: 50px;
  color: #b8c5cc;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  transition: 0.2s;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 100px;
    height: 24px;
    font-size: 12px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    width: 120px;
    height: 24px;
    font-size: 13px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 150px;
    height: 24px;
    font-size: 14px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 41px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 20px;
    background: transparent;
  }
  &:hover {
    color: #ffffff;
    background: #fe6060;
    border: 1px solid #fe6060;
  }
`;

export const RoundedBottomEdit = styled.span`
  // padding: 5px 40px 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 24px;
  font-size: 14px;
  border: 1px solid #198acb;
  background: #198acb;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  transition: 0.2s;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 100px;
    height: 24px;
    font-size: 12px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    width: 120px;
    height: 24px;
    font-size: 13px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 150px;
    height: 24px;
    font-size: 14px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 41px;
    border: 1px solid #198acb;
    background: #198acb;
    border-radius: 50px;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonRelocate = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    align-items: center;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    align-items: center;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 0px;
  }
`;

export const BottomMarginMobile = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  //width: 100%;
`;

export const FullWidth = styled.div`
  width: 100%;
`;
