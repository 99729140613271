import styled, { css, keyframes } from 'styled-components';
import { Placement } from '@popperjs/core';

const opacityOn = keyframes`
0%{
opacity: 0;
}
100%{
opacity: 1;
}
`;
export const Wrapper = styled.div`
  display: inherit;
  position: relative;
`;

type TooltipProps = {
  $direction: Placement;
  $widthInRem?: number;
};
export const Tooltip = styled.div<TooltipProps>`
  font-size: 1rem;
  z-index: 10;
  position: absolute;
  display: block;
  ${({ $direction }) =>
    $direction.startsWith('left') || $direction.startsWith('right')
      ? css`
          // margin: 0 0.2rem;
        `
      : css`
          // margin: 0.2rem 0;
        `};
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: ${({ theme }) => theme.colors.revert};
  background-clip: padding-box;
  // border: 1px solid ${({ theme }) => theme.colors.grey45};
  box-shadow: 0 1px 6px 4px hsla(0, 0%, 0%, 0.25);
  border-radius: 0.3rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.2;
  opacity: 1;
  min-width: ${({ $widthInRem }) => ($widthInRem ? $widthInRem : 6)}rem;
  animation: ${opacityOn} 0.15s ease-in-out;
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0.5rem 0.75rem;
  }
`;

export const Arrow = styled('div')`
  position: absolute;
  width: 3em;
  height: 3em;
  &[data-placement*='bottom'] {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 0 1.5em 1em 1.5em;
      border-color: transparent transparent #232323 transparent;
    }
  }
  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
    margin-bottom: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 1em 1.5em 0 1.5em;
      border-color: #232323 transparent transparent transparent;
    }
  }
  &[data-placement*='right'] {
    left: 0;
    margin-left: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1.5em 1em 1.5em 0;
      border-color: transparent #232323 transparent transparent;
    }
  }
  &[data-placement*='left'] {
    right: 0;
    margin-right: -0.9em;
    height: 2em;
    width: 1em;
    &::before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent ${({ theme }) => theme.colors.revert};
    }
  }
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export const Heading = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.colors.font};
  font-size: 0.9rem;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.font};
`;
