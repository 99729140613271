import CustomModal from '../../../../components/CustomModal';
import { ReactComponent as Warn } from '../../../../assets/icons/profile/warn.svg';
import React, { useState } from 'react';
import { useFormInput } from '../../../../hooks/useFormInput';
import * as S from './styles';
import { checkCode, sendCode } from '../../../../API/users';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useAccessToken, useCurrentUser } from '../../../../store/reducers';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import currentUserActions from '../../../../store/actions/currentUser';
import alertActions from '../../../../store/actions/alert';

type WarnPhoneProps = {};
export const WarnPhone = ({}: WarnPhoneProps) => {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { setCurrentUser } = bindActionCreators(currentUserActions, dispatch);
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const currentUser = useCurrentUser();
  const token = useAccessToken();
  const [values, reset] = useFormInput({
    code: '',
  });
  const [isShow, setIsShow] = useState(false);
  const [isSend, setSend] = useState(false);

  const onSend = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await sendCode(axiosPrivate, currentUser?.uuid, token);
      setSend(true);
    } catch (error) {
      if (typeof error === 'string') setAlert('danger', true, error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await checkCode(axiosPrivate, currentUser?.uuid, values.value.code, token);
      const { body: user } = response.data;
      setCurrentUser(user);
      setAlert('success', true, 'Номер телефона успешно подтвержден');
      setIsShow(false);
    } catch (error) {
      if (typeof error === 'string') setAlert('danger', true, error);
    }
  };
  const onType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 6) return;
    values.onChange(e);
  };

  return (
    <>
      <Warn onClick={() => setIsShow(true)} />
      <CustomModal isShow={isShow} setIsShow={setIsShow} closeButton={true} size="lg">
        <div>
          <h3 className="text-center">Код подтверждения</h3>
          <form className="message-form">
            <div className="text-center">
              <div>
                {isSend ? (
                  <div className="fs-11 fw-5">На ваш телефон отправлено смс-сообщение с кодом подтверждения</div>
                ) : (
                  <div className="fs-11 fw-5">Для подтверждения номера телефона введите код из смс-сообщения</div>
                )}
              </div>
            </div>
            <div className="row align-items-center fs-11 mt-3">
              <div className="mb-1 mb-sm-3">
                <div className="gray-3 d-flex justify-content-between">
                  <div>Код из SMS</div>
                  {!isSend && <S.Button onClick={onSend}>Отправить код</S.Button>}
                </div>
                <input
                  id={'code'}
                  name={'code'}
                  type="number"
                  className="w-100 mt-1"
                  value={values.value.code}
                  onChange={onType}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-1 mx-auto fs-12 mt-4"
              onClick={handleSubmit}
              disabled={!values.value.code}
            >
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </CustomModal>
    </>
  );
};
