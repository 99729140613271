type CookiesInfoProps = {
  isOpen: boolean;
  isShow: boolean;
  setAccept: () => void;
};
export const CookiesInfo = ({ isOpen, isShow, setAccept }: CookiesInfoProps) => {
  return isOpen ? (
    <div className={`cookies__wrapper ${isShow ? 'show' : ''}`}>
      <div className={'cookies__title'}>
        Мы используем cookie-файлы для предоставления вам наиболее актуальной информации.
      </div>
      <div className={'cookies__content'}>
        Продолжая использовать сайт, Вы соглашаетесь с использованием cookie-файлов
      </div>
      <div className={'cookies__control'}>
        <button className={'btn btn-1'} onClick={setAccept}>
          Согласен
        </button>
      </div>
    </div>
  ) : null;
};
