import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountMenu from '../pages/PersonalAccount/AccountMenu/AccountMenu';
import { Outlet } from 'react-router-dom';
import * as S from './styles';
import { Dropdown } from '../components/Dropdown/Dropdown';
import { useToggle } from '../hooks/useToggle';
import Favorite from '../pages/PersonalAccount/AccountMenu/Favorites/Favorites'
//import { setTabAds, setTabServices } from '../store/reducers/favswitchReducer';
import accessTokenActions from '../store/actions/accessToken';
import currentUserActions from '../store/actions/currentUser';
import actionTypes from '../store/actions/actionTypes';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useAccessToken } from '../store/reducers';
import { bindActionCreators } from 'redux';
import favoriteCount from '../store/actions/favoriteCount';
import apiRoutes from '../API/config/apiRoutes';
import CustomModal from '../components/CustomModal';

import { getQuestion } from '../API/question';
import alertActions from '../store/actions/alert';
import { ReactComponent as PartnerIcon } from '../assets/icons/profile/parther.svg';
import { ReactComponent as BlackMarkerIcon } from '../assets/icons/blackMarker.svg';

export const items = [
  {
    id: 1,
    content: 'Профиль',
    navigate: 'profile',
    tab: 'Профиль',
    icon: <S.MyProfile />,
  },
  {
    id: 2,
    content: 'Мои объявления',
    navigate: 'my-ads',
    tab: 'Мои объявления',
    icon: <S.MyAnnouncement />,
  },
  {
    id: 3,
    content: 'Мои услуги',
    navigate: 'my-services',
    tab: 'Мои услуги',
    icon: <S.MyServices />,
  },
  {
    id: 4,
    content: 'Избранное',
    navigate: 'favorites',
    tab: 'Избранное',
    icon: <S.HeartMenu />,
  },
  {
    id: 9,
    content: 'Черная метка',
    navigate: 'black-marker',
    tab: 'Черная метка',
    icon: <BlackMarkerIcon style={{width:20,height:20, marginRight:'1em'}}/>,
  },
  {
    id: 5,
    content: 'Мои отклики',
    navigate: 'responses',
    tab: 'Мои отклики',
    icon: <S.MyTouch />,
  },
  {
    id: 6,
    content: 'В работе',
    navigate: 'in-work',
    tab: 'В работе',
    icon: <S.MyBag />,
  },
  {
    id: 7,
    content: 'Сообщения',
    navigate: 'my-messages',
    tab: 'Сообщения',
    icon: <S.MyEmails />,
  },
  {
    id: 8,
    content: 'Отзывы',
    navigate: 'my-reviews',
    tab: 'Отзывы',
    icon: <S.MyReviews />,
  },
  {
    id: 9,
    content: 'Стать партнером',
    navigate: 'partner',
    tab: 'Стать партнером',
    icon: <PartnerIcon style={{width:20,height:20, marginRight:'1em'}}/>,
  },
  {
    id: 10,
    content: 'Задать вопрос',
    navigate: 'skipped',
    tab: 'Задать вопрос',
    icon: <S.MyQuestion />,
  },
  {
    id: 11,
    content: 'Выйти',
    tab: 'Выйти',
    navigate: 'skipped',
    icon: <S.MyLeave />,
  },
];

const PersonalAccountLayout = ({ isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, toggle] = useToggle();
  const handleCreateButtonClick = () => {
    navigate('/advertise');
  };
  const handleCreateButtonClick2 = () => {
    navigate('/personal-account/my-services/create');
  };

  const conversationCount = useSelector((state) => state?.conversationCount);
  const favs = useSelector((state) => state?.favoriteCount);
  const [activeTab, setActiveTab] = useState('Профиль');
  const tab = useSelector((state) => state.tab.tab);
  const changeTab = (newTab) => {
    dispatch(actionTypes.setTab(newTab));
  };
  // const handleTabChange = (selectedTab) => {
  //   dispatch({ type: 'SET_TAB', payload: selectedTab });
  // };

  const favsCount = Object.values(favs)
    .map((val) => Number(val))
    .reduce((val, acc) => val + acc);

  // const [adsData, setAdsData] = useState({ items: [], isLoading: false });
  // const [servicesData, setServicesData] = useState({ items: [], isLoading: false });
  // const fetchAdsData = async () => {
  //     setAdsData({ ...adsData, isLoading: true });
  //     const data = await getAdsRequest();
  //     setAdsData({ items: data, isLoading: false });
  // };
  // const fetchServicesData = async () => {
  //     setServicesData({ ...servicesData, isLoading: true });
  //     const data = await getServicesRequest();
  //     setServicesData({ items: data, isLoading: false });
  // };
  // useEffect(() => {
  // if (tab === 'ads') {
  //     fetchAdsData();
  // } else if (tab === 'services') {
  //     fetchServicesData();
  // }
  // }, [tab]);
  const adsButtonRef = useRef(null);

  useEffect(() => {
    adsButtonRef.current?.click();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/personal-account/favorites'))
      setActiveTab('Избранное')
    if (location.pathname.includes('/personal-account/my-messages'))
      setActiveTab('Сообщения')
  },[location])

  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const { setFavoriteCount } = bindActionCreators(favoriteCount, dispatch);
  const { resetToken } = bindActionCreators(accessTokenActions, dispatch);
  const { resetCurrentUser } = bindActionCreators(currentUserActions, dispatch);
  const token = useAccessToken();
  const [isShowModalExit, setIsShowModalExit] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const modifiedItems = items.map(item => {
    if (item.content === 'Избранное') {
      return {
        ...item,
        highlight: activeTab === 'Избранное' || favsCount > 0
      };
    }
    return item;
  });
  const handleLogout = async () => {
    resetToken();
    resetCurrentUser();
    setFavoriteCount({
      adsCount: 0,
      servicesCount: 0,
    });
    navigate('/');
    setIsShowModalExit(false);
    await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.LOGOUT}`, { token: token });
  };
  const [isShowQuestionModal, setIsShowQuestionModal] = useState(false);
  const handleAskQuestion = () => {
    setIsShowQuestionModal(true);
  };
  const [data, setData] = useState({
    name: '',
    email: '',
    question: '',
  });
  const [valid, setValid] = useState({
    isInValidName: false,
    isInValidEmail: false,
    isInValidQuestions: false,
  });

  const mailSample = Object.values(data).find((i) => i?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isInValidName = data?.name === undefined || data.name?.length < 1 || data.name?.length > 55;
    const isInValidEmail = data?.email === undefined || mailSample === undefined;
    const { question } = data;
    const isInValidQuestions = question?.length === undefined || question?.length < 5 || question?.length > 1024;

    if (isInValidName) {
      setValid({ ...valid, isInValidName: true });
    } else if (isInValidEmail) {
      setValid({ ...valid, isInValidEmail: true });
    } else if (isInValidQuestions) {
      setValid({ ...valid, isInValidQuestions: true });
    } else {
      const formData = new FormData();
      const req = { ...data };
      for (let key in req) {
        formData.append(key, req[key as keyof typeof req]);
      }
      getQuestion(formData)
        .then(() => {
          setData({
            name: '',
            email: '',
            question: '',
          });
          setIsShowQuestionModal(false);
          setAlert('success', true, 'Вопрос успешно отправлен нашим консультантам, ждите ответа');
        })
        .catch(() => {
          setAlert('danger', true, 'Произошла ошибка сервера');
        });
    }
  };

  const resetFieldVal = (field) => {
    setValid((prevValid) => ({ ...prevValid, [field]: false }));
  };
  return (
    <>
      <S.CustomSkeleton>
        {!isMobile && <S.CustomPTeg>Личный кабинет</S.CustomPTeg>}
        {location.pathname.includes('/personal-account/my-ads') && (
          <S.CustomCreateButton onClick={handleCreateButtonClick}>Создать объявление</S.CustomCreateButton>
        )}
        {location.pathname.includes('/personal-account/my-services') && (
          <S.CustomCreateButton onClick={handleCreateButtonClick2}>Создать услугу</S.CustomCreateButton>
        )}
        {/* {!isMobile  (<div></div>)} */}
        {/* {!isMobile && location.pathname.includes('/personal-account/favorites') && <S.CustomCreateButton onClick={handleCreateButtonClick2}>Создать услугу</S.CustomCreateButton>} */}
        {/* {isMobile && <Dropdown />} */}
        {location.pathname.includes('/personal-account/favorites') && (
          <ul className="tabs mb-4">
            <S.CustomLi>
              <S.AdsButton className={tab === 'ads' ? 'focusedbut' : ''} onClick={() => changeTab('ads')} active ref={adsButtonRef}>
                Объявления
              </S.AdsButton>
              <S.UpperCounter>{favs.adsCount}</S.UpperCounter>
            </S.CustomLi>
            <S.CustomLi>
              <S.ServButton className={tab === 'services' ? 'focusedbut' : ''} onClick={() => changeTab('services')}>
                Услуги
              </S.ServButton>
              <S.UpperCounter>{favs.servicesCount}</S.UpperCounter>
            </S.CustomLi>
          </ul>

        )}

        {isMobile && (
          <S.ProfilePathDivider>
          <S.DropOpener onClick={toggle()}>
            {items.find((item) => item.content === activeTab)?.icon}
            {activeTab}
          </S.DropOpener>
          <Dropdown
            isOpen={isOpen}
            isShow={isShowQuestionModal}
            setIsShow={setIsShowQuestionModal}
            handleAskQuestion={handleAskQuestion}
            array={
              modifiedItems
              .filter(({ tab }) => tab !== activeTab)
              .map(({ content, icon, tab, navigate: to, highlight }) => {
                const isLogoutItem = tab === 'Выйти';
                const IsAskedQuestion = tab === 'Задать вопрос';
                const IsReviews = tab === 'Отзывы';
                return {
                  content,
                  action: () => {
                    toggle(false)();
                    if (isLogoutItem) {
                      handleLogout();
                    } else if (IsReviews) {
                      navigate(to);
                    } else if (IsAskedQuestion) {
                      handleAskQuestion();
                    } else {
                      navigate(to);
                      setActiveTab(tab);
                    }
                  },

                  icon,
                  highlight,
                  count: to === 'favorites' ? favsCount : to === 'my-messages' ? conversationCount : undefined,
                };
              })
            }
            direction="bottom"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            favsCount={favsCount}
          />
        </S.ProfilePathDivider>

        )}
      </S.CustomSkeleton>
      <CustomModal isShow={isShowQuestionModal} setIsShow={setIsShowQuestionModal} closeButton={true} size="lg">
        <div>
          <h3 className="text-center">Задать вопрос</h3>
          <form className="message-form">
            <div className="text-center">
              <div>
                <div className="fs-11 fw-5">Вам ответит администратор</div>
              </div>
            </div>
            <div className="row align-items-center fs-11 mt-3">
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="name"
                  style={{
                    color: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                >
                  Ваше имя:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Имя"
                  value={data.name}
                  id="name"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    resetFieldVal(e, 'isInValidName');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="email"
                  style={{
                    color: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                >
                  Ваш Email:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Email"
                  value={data.email}
                  id="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    resetFieldVal(e, 'isInValidEmail');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="question"
                  style={{
                    color: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                >
                  Ваш вопрос:
                </label>
                <span className="fs-08 gray-3 mt-2">От 5 символов</span>
              </div>
              <div className="col-sm-9 mb-sm-3">
                <textarea
                  style={{
                    borderColor: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Вопрос"
                  value={data.question}
                  id="question"
                  onChange={(e) => {
                    setData({ ...data, question: e.target.value });
                    resetFieldVal(e, 'isInValidQuestions');
                  }}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </CustomModal>
      <div className={!isMobile ? 'row' : ''}>
        {!isMobile && (
          <div className="col-lg-4 col-xl-3">
            <S.RoundedCustom className="frame p-4">
              <AccountMenu />
            </S.RoundedCustom>
          </div>
        )}
        <S.CustomWidthDivider className={'col-lg-8 col-xl-9'}>
          <S.CustomContentWrapper className={!isMobile ? 'frame pt-4 pt-xxl-5' : ''}>
            <Outlet />
          </S.CustomContentWrapper>
        </S.CustomWidthDivider>
      </div>
      {/* <Favorite taber={tab} /> */}
    </>
  );
};

export default PersonalAccountLayout;
