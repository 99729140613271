import {
  onCheckboxHandler,
  onInputHandler,
  onMultiCheckboxHandler,
  onSelectHandler,
} from '../helpers/collectDataFromForm';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { estateTypeToEstateId } from '../pages/Ad.List.Page';
import { getTypesEstate } from '../API/typesEstate';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import env from '../config/env';
import useSearchForm from '../hooks/useSearchForm';
import { debounce } from 'lodash';
import { ReactComponent as BlackMarkerBigIcon } from '../assets/icons/blackMarkerBig.svg';
import { Tooltip } from 'bootstrap';
import { createBlackMarker } from '../API/blackMarker';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useAccessToken, useCurrentUser } from '../store/reducers';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../store/actions/alert';

export type EventWithTarget = {
  target: {
    name: string;
    value: string;
  };
};
type EstateFiltersProps = {
  filters: {
    transactionType: number;
    typesEstate: number;
    districts: string[];
    orderBy: 'asc' | 'dsc';
    estateId: number;
  } & any;
  setFilters: (arg: {}) => void;
  onResetFilters: () => void;
  onApplyFilters: () => void;
  foundCount: number;
  toogleBlackMarker: () => void;
};

const transactionsElts = [
  {
    id: 2,
    name: 'Купить',
  },
  {
    id: 1,
    name: 'Снять',
  },
];

export const typesEstateElts = [
  {
    id: 1,
    name: 'Квартира\\Комната',
  },
  {
    id: 2,
    name: 'Дом\\Дача',
  },
  {
    id: 3,
    name: 'Земельный участок',
  },
  {
    id: 4,
    name: 'Паркинг\\Гараж',
  },
  {
    id: 5,
    name: 'Коммерческая недвижимость',
  },
];

const roomsElts = [
  {
    id: 1,
    name: '1 комнатная',
  },
  {
    id: 2,
    name: '2 комнатная',
  },
  {
    id: 3,
    name: '3 комнатная',
  },
  {
    id: 4,
    name: '4 комнатная',
  },
  {
    id: 5,
    name: '5 комнатная',
  },
  {
    id: 6,
    name: '5+ комнат',
  },
  {
    id: 7,
    name: 'Свободная планировка',
  },
  {
    id: 0,
    name: 'Студия',
  },
];

const areaTypes = [
  {
    id: 0,
    name: 'СНТ',
  },
  {
    id: 1,
    name: 'ИЖС',
  },
  {
    id: 2,
    name: 'ЛПХ',
  },
];

export const EstateFilters = ({
  filters,
  setFilters,
  onResetFilters,
  onApplyFilters,
  foundCount,
  toogleBlackMarker,
}: EstateFiltersProps) => {
  const axiosPrivate = useAxiosPrivate();
  const token = useAccessToken();
  const currentUser = useCurrentUser();

  const { search, setSearch, onSearch } = useSearchForm('');
  const sugData = useRef(null);
  const [estates, setEstates] = useState([]);
  useLayoutEffect(() => {
    getTypesEstate()
      .then((response) => response.find((type: { id: number }) => type.id === filters.typesEstate))
      .then(
        (result) =>
          result &&
          result.estates.length &&
          result.estates.map((item: { name: string; id: number }) => ({ title: item.name, value: item.id }))
      )
      .then((estateIds) => {
        setEstates(estateIds);
        if (
          !!estates &&
          !estates.length &&
          !!filters.estateId &&
          !!estateIds &&
          filters.estateId !== estateIds[0].value
        )
          return;
        estateIds?.length && onSelectHandler(estateIds[0].value, 'estateId', setFilters);
      });
  }, [filters.typesEstate]);

  const setFilter = (newFilters: { [key: string]: any }) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const debouncedFn = useMemo(
    () =>
      debounce((v: string) => {
        const str = v.replaceAll('.', '').trim();
        setFilters({ addressOrResidentalComplex: str });
      }, 300),
    [filters]
  );

  const tooltip = useRef();
  const tooltipRef = useRef();
  useEffect(() => {
    if (!tooltip.current) {
      tooltip.current = new Tooltip(tooltipRef.current, {
        title: `Функционал позволяет уведомлять об интересующих объектах по определенному адресу`,
        placement: 'top',
        trigger: 'hover',
        delay: { show: 300, hide: 0 },
      });
    }
    if (!search) {
      tooltip.current?.enable();
    } else {
      tooltip.current?.disable();
    }
  }, [search]);

  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const onBlackMarker = () => {
    if (currentUser?.id) {
      if (search) {
        createBlackMarker(axiosPrivate, search, currentUser?.id, token)
          .then((res) => {
            if (res?.isExist) setAlert('success', true, 'Черная метка уже была создана');
            else {
              setAlert('success', true, 'Черная метка успешно создана');
              toogleBlackMarker();
            }
          })
          .catch((err) => {
            setAlert('danger', true, 'Произошла ошибка сервера!');
          });
      } else {
        setAlert('danger', true, 'Введите адрес для создания черной метки и нажмите на значок');
      }
    } else {
      setAlert('danger', true, 'Функция доступна только зарегистрированным пользователям');
    }
  };

  return (
    <div className={'filters-ymap'}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, height: 35 }}>
          <AddressSuggestions
            //  value={filters.addressOrResidentalComplex || search}
            token={env.DADATA_TOKEN}
            onChange={(e: DaDataSuggestion<DaDataAddress> | undefined) => {
              if (e) {
                setSearch(e.value);
                debouncedFn(e.value);
              }
            }}
            containerClassName="catalog__search"
            // suggestionsClassName={'suggestions'}
            inputProps={{
              style: { marginBottom: '1rem' },
              className: 'articleInput',
              placeholder: 'Адрес или ЖК',
              onChange: (e: React.FormEvent<HTMLInputElement> & EventWithTarget) => {
                setSearch(e.target.value);
                debouncedFn(e.target.value);
              },
            }}
            count={9999}
            delay={1000}
            ref={sugData}
          />
        </div>
        <div style={{display: 'flex',alignItems:'center',justifyContent:'center', width:50 }}>
          <BlackMarkerBigIcon
            style={{ height: 28, width:28, cursor: 'pointer', opacity: !search ? 0.5 : 1 }}
            ref={!search ? tooltipRef : null}
            onClick={onBlackMarker}
          />
        </div>
      </div>

      <fieldset className="mb-4 mt-4">
        <legend className="title-font fs-11 fw-6 mb-3">Действие:</legend>
        {transactionsElts.map(({ id, name }) => (
          <label className="ps-2 mb-3" htmlFor={'transactionType' + id} key={id}>
            <input
              type="radio"
              id={'transactionType' + id}
              name={'transactionType' + id}
              value={'transactionType' + id}
              checked={filters?.transactionType + 1 === id}
              onChange={() => {
                setFilter({ transactionType: id - 1 });
              }}
            />
            <span className="ms-3">{name}</span>
          </label>
        ))}
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Категория:</legend>
        {typesEstateElts.map(({ id, name }) => (
          <label className="ps-2 mb-3" htmlFor={'typesEstate' + id} key={id}>
            <input
              type="radio"
              id={'typesEstate' + id}
              name={'typesEstate' + id}
              value={'typesEstate' + id}
              checked={filters?.typesEstate === id}
              onChange={() => {
                setFilter({ typesEstate: id, estateId: estateTypeToEstateId[id as keyof typeof estateTypeToEstateId] });
              }}
            />
            <span className="  ms-3">{name}</span>
          </label>
        ))}
      </fieldset>
      {estates && estates.length && (
        <fieldset className="mb-4">
          <legend className="title-font  fs-11 fw-6 mb-3">Объект:</legend>
          {estates.map(({ title, value }) => (
            <label className="ps-2 mb-3" htmlFor={'estate' + value} key={value}>
              <input
                type="radio"
                id={'estate' + value}
                name={'estate' + value}
                value={'estate' + value}
                checked={filters?.estateId === value}
                onChange={() => {
                  setFilter({ estateId: value });
                }}
              />
              <span className="ms-3">{title}</span>
            </label>
          ))}
        </fieldset>
      )}
      {filters.typesEstate === 1 && (
        <>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Количество комнат:</legend>
            {roomsElts.map(({ id, name }) => (
              <label className="ps-2 mb-3" key={id} htmlFor={id === 0 ? 'studio' : id + 'room'}>
                <input
                  id={id === 0 ? 'studio' : id + 'room'}
                  type="checkbox"
                  name="rooms"
                  value={id === 0 ? 'studio' : id + 'room'}
                  checked={filters?.roomTypes?.includes(id) || false}
                  onChange={() => onMultiCheckboxHandler('roomTypes', id, setFilters)}
                />
                <span className="  ms-3">{name}</span>
              </label>
            ))}
          </fieldset>

          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Цена:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-2">От</div>
              <input
                type="number"
                className="w-100 price me-3"
                value={filters?.startPrice || ''}
                onChange={(e) => onInputHandler(e, 'startPrice', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100 price"
                value={filters?.endPrice || ''}
                onChange={(e) => onInputHandler(e, 'endPrice', true, setFilters)}
              />
            </div>
          </fieldset>
          {filters.transactionType === 0 && (
            <fieldset className="mb-4">
              <legend className="title-font  fs-11 fw-6 mb-3">Срок аренды:</legend>
              <label className="ps-2 mb-3">
                <input
                  type="checkbox"
                  name="lease"
                  checked={filters?.rentalTypes?.includes(0) || false}
                  onChange={() => onMultiCheckboxHandler('rentalTypes', 0, setFilters)}
                />
                <span className="  ms-3">Посуточно</span>
              </label>
              <label className="ps-2 mb-3">
                <input
                  type="checkbox"
                  name="lease"
                  checked={filters?.rentalTypes?.includes(3) || false}
                  onChange={() => onMultiCheckboxHandler('rentalTypes', 3, setFilters)}
                />
                <span className="  ms-3">Краткосрочно</span>
              </label>
              <label className="ps-2 mb-3">
                <input
                  type="checkbox"
                  name="lease"
                  checked={filters?.rentalTypes?.includes(1) || false}
                  onChange={() => onMultiCheckboxHandler('rentalTypes', 1, setFilters)}
                />
                <span className="  ms-3">Длительная аренда</span>
              </label>
            </fieldset>
          )}
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Ремонт:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                checked={filters?.repairTypes?.includes(3) || false}
                onChange={() => onMultiCheckboxHandler('repairTypes', 3, setFilters)}
              />
              <span className="  ms-3">Без ремонта</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                checked={filters?.repairTypes?.includes(0) || false}
                onChange={() => onMultiCheckboxHandler('repairTypes', 0, setFilters)}
              />
              <span className="  ms-3">Косметический</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                checked={filters?.repairTypes?.includes(1) || false}
                onChange={() => onMultiCheckboxHandler('repairTypes', 1, setFilters)}
              />
              <span className="  ms-3">Евроремонт</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                checked={filters?.repairTypes?.includes(2) || false}
                onChange={() => onMultiCheckboxHandler('repairTypes', 2, setFilters)}
              />
              <span className="  ms-3">Дизайнерский</span>
            </label>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Дополнительные характеристики:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasKitchenFurniture"
                checked={filters?.hasKitchenFurniture || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Кухонная мебель</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasFurniture"
                checked={filters?.hasFurniture || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Мебель в комнатах</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasRefrigerator"
                checked={filters?.hasRefrigerator || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Холодильник</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasWashingMachine"
                checked={filters?.hasWashingMachine || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Стиральная машина</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasDishWasher"
                checked={filters?.hasDishWasher || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Посудомоечная машина</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasTv"
                checked={filters?.hasTv || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Телевизор</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasConditioner"
                checked={filters?.hasConditioner || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Кондиционер</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasInternet"
                checked={filters?.hasInternet || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Интернет</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasBathroom"
                checked={filters?.hasBathroom || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Ванна</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasShowerCabin"
                checked={filters?.hasShowerCabin || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-3">Душевая кабина</span>
            </label>
            {filters.transactionType === 0 && (
              <>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="withKids"
                    checked={filters?.withKids || false}
                    onChange={(e) => onCheckboxHandler(e, setFilters)}
                  />
                  <span className="  ms-3">Можно с детьми</span>
                </label>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="withPets"
                    checked={filters?.withPets || false}
                    onChange={(e) => onCheckboxHandler(e, setFilters)}
                  />
                  <span className="  ms-3">Можно с животными</span>
                </label>
              </>
            )}
          </fieldset>
          <AdvansedFilters filters={filters} setFilters={setFilters} />
        </>
      )}

      {filters.typesEstate === 2 && (
        <>
          <fieldset className="mb-4">
            <legend className="title-font fs-12 fw-6 mb-3">Расположение:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="locationType"
                value="locationType"
                checked={filters?.locationType?.includes(0) || false}
                onChange={() => onMultiCheckboxHandler('locationType', 0, setFilters)}
              />
              <span className="fs-11 ms-3">Отдельный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="locationType"
                value="locationType"
                checked={filters?.locationType?.includes(1) || false}
                onChange={() => onMultiCheckboxHandler('locationType', 1, setFilters)}
              />
              <span className="fs-11 ms-3">Кооперативный</span>
            </label>
          </fieldset>
          {/*<fieldset className="mb-4">*/}
          {/*  <legend className="title-font fs-12 fw-6 mb-3">Охрана:</legend>*/}
          {/*  <label className="ps-2 mb-3">*/}
          {/*    <input*/}
          {/*      id={'hasSecurity'}*/}
          {/*      type="checkbox"*/}
          {/*      name="hasSecurity"*/}
          {/*      value="hasSecurity"*/}
          {/*      checked={filters?.hasSecurity?.includes(1) || false}*/}
          {/*      onChange={() => {*/}
          {/*        onMultiCheckboxHandler('hasSecurity', 1, setFilters);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <span className="fs-11 ms-3">Да</span>*/}
          {/*  </label>*/}
          {/*  <label className="ps-2 mb-3">*/}
          {/*    <input*/}
          {/*      id={'notSecurity'}*/}
          {/*      type="checkbox"*/}
          {/*      name="hasSecurity"*/}
          {/*      value="hasSecurity"*/}
          {/*      checked={filters?.hasSecurity?.includes(0) || false}*/}
          {/*      onChange={() => {*/}
          {/*        onMultiCheckboxHandler('hasSecurity', 0, setFilters);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <span className="fs-11 ms-3">Нет</span>*/}
          {/*  </label>*/}
          {/*</fieldset>*/}

          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Площадь, соток:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-2">От</div>
              <input
                type="number"
                className="w-100 me-3"
                value={filters?.startAcres || ''}
                onChange={(e) => onInputHandler(e, 'startAcres', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100"
                value={filters?.endAcres || ''}
                onChange={(e) => onInputHandler(e, 'endAcres', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend className="title-font  fs-11 fw-6 mb-3">Тип участка:</legend>
            {areaTypes.map((area) => (
              <label className="ps-2 mb-3" key={area.id}>
                <input
                  type="checkbox"
                  name="areaTypes"
                  value="areaTypes"
                  checked={filters?.areaTypes?.includes(area.id) || false}
                  onChange={() => {
                    onMultiCheckboxHandler('areaTypes', area.id, setFilters);
                  }}
                />
                <span className="fs-11 ms-3">{area.name}</span>
              </label>
            ))}
          </fieldset>
          {/*<fieldset className="mb-4">*/}
          {/*  <legend className="title-font  fs-11 fw-6 mb-3">Удаленность от города:</legend>*/}
          {/*  <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">*/}
          {/*    <div className="  me-2">От</div>*/}
          {/*    <input*/}
          {/*      type="number"*/}
          {/*      className="me-3"*/}
          {/*      value={filters?.startCityDistance || ''}*/}
          {/*      onChange={(e) => onInputHandler(e, 'startCityDistance', true, setFilters)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</fieldset>*/}
          <AdvansedFilters filters={filters} setFilters={setFilters} />
        </>
      )}

      {filters.typesEstate === 3 && (
        <>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Площадь, соток:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="me-2">От</div>
              <input
                type="number"
                className="w-100 me-3"
                value={filters?.startAcres || ''}
                onChange={(e) => onInputHandler(e, 'startAcres', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100"
                value={filters?.endAcres || ''}
                onChange={(e) => onInputHandler(e, 'endAcres', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Расстояние до города:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-2">От</div>
              <input
                type="number"
                className="w-100 me-3"
                value={filters?.startCityDistance || ''}
                onChange={(e) => onInputHandler(e, 'startCityDistance', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100"
                value={filters?.endCityDistance || ''}
                onChange={(e) => onInputHandler(e, 'endCityDistance', true, setFilters)}
              />
            </div>
          </fieldset>
        </>
      )}

      {filters.typesEstate === 4 && (
        <>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Общая площадь:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-2">От</div>
              <input
                type="number"
                className="w-100 area me-3"
                value={filters?.startTotalArea || ''}
                onChange={(e) => onInputHandler(e, 'startTotalArea', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100 area"
                value={filters?.endTotalArea || ''}
                onChange={(e) => onInputHandler(e, 'endTotalArea', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font fs-12 fw-6 mb-3">Охрана:</legend>
            <label className="ps-2 mb-3">
              <input
                id={'hasSecurity'}
                type="checkbox"
                name="hasSecurity"
                value="hasSecurity"
                checked={filters?.hasSecurity?.includes(1) || false}
                onChange={() => {
                  onMultiCheckboxHandler('hasSecurity', 1, setFilters);
                }}
              />
              <span className="fs-11 ms-3">Да</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                id={'notSecurity'}
                type="checkbox"
                name="hasSecurity"
                value="hasSecurity"
                checked={filters?.hasSecurity?.includes(0) || false}
                onChange={() => {
                  onMultiCheckboxHandler('hasSecurity', 0, setFilters);
                }}
              />
              <span className="fs-11 ms-3">Нет</span>
            </label>
          </fieldset>
        </>
      )}
      {filters.typesEstate === 5 && (
        <>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Общая площадь:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-2">От</div>
              <input
                type="number"
                className="w-100 area me-3"
                value={filters?.startTotalArea || ''}
                onChange={(e) => onInputHandler(e, 'startTotalArea', true, setFilters)}
              />
              <div className="  me-2">До</div>
              <input
                type="number"
                className="w-100 area"
                value={filters?.endTotalArea || ''}
                onChange={(e) => onInputHandler(e, 'endTotalArea', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font fs-12 fw-6 mb-3">Тип здания:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                value="no repair"
                checked={filters?.buildingType?.includes(0) || false}
                onChange={() => onMultiCheckboxHandler('buildingType', 0, setFilters)}
              />
              <span className="fs-11 ms-3">Бизнес-центр</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                value="repair 1"
                checked={filters?.buildingType?.includes(1) || false}
                onChange={() => onMultiCheckboxHandler('buildingType', 1, setFilters)}
              />
              <span className="fs-11 ms-3">Торговый центр</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                value="repair 2"
                checked={filters?.buildingType?.includes(2) || false}
                onChange={() => onMultiCheckboxHandler('buildingType', 2, setFilters)}
              />
              <span className="fs-11 ms-3">Административное здание</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                value="repair 3"
                checked={filters?.buildingType?.includes(3) || false}
                onChange={() => onMultiCheckboxHandler('buildingType', 3, setFilters)}
              />
              <span className="fs-11 ms-3">Жилой дом</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="repair"
                value="repair 3"
                checked={filters?.buildingType?.includes(4) || false}
                onChange={() => onMultiCheckboxHandler('buildingType', 4, setFilters)}
              />
              <span className="fs-11 ms-3">Другое</span>
            </label>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Тип дома:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Кирпичный"
                checked={filters?.houseBuildingTypes?.includes(0) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 0, setFilters)}
              />
              <span className="  ms-2">Кирпичный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Панельный"
                checked={filters?.houseBuildingTypes?.includes(1) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 1, setFilters)}
              />
              <span className="  ms-2">Панельный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Монолитный"
                checked={filters?.houseBuildingTypes?.includes(2) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 2, setFilters)}
              />
              <span className="  ms-2">Монолитный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Блочный"
                checked={filters?.houseBuildingTypes?.includes(3) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 3, setFilters)}
              />
              <span className="  ms-2">Блочный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Деревянный"
                checked={filters?.houseBuildingTypes?.includes(4) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 4, setFilters)}
              />
              <span className="  ms-2">Деревянный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Кирпично-монолитный"
                checked={filters.houseBuildingTypes?.includes(5) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 5, setFilters)}
              />
              <span className="  ms-2">Кирпично-монолитный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Керамзитный"
                checked={filters.houseBuildingTypes?.includes(6) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 6, setFilters)}
              />
              <span className="  ms-2">Керамзитный</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Газоблок"
                checked={filters.houseBuildingTypes?.includes(7) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 7, setFilters)}
              />
              <span className="  ms-2">Газоблок</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Пеноблок"
                checked={filters.houseBuildingTypes?.includes(8) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 8, setFilters)}
              />
              <span className="  ms-2">Пеноблок</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Армолитовые блоки"
                checked={filters.houseBuildingTypes?.includes(9) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 9, setFilters)}
              />
              <span className="  ms-2">Армолитовые блоки</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Сип-панели"
                checked={filters.houseBuildingTypes?.includes(10) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 10, setFilters)}
              />
              <span className="  ms-2">Сип-панели</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="house-type"
                value="Смешанные"
                checked={filters.houseBuildingTypes?.includes(11) || false}
                onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 11, setFilters)}
              />
              <span className="  ms-2">Смешанные</span>
            </label>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Год постройки:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-3">От</div>
              <input
                type="number"
                className="me-3"
                pattern="[0-9]{4}"
                value={filters?.yearOfConstruction || ''}
                onChange={(e) => onInputHandler(e, 'yearOfConstruction', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Лифт:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="lift"
                value="Пассажирский"
                checked={filters?.elevatorTypes?.includes(1) || false}
                onChange={() => onMultiCheckboxHandler('elevatorTypes', 1, setFilters)}
              />
              <span className="  ms-2">Пассажирский</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="lift"
                value="Грузовой"
                checked={filters?.elevatorTypes?.includes(2) || false}
                onChange={() => onMultiCheckboxHandler('elevatorTypes', 2, setFilters)}
              />
              <span className="  ms-2">Грузовой</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="lift"
                value="Пассажирский/Грузовой"
                checked={filters.elevatorTypes?.includes(3) || false}
                onChange={() => onMultiCheckboxHandler('elevatorTypes', 3, setFilters)}
              />
              <span className="  ms-2">Пассажирский/Грузовой</span>
            </label>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Высота потолков:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
              <div className="  me-3">От</div>
              <input
                type="number"
                className="length me-3"
                value={filters?.ceilingHeight || ''}
                onChange={(e) => onInputHandler(e, 'ceilingHeight', true, setFilters)}
              />
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Пандус:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start   flex-wrap">
              <label className="ps-2">
                <input
                  type="checkbox"
                  name="hasRamp"
                  checked={filters?.hasRamp || false}
                  onChange={(e) => onCheckboxHandler(e, setFilters)}
                />
                <span className="  ms-2">Есть</span>
              </label>
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Мусоропровод:</legend>
            <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start   flex-wrap">
              <label className="ps-2">
                <input
                  type="checkbox"
                  name="hasGarbageСhute"
                  checked={filters?.hasGarbageСhute || false}
                  onChange={(e) => onCheckboxHandler(e, setFilters)}
                />
                <span className="  ms-2">Есть</span>
              </label>
            </div>
          </fieldset>
          <fieldset className="mb-4">
            <legend className="title-font  fs-11 fw-6 mb-3">Парковка:</legend>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasGroundParking"
                checked={filters?.hasGroundParking || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-2">Наземная</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasUnderGroundParking"
                checked={filters?.hasUnderGroundParking || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-2">Подземная</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasMoreLayerParking"
                checked={filters?.hasMoreLayerParking || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-2">Многоуровневая</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasYardParking"
                checked={filters.hasYardParking || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-2">Открытая во дворе</span>
            </label>
            <label className="ps-2 mb-3">
              <input
                type="checkbox"
                name="hasBarrierParking"
                checked={filters.hasBarrierParking || false}
                onChange={(e) => onCheckboxHandler(e, setFilters)}
              />
              <span className="  ms-2">Во дворе за шлагбаумом</span>
            </label>
          </fieldset>
        </>
      )}
      {(filters.typesEstate === 1 || filters.typesEstate === 2) && (
        <button
          className="btn-filter color-1 mx-auto btn-collapse mb-4"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#advanced-filter"
          aria-expanded="false"
        >
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.4286 -3.0664e-08L9 6.25L2.57143 -1.83984e-07L1.04343e-07 1.25L9 10L18 1.25L15.4286 -3.0664e-08Z"
              fill="#146492"
            />
          </svg>
          <span className="ms-3   fw-5" />
        </button>
      )}
      <div className="d-flex  align-items-baseline align-items-lg-start justify-content-between mb-3">
        <button
          className="btn btn-1 color-1 fw-5"
          onClick={(e) => {
            e.preventDefault();
            onApplyFilters();
          }}
        >
          Найденно {foundCount}{' '}
          {(function (number, titles) {
            const cases = [2, 0, 1, 1, 1, 2];
            return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
          })(foundCount, ['объявление', 'объявления', 'объявлений'])}
        </button>
        <button type="button" onClick={onResetFilters} className="color-1   fw-5">
          Очистить фильтр
        </button>
      </div>
    </div>
  );
};

type AdvansedFiltersProps = {
  filters: {
    transactionType: number;
    typesEstate: number;
    districts: string[];
    orderBy: 'asc' | 'dsc';
    estateId: number;
  } & any;
  setFilters: (arg: {}) => void;
};
const AdvansedFilters = ({ filters, setFilters }: AdvansedFiltersProps) => {
  return (
    <div className="collapse" id="advanced-filter">
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Общая площадь:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
          <div className="  me-2">От</div>
          <input
            type="number"
            className="w-100 area me-3"
            value={filters?.startTotalArea || ''}
            onChange={(e) => onInputHandler(e, 'startTotalArea', true, setFilters)}
          />
          <div className="  me-2">До</div>
          <input
            type="number"
            className="w-100 area"
            value={filters?.endTotalArea || ''}
            onChange={(e) => onInputHandler(e, 'endTotalArea', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Жилая площадь:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start ">
          <div className="  me-2">От</div>
          <input
            type="number"
            className="w-100 area me-3"
            value={filters?.startLivingArea || ''}
            onChange={(e) => onInputHandler(e, 'startLivingArea', true, setFilters)}
          />
          <div className="  me-2">До</div>
          <input
            type="number"
            className="w-100 area"
            value={filters?.endLivingArea || ''}
            onChange={(e) => onInputHandler(e, 'endLivingArea', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Площадь кухни:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start">
          <div className="  me-2">От</div>
          <input
            type="number"
            className="w-100 area me-3"
            value={filters?.startKitchenArea || ''}
            onChange={(e) => onInputHandler(e, 'startKitchenArea', true, setFilters)}
          />
          <div className="  me-2">До</div>
          <input
            type="number"
            className="w-100 area"
            value={filters?.endKitchenArea || ''}
            onChange={(e) => onInputHandler(e, 'endKitchenArea', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Планировка:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="layout"
            value="Изолированная"
            checked={filters?.layoutTypes?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('layoutTypes', 0, setFilters)}
          />
          <span className="  ms-3">Изолированная</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="layout"
            value="Смежная"
            checked={filters?.layoutTypes?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('layoutTypes', 1, setFilters)}
          />
          <span className="  ms-3">Смежная</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="layout"
            value="Свободная"
            checked={filters?.layoutTypes?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('layoutTypes', 2, setFilters)}
          />
          <span className="  ms-3">Свободная</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="layout"
            value="Смежно-изолированная"
            checked={filters.layoutTypes?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('layoutTypes', 3, setFilters)}
          />
          <span className="  ms-3">Смежно-изолированная</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Санузел:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="bathroom"
            value="Раздельный"
            checked={filters?.WCTypes?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('WCTypes', 0, setFilters)}
          />
          <span className="  ms-3">Раздельный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="bathroom"
            value="Совмещенный"
            checked={filters?.WCTypes?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('WCTypes', 1, setFilters)}
          />
          <span className="  ms-3">Совмещенный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="bathroom"
            value="2 и более"
            checked={filters?.WCTypes?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('WCTypes', 2, setFilters)}
          />
          <span className="  ms-3">2 и более</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Этаж:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
          <div className="  me-2">От</div>
          <input
            type="number"
            className="w-100 me-3"
            value={filters?.startFloor || ''}
            onChange={(e) => onInputHandler(e, 'startFloor', true, setFilters)}
          />
          <div className="  me-2">До</div>
          <input
            type="number"
            className="w-100"
            value={filters?.endFloor || ''}
            onChange={(e) => onInputHandler(e, 'endFloor', true, setFilters)}
          />
        </div>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start   mt-2">
          <label className="ps-2">
            <input
              type="checkbox"
              name="notFirstFloor"
              checked={filters?.notFirstFloor || false}
              onChange={(e) => onCheckboxHandler(e, setFilters)}
            />
            <span className="  ms-3">Не первый</span>
          </label>
          <label className="ms-5 ms-lg-2 mt-lg-2">
            <input
              type="checkbox"
              name="notLastFloor"
              checked={filters?.notLastFloor || false}
              onChange={(e) => onCheckboxHandler(e, setFilters)}
            />
            <span className="  ms-3">Не последний</span>
          </label>
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Этажей в доме:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
          <div className="  me-2">От</div>
          <input
            type="number"
            className="w-100 me-3"
            value={filters?.startMaxFloor || ''}
            onChange={(e) => onInputHandler(e, 'startMaxFloor', true, setFilters)}
          />
          <div className="  me-2">До</div>
          <input
            type="number"
            className="w-100"
            value={filters?.endMaxFloor || ''}
            onChange={(e) => onInputHandler(e, 'endMaxFloor', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Балкон/Лоджия:</legend>
        <label className="ps-2 mb-2">
          <input
            type="checkbox"
            name="balcony"
            value="Балкон"
            checked={filters?.balconyTypes?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('balconyTypes', 1, setFilters)}
          />
          <span className="  ms-3">Балкон</span>
        </label>
        <label className="ps-2 mb-2">
          <input
            type="checkbox"
            name="balcony"
            value="Лоджия"
            checked={filters?.balconyTypes?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('balconyTypes', 2, setFilters)}
          />
          <span className="  ms-3">Лоджия</span>
        </label>
        <label className="ps-2 mb-2">
          <input
            type="checkbox"
            name="balcony"
            value="Несколько"
            checked={filters?.balconyTypes?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('balconyTypes', 3, setFilters)}
          />
          <span className="  ms-3">Несколько</span>
        </label>
        <label className="ps-2 mb-2">
          <input
            type="checkbox"
            name="balcony"
            value="Нет"
            checked={filters?.balconyTypes?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('balconyTypes', 0, setFilters)}
          />
          <span className="  ms-3">Нет</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Тип дома:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Кирпичный"
            checked={filters?.houseBuildingTypes?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 0, setFilters)}
          />
          <span className="  ms-2">Кирпичный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Панельный"
            checked={filters?.houseBuildingTypes?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 1, setFilters)}
          />
          <span className="  ms-2">Панельный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Монолитный"
            checked={filters?.houseBuildingTypes?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 2, setFilters)}
          />
          <span className="  ms-2">Монолитный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Блочный"
            checked={filters?.houseBuildingTypes?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 3, setFilters)}
          />
          <span className="  ms-2">Блочный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Деревянный"
            checked={filters?.houseBuildingTypes?.includes(4) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 4, setFilters)}
          />
          <span className="  ms-2">Деревянный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Кирпично-монолитный"
            checked={filters.houseBuildingTypes?.includes(5) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 5, setFilters)}
          />
          <span className="  ms-2">Кирпично-монолитный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Керамзитный"
            checked={filters.houseBuildingTypes?.includes(6) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 6, setFilters)}
          />
          <span className="  ms-2">Керамзитный</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Газоблок"
            checked={filters.houseBuildingTypes?.includes(7) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 7, setFilters)}
          />
          <span className="  ms-2">Газоблок</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Пеноблок"
            checked={filters.houseBuildingTypes?.includes(8) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 8, setFilters)}
          />
          <span className="  ms-2">Пеноблок</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Армолитовые блоки"
            checked={filters.houseBuildingTypes?.includes(9) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 9, setFilters)}
          />
          <span className="  ms-2">Армолитовые блоки</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Сип-панели"
            checked={filters.houseBuildingTypes?.includes(10) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 10, setFilters)}
          />
          <span className="  ms-2">Сип-панели</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="house-type"
            value="Смешанные"
            checked={filters.houseBuildingTypes?.includes(11) || false}
            onChange={() => onMultiCheckboxHandler('houseBuildingTypes', 11, setFilters)}
          />
          <span className="  ms-2">Смешанные</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Год постройки:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
          <div className="  me-3">От</div>
          <input
            type="number"
            className="me-3"
            pattern="[0-9]{4}"
            value={filters?.yearOfConstruction || ''}
            onChange={(e) => onInputHandler(e, 'yearOfConstruction', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Лифт:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="lift"
            value="Пассажирский"
            checked={filters?.elevatorTypes?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('elevatorTypes', 1, setFilters)}
          />
          <span className="  ms-2">Пассажирский</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="lift"
            value="Грузовой"
            checked={filters?.elevatorTypes?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('elevatorTypes', 2, setFilters)}
          />
          <span className="  ms-2">Грузовой</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="lift"
            value="Пассажирский/Грузовой"
            checked={filters.elevatorTypes?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('elevatorTypes', 3, setFilters)}
          />
          <span className="  ms-2">Пассажирский/Грузовой</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Высота потолков:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start  ">
          <div className="  me-3">От</div>
          <input
            type="number"
            className="length me-3"
            value={filters?.ceilingHeight || ''}
            onChange={(e) => onInputHandler(e, 'ceilingHeight', true, setFilters)}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Окна:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windows"
            value="Во двор"
            checked={filters.window?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('window', 0, setFilters)}
          />
          <span className="  ms-3">Во двор</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windows"
            value="На улицу"
            checked={filters.window?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('window', 1, setFilters)}
          />
          <span className="  ms-3">На улицу</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windows"
            value="На солнечную сторону"
            checked={filters.window?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('window', 2, setFilters)}
          />
          <span className="  ms-3">На солнечную сторону</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windows"
            value="На две стороны"
            checked={filters.window?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('window', 3, setFilters)}
          />
          <span className="  ms-3">На две стороны</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Тип окон:</legend>

        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Обычное прямоугольное"
            checked={filters.windowType?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 0, setFilters)}
          />
          <span className="  ms-3">Обычное прямоугольное</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="С вращающейся рамой"
            checked={filters.windowType?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 1, setFilters)}
          />
          <span className="  ms-3">С вращающейся рамой</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="В нише"
            checked={filters.windowType?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 2, setFilters)}
          />
          <span className="  ms-3">В нише</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Панорамное"
            checked={filters.windowType?.includes(3) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 3, setFilters)}
          />
          <span className="  ms-3">Панорамное</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Французское"
            checked={filters.windowType?.includes(4) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 4, setFilters)}
          />
          <span className="  ms-3">Французское</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Эркер"
            checked={filters.windowType?.includes(5) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 5, setFilters)}
          />
          <span className="  ms-3">Эркер</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="С изогнутым верхом"
            checked={filters.windowType?.includes(6) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 6, setFilters)}
          />
          <span className="  ms-3">С изогнутым верхом</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Выгнутое"
            checked={filters.windowType?.includes(7) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 7, setFilters)}
          />
          <span className="  ms-3">Выгнутое</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Со скользящей рамой"
            checked={filters.windowType?.includes(8) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 8, setFilters)}
          />
          <span className="  ms-3">Со скользящей рамой</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="windowType"
            value="Со створным переплетом"
            checked={filters.windowType?.includes(9) || false}
            onChange={() => onMultiCheckboxHandler('windowType', 9, setFilters)}
          />
          <span className="  ms-3">Со створным переплетом</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Хозпостройки:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="outBuildingType"
            value="Гараж"
            checked={filters.outBuildingType?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('outBuildingType', 0, setFilters)}
          />
          <span className="  ms-3">Гараж</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="outBuildingType"
            value="Баня"
            checked={filters.outBuildingType?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('outBuildingType', 1, setFilters)}
          />
          <span className="  ms-3">Баня</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="outBuildingType"
            value="Хозпостройки"
            checked={filters.outBuildingType?.includes(2) || false}
            onChange={() => onMultiCheckboxHandler('outBuildingType', 2, setFilters)}
          />
          <span className="  ms-3">Хозпостройки</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Подвал:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasBasement"
            value="Нет"
            checked={filters.hasBasement?.includes(0) || false}
            onChange={() => onMultiCheckboxHandler('hasBasement', 0, setFilters)}
          />
          <span className="  ms-3">Нет</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasBasement"
            value="Есть"
            checked={filters.hasBasement?.includes(1) || false}
            onChange={() => onMultiCheckboxHandler('hasBasement', 1, setFilters)}
          />
          <span className="  ms-3">Есть</span>
        </label>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Дополнительно:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start   flex-wrap">
          <label className="ps-2">
            <input
              type="checkbox"
              name="hasRamp"
              checked={filters?.hasRamp || false}
              onChange={(e) => onCheckboxHandler(e, setFilters)}
            />
            <span className="  ms-2">Пандус</span>
          </label>
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Мусоропровод:</legend>
        <div className="d-flex flex-lg-column  align-items-baseline align-items-lg-start   flex-wrap">
          <label className="ps-2">
            <input
              type="checkbox"
              name="hasGarbageСhute"
              checked={filters?.hasGarbageСhute || false}
              onChange={(e) => onCheckboxHandler(e, setFilters)}
            />
            <span className="  ms-2">Есть</span>
          </label>
        </div>
      </fieldset>
      <fieldset className="mb-4">
        <legend className="title-font  fs-11 fw-6 mb-3">Парковка:</legend>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasGroundParking"
            checked={filters?.hasGroundParking || false}
            onChange={(e) => onCheckboxHandler(e, setFilters)}
          />
          <span className="  ms-2">Наземная</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasUnderGroundParking"
            checked={filters?.hasUnderGroundParking || false}
            onChange={(e) => onCheckboxHandler(e, setFilters)}
          />
          <span className="  ms-2">Подземная</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasMoreLayerParking"
            checked={filters?.hasMoreLayerParking || false}
            onChange={(e) => onCheckboxHandler(e, setFilters)}
          />
          <span className="  ms-2">Многоуровневая</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasYardParking"
            checked={filters.hasYardParking || false}
            onChange={(e) => onCheckboxHandler(e, setFilters)}
          />
          <span className="  ms-2">Открытая во дворе</span>
        </label>
        <label className="ps-2 mb-3">
          <input
            type="checkbox"
            name="hasBarrierParking"
            checked={filters.hasBarrierParking || false}
            onChange={(e) => onCheckboxHandler(e, setFilters)}
          />
          <span className="  ms-2">Во дворе за шлагбаумом</span>
        </label>
      </fieldset>
    </div>
  );
};
