export default {
  //auth
  ACTIVATE_ACCOUNT: '/auth/activate',
  LOGIN: '/auth/login',
  RESET_PASSWORD: '/auth/rememberPassword',
  REGISTRATION: '/auth/register',
  LOGOUT: '/auth/logout',
  REFRESH: '/auth/refresh',

  //page ads
  ADS_ACTIONS: '/realEstates',
  ADS_ACTIONS_WISHLIST: '/realEstates/wishlist',
  ADS_REPORT: '/realEstates/reports',
  CREATE_AD: '/realEstates/create',
  GET_RESPONSES_AD: '/responses/realEstate',

  //catalog
  GET_CATALOG: '/realEstates/paginate',
  GET_DISTRICTS: '/districts',

  //cities
  GET_CITIES: '/cities',

  //main page
  GET_RECOMMEND_ADS: '/realEstates/recommended',
  GET_POPULAR_ADS: '/realEstates/popular',
  GET_BANNERS: '/banners',
  GET_STORIES: '/stories',

  //articles
  GET_ARTICLES_ACTIONS: '/news',
  GET_ARTICLES_RANDOM: '/news/random',
  GET_ARTICLES_TOP: '/news/top',

  //blackMarker
  CREATE_BLACK_MARKER: '/user/blackMarker',
  DELETE_BLACK_MARKER: '/user/blackMarker/delete',
  PAGINATE_BLACK_MARKER: '/user/blackMarker/paginate',
  GET_REALESTATES_FROM_BM:'/user/blackMarker/realEstates',

  //question
  ASK_QUESTIONS: '/questions',

  // rieltor calls
  CALL_RIELTOR: '/call-rieltor',

  // realEstates - general
  GET_ESTATE_TYPES: '/realEstates/types',
  GET_FOR_MAP: '/realEstates/getForMap',
  CREATE_ESTATE_RESPONSE: '/responses/create/realEstate',

  //profile
  USER_UPDATE: '/user/update',
  GET_USER: '/user',
  USER_DELETE_AVATAR: '/user/deleteAvatar',
  USER_ADS: '/user/realEstates',
  USER_ADS_WISHLIST: '/user/adsWishlist',
  USER_SERVICES_WISHLIST: '/user/servicesWishlist',
  USER_DELETE_AD: '/realEstates/delete',
  USER_UPDATE_AD: '/realEstates/update',
  USER_REVIEWS: '/user/reviews',
  SEND_CODE: '/user/phone/sendCode',
  CHECK_CODE: '/user/phone/checkCode',

  //users pages
  ADD_REVIEW_USER: '/user/reviews/add',
  REPORT_ACTIONS_USER: '/user/reports',
  REPORT_ACTIONS_REVIEWS: '/user/reviewsReports',
  REVIEWS_IN_USERS_PAGE: '/user/reviews',

  //service
  GET_SERVICE_TYPES: '/services/types',
  GET_SERVICE_TYPE: '/services/type',
  GET_SERVICE_SUB_TYPES: '/services/subServicesTypes',
  GET_SERVICE_ATTRIBUTES_TYPES: '/services/attributesTypes',
  SERVICES_ACTIONS: '/services',
  ADD_SERVICES: '/services/add',
  SERVICES_ACTIONS_WISHLIST: '/services/wishlist',

  // responses
  RESPONSES_INCOMINGS: '/responses/incumings',
  RESPONSES_OUTGOINGS: '/responses/outgoings',
  RESPONSES_INPROCESS_OWNER: '/responses/inProcess/owner',
  RESPONSES_INPROCESS_EXECUTOR: '/responses/inProcess/executor',
  RESPONSES_COMPLETED: '/responses/completed',
  RESPONSES_ACCEPT: '/responses/accept',
  RESPONSES_COMPLETE: '/responses/complete',
  RESPONSES_ACTIONS: '/responses',
  RESPONSES_CREATE: '/responses/create/service',

  // delete images
  DELETE_IMAGE: '/realEstates/deleteImage',
  DELETE_IMAGE_SERVICE: '/services/deleteImage',
  DELETE_VIDEO: '/realEstates/deleteVideo',
};
