import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { deleteService } from '../../../API/services';
import { userInfo } from '../../../API/users';
import CustomModal from '../../../components/CustomModal';
import Loader from '../../../components/Loader';
import ServiceCard from '../../../components/ServiceCard/ServiceCard';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useIsMobile from '../../../hooks/useIsMobile';
import alertActions from '../../../store/actions/alert';
import { useAccessToken, useCurrentUser } from '../../../store/reducers';
import * as S from './styles';

export default function UserServices() {
  const currentUser = useCurrentUser();
  const axiosPrivate = useAxiosPrivate();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userService, setUserService] = useState({
    isLoading: false,
    data: [],
  });
  const currentToken = useAccessToken();
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  useEffect(() => {
    currentUser && userInfo(currentUser?.id).then((res) => setUserService({ isLoading: true, data: res.services }));
    window.scrollTo(0, 0);
  }, [currentUser?.id]);

  const [serviceId, setServiceId] = useState(null);
  const isMobile = useIsMobile(768);

  return (
    <S.StyledDivWrapper className="pb-xxl-5">
      {userService.isLoading ? (
        userService?.data?.length !== 0 ? (
          userService.data.map((service) => (
            <ServiceCard
              service={service}
              user={currentUser}
              type={!isMobile && 'as-a-list'}
              description={service.description}
              serviceTypeId={service.serviceTypeId}
              key={service.id}
              id={service.id}
              labels={service.labels}
              images={service.images}
              subServices={service.subServices}
              callback={(id) => {
                setServiceId(id);
                setShowModalDelete(true);
              }}
            />
          ))
        ) : (
          <div>
            <img src="/img/services-gray.svg" alt="услуги" className="img-fluid d-block mx-auto mb-4 mb-xl-5" />
            <div className="fs-12 text-center">
              Предоставляете услуги риелтора, дизайнера, ремонтного рабочего или грузоперевозчика? <br /> Создайте
              объявление об услугах и находите заказчиков.
            </div>
            <Link to="create" className="btn btn-1 fs-12 mx-auto mt-4 mt-xl-5">
              Создать
            </Link>
          </div>
        )
      ) : (
        <div className="p-5 w-100 d-flex justify-content-center">
          <Loader color="#146492" />
        </div>
      )}

      <CustomModal isShow={showModalDelete} setIsShow={setShowModalDelete} closeButton={true}>
        <div>
          <div className="text-center fs-15 fw-6 title-font my-5">Вы уверены что хотите удалить услугу?</div>
          <div className="row row-cols-2">
            <div>
              <button
                type="button"
                onClick={() => setShowModalDelete(false)}
                className="btn btn-2 w-100 fs-11 text-uppercase"
              >
                Отмена
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-1 w-100 fs-11 text-uppercase"
                onClick={() => {
                  deleteService(axiosPrivate, serviceId, currentToken)
                    .then(() => {
                      userInfo(currentUser?.id).then((res) =>
                        setUserService({
                          isLoading: true,
                          data: res.services,
                        })
                      );
                      setShowModalDelete(false);
                      setAlert('success', true, 'Услуга успешно удалена');
                    })
                    .catch(() => {
                      setShowModalDelete(false);
                      setAlert('danger', true, 'Произошла ошибка сервера');
                    });
                }}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </S.StyledDivWrapper>
  );
}
