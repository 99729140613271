import actionTypes from '../actions/actionTypes';

const initialState = null;

const reducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case actionTypes.setFavoriteCount:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
