import { useEffect, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

export default function useIsMobile(widthTrigger = 991) {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    width < widthTrigger ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  return isMobile;
}
