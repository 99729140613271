import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card/CardFav';
import { Link } from 'react-router-dom';
import { useAccessToken, useCurrentUser } from '../../../store/reducers';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { deleteAds, getMyAds } from '../../../API/users';
import Loader from '../../../components/Loader';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../../../store/actions/alert';
import usePagination from '../../../hooks/pagination';
import Pagination from '../../../components/Pagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as S from './styles';

const DeleteButton = ({deleteAd, item, view}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState(null);

  return <S.ButtonRelocate className={view === 'as-a-list' ? 'd-flex' : ''}>
  <Link
    to={`/advertise/${item?.uuid}`}
    className={
      view === 'as-a-list' ? 'color-1 d-flex' : 'mt-2 color-1 d-flex align-items-center'
    }
  >
    {/* <img src="/img/icons/pa-9.svg" alt="Редактировать" /> */}
    <S.RoundedBottomEdit>Редактировать</S.RoundedBottomEdit>
  </Link>
  <button
    onClick={() => {setShowModal(true); setSelectedUuid(item?.uuid)}}
    type="button"
    className={
      view === 'as-a-list' ? 'color-1 d-flex' : 'mt-2 color-1 d-flex align-items-center'
    }
  >
    {/* <img src="/img/icons/pa-10.svg" alt="Удалить" /> */}
    <S.RoundedBottomRemove className="ms-2">Удалить</S.RoundedBottomRemove>
  </button>
  {showModal && <div className="modal fade show" id="delete-ad" tabIndex="-1" style={{ display: "block" }} aria-modal="true" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
        <button type="button" className="btn-close" onClick={() => setShowModal(false)}>
            <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00006 1.18237L15 15.9049" />
              <path d="M14.9999 1.18237L1.00001 15.9049" />
            </svg>
          </button>
          <div className="text-center fs-15 fw-6 title-font my-5">
            Вы уверены что хотите удалить объявление?
          </div>
          <div className="row row-cols-2">
            <div>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-2 w-100 fs-11 text-uppercase"
                onClick={() => setShowModal(false)}
              >
                Отмена
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-1 w-100 fs-11 text-uppercase"
                onClick={() => {
                  setShowModal(false)
                  deleteAd(selectedUuid);
                }}
                data-bs-dismiss="modal"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>}
</S.ButtonRelocate>
}

export default function UserAds({ routeName }) {
  const [view, setView] = useState('as-a-list');
  const axiosPrivate = useAxiosPrivate();
  const currentUser = useCurrentUser();
  const token = useAccessToken();
  const userId = currentUser?.id;
  const [myAds, setMyAds] = useState({ isLoaded: false, data: [] });

  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const adsPag = usePagination(2);
  const [nextPage, setNextPage] = useState(2);

  const getFetchData = async () => {
    getMyAds(userId, nextPage, token, 2, axiosPrivate).then((res) => {
      setNextPage(nextPage + 1);
      setMyAds({
        isLoaded: true,
        data: [...myAds.data, ...res.body.data],
        meta: res.body,
      });
    });
  };

  useEffect(() => {
    function updateSize() {
      if (window.matchMedia('(max-width: 1399px)').matches) {
        setView('tiled');
      } else {
        setView('as-a-list');
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    getMyAds(userId, 1, token, 2, axiosPrivate).then((res) => {
      setMyAds({
        isLoaded: true,
        data: res.body.data,
        meta: res.body,
      });
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (myAds.data?.length === 0) {
      adsPag.setStartingPage(1);
      adsPag.setCurrentPage(1);
      setNextPage(2);
    }
  }, [myAds.data?.length]);

  const deleteAd = async (uuid) => {
    await deleteAds(axiosPrivate, uuid, token)
      .then(() => {
        setMyAds({
          ...myAds,
          meta: {
            ...myAds.meta,
            meta: {
              ...myAds.meta.meta,
              total: myAds.meta.meta.total - 1,
            },
          },
          data: myAds.data.filter(({ uuid: id }) => id !== uuid),
        });
        // getMyAds(userId, adsPag.currentPage, token, adsPag.pageLimit, axiosPrivate).then((res) => {
        //   setMyAds({
        //     isLoaded: true,
        //     data: res.body.data,
        //     meta: res.body,
        //   });
        // });
        setAlert('success', true, 'Объявление успешно удалено');
      })
      .catch(() => {
        setAlert('danger', true, 'Произошла ошибка');
      });
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="pb-3 pb-sm-4 pb-xxl-5">
      {/* <nav className=" d-block d-lg-none mt-3 mb-3 mb-sm-5" aria-label="breadcrumb">
        <Link to="/personal-account" className="gray-3">
          &#10094; Назад
        </Link>
      </nav> */}
      {/* <h4 className="text-center color-1 mb-3 mb-sm-4 mb-xl-5">Мои объявления</h4> */}

      <div
      //className={view === 'as-a-list' ? '' : 'row row-cols-sm-2 gx-2 gx-md-4'}
      >
        {myAds?.isLoaded ? (
          myAds?.data?.length > 0 ? (
            <InfiniteScroll
              dataLength={myAds?.data?.length}
              next={() => getFetchData()}
              hasMore={nextPage <= myAds?.meta?.meta?.last_page}
              loader={
                <div className="p-5 w-100 d-flex justify-content-center">
                  <Loader color="#146492" />
                </div>
              }
            >
              <S.FullWidth className={view === 'as-a-list' ? '' : 'row row-cols-sm-2 gx-2 gx-md-4'}>
                {myAds?.data?.map((item) => {
                  return (
                    <S.BottomMarginMobile key={item?.id}>
                      <Card
                        ads={item}
                        type={view}
                        pictures={[item?.image, item?.images]}
                        isVip={item?.isVip}
                        isHot={item?.isHot}
                        title={item?.title}
                        price={item?.price}
                        transactionType={item?.transactionType}
                        residentalComplex={item?.residentalComplex}
                        residentalComplexForUser={item?.residentalComplexForUser}
                        address={item?.address}
                        metro={item?.metro}
                        text={item?.description}
                        date={item?.createdAtForUser}
                        uuid={item?.uuid}
                        user={item?.user}
                        isLargeFont={true}
                        communalPrice={item?.communalPrice}
                        pledge={item?.pledge}
                        commissionForUser={item?.commissionForUser}
                        prepaymentTypeForUser={item?.prepaymentTypeForUser}
                        rentalTypeForUser={item?.rentalTypeForUser}
                        id={item?.id}
                        wishlistStatus={item?.wishlistStatus}
                        userAvatar={item.user?.avatar}
                        reportStatus={item.reportStatus}
                        routeName={routeName}
                        totalArea={item?.totalArea}
                        estateName={item?.estate?.name}
                        buildingTypeForUser={item?.buildingTypeForUser}
                        realEstateTypeForUser={item?.estate?.realEstateTypeForUser}
                        acres={item?.acres}
                        viewsCount={item.viewsCount}
                        style={{ width: '100%' }}
                        controlButton={
                          <DeleteButton deleteAd={deleteAd} item={item} view={view} />
                        }
                      />

                      {/* <div className="modal fade" id="delete-ad" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <button type="button" className="btn-close" data-bs-dismiss="modal">
                                <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1.00006 1.18237L15 15.9049" />
                                  <path d="M14.9999 1.18237L1.00001 15.9049" />
                                </svg>
                              </button>
                              <div className="text-center fs-15 fw-6 title-font my-5">
                                Вы уверены что хотите удалить объявление?
                              </div>
                              <div className="row row-cols-2">
                                <div>
                                  <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="btn btn-2 w-100 fs-11 text-uppercase"
                                  >
                                    Отмена
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-1 w-100 fs-11 text-uppercase"
                                    onClick={() => {
                                      deleteAd(selectedUuid);
                                    }}
                                    data-bs-dismiss="modal"
                                  >
                                    Удалить
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </S.BottomMarginMobile>
                  );
                })}
              </S.FullWidth>
            </InfiniteScroll>
          ) : (
            <h6 className="m-auto p-5 text-center">Объявлений нет</h6>
          )
        ) : (
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        )}
      </div>
      {/*<Pagination*/}
      {/*  pageLimit={adsPag.pageLimit}*/}
      {/*  currentPage={adsPag.currentPage}*/}
      {/*  setCurrentPage={adsPag.setCurrentPage}*/}
      {/*  pagesDisplayedLimit={3}*/}
      {/*  itemsAmount={myAds?.meta?.meta?.total || 0}*/}
      {/*  startingPage={adsPag.startingPage}*/}
      {/*  className="mt-4 mt-sm-5"*/}
      {/*  setStartingPage={adsPag.setStartingPage}*/}
      {/*/>*/}
    </div>
  );
}
