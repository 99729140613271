import styled from 'styled-components';
import { ReactComponent as Favorite } from './assets/img/favorite.svg';
import { ReactComponent as Profile } from './assets/img/pa-1.svg';
import { ReactComponent as Announcement } from './assets/img/pa-2.svg';
import { ReactComponent as Touch } from './assets/img/pa-11.svg';
import { ReactComponent as Services } from './assets/img/pa-3.svg';
import { ReactComponent as Bag } from './assets/img/pa-12.svg';
import { ReactComponent as Emails } from './assets/img/email.svg';
import { ReactComponent as Review } from './assets/img/star.svg';
import { ReactComponent as Leave } from './assets/img/leave-icon.svg';
import { ReactComponent as Quest } from './assets/img/question.svg';

export const HeartMenu = styled(Favorite)`
  margin-right: 1em;
  width: 23px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyProfile = styled(Profile)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyAnnouncement = styled(Announcement)`
  margin-right: 1em;
  width: 22.5px;
  height: 22.5px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyTouch = styled(Touch)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyServices = styled(Services)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyBag = styled(Bag)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyEmails = styled(Emails)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyReviews = styled(Review)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyLeave = styled(Leave)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;

export const MyQuestion = styled(Quest)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;

export const CustomH1 = styled.h1`
  color: #198acb !important;
`;

// export const NavLinkCustom = styled.a`
//     font-family: Montserrat!important;
//     font-size: 24px;
//     font-weight: 400;
//     color: #44494C;
// `
type CounterProps = {
  $right?: number;
  $bottom?: number;
};
export const CounterMenuFav = styled.span<CounterProps>`
  position: absolute;
  top: -5px;
  right: 6px;
  // right: ${({ $right }) => (typeof $right === 'number' ? $right : -10)}px;
  // top: ${({ $bottom }) => (typeof $bottom === 'number' ? $bottom : -2)}px;
  width: 19px;
  height: 19px;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.revert};
  color: ${({ theme }) => theme.colors.grey33};
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  z-index: 100;
`;
export const CounterMenuEm = styled.span<CounterProps>`
  position: absolute;
  width: 19px;
  height: 19px;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.revert};
  color: ${({ theme }) => theme.colors.grey33};
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  z-index: 100;
  margin-left: 10px;
  margin-top: -17px;
`;

export const SpaceDivider = styled.div`
  width: 15px;
  height: 1px;
  background: transparent;
`;
