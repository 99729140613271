import React from 'react';
import * as SS from '../../pages/PersonalAccount/UserProfile/styles';
import { checkPhotoPath } from '../../helpers/photo';
import * as S from '../Card/styles';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PartnerIcon } from '../../assets/icons/profile/parther.svg';

const StarRatingWithNumber = ({ rating }) => {
  const roundedRating = Math.round(rating);
  return (
    <SS.UserPageRatingWrapper isMobile={true}>
      <img src="/img/icons/star-blue.svg" alt="Рейтинг" />
      <SS.UserPageRatingSpan isMobile={true}>{roundedRating}</SS.UserPageRatingSpan>
    </SS.UserPageRatingWrapper>
  );
};

export default function UserHeader({ user, isReview, avatarClick }) {
  console.log({ user });
  if (isReview) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="user-photo" style={{ position: 'relative' }}>
            <img
              style={{
                'object-fit': 'cover',
                width: '5rem',
                height: '5rem',
                borderRadius: '50%',
              }}
              src={checkPhotoPath(user?.avatar)}
              alt={user?.fullName}
            />
            {user?.isPartner ? (
              <PartnerIcon
                style={{
                  width: '35%',
                  height: '35%',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translate(0%, 0%)',
                }}
              />
            ) : null}
            {/*<div className="indicator online"/>*/}
          </div>

          <SS.SkeletonMargin2>
            <SS.AvatarTitle>{user?.fullName}</SS.AvatarTitle>
            <SS.AvatarUnder>На сайте с {user?.createdAtForUser}</SS.AvatarUnder>
          </SS.SkeletonMargin2>
        </div>

        <StarRatingWithNumber rating={user?.rating || 0} />
      </div>
    );
  } else {
    return (
      <S.AuthorSkeletonBetweemFav
        style={{
          'max-width': '500px',
          display: 'flex',
          'align-items': 'center',
        }}
      >
        <S.AuthorSkeleton>
          <S.ImgHolder2>
            {typeof avatarClick ? (
              <span style={{ position: 'relative' }}>
                <img
                  src={checkPhotoPath(user?.avatar) ? checkPhotoPath(user?.avatar) : '/img/img-photo.svg'}
                  alt="Фото"
                  onClick={avatarClick}
                />
                {user?.isPartner ? (
                  <PartnerIcon
                    style={{
                      width: '35%',
                      height: '35%',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      transform: 'translate(0%, 0%)',
                    }}
                  />
                ) : null}
              </span>
            ) : (
              <NavLink to={`/user/${user?.id}`} style={{ position: 'relative' }}>
                <img
                  src={checkPhotoPath(user?.avatar) ? checkPhotoPath(user?.avatar) : '/img/img-photo.svg'}
                  alt="Фото"
                />
                {user?.isPartner ? (
                  <PartnerIcon
                    style={{
                      width: '35%',
                      height: '35%',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      transform: 'translate(0%, 0%)',
                    }}
                  />
                ) : null}
              </NavLink>
            )}
          </S.ImgHolder2>

          <SS.SkeletonMargin2>
            <SS.AvatarTitle>{user?.fullName}</SS.AvatarTitle>
            <SS.AvatarUnder>На сайте с {user?.createdAtForUser}</SS.AvatarUnder>
          </SS.SkeletonMargin2>

          {/* <S.NameTextFav>
              <NavLink to={`/user/${user?.id}`}>{user?.fullName}</NavLink>
            </S.NameTextFav>
            {user?.createdAtForUser && (
              <S.DataRegistrationTextFav>На сайте с {user?.createdAtForUser}</S.DataRegistrationTextFav>
            )} */}
          {/* <ShowPhone className="fs-09 mt-3" phone={.user?.phone} /> */}
          {/* </div> */}
        </S.AuthorSkeleton>
        <StarRatingWithNumber rating={user?.rating || 0} />
      </S.AuthorSkeletonBetweemFav>
    );
  }
}
