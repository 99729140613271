import CityContainer from '../CityContainer/CityContainer';
import YMap from '../../YMap';
import OffcanvasCards from '../../OffcanvasCards';
import { EstateFilters } from '../../EstateFilters';
import CustomModal from '../../CustomModal';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../helpers/isEmpty';
import getForMap from '../../../API/ymap';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import * as S from './styles';
import { ReactComponent as Filter } from '../../../assets/icons/main/filter.svg';
import { Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

type MapProps = {};
export const MapSection = ({}: MapProps) => {
  const { width } = useWindowDimensions();
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isShowModalFilters, setIsShowModalFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [additionalFilters, setAdditionalFilters] = useState({});
  const [favId, setFavId] = useState('');
  const { currentUser, city, favs } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    city: state.selectedCity,
    favs: state.favoriteCount,
  }));
  const userId = currentUser?.id;
  const [mapData, setMapData] = useState<{ [key: string]: any }[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const [cards, setCards] = useState<{ [key: string]: any }[]>([]);
  const [blackMarkerClick, setBlackMarkerClick] = useState(false);

  const onResetFilters = () => {
    sessionStorage.removeItem('filters');
    setFilters({});
    setAdditionalFilters({});
    setIsShowModalFilters(false);
    setIsShowFilters(false);
  };

  const onApplyFilters = () => {
    if (!isEmpty(additionalFilters)) sessionStorage.setItem('filters', JSON.stringify(additionalFilters));
    setFilters((prevFilters) => ({ ...prevFilters, ...additionalFilters }));
    setIsShowModalFilters(false);
    setIsShowFilters(false);
  };

  useEffect(() => {
    sessionStorage.removeItem('filters');
    // const filters = sessionStorage.getItem('filters');
    // if (!!filters) {
    //   const filtersObj = JSON.parse(filters);
    //   setFilters(filtersObj);
    // }
  }, []);

  useEffect(() => {
    setAdditionalFilters((prevAdditionalFilters) => ({
      ...prevAdditionalFilters,
      ...filters,
    }));
  }, [filters]);

  useEffect(() => {
    //  if (isEmpty(filters) && !!sessionStorage.getItem('filters')) return;
    getForMap(city, userId, filters).then((items) => {
      setMapData([]);
      setTimeout(() => setMapData(items), 500);
      console.log({ items });
    });
  }, [city, userId, favs, filters, blackMarkerClick]);

  useEffect(() => {
    if (ids?.length) {
      const result: any[] = [];

      mapData.forEach((item) => {
        ids.forEach((id) => {
          if (id === item.id) {
            result.push(item);
          }
        });
      });

      setCards(result);
    }
  }, [ids, mapData]);

  useEffect(() => {
    // if (isShowFilters && width < 992) setIsShowFilters(false);
    // if (isShowModalFilters && width > 991) setIsShowModalFilters(false);
    if (isShowModalFilters) setIsShowModalFilters(false);
  }, [width]);

  return (
    <section id="sec-3" className="container">
      <S.Wrapper className={'main-page__ymaps-container'}>
        <S.Header>
          <CityContainer />
          <button
            type="button"
            className="d-flex align-items-center"
            onClick={() => {
              setIsShowModalFilters((prevIsShowFilters) => !prevIsShowFilters);
              // if (width < 992) setIsShowModalFilters((prevIsShowFilters) => !prevIsShowFilters);
              // else setIsShowFilters((prevIsShowFilters) => !prevIsShowFilters);
            }}
          >
            <Filter />
            <span className="ms-2 fs-11 fw-5">Фильтр</span>
          </button>
        </S.Header>
        <div className={'relative-divider'}>
          {mapData && (
            <YMap
              items={mapData}
              className="main-page__ymaps"
              callback={(ids: string[]) => setIds(ids)}
              favId={favId}
              setFavs={() => setFavId('')}
            />
          )}
          <OffcanvasCards
            className="main-page__offcanvas-cards"
            cards={cards}
            hideOffcanvas={() => {
              setCards([]);
              setIds([]);
            }}
            onFav={(id: string) => {
              if (userId) setFavId(id);
            }}
          />
          {/* {isShowFilters && (
            <div className={'filters'}>
              <EstateFilters
                filters={additionalFilters}
                setFilters={setAdditionalFilters}
                onResetFilters={onResetFilters}
                onApplyFilters={onApplyFilters}
                foundCount={mapData.length}
              />
            </div>
          )} */}
          <CustomModal
            isShow={isShowModalFilters}
            setIsShow={setIsShowModalFilters}
            closeButton={true}
            className="modal__map-filters  filter-modal"
            centered={true}
            isMobile={width < 576}
          >
            <EstateFilters
              toogleBlackMarker={() => setBlackMarkerClick(!blackMarkerClick)}
              filters={additionalFilters}
              setFilters={setAdditionalFilters}
              onResetFilters={onResetFilters}
              onApplyFilters={onApplyFilters}
              foundCount={mapData.length}
            />
          </CustomModal>
        </div>
      </S.Wrapper>
    </section>
  );
};
