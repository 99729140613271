import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import Rating from 'react-rating';
import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import { bindActionCreators } from 'redux';
import { EffectFade, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { createAdResponse, getAdsPage, getResponsesAd } from '../API/adspage';
import { getServiceType } from '../API/services';
import { emitCreateWithRealEstateTopicMessage } from '../API/socketConversations';
import { userInfo } from '../API/users';
import Breadcrumbs from '../components/Breadcrumbs';
import BtnRep from '../components/BtnRep';
import CustomModal from '../components/CustomModal';
import CustomSelect from '../components/CustomSelect/CustomSelect';
import ShowPhone from '../components/ShowPhone';
import SliderResponces from '../components/SliderResponces';
import YMap from '../components/YMap';
import ForAboutBuildingCommercialAd from '../components/cardPageComponents/forAboutBuildingCommercialAd';
import ForAboutBuildingLivingAd from '../components/cardPageComponents/forAboutBuildingLivingAd';
import ForAboutBuildingParkingAd from '../components/cardPageComponents/forAboutBuildingParkingAd';
import ForAboutBuildingResidentialAd from '../components/cardPageComponents/forAboutBuildingResidentialAd';
import ForCommercialAd from '../components/cardPageComponents/forCommercialAd';
import ForLivingAd from '../components/cardPageComponents/forLivingAd';
import ForParkingAd from '../components/cardPageComponents/forParkingAd';
import ForResidentialAd from '../components/cardPageComponents/forResidentialAd';
import ForSteadAd from '../components/cardPageComponents/forSteadAd';
import { localEstates } from '../helpers/localEstates';
import { checkPhotoPath } from '../helpers/photo';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import alertActions from '../store/actions/alert';
import { useAccessToken, useCurrentUser } from '../store/reducers';
import BtnFav from '../components/BtnFav';
import { formatAddress } from '../helpers/formatingDate';

import PageWithBackLayout from '../layouts/PageWithBack.Layout';

import * as S from './PersonalAccount/UserProfile/styles';
import * as SS from '../components/Card/styles';
import useIsMobile from '../hooks/useIsMobile';
import CardFav from '../components/Card/CardFav';
import styled from 'styled-components';

import { ReactComponent as ExpertSVG } from '../assets/icons/expert.svg';

import Scroll, { Link, animateScroll as scroll } from 'react-scroll';

import UserHeader from '../components/User/UserHeader';

import { incrementTodayViewsCount } from '../API/adspage';
import Loader from '../components/Loader';

import { ReactComponent as PartnerIcon } from '../assets/icons/profile/parther.svg';

const advertiseSteps = [
  { title: 'Фото' },
  { title: 'Описание' },
  { title: 'Характеристики' },
  { title: 'Дополнительно' },
  { title: 'Карта' },
];

const ALink = styled.a`
  text-decoration: underline;
  display: block;
  // margin-top: 25px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  // font-size: 22px;
  line-height: 100%;
  text-decoration-line: underline;

  color: #198acb;
  padding: 20px 0px;
`;

const StarRatingWithNumber = ({ rating }) => {
  const roundedRating = Math.round(rating);
  const isMobile = true;
  return (
    <S.UserPageRatingWrapper isMobile={isMobile}>
      <img src="/img/icons/star-blue.svg" alt="Рейтинг" />
      <S.UserPageRatingSpan isMobile={isMobile}>{roundedRating}</S.UserPageRatingSpan>
    </S.UserPageRatingWrapper>
  );
};

const UserHeader1 = ({ userInformation }) => {
  const isMobile = useIsMobile(768);

  return (
    <div style={{ width: '100%', background: '#F3F3F3' }}>
      <div
        className="container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'space-between' : 'inherit',
        }}
      >
        <div className="d-flex">
          <div className="user-photo" style={{ position: 'relative' }}>
            <img
              style={{
                'object-fit': 'cover',
                width: '5rem',
                height: '5rem',
                borderRadius: '50%',
              }}
              src={checkPhotoPath(userInformation?.avatar)}
              alt={userInformation?.fullName}
            />
            {userInformation?.isPartner ? (
              <PartnerIcon
                style={{
                  width: '35%',
                  height: '35%',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translate(0%, 0%)',
                }}
              />
            ) : null}
            {/*<div className="indicator online"/>*/}
          </div>
          <S.RowAvatartitle>
            <S.SkeletonMargin2>
              <S.AvatarTitle>
                {userInformation?.firstName} {userInformation?.lastName}
              </S.AvatarTitle>
              <S.AvatarUnder>На сайте с {userInformation?.createdAtForUser}</S.AvatarUnder>
            </S.SkeletonMargin2>
          </S.RowAvatartitle>
        </div>

        <StarRatingWithNumber rating={userInformation?.rating || 0} />
      </div>
    </div>
  );
};

export default function CardPage() {
  const token = useAccessToken();
  const [pageTop, setPageTop] = useState(false);
  const [ads, setAds] = useState<any>(null);
  const { uuid } = useParams();
  const user = useCurrentUser();
  const userId = user?.id;
  const [isShowResponseModal, setIsShowResponseModal] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const maxNumber = 5;
  const [responseData, setResponseData] = useState<any>({
    userId,
    token,
  });
  const [userInformation, setUserInformation] = useState({});
  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
  };
  // write message modal
  const [isShowWriteMessageModal, setIsShowWriteMessageModal] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');
  // alert actions
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const [userServices, setUserServices] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [isValidService, setIsValidService] = useState(false);
  const [responsesAd, setResponsesAd] = useState({});

  // useEffect(() => {
  //   const incrementTodayViewsCountHandler = async () => {
  //     await incrementTodayViewsCount(ads.uuid);
  //   };
  //   incrementTodayViewsCountHandler();
  // }, []);

  useEffect(() => {
    user &&
      userInfo(user?.id).then((res) => {
        setUserInformation(res);
      });
  }, [user]);

  useEffect(() => {
    getAdsPage(uuid, userId).then((res) => {
      res.address = formatAddress(res.address);
      setAds(res);
    });
    window.scrollTo(0, 0);
  }, [uuid, userId]);

  useEffect(() => {
    userInformation?.services?.map((service) => {
      getServiceType(axiosPrivate, service?.serviceTypeId).then((serviceType) => {
        setUserServices((prevServices) => [
          ...prevServices,
          {
            value: service?.id,
            title: serviceType?.name,
          },
        ]);
      });
    });
  }, [userInformation]);

  useEffect(() => {
    function updateScroll() {
      let st = window.pageYOffset;
      if (st > 200) {
        setPageTop(true);
      } else {
        setPageTop(false);
      }
    }

    window.addEventListener('scroll', updateScroll);
    updateScroll();
    return () => window.removeEventListener('scroll', updateScroll);
  }, []);

  useEffect(() => {
    ads?.id &&
      getResponsesAd(ads?.id, token).then((res) => {
        setResponsesAd(res);
      });
  }, [ads?.id, token]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [showFullDesc, setShowFullDesc] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    ads?.id && setResponseData((prevState) => ({ ...prevState, realEstateId: ads?.id }));
  }, [ads?.id]);

  const [roomsType, setRoomsType] = useState('');

  useEffect(() => {
    const rr = [0, 1, 2, 3, 4, 5, 6];
    setRoomsType(rr.find((i) => i === ads?.roomType));
  }, [ads?.roomType]);

  const Words = () => {
    if (ads?.user?.realEstatesCount < 1) {
      return 'объект';
    } else if (ads?.user?.realEstatesCount >= 1 && ads?.user?.realEstatesCount < 4) {
      return 'объекта';
    } else {
      return 'объектов';
    }
  };

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setIsShowWriteMessageModal(false);
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithRealEstateTopicMessage(ads?.user?.id, {
        conversationId: 0,
        realEstateId: ads?.id,
        text: messageInput,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  const mapRef = useRef(null);

  const goToMap = () => {
    if (mapRef !== null) {
      window.scrollTo({
        left: 0,
        top: mapRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const title = () => {
    if (ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.kvartiri) {
      return (
        <span>
          {ads?.estate?.name} {ads?.totalArea} м<sup>2</sup>
        </span>
      );
    }
    if (ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.zemelia) {
      return (
        <span>
          {ads?.estate?.name} {ads?.acres} м<sup>2</sup>
        </span>
      );
    }
    if (ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.commer) {
      return <span>{ads?.estate?.name}</span>;
    }
    if (ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.parking) {
      return (
        <span>
          {ads?.estate?.name} {ads?.totalArea} м<sup>2</sup>
        </span>
      );
    }
  };

  useEffect(() => {
    if (responseData?.serviceId !== undefined) {
      setIsValidService(false);
    }
  }, [responseData?.serviceId]);

  const [responseIsLoading, setResponceIsLoading] = useState(false);

  const onSubmitInModal = (e) => {
    e.preventDefault();
    setIsShowResponseModal(false);
    setResponceIsLoading(true);

    setTimeout(() => {
      if (responseData?.serviceId === undefined) {
        setIsValidService(true);
      } else {
        const formData = new FormData();
        for (const key in responseData) {
          formData.append(key, responseData[key]);
        }
        images.forEach((image) => {
          formData.append('images[]', image.file);
        });
        createAdResponse(axiosPrivate, formData, token)
          .then(() => {
            setResponseData({ token, userId, realEstateId: ads?.id });
            setImages([]);
            setTimeout(() => {
              setAlert('success', true, 'Отклик успешно отправлен');
            }, 500);
            getResponsesAd(ads?.id, token).then((res) => {
              setResponsesAd(res);
            });
            setResponceIsLoading(false);
          })
          .catch(() => {
            setAlert('danger', true, 'Произошла ошибка');
            setResponceIsLoading(false);
          });
      }
    }, 0);
  };

  const isMobile = useIsMobile(768);

  const onCopyCadastralNumber = (e, num) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard
      .writeText(`${num}`)
      .then(() => setAlert('success', true, 'Кадастровый номер скопирован в буфер обмена'));
  };

  return ads ? (
    <PageWithBackLayout>
      <div style={{ width: '100%', background: '#F3F3F3' }}>
        <div className="container">
          <NavLink to={`/user/${ads?.user?.id}`}>
            <UserHeader1 userInformation={ads?.user} />
            {/* <UserHeader user={ads?.user} /> */}
          </NavLink>
          <div name="anchor-1">
            <CardFav
              ads={ads}
              id={ads.id}
              uuid={ads.uuid}
              user={ads.user}
              reportStatus={ads.reportStatus}
              isAdView={true}
              isTransparent={true}
              type={!isMobile && 'as-a-list'}
              // pictures={[ads.image, ads.images]}
              isVip={ads.isVip}
              totalArea={ads.totalArea}
              isHot={ads.isHot}
              title={ads.title}
              price={ads.price}
              transactionType={ads.transactionType}
              residentalComplex={ads.residentalComplex}
              residentalComplexForUser={ads.residentalComplexForUser}
              address={ads.address}
              text={ads.description}
              date={ads.createdAtForUser}
              communalPrice={ads.communalPrice}
              pledge={ads.pledge}
              commissionForUser={ads.commissionForUser}
              prepaymentTypeForUser={ads.prepaymentTypeForUser}
              rentalTypeForUser={ads.rentalTypeForUser}
              wishlistStatus={ads.wishlistStatus}
              userAvatar={ads.user?.avatar}
              estateName={ads?.estate?.name}
              buildingTypeForUser={ads?.buildingTypeForUser}
              realEstateTypeForUser={ads?.estate?.realEstateTypeForUser}
              acres={ads?.acres}
              viewsCount={ads.viewsCount}
              showUser={false}
              link={undefined}
              isLargeFont={true}
              withSide={true}
              todayViewsCount={ads.todayViewsCount}
              isOffcanvas={undefined}
              isLast={undefined}
            />
          </div>
        </div>
      </div>
      <div>
        <button
          style={{
            width: '300px',
            height: '50px',
            background: '#198ACB',
            borderRadius: '10px',
            color: '#FFF',
            'font-size': ' 1.5rem',
            // padding: 5,
          }}
        >
          <span style={{ fontSize: '1.7rem' }}>₽</span> Купить в ипотеку
        </button>
      </div>

      <div class="container" style={{ 'font-size': 'larger' }}>
        {/* <div className={pageTop ? 'card-page-top py-2 d-md-none' : 'card-page-top d-none py-2'}>
          <div className="container">
            <div className="d-flex">
              <button type="button" className="btn-pin" onClick={goToMap}>
                <svg viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 18C7.5 18 15 11.6033 15 6.75C15 4.95979 14.2098 3.2429 12.8033 1.97703C11.3968 0.711159 9.48912 0 7.5 0C5.51088 0 3.60322 0.711159 2.1967 1.97703C0.790176 3.2429 2.96403e-08 4.95979 0 6.75C0 11.6033 7.5 18 7.5 18ZM7.5 10.125C6.50544 10.125 5.55161 9.76942 4.84835 9.13649C4.14509 8.50355 3.75 7.64511 3.75 6.75C3.75 5.85489 4.14509 4.99645 4.84835 4.36351C5.55161 3.73058 6.50544 3.375 7.5 3.375C8.49456 3.375 9.44839 3.73058 10.1517 4.36351C10.8549 4.99645 11.25 5.85489 11.25 6.75C11.25 7.64511 10.8549 8.50355 10.1517 9.13649C9.44839 9.76942 8.49456 10.125 7.5 10.125Z" />
                </svg>
              </button>
              <BtnFav id={ads?.id} wishlistStatus={ads?.wishlistStatus} type={'realEstate'} />
              <BtnRep realEstateId={ads?.id} reportStatus={ads?.reportStatus} type="reportAd" />
            </div>
          </div>
        </div> */}
        <section id="sec-11" className="container pb-5">
          <div className="row">
            <div className="container d-flex">
              <div className="col-lg-9 col-sm-12" style={isMobile ? { width: '100%' } : {}}>
                <div name="anchor-2">
                  <h4 style={{ color: 'black' }}>Описание</h4>
                  <p className="fs-11 text-break">
                    {ads?.description.length < 100 || showFullDesc
                      ? ads?.description
                      : ads?.description.slice(0, 100) + '... '}
                    {ads?.description.length > 100 && !showFullDesc ? (
                      <a className="highlighted" onClick={() => setShowFullDesc(true)}>
                        Читать далее
                      </a>
                    ) : null}
                  </p>
                </div>

                <h4 className="mt-4 mt-sm-5 mb-3" style={{ color: 'black' }}>
                  О сделке
                </h4>
                {ads?.transactionType === 1 ? (
                  <div className="column-2">
                    {ads?.sellerTypeForUser !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Продавец</span>
                        </div>
                        <div className="right">
                          <span>{ads?.sellerTypeForUser}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.saleTypeForUser !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Условия сделки</span>
                        </div>
                        <div className="right">
                          <span>{ads?.saleTypeForUser}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.isMortgage !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Ипотека</span>
                        </div>
                        <div className="right">
                          <span>{ads?.isMortgage ? 'да' : 'нет'}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.isEncumbrances !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Обременения</span>
                        </div>
                        <div className="right">
                          <span>{ads?.isEncumbrances ? 'да' : 'нет'}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.cadastralNumber !== null ? (
                      // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      //   <SS.CopyLinkButton2
                      //     onClick={(e) => onCopyCadastralNumber(e, ads?.cadastralNumber)}
                      //     style={{ marginRight: 10 }}
                      //   />
                        <div className="specification fs-11" style={{
                          // marginTop: 0
                           }}>
                          <div className="left">
                            <span>
                              Кадастровый номер{' '}
                              {ads?.estate?.realEstateTypeForUser?.toLowerCase().includes(localEstates.dom)
                                ? ' дома'
                                : ''}
                            </span>
                          </div>
                          <div className="right">
                            <span>{ads?.cadastralNumber}</span>
                          </div>
                        </div>
                      // </div>
                    ) : null}
                    {ads?.landCadastralNumber !== null ? (
                      <>
                        {ads?.estate?.realEstateTypeForUser?.toLowerCase().includes(localEstates.dom) ? (
                          // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          //   <SS.CopyLinkButton2
                          //     onClick={(e) => onCopyCadastralNumber(e, ads?.landCadastralNumber)}
                          //     style={{ marginRight: 10 }}
                          //   />
                            <div className="specification fs-11">
                              <div className="left">
                                <span>Кадастровый номер земли</span>
                              </div>
                              <div className="right">
                                <span>{ads?.landCadastralNumber}</span>
                              </div>
                            </div>
                          // </div>
                        ) : null}
                      </>
                    ) : null}
                    {ads?.landCadastralNumber !== null ? (
                      <ALink href="https://egrpru.com/" className="fs-11 text-break">
                        Проверка ЕГРН
                      </ALink>
                    ) : null}
                  </div>
                ) : null}

                {ads?.transactionType === 0 ? (
                  <div className="column-2">
                    {ads?.rentalPeriodTypeForUser !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Тип аренды</span>
                        </div>
                        <div className="right">
                          <span>{ads?.rentalPeriodTypeForUser}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.sellerTypeForUser !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Продавец</span>
                        </div>
                        <div className="right">
                          <span>{ads?.sellerTypeForUser}</span>
                        </div>
                      </div>
                    ) : null}
                    {ads?.saleTypeForUser !== null ? (
                      <div className="specification fs-11">
                        <div className="left">
                          <span>Условия сделки</span>
                        </div>
                        <div className="right">
                          <span>{ads?.saleTypeForUser}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div name="anchor-3">
                  <h4 className="mt-4 mt-sm-5 mb-3" style={{ color: 'black' }}>
                    Характерстики
                  </h4>
                  {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.kvartiri) ? (
                    <ForLivingAd
                      rooms={ads?.roomsForUser}
                      totalArea={ads?.totalArea}
                      livingArea={ads?.livingArea}
                      livingAreaForUser={ads?.livingAreaForUser}
                      kitchenArea={ads?.kitchenArea}
                      kitchenAreaForUser={ads?.kitchenAreaForUser}
                      maxFloorForUser={ads?.maxFloorForUser}
                      floor={ads?.floor}
                      layoutForUser={ads?.layoutForUser}
                      repairTypeForUser={ads?.repairTypeForUser}
                      WCTypeForUser={ads?.WCTypeForUser}
                      balconyTypeForUser={ads?.balconyTypeForUser}
                      elevatorTypeForUser={ads?.elevatorTypeForUser}
                      windRoseDirectionType={ads?.windRoseDirectionTypeForUser}
                      window={ads?.windowForUser}
                      windowType={ads?.windowTypeForUser}
                      estateType={ads?.estate?.name}
                      outBuildingType={ads?.outBuildingTypeForUser}
                      landArea={ads?.landArea}
                      estateTypeForUser={ads?.estateTypeForUser}
                      areaTypeForUser={ads?.areaTypeForUser}
                    />
                  ) : null}
                  {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.parking) ? (
                    <ForParkingAd
                      hasSecurity={ads?.hasSecurity}
                      locationTypeForUser={ads?.locationTypeForUser}
                      totalArea={ads?.totalArea}
                      estateType={ads?.estate?.name}
                    />
                  ) : null}
                  {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.commer) ? (
                    <ForCommercialAd
                      totalArea={ads?.totalArea}
                      buildingType={ads?.buildingTypeForUser}
                      directionTypeForUser={ads?.directionTypeForUser}
                      hasVentilation={ads?.hasVentilation}
                      gradeType={ads?.gradeTypeForUser}
                      hasSecurityAlarm={ads?.hasSecurityAlarm}
                      hasFireAlarm={ads?.hasFireAlarm}
                    />
                  ) : null}
                  {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.zemelia) ? (
                    <ForSteadAd acres={ads?.acres} cityDistance={ads?.cityDistance} />
                  ) : null}
                  {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.dom) ? (
                    <ForResidentialAd
                      rooms={ads?.roomsForUser}
                      totalArea={ads?.totalArea}
                      livingArea={ads?.livingArea}
                      livingAreaForUser={ads?.livingAreaForUser}
                      kitchenArea={ads?.kitchenArea}
                      kitchenAreaForUser={ads?.kitchenAreaForUser}
                      maxFloorForUser={ads?.maxFloorForUser}
                      floor={ads?.floor}
                      layoutForUser={ads?.layoutForUser}
                      repairTypeForUser={ads?.repairTypeForUser}
                      WCTypeForUser={ads?.WCTypeForUser}
                      balconyTypeForUser={ads?.balconyTypeForUser}
                      elevatorTypeForUser={ads?.elevatorTypeForUser}
                      windRoseDirectionType={ads?.windRoseDirectionTypeForUser}
                      window={ads?.windowForUser}
                      windowType={ads?.windowTypeForUser}
                      estateType={ads?.estate?.name}
                      outBuildingType={ads?.outBuildingTypeForUser}
                      landArea={ads?.landArea}
                      estateTypeForUser={ads?.estateTypeForUser}
                      areaTypeForUser={ads?.areaTypeForUser}
                    />
                  ) : null}
                </div>

                {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.kvartiri) ||
                ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.dom) ? (
                  <div name="anchor-4">
                    <h4 className="mt-4 mt-sm-5 mb-3" style={{ color: 'black' }}>
                      Дополнительная информация
                    </h4>
                    <div className="row row-cols-2 row-cols-md-3 gx-2 gx-sm-4">
                      {ads?.hasGroundParking ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/parking.svg" alt="Парковка" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Парковка</span>
                        </div>
                      ) : (
                        ''
                      )}

                      {ads?.hasKitchenFurniture ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/kitchen-furniture.svg" alt="Кухонная мебель" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Кухонная мебель</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasFurniture ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/furniture.svg" alt="Мебель в комнатах" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Мебель в комнатах</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasRefrigerator ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/fridge.svg" alt="Холодильник" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Холодильник</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasWashingMachine ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/washer.svg" alt="Стиральная машина" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Стиральная машина</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasDishWasher ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/disher.svg" alt="Посудомоечная машина" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Посудомоечная машина</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasTv ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/tv.svg" alt="Телевизор" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Телевизор</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasConditioner ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/air-conditioner.svg" alt="Кондиционер" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Кондиционер</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasInternet ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/internet.svg" alt="Интернет" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Интернет</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasBathroom ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/bath.svg" alt="Ванна" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Ванна</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.hasShowerCabin ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/shower.svg" alt="Душевая кабина" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Душевая кабина</span>
                        </div>
                      ) : (
                        ''
                      )}

                      {ads?.withPets ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/pets.svg" alt="Можно с животными" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Можно с животными</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {ads?.withKids ? (
                        <div className="d-flex align-items-center fs-11 mb-2">
                          <img src="/img/icons/kids.svg" alt="Можно с детьми" className="icon-mini" />
                          <span className="ms-2 ms-sm-3">Можно с детьми</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : null}

                <h4 className="mt-4 mt-sm-5 mb-3" style={{ color: 'black' }}>
                  О здании
                </h4>
                {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.kvartiri) ? (
                  <ForAboutBuildingLivingAd
                    houseBuildingTypeForUser={ads?.houseBuildingTypeForUser}
                    elevatorTypeForUser={ads?.elevatorTypeForUser}
                    yearOfConstructionForUser={ads?.yearOfConstructionForUser}
                    ceilingHeightForUser={ads?.ceilingHeightForUser}
                    hasRamp={ads?.hasRamp}
                    hasGroundParking={ads?.hasGroundParking}
                    hasMoreLayerParking={ads?.hasMoreLayerParking}
                    hasUnderGroundParking={ads?.hasUnderGroundParking}
                    hasGarbage={ads?.hasGarbage}
                    hasYardParking={ads?.hasYardParking}
                    hasBarrierParking={ads?.hasBarrierParking}
                    outBuildingType={ads?.outBuildingType}
                    hasBasement={ads?.hasBasement}
                  />
                ) : null}
                {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.parking) ? (
                  <ForAboutBuildingParkingAd
                    estateType={ads?.estate?.name}
                    yearOfConstructionForUser={ads?.yearOfConstructionForUser}
                    hasGroundParking={ads?.hasGroundParking}
                    hasMoreLayerParking={ads?.hasMoreLayerParking}
                    hasUnderGroundParking={ads?.hasUnderGroundParking}
                    hasYardParking={ads?.hasYardParking}
                    hasBarrierParking={ads?.hasBarrierParking}
                  />
                ) : null}
                {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.commer) ? (
                  <ForAboutBuildingCommercialAd
                    ceilingHeightForUser={ads?.ceilingHeightForUser}
                    hasRamp={ads?.hasRamp}
                    hasGroundParking={ads?.hasGroundParking}
                    hasMoreLayerParking={ads?.hasMoreLayerParking}
                    hasUnderGroundParking={ads?.hasUnderGroundParking}
                    hasGarbage={ads?.hasGarbage}
                    hasYardParking={ads?.hasYardParking}
                    hasBarrierParking={ads?.hasBarrierParking}
                    houseBuildingTypeForUser={ads?.houseBuildingTypeForUser}
                    elevatorTypeForUser={ads?.elevatorTypeForUser}
                    yearOfConstructionForUser={ads?.yearOfConstructionForUser}
                  />
                ) : null}
                {ads?.estate?.realEstateTypeForUser?.toLowerCase()?.includes(localEstates.dom) ? (
                  <ForAboutBuildingResidentialAd
                    houseBuildingTypeForUser={ads?.houseBuildingTypeForUser}
                    elevatorTypeForUser={ads?.elevatorTypeForUser}
                    yearOfConstructionForUser={ads?.yearOfConstructionForUser}
                    ceilingHeightForUser={ads?.ceilingHeightForUser}
                    hasRamp={ads?.hasRamp}
                    hasGroundParking={ads?.hasGroundParking}
                    hasMoreLayerParking={ads?.hasMoreLayerParking}
                    hasUnderGroundParking={ads?.hasUnderGroundParking}
                    hasGarbage={ads?.hasGarbage}
                    hasYardParking={ads?.hasYardParking}
                    hasBarrierParking={ads?.hasBarrierParking}
                    outBuildingType={ads?.outBuildingType}
                    hasBasement={ads?.hasBasement}
                  />
                ) : null}

                {ads ? (
                  <div name="anchor-5">
                    <h4 className="mt-4 mt-sm-5" ref={mapRef} style={{ color: 'black' }}>
                      {isMobile ? 'На карте' : 'Карта'}
                    </h4>
                    <YMap items={[ads]} className="card-page__ymaps" />
                  </div>
                ) : null}
              </div>
              <form className="d-none d-lg-block col-lg-3 position-relative">
                <aside>
                  <nav className="contents mb-4 mb-lg-5">
                    <ol>
                      {advertiseSteps?.map(({ title }, index) => {
                        // if (data?.estateTypeName?.toLowerCase().includes(localEstates.zemelia)) index++;
                        return (
                          <li data-target={`anchor-${index + 1}`} key={index}>
                            <Link
                              activeClass="active"
                              to={`anchor-${index + 1}`}
                              spy={true}
                              smooth={true}
                              hashSpy={true}
                              offset={-80}
                              duration={300}
                              isDynamic={true}
                            >
                              <span>{title}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ol>
                  </nav>
                </aside>
              </form>
            </div>
          </div>
        </section>
      </div>

      <div>
        <button
          style={{
            width: '300px',
            height: '50px',
            background: '#198ACB',
            borderRadius: '10px',
            color: '#FFF',
            'font-size': ' 1.5rem',
            padding: 10,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-evenly',
          }}
        >
          <ExpertSVG />
          <span style={{ marginLeft: 5 }}>Заказать экспертизу</span>
        </button>
      </div>

      <section className="sec-4 container mb-6">
        <h3 style={{ color: 'black' }}>Предложения</h3>
        <div className="position-relative">
          {responseIsLoading ? (
            <div className="p-5 w-100 d-flex justify-content-center">
              <Loader color="#146492" />
            </div>
          ) : responsesAd?.meta?.total > 0 ? (
            <SliderResponces responses={responsesAd} />
          ) : (
            <p>Откликов нет</p>
          )}
        </div>
        <button
          disabled={!user || userId === String(user?.id)}
          type="button"
          // data-bs-toggle={!!currentUser ? 'modal' : undefined}
          // data-bs-target={!!currentUser ? '#write-review' : undefined}
          className="btn btn-1 fs-11 mx-auto mx-md-0 mb-4 mt-1"
          onClick={(e) => {
            setIsShowResponseModal(true);
          }}
        >
          Добавить отклик
        </button>
      </section>

      <CustomModal isShow={isShowWriteMessageModal} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <div className="d-flex align-items-center">
            <div className="photo me-2 me-sm-4">
              <img src={checkPhotoPath(ads?.user?.avatar)} alt={ads?.user?.fullName} />
            </div>
            <div>
              <h4>{ads?.user?.fullName}</h4>
            </div>
          </div>
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError ? (
            <span className="message-form__error w-100 text-danger">{messageInputError}</span>
          ) : null}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>
      <CustomModal isShow={isShowResponseModal} setIsShow={setIsShowResponseModal} closeButton size="lg">
        <form>
          <div className="text-capitalize mt-4 mb-4">
            <div className="fw-bold" style={{ color: `${isValidService ? '#DA1E2A' : ''}` }}>
              Предостовляемая услуга*:
            </div>
            <CustomSelect
              className="custom-select_create-response-ad"
              checkedOptions={[responseData?.title]}
              options={userServices}
              callback={({ value, title }: any) => {
                setResponseData((prevState) => ({
                  ...prevState,
                  serviceId: value,
                  title,
                }));
              }}
            />
          </div>
          <div className="mt-4 mb-4">
            <div className="fw-bold">Комментарий:</div>
            <textarea
              onChange={(e) => {
                setResponseData((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }));
              }}
            />
          </div>

          <div className="mt-4 mb-4">
            <div className="fw-bold">Примеры работ:</div>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={['JPG', 'JPEG', 'PNG']}
            >
              {({ imageList, onImageUpdate, onImageRemove, onImageUpload, dragProps, onImageRemoveAll }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <div className="imgs-box">
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" width="100" />
                        <div className="image-item__btn-wrapper-in-response">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onImageUpdate(index);
                            }}
                          >
                            Изменить
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onImageRemove(index);
                            }}
                          >
                            Удалить
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-1 px-3 px-sm-4 me-3 me-sm-4"
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="10.75" x2="10.75" y2="21" stroke="white" strokeWidth="1.5" />
                        <line y1="10.25" x2="21" y2="10.25" stroke="white" strokeWidth="1.5" />
                      </svg>
                      <span className="ms-2">Добавить фото</span>
                    </button>
                    <button type="button" onClick={onImageRemoveAll}>
                      Удалить все
                    </button>
                  </div>
                </div>
              )}
            </ImageUploading>
            <div>
              <span className="fs-08 gray-3 mt-2">Не больше 5 фото, форматы: JPG, JPEG, PNG</span>
            </div>
          </div>
          <button className="btn btn-1 w-100 fs-15 px-3 mt-2 mt-xl-3" onClick={(e) => onSubmitInModal(e)}>
            Отправить
          </button>
        </form>
      </CustomModal>
    </PageWithBackLayout>
  ) : null;
}
