import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getRandomArticle, getTopNews } from '../../../../API/news';
import { checkPhotoPath } from '../../../../helpers/photo';
import usePagination from '../../../../hooks/pagination';
import * as S from './styles';
import ArticleTopCard from './Article/ArticleTopCard';
import useIsMobile from '../../../../hooks/useIsMobile';

type ArticleType = {
  id: string;
  title: string;
  description: string;
  image: string;
  slug: string;
  adRequesites:string;
};

type SliderProps = {
  data?: any[];
};
export const Slider = ({ data }: SliderProps) => {
  // const { page } = useParams();
  // const articlesPag = usePagination(6);
  // const [randomArticle, setRandomArticles] = useState<ArticleType[]>([]);
  // const [newsArticles, setNewsArticles] = useState<ArticleType[]>([]);

  // useEffect(() => {
  //   getTopNews().then((result) => {
  //     setNewsArticles(result);
  //     setRandomArticles(result);
  //   });
  // }, [page]);

  // useEffect(() => {
  //   if (filter)
  //     setRandomArticles(
  //       newsArticles?.filter(
  //         (article) =>
  //           article.title.toLowerCase().includes(filter.toLowerCase()) ||
  //           article.description.toLowerCase().includes(filter.toLowerCase())
  //       )
  //     );
  //   else setRandomArticles(newsArticles);
  // }, [filter]);

  const isMobile = useIsMobile(768)

  return (
    <S.Wrapper>
      <Swiper
        className="swiper-4"
        spaceBetween={12}
        slidesPerView={isMobile ? 1 : 2.5}
        touchStartPreventDefault={true}
        grabCursor={true}
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 18,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
      >
        {data?.map?.((article) => (
          <S.StyledSwiperSlide key={article.id}>
            <ArticleTopCard
              imgUrl={checkPhotoPath(article.image)}
              title={article.title}
              text={article.description}
              articleUrl={article.slug}
              adRequesites={article.adRequesites}
            />
          </S.StyledSwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  );
};
