import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRecommend } from '../API/mainpagereq';
import { getArticle, getRandomArticle } from '../API/news';
import Breadcrumbs from '../components/Breadcrumbs';
import Card from '../components/Card/CardFav';
import * as S from '../components/Card/styles';
import Loader from '../components/Loader';
import { Slider } from '../components/Main/Articles/Slider/Slider';
import { checkPhotoPath } from '../helpers/photo';
import { useCurrentUser } from '../store/reducers';

export default function ArticlePage() {
  const user = useCurrentUser();
  const city = useSelector((state) => state?.selectedCity);
  const [recommend, setRecommend] = useState([]);
  const { slug } = useParams();
  const [articleInfo, setArticleInfo] = useState<any>();
  const [limit, setLimit] = useState(2);

  useEffect(() => {
    city && user?.id && getRecommend(user.id, limit, city).then((res) => setRecommend(res?.data));
  }, [city, user?.id, limit]);

  useEffect(() => {
    getArticle(slug).then((res) => setArticleInfo(res));
    window.scrollTo(0, 0);
  }, [slug]);


  //RANDOM
  const [isLoadingRandom, setIsLoadingRandom] = useState(true);
  const [randomArticles, setRandomArticles] = useState([]);

  useEffect(() => {
    console.log({articleInfo});
    articleInfo?.id && getRandomArticle(1, 5, articleInfo?.id).then((result) => {
      setRandomArticles(result);
      setIsLoadingRandom(false)
    });
  }, [articleInfo]);

  return (
    <main>
      <Breadcrumbs currentRouteName={articleInfo?.title || 'Название статьи'} />

      <section id="sec-8" className="container pb-5">
        <div className="row gx-xxl-5 mb-5">
          <div className="col-xl-8 col-xxl-9 article-full">
            <img
              src={checkPhotoPath(articleInfo?.image)}
              alt={articleInfo?.title}
              className="article-page-img order-3"
            />
            <h1 className="order-1 text-center text-md-left">{articleInfo?.title}</h1>
            <div className="d-flex gray-4 fs-12 mb-4 order-2">
              <img src="/img/icons/bi_calendar-event-fill.svg" alt="дата" />
              <div className="ms-2">{articleInfo?.createdAtForUser}</div>
              <img src="/img/icons/time.svg" alt="время" className="ms-4" />
              <div className="ms-2">{articleInfo?.readingTime} мин</div>
            </div>
            <div className="text fw-3 fs-11 order-4">
              <S.FontPriceScaler isLarge={true}>
                <p dangerouslySetInnerHTML={{ __html: articleInfo?.description }} />
              </S.FontPriceScaler>
            </div>
          </div>
          {recommend?.length > 0 ? (
            <div className="d-none d-xl-block col-xl-4 col-xxl-3 show-more">
              <h3 className="text-center">Объявления</h3>
              <div className="h-auto">
                {recommend?.map((i) => (
                  <Card
                    key={i?.id}
                    uuid={i?.uuid}
                    isVip={i?.isVip}
                    isHot={i?.isHot}
                    className="mb-4"
                    type="tiled"
                    pictures={[i?.image, i?.images]}
                    title={i?.title}
                    price={i?.price}
                    residentalComplex={i?.residentalComplex}
                    residentalComplexForUser={i?.residentalComplexForUser}
                    address={i?.address}
                    metro={i?.metro}
                    text={i?.description}
                    date={i?.createdAtForUser}
                    communalPayments={i?.communalPriceForUser}
                    commission={i?.commissionForUser}
                  />
                ))}
                <button
                  type="button"
                  className="mx-auto color-1 fw-5 fs-12 d-flex align-items-center"
                  onClick={() => setLimit(limit + 2)}
                >
                  <span className="me-3">Показать еще</span>
                  <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21.6832" y1="0.730271" x2="10.7468" y2="10.961" stroke="#146492" strokeWidth="2" />
                    <line
                      y1="-1"
                      x2="14.9757"
                      y2="-1"
                      transform="matrix(0.730271 0.683157 0.683157 -0.730271 2 0)"
                      stroke="#146492"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className="d-none d-xl-block col-xl-4 col-xxl-3 show-more">
              <h3 className="text-center">Объявления</h3>
              <div className="text-center">Не найдены</div>
            </div>
          )}
        </div>

        <h3 className="text-center text-md-left">Другие статьи по теме</h3>
        {isLoadingRandom ? (
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        ) : randomArticles.length ? (
          <div className="position-relative">
            <Slider data={randomArticles} />
          </div>
        ) : null}
      </section>
    </main>
  );
}
