import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.section`
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-bottom: 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const User = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    // margin-right: 0.75rem;
  }
  div {
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.font};
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      width: 56px;
      height: 56px;
      margin-right: 1rem;
    }
  }
`;
