import { FormEvent, useState } from 'react';

import { onCheckboxHandler } from '../../helpers/collectDataFromForm';
import CustomModal from '../CustomModal';

type Props = {
  isShow: boolean;
  setIsShow: (arg: boolean) => void;
  pndData: any;
  apiAction?: (...arg: any[]) => any;
};
export default function ReportModal({ isShow, setIsShow, pndData, apiAction }: Props) {
  const [data, setData] = useState({
    ...pndData,
    isFalsyAd: false,
    isNotValidPrice: false,
    isNotValidDesc: false,
    isNotValidPhotos: false,
    isOther: false,
    comment: '',
  });

  const [valid, setValid] = useState({
    isInValidComment: false,
  });
  const resetFieldVal = (field: string) => {
    setValid((prevValid) => ({ ...prevValid, [field]: false }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { comment } = data;
    const isInValidComment = comment?.length > 255;

    if (isInValidComment) {
      setValid({ ...valid, isInValidComment: true });
    } else {
      const formData = new FormData();
      const req = { ...data };
      for (let key in req) {
        formData.append(key, req[key as keyof typeof req]);
      }
      typeof apiAction === 'function' && apiAction(formData);
    }
  };

  return (
    <CustomModal isShow={isShow} setIsShow={setIsShow} closeButton={true} size="lg">
      <div>
        <h3 className="text-center">Пожаловаться</h3>
        <form className="message-form">
          <div className="row fs-11 mt-3">
            <div className="col-sm-3 mb-1 mb-sm-3">
              <label className="gray-3" htmlFor="reasons">
                Причина жалобы:
              </label>
            </div>
            <div className="col-sm-9 mb-sm-3">
              <fieldset className="mb-4">
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="isFalsyAd"
                    checked={data?.isFalsyAd || false}
                    onChange={(e) => {
                      onCheckboxHandler(e, setData);
                    }}
                  />
                  <span className="fs-11 ms-3">Ложное объявление</span>
                </label>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="isNotValidPrice"
                    checked={data?.isNotValidPrice || false}
                    onChange={(e) => {
                      onCheckboxHandler(e, setData);
                    }}
                  />
                  <span className="fs-11 ms-3">Цена не действительна</span>
                </label>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="isNotValidDesc"
                    checked={data?.isNotValidDesc || false}
                    onChange={(e) => {
                      onCheckboxHandler(e, setData);
                    }}
                  />
                  <span className="fs-11 ms-3">Не соответствует описанию</span>
                </label>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="isNotValidPhotos"
                    checked={data?.isNotValidPhotos || false}
                    onChange={(e) => {
                      onCheckboxHandler(e, setData);
                    }}
                  />
                  <span className="fs-11 ms-3">Фотографии не действительны</span>
                </label>
                <label className="ps-2 mb-3">
                  <input
                    type="checkbox"
                    name="isOther"
                    checked={data?.isOther || false}
                    onChange={(e) => {
                      onCheckboxHandler(e, setData);
                    }}
                  />
                  <span className="fs-11 ms-3">Другое</span>
                </label>
              </fieldset>
            </div>
          </div>
          <div className="row fs-11 mt-3">
            <div className="col-sm-3 mb-1 mb-sm-3">
              <label
                className="gray-3"
                htmlFor="comment"
                style={{
                  color: valid.isInValidComment ? '#DA1E2A' : '',
                }}
              >
                Комментарий:
              </label>
              <span className="fs-08 gray-3 mt-2">Не более 255 символов</span>
            </div>
            <div className="col-sm-9 mb-sm-3">
              <textarea
                style={{
                  borderColor: valid.isInValidComment ? '#DA1E2A' : '',
                }}
                placeholder="Ваш комментарий..."
                value={data.comment}
                id="comment"
                onChange={(e) => {
                  setData({ ...data, comment: e.target.value });
                  resetFieldVal('isInValidComment');
                }}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
            ОТПРАВИТЬ
          </button>
        </form>
      </div>
    </CustomModal>
  );
}
