import * as S from './styles';
import React from 'react';
import { ReactComponent as Star } from '../../assets/icons/main/star.svg';

type RatingStarProps = {
  rating?: string;
  fontSize?: number;
};
export const RatingStar = ({ rating = '5', fontSize }: RatingStarProps) => {
  return (
    <S.Star>
      <Star />
      <S.Rating $isSingle={rating.length === 1 || rating.endsWith('0')} $fontSize={fontSize}>
        {rating.endsWith('0') ? rating.substring(0, 1) : rating}
      </S.Rating>
    </S.Star>
  );
};
