import styled, { css } from 'styled-components';
import { getTransition } from '../../Styles/utilites';
import { Link } from 'react-router-dom';

type WrapperPropsDropdown = {
  $isOpen: boolean;
  $isJsxIn?: boolean;
  $widthInRem?: number;
  $direction: 'top' | 'bottom';
};

export const Wrapper = styled.div<WrapperPropsDropdown>`
  position: absolute;
  z-index: 100;
  display: flex;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 5px 5px hsla(0, 0%, 0%, 0.1);
  border-radius: 10px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  background: white;

  max-height: ${({ $isOpen }) => ($isOpen ? '500px' : '50px')};
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ $direction }) =>
    $direction === 'bottom'
      ? css`
          top: 90%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `
      : css`
          bottom: 90%;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `}
  left: 0;
  right: 0;
  ${getTransition(500, ['opacity', 'visibility', 'transform', 'max-height'], 'ease')}
`;
export const Container = styled.ul`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 0 0 0;
  margin: 0;
  font-size: 1rem;
  /* text-align: center; */
  list-style: none;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-start;

  li {
    cursor: pointer;
    padding: 0.5em 1em;
  }
`;

type CounterProps = {
  $right?: number;
  $bottom?: number;
};
export const CounterMenuFav = styled.span<CounterProps>`
  position: absolute;
  // right: ${({ $right }) => (typeof $right === 'number' ? $right : -10)}px;
  // top: ${({ $bottom }) => (typeof $bottom === 'number' ? $bottom : -2)}px;
  width: 19px;
  height: 19px;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.revert};
  color: ${({ theme }) => theme.colors.grey33};
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  z-index: 100;
  margin-top: -8px;
  margin-left: -105px;
`;

export const SpaceDivider = styled.div`
  width: 15px;
  height: 1px;
  background: transparent;
`;

type HeartLinkProps = {
  $red: boolean;
};
export const HeartLink = styled(Link)<HeartLinkProps>`
  > svg {
    path {
      fill: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.brandColor)};
      stroke: ${({ theme, $red }) => ($red ? theme.colors.brown : theme.colors.brandColor)};
      ${getTransition(300, ['fill', 'stroke'])}
    }
  }
  &:hover {
    > svg {
      path {
        fill: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.darkBrand)};
        stroke: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.darkBrand)};
      }
    }
  }
`;