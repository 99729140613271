import styled from 'styled-components';

export const Footer = styled.footer`
  height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.brandColor};

  @media screen and ${({ theme }) => theme.media.md} {
    z-index: 1;
    position: static;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.revert};
    color: #fff;
    height: 310px;
    padding: 3em 0;
  }
`;
