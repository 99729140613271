import { NavLink } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { checkPhotoPath } from '../helpers/photo';
import * as SS from '../pages/PersonalAccount/UserProfile/styles';
import * as S from './Main/Articles/Slider/styles';

import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CreateReview, getSubReviews } from '../API/userspage';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useIsMobile from '../hooks/useIsMobile';
import { useAccessToken, useCurrentUser } from '../store/reducers';
import BtnRep from './BtnRep';
import CustomModal from './CustomModal';
import UserHeader from './User/UserHeader';
import ReportModal from './ReportModal';

import { bindActionCreators } from 'redux';
import alertActions from '../store/actions/alert';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div<any>`
  scale: ${(props) => props.scale || 1};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: 'row';
  justify-content: center;
  gap: 5px;
  color: red;
  cursor: pointer;

  a,
  svg {
    width: 32px;
    height: ${({ isHoz }) => {
      return isHoz ? '32px' : 'auto';
    }};
  }

  div {
    cursor: pointer;
  }
  div:first-child {
    svg {
      path {
        fill: #d46b25;
      }
    }
  }
  div:not(:first-child),
  a {
    svg {
      margin-top: ${({ isHoz }) => {
        return isHoz ? '0px' : '0rem';
      }};
    }
  }
`;

const StarRatingWithNumber = ({ rating }) => {
  const roundedRating = Math.round(rating);
  return (
    <SS.UserPageRatingWrapper isMobile={true}>
      <img src="/img/icons/star-blue.svg" alt="Рейтинг" />
      <SS.UserPageRatingSpan isMobile={true}>{roundedRating}</SS.UserPageRatingSpan>
    </SS.UserPageRatingWrapper>
  );
};

const ReviewCard = ({
  review,
  isMobile,
  deleteReportForReview,
  addReportForReview,
  pathname,
  search,
  open,
  isOpen = false,
  subReviews,
  pageUser,
  createSubReview,
}: any) => {
  // const imgsUrl = `${process.env.REACT_APP_PHOTO_URL}`;
  // const sortedImages = response.images.sort((a, b) => a.order - b.order);
  // const urls = sortedImages.map((serviceImage) => `${imgsUrl}${serviceImage.image}`);

  const [isEdit, setIsEdit] = useState(false);
  const [msg, setMsg] = useState('');
  const close = () => {
    setMsg('');
    setIsEdit(false);
  };
  const handleSubmit = () => {
    createSubReview(msg);
    close();
  };

  const msgNum = subReviews?.length || 0;

  const currentUser = useCurrentUser();

  //REPORT
  //UTILS
  const axiosPrivate = useAxiosPrivate();

  //REPORT
  //ALERTS
  // alert actions
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const onlyAuth = () => setAlert('danger', true, 'Функция доступна авторизованным пользователям');
  const alreadySent = () => setAlert('danger', true, 'Жалоба уже отправлена');

  //DATE
  const currentToken = useAccessToken();
  const [data, setData] = useState({
    token: currentToken,
    userId: currentUser?.id,
    usersReviewId: review?.id,
  });
  useEffect(() => {
    setData({
      token: currentToken,
      userId: currentUser?.id,
      usersReviewId: review?.id,
    });
  }, [currentToken, currentUser?.id, review?.id]);

  //REPORT STATUS
  const [isExistUserReviewReport, setIsExistUserReviewReport] = useState(review?.reportStatus);
  useEffect(() => {
    setIsExistUserReviewReport(review?.reportStatus);
  }, [review?.reportStatus]);

  //USER ACTIONS
  const [isReportOpen, setIsReportOpen] = useState(false);
  const reportAdAction = async (payload: any) => {
    if (!currentUser) {
      onlyAuth();
    } else {
      setIsReportOpen(false);
      await addReportForReview(payload)
        .then(() => {
          setIsExistUserReviewReport((reportAdStatus) => !reportAdStatus);
          setAlert('success', true, 'Жалоба успешно добавлена');
        })
        .catch((e) => {
          setAlert('danger', true, 'Произошла ошибка');
        });
    }
  };

  const reportTitle = isExistUserReviewReport ? 'Жалоба отправлена' : 'Пожаловаться';
  const reportOnClick = () => {
    !currentUser ? onlyAuth() : isExistUserReviewReport ? alreadySent() : setIsReportOpen(true);
  };

  return (
    <>
      <NavLink
        to={`/user/${review?.from?.id}`}
        state={{
          prevRoute: pathname,
          prevSearch: search,
        }}
        style={{
          width: '100%',
        }}
      >
        <UserHeader user={review.from} isReview={true} />
      </NavLink>

      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            padding: 10,
            marginBottom: 20,
          }}
        >
          <div style={{ display: 'flex', gap: 20 }}>
            {/* <div style={{ position: 'relative', width: 'calc(50% - 10px)' }}>
          <HoverSlider urls={urls} onClick={(e) => openModalWithImage(e, urls)} />
          <SS.CustomButtons2>
            <SS.ItemContainerTotal>
              <SS.FullScreenImgButt onClick={(e) => openModalWithImage(e, urls)} />
            </SS.ItemContainerTotal>
          </SS.CustomButtons2>
        </div> */}
            <div style={{ width: 'calc(50% - 10px)', overflow: 'auto' }}>
              <h5
                className="text-center text-md-start"
                style={{
                  fontFamily: "'Montserrat'",
                  fontStyle: 'normal',
                  fontWeight: '600',
                  // fontSize: 20,
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#198ACB',
                  // color: '#000',
                  marginBottom: 20,
                }}
              >
                Комментарий
              </h5>
              <p
                style={{
                  fontFamily: "'Montserrat'",
                  fontStyle: 'normal',
                  color: '#6F6F6F',
                  fontSize: 14,
                  fontWeight: '600',
                  marginTop: 20,
                }}
              >
                {review?.description}
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            className="fs-14"
            style={{
              color: '#C59C7E',
              display: 'flex',
              fontSize: 18,
            }}
          >
            {review?.createdAtForUser}
            {isOpen || msgNum === 0 ? null : (
              <div
                style={{
                  color: '#146492',
                  marginLeft: 15,
                  cursor: 'pointer',
                }}
                onClick={open}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 18C6.73478 18 6.48043 17.8946 6.29289 17.7071C6.10536 17.5196 6 17.2652 6 17V14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V12C18 12.5304 17.7893 13.0391 17.4142 13.4142C17.0391 13.7893 16.5304 14 16 14H11.9L8.2 17.71C8 17.9 7.75 18 7.5 18H7ZM8 12V15.08L11.08 12H16V2H2V12H8Z"
                    fill="#146492"
                  />
                </svg>
                <span style={{ paddingLeft: 3 }}>{msgNum}</span>
              </div>
            )}
          </div>
          <Wrapper isHoz={true} isHorizontalAll={true} isHorizontalPC={true} onClick={reportOnClick}>
            <BtnRep isHoz={true} title={reportTitle} />
            {!isMobile ? reportTitle : null}
          </Wrapper>
        </div>

        {isOpen ? (
          <>
            <hr />
            <div style={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
              {subReviews?.map?.((subReview) => {
                return (
                  <div className="review" key={subReview.id} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', right: 20, top: 20 }}>{subReview?.createdAtForUser}</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div className="user-photo" style={{}}>
                        <img
                          style={{
                            'object-fit': 'cover',
                            width: '5rem',
                            height: '5rem',
                            borderRadius: '50%',
                          }}
                          src={checkPhotoPath(subReview.from?.avatar)}
                          alt={subReview.from?.fullName}
                        />
                      </div>

                      <SS.SkeletonMargin2>
                        <SS.AvatarTitle>{subReview.from?.fullName}</SS.AvatarTitle>
                        <span style={{ marginTop: 10 }}>{subReview.description}</span>
                      </SS.SkeletonMargin2>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        {currentUser?.id === pageUser?.id ? (
          <>
            <hr />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
              onClick={() => !isEdit && setIsEdit(true)}
            >
              {isEdit ? (
                <div style={{ width: '100%' }}>
                  <textarea
                    style={{ minHeight: 'auto', border: 0 }}
                    placeholder="Комментарий"
                    value={msg}
                    id="question"
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                  />
                  <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                    <button type="submit" className="btn btn-2 mx-auto fs-12 mt-4" onClick={close}>
                      Отмена
                    </button>
                    <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
                      Отправить
                    </button>
                  </div>
                </div>
              ) : (
                <span style={{ color: '#7DA3B9', fontSize: 14 }}>Оставить комментарий...</span>
              )}
            </div>
          </>
        ) : null}
      </div>

      {/* REPORT MODAL */}
      {isReportOpen &&
        ReactDOM.createPortal(
          <ReportModal isShow={isReportOpen} setIsShow={setIsReportOpen} apiAction={reportAdAction} pndData={data} />,
          document.getElementById('root') as HTMLElement
        )}
    </>
  );
};

const ReviewSlide = ({
  review,
  isMobile,
  deleteReportForReview,
  addReportForReview,
  pathname,
  search,
  pageUser,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const page = 1;

  const currentUser = useCurrentUser();
  const currentToken = useAccessToken();

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const createSubReview = (description: string) => {
    console.log({ review });
    CreateReview(axiosPrivate, {
      rating: 5,
      description,
      parentId: review?.id,
      toId: review?.fromId,
      fromId: currentUser?.id,
      token: currentToken,
    }).then(() => {
      getSubReviews(axiosPrivate, review?.id, page, currentUser?.id).then((res) => {
        setSubreviews(res.data);
      });
    });
  };

  const [subReviews, setSubreviews] = useState([]);
  useEffect(() => {
    review?.id &&
      getSubReviews(axiosPrivate, review?.id, page, currentUser?.id).then((res) => {
        setSubreviews(res.data);
      });
  }, [currentUser?.id, review?.id, page]);

  return (
    <>
      <div
        className="review"
        style={{
          'border-radius': 10,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <ReviewCard
          review={review}
          isMobile={isMobile}
          deleteReportForReview={deleteReportForReview}
          addReportForReview={addReportForReview}
          pathname={pathname}
          search={search}
          open={() => setIsDetailOpen(true)}
          subReviews={subReviews}
          pageUser={pageUser}
          createSubReview={createSubReview}
        />
      </div>

      {isDetailOpen &&
        ReactDOM.createPortal(
          <CustomModal isShow={isDetailOpen} setIsShow={setIsDetailOpen} closeButton={true} size="lg">
            <ReviewCard
              review={review}
              isMobile={isMobile}
              deleteReportForReview={deleteReportForReview}
              addReportForReview={addReportForReview}
              pathname={pathname}
              search={search}
              isOpen={true}
              subReviews={subReviews}
              pageUser={pageUser}
              createSubReview={createSubReview}
            />
          </CustomModal>,
          document.getElementById('root') as HTMLElement
        )}
    </>
  );
};

export const SliderReviews = ({ reviews, deleteReportForReview, addReportForReview, pathname, search, pageUser }) => {
  const isMobile = useIsMobile(768);

  return (
    <S.Wrapper>
      <Swiper
        className="swiper-4"
        spaceBetween={0}
        slidesPerView={isMobile ? 1 : 3}
        touchStartPreventDefault={true}
        grabCursor={true}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation, Pagination]}
      >
        {reviews?.map?.((review) => {
          return (
            <SwiperSlide key={review?.id}>
              <ReviewSlide
                review={review}
                isMobile={isMobile}
                deleteReportForReview={deleteReportForReview}
                addReportForReview={addReportForReview}
                pathname={pathname}
                search={search}
                pageUser={pageUser}
              />
            </SwiperSlide>
          );
        })}
        <div className="swiper-button-prev">
          <img src="/img/icons/prev.svg" alt="предыдущий" className="w-100" />
        </div>
        <div className="swiper-button-next">
          <img src="/img/icons/next.svg" alt="следующий" className="w-100" />
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </S.Wrapper>
  );
};
