import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getServiceType, getSubServicesTypes, incrementTodayViewsCount } from '../../API/services';
import { emitCreateWithRealEstateTopicMessage } from '../../API/socketConversations';
import { format } from '../../helpers/numFormat';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import HoverSlider from '../HoverSlider/HoverSlider';
import * as S from './styles';
//import ImgPreview from './ImgPreview';
import ImgPreview from '../ImgPreview';

import UserHeader from '../User/UserHeader';

import * as SS from '../Card/styles';

import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addReportUser, deleteReportUser } from '../../API/userspage';
import { ReactComponent as Cross } from '../../assets/icons/card/cross.svg';
import { ReactComponent as Dots } from '../../assets/icons/card/dots.svg';
import { ReactComponent as Phone } from '../../assets/icons/card/phone.svg';
import { checkPhotoPath } from '../../helpers/photo';
import { useClickOutside } from '../../hooks/useClickOutside';
import alertActions from '../../store/actions/alert';
import { useAccessToken, useCurrentUser } from '../../store/reducers';
import BtnFav from '../BtnFav';
import { ContactsPanel } from '../Card/ContactsPanel/ContactsPanel';
import CustomModal from '../CustomModal';
import { Popover } from '../Popover/Popover';
import { RatingStar } from '../RatingStar/RatingStar';
import ReportModal from '../ReportModal';

export const Price = styled.div`
  color: #545454;
  font-family: Lato;
  font-style: normal;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%; /* 20px */
  margin-right: 0.35rem;
  margin-bottom: 0.5rem;
`;
const ServiceCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const user = useCurrentUser();

  const [isOpenContacts, setOpenContacts] = useState(false);
  const ref = useRef();
  useClickOutside(() => setOpenContacts(false), ref);

  const [toogleZIDX, setToogleZIDX] = useState(false);

  const [serviceType, setServiceType] = useState({});
  const [subServices, setSubServices] = useState([]);

  useEffect(() => {
    getServiceType(axiosPrivate, props.serviceTypeId).then((serviceType) => setServiceType(serviceType));
  }, []);

  useEffect(() => {
    getSubServicesTypes(axiosPrivate, props.serviceTypeId).then((servicesTypes) => {
      setSubServices(
        servicesTypes
          ?.filter((type) => props.subServices?.find((subService) => type?.id === subService?.serviceTypeSubServiceId))
          .map((subType) => {
            const data = props.subServices.find((subService) => subType?.id === subService?.serviceTypeSubServiceId);
            return {
              ...subType,
              price: data.price,
              isFromPrice: data.isFromPrice,
            };
          })
      );
    });
  }, []);

  const [imageUrls, setImageUrls] = useState([]);
  const { pathname } = useLocation();
  const { search } = useLocation();
  useEffect(() => {
    const imgsUrl = `${process.env.REACT_APP_PHOTO_URL}/uploads`;
    if (props.images) {
      const sortedImages = props.images.sort((a, b) => a.order - b.order);
      const urls = sortedImages.map((serviceImage) => `${imgsUrl}/${serviceImage.image}`);
      setImageUrls(urls);
    }
  }, [props.images]);

  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    setContentHeight(contentRef?.current?.scrollHeight);
  }, []);
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false);

  const [viewsCount, setViewsCount] = useState(null);
  const [todayViewsCount, setTodayViewsCount] = useState(null);

  useEffect(() => {
    props.service.viewsCount && setViewsCount(props.service.viewsCount);
    props.service.todayViewsCount && setTodayViewsCount(props.service.todayViewsCount);
  }, []);

  const handleToggleClick = async () => {
    if (!isOpen) {
      const { viewsCount, todayViewsCount } = await incrementTodayViewsCount(axiosPrivate, props?.id);
      setViewsCount(viewsCount);
      setTodayViewsCount(todayViewsCount);
      setIsOpen(true);
    }

    setIsExpanded(!isExpanded);
    setIsReadMoreClicked(!isReadMoreClicked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState(null);
  const openModalWithImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (imageUrls && imageUrls.length > 0) {
      setCurrentImgIndex(0);
      setIsModalOpen(true);
    }
  };

  const onCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard
      .writeText(`https://dakvartira.ru/user/${props?.user.id}`)
      .then(() => setAlert('success', true, 'Ссылка скопирована в буфер обмена'));
  };

  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const [isShowWriteMessageModal, setIsShowWriteMessageModal] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setIsShowWriteMessageModal(false);
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithRealEstateTopicMessage(props?.user?.id, {
        conversationId: 0,
        realEstateId: props?.id,
        text: messageInput,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  const onClickMail = useCallback(() => {
    if (user) {
      setIsShowWriteMessageModal(true);
    } else {
      setAlert('danger', true, 'Функция доступна авторизованным пользователям');
    }
  }, [user, isShowWriteMessageModal]);

  //REPORT
  //ALERTS
  const onlyAuth = () => setAlert('danger', true, 'Функция доступна авторизованным пользователям');
  const alreadySent = () => setAlert('danger', true, 'Жалоба уже отправлена');

  //DATE
  const currentToken = useAccessToken();
  const currentUser = useCurrentUser();
  const [data, setData] = useState({
    token: currentToken,
    fromId: user?.id,
    toId: props?.user?.id || props?.service?.userId,
  });
  useEffect(() => {
    setData({
      token: currentToken,
      fromId: user?.id,
      toId: props?.service?.user?.id || props?.service?.userId,
    });
  }, [currentToken, user?.id, props?.service?.user?.id, props?.service?.userId]);

  //REPORT STATUS
  const [isExistUserReport, setIsExistUserReport] = useState(props?.service?.reportStatus || props?.reportStatus);
  useEffect(() => {
    setIsExistUserReport(
      props?.service?.reportStatus !== undefined ? props?.service?.reportStatus : props?.reportStatus
    );
  }, [props?.reportStatus, props?.service?.reportStatus]);

  // console.log({ isExistUserReport });

  //USER ACTIONS
  const [isReportOpen, setIsReportOpen] = useState(false);
  const reportAdAction = async (payload: any) => {
    if (!user) {
      onlyAuth();
    } else {
      setIsReportOpen(false);
      await addReportUser(axiosPrivate, payload)
        .then(() => {
          setIsExistUserReport((reportAdStatus) => !reportAdStatus);
          setAlert('success', true, 'Жалоба успешно добавлена');
        })
        .catch((e) => {
          setAlert('danger', true, 'Произошла ошибка');
        });
    }
  };

  const reportTitle = isExistUserReport ? 'Жалоба отправлена' : 'Пожаловаться';
  const reportOnClick = () => {
    !user ? onlyAuth() : isExistUserReport ? alreadySent() : setIsReportOpen(true);
  };

  return (
    <>
      {props?.type === 'as-a-list' ? (
        <SS.RoundedBordersCard
          className={`card-midi${props.className || ''}`}
          style={{
            background: props?.isTransparent ? 'transparent' : '',
            boxShadow: props?.isTransparent ? 'none' : 'initial',
          }}
        >
          {props?.showUser && (
            <NavLink to={`/user/${props?.user?.id}`}>
              <UserHeader user={props.user} avatarClick={openModalWithImage} />
            </NavLink>
          )}

          <div className={`${props.isAdView ? '' : 'h-100'} row `}>
            <div className={`${props.isAdView ? 'col-4' : 'col-6'}`}>
              {props?.isUserPage ? (
                <div style={{ position: 'relative' }}>
                  <HoverSlider urls={imageUrls} onClick={openModalWithImage} />
                  <ImgPreview urls={imageUrls} onClick={openModalWithImage} />
                  <SS.CustomButtons2>
                    <SS.ItemContainerTotal>
                      <SS.FullScreenImgButt onClick={openModalWithImage} />
                      <SS.ItemContainerRight>
                        <SS.CopyLinkButton2 onClick={onCopy} style={{ marginRight: 10 }} />
                        <BtnFav
                          id={props?.id}
                          wishlistStatus={props?.wishlistStatus}
                          onFav={props.onFav}
                          position={'static'}
                          callbackDeleteWish={props?.callbackDeleteWish}
                        />
                      </SS.ItemContainerRight>
                    </SS.ItemContainerTotal>
                  </SS.CustomButtons2>
                </div>
              ) : (
                <NavLink
                  to={`/user/${props?.user?.id}`}
                  state={{
                    prevRoute: pathname,
                    routeName: props?.routeName,
                    prevSearch: search,
                  }}
                  style={{ position: 'relative' }}
                >
                  <HoverSlider urls={imageUrls} />
                  <ImgPreview urls={imageUrls} />
                  <SS.CustomButtons2>
                    <SS.ItemContainerTotal>
                      <SS.FullScreenImgButt onClick={openModalWithImage} />
                      <SS.ItemContainerRight>
                        <SS.CopyLinkButton2 onClick={onCopy} style={{ marginRight: 10 }} />
                        <BtnFav
                          id={props?.id}
                          wishlistStatus={props?.wishlistStatus}
                          onFav={props.onFav}
                          position={'static'}
                          callbackDeleteWish={props?.callbackDeleteWish}
                        />
                      </SS.ItemContainerRight>
                    </SS.ItemContainerTotal>
                  </SS.CustomButtons2>
                </NavLink>
              )}
            </div>

            <div
              className={`${props.isAdView ? 'col-8' : 'col-6'} flex-column justify-content-between align-items-start`}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                >
                  <SS.FontTitleScaler isLarge={props.isLargeFont} className="title-font fw-7">
                    <NavLink
                      to={`/user/${props?.user?.id}`}
                      state={{
                        prevRoute: pathname,
                        routeName: props?.routeName,
                        prevSearch: search,
                      }}
                    >
                      {serviceType?.name}
                    </NavLink>
                  </SS.FontTitleScaler>
                  <div className="mb-3">
                    <S.CardServ>Подуслуги:</S.CardServ>
                    <S.ScrollWrapper>
                      {subServices?.map((subServices, idx) => (
                        <S.GridCell key={idx}>
                          <S.ServShield>{subServices.name}</S.ServShield>
                          <Price>
                            {subServices.isFromPrice && 'от '}
                            {format(subServices.price.toString())} ₽
                          </Price>
                        </S.GridCell>
                      ))}
                    </S.ScrollWrapper>
                  </div>
                  <div className="mb-3">
                    <S.CardServ2>О себе:</S.CardServ2>
                    <S.DescriptUnder isExpanded={isExpanded} contentHeight={contentHeight} ref={contentRef}>
                      {props.description}{' '}
                    </S.DescriptUnder>
                    {props.description.length > 100 && (
                      <S.ReadMoreButton onClick={handleToggleClick}>
                        {isReadMoreClicked ? 'Скрыть' : 'Читать далее'}
                      </S.ReadMoreButton>
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        'justify-content': 'flex-end',
                      }}
                    ></div>
                  </div>
                  {props.metro && (
                    <div className="d-flex align-items-center mb-2 mb-xxl-3">
                      <img src="/img/icons/metro.svg" alt="адрес" />
                      <div className="fs-09 ms-2">
                        <div>{props.metro}</div>
                      </div>
                    </div>
                  )}
                  {!currentUser || (props?.user?.id || props?.service?.userId) !== currentUser?.id ? (
                    <ContactsPanel
                      phone={props?.user?.phone}
                      mail={props?.user?.email}
                      link={`https://dakvartira.ru/user/${props?.user?.id}`}
                      onClickMail={onClickMail}
                      isHorizontalPC={true}
                      isHorizontalAll={true}
                      reportOnClick={reportOnClick}
                      reportTitle={reportTitle}
                    />
                  ) : null}
                  {viewsCount && (
                    <SS.OrangeGroup>
                      <SS.OrangeTextD isLarge={props.isLargeFont}>{props?.date}</SS.OrangeTextD>
                      <SS.Eye className="d-flex color-2 ms-4">
                        <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
                        <SS.OrangeTextS isLarge={props.isLargeFont}>
                          {viewsCount} {todayViewsCount > 0 && `(${todayViewsCount})`}
                        </SS.OrangeTextS>
                      </SS.Eye>
                    </SS.OrangeGroup>
                  )}
                  {!!props.controlButton && <span> {props.controlButton}</span>}
                  <span>
                    {props.callback && (
                      <div className="row justify-content-start mt-3">
                        <S.DisplayButton>
                          <div className="row row-cols-2 align-items-center g-2 g-sm-4">
                            <S.EditButton>
                              <NavLink to={`create/${props.id}`}>Редактировать</NavLink>
                            </S.EditButton>
                            <div>
                              <S.RemoveButton type="button">
                                <span
                                  className="ms-1 ms-sm-2"
                                  onClick={() => {
                                    props.callback && props.callback(props.id);
                                  }}
                                >
                                  Удалить услугу
                                </span>
                              </S.RemoveButton>
                            </div>
                          </div>
                        </S.DisplayButton>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SS.RoundedBordersCard>
      ) : (
        <SS.StyledCardMiniFav
          className={`card-mini ${props.className || ''}`}
          style={{ 'z-index': toogleZIDX ? '1' : '0', maxHeight: '' }}
        >
          {props.showUser && (
            <NavLink to={`/user/${props?.user?.id}`}>
              <UserHeader user={props.user} />
            </NavLink>
          )}

          {/* <NavLink to={`/user/${props?.user?.id}`}> */}
          <div style={{ position: 'relative', zIndex: '0' }}>
            <HoverSlider urls={imageUrls} onClick={openModalWithImage} />
            <SS.CustomButtons2>
              <SS.ItemContainerTotal>
                <SS.FullScreenImgButt onClick={openModalWithImage} />
                <SS.ItemContainerRight>
                  <SS.CopyLinkButton2 onClick={onCopy} style={{ marginRight: 10 }} />
                  <BtnFav
                    id={props?.id}
                    wishlistStatus={props?.wishlistStatus}
                    onFav={props.onFav}
                    position={'static'}
                    callbackDeleteWish={props?.callbackDeleteWish}
                  />
                </SS.ItemContainerRight>
              </SS.ItemContainerTotal>
            </SS.CustomButtons2>
          </div>
          {/* </NavLink> */}

          <div className="p-3 d-flex flex-column" style={{ 'z-index': '20' }}>
            <NavLink to={`/user/${props?.user?.id}`}>
              <div className="d-flex flex-column justify-content-between mb-2 gap-1">
                <SS.FontTitleScaler isLarge={props.isLargeFont}>{serviceType?.name}</SS.FontTitleScaler>
              </div>
            </NavLink>
            <div className="address d-flex align-items-center mb-3" style={{ 'justify-content': 'space-between' }}>
              <div class="col-9">
                <div className="mb-3">
                  <S.CardServ>Подуслуги:</S.CardServ>
                  <S.ScrollWrapper>
                    {subServices?.map((subServices, idx) => (
                      <S.GridCell key={idx}>
                        <S.ServShield>{subServices.name}</S.ServShield>
                        <Price>
                          {subServices.isFromPrice && 'от '}
                          {format(subServices.price.toString())} ₽
                        </Price>
                      </S.GridCell>
                    ))}
                  </S.ScrollWrapper>
                </div>
                <div>
                  <S.CardServ2>О себе:</S.CardServ2>
                  <S.DescriptUnder isExpanded={isExpanded} contentHeight={contentHeight} ref={contentRef}>
                    {props.description}
                  </S.DescriptUnder>
                  <S.ReadMoreButton onClick={handleToggleClick}>
                    {isReadMoreClicked ? 'Скрыть' : 'Читать далее'}
                  </S.ReadMoreButton>
                </div>
              </div>

              {props?.withSide && (
                <SS.SidePanelWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  isMobile={isMobile}
                >
                  {!currentUser || (props?.user?.id || props?.service?.userId) !== currentUser?.id ? (
                    <>
                      <SS.SideElt as={'a'} href={'tel:+' + props?.user?.phone} onClick={(e) => e.stopPropagation()}>
                        <Phone />
                      </SS.SideElt>
                      <Popover
                        widthInRem={2}
                        direction={'left'}
                        action={'click'}
                        tooltip={
                          <ContactsPanel
                            phone={props?.user?.phone}
                            mail={props?.user?.email}
                            onClickMail={onClickMail}
                            isHoz={isMobile}
                            reportOnClick={reportOnClick}
                            reportTitle={reportTitle}
                          />
                        }
                        toogleZidx={(isOutside) =>
                          setToogleZIDX((prev) => (isOutside !== undefined ? isOutside : !prev))
                        }
                        isLast={props.isLast}
                      >
                        <SS.SideElt
                          ref={ref}
                          onClick={(e) => {
                            setOpenContacts((prev) => !prev);
                          }}
                        >
                          {isOpenContacts ? <Cross /> : <Dots />}
                        </SS.SideElt>
                      </Popover>
                    </>
                  ) : null}
                  {props?.isOffcanvas && (
                    <SS.SideElt $isRating={true}>
                      <RatingStar rating={props?.user?.rating} fontSize={1} />
                    </SS.SideElt>
                  )}
                </SS.SidePanelWrapper>
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                'justify-content': 'flex-end',
              }}
            ></div>
            {props.callback && (
              <div className="row justify-content-start">
                <S.DisplayButton>
                  <div className="row row-cols-2 align-items-center g-2 g-sm-4">
                    <S.EditButton>
                      <NavLink to={`create/${props.id}`}>Редактировать</NavLink>
                    </S.EditButton>
                    <div>
                      <S.RemoveButton type="button">
                        <span
                          className="ms-1 ms-sm-2"
                          onClick={() => {
                            props.callback && props.callback(props.id);
                          }}
                        >
                          Удалить услугу
                        </span>
                      </S.RemoveButton>
                    </div>
                  </div>
                </S.DisplayButton>
              </div>
            )}
            {viewsCount && (
              <div className="d-flex align-items-center justify-content-between">
                <SS.OrangeTextD isLarge={props.isLargeFont}>{props?.date}</SS.OrangeTextD>
                <SS.Eye className="d-flex color-2 ms-4">
                  <SS.OrangeTextS isLarge={props.isLargeFont}>
                    {viewsCount} {todayViewsCount > 0 && `(${todayViewsCount})`}
                  </SS.OrangeTextS>
                  <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
                </SS.Eye>
              </div>
            )}
          </div>

          <span className="mb-2" style={{ display: 'block' }}>
            {' '}
            {props.controlButton}
          </span>
        </SS.StyledCardMiniFav>
      )}

      <CustomModal isShow={isShowWriteMessageModal} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <div className="d-flex align-items-center">
            <div className="photo me-2 me-sm-4">
              <img src={checkPhotoPath(props?.user?.avatar)} alt={props?.user?.fullName} />
            </div>
            <div>
              <h4>{props?.user?.fullName}</h4>
            </div>
          </div>
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>

      {/* REPORT MODAL */}
      {isReportOpen &&
        ReactDOM.createPortal(
          <ReportModal isShow={isReportOpen} setIsShow={setIsReportOpen} apiAction={reportAdAction} pndData={data} />,
          document.getElementById('root') as HTMLElement
        )}

      {isModalOpen &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 3000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            onClick={() => setIsModalOpen(false)}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" viewBox="0 0 24 24">
                <path d="M18.3 5.71a1 1 0 00-1.41 0L12 10.59l-4.89-4.88a1 1 0 00-1.41 1.41L10.59 12l-4.89 4.89a1 1 0 101.41 1.41L12 13.41l4.89 4.89a1 1 0 001.41-1.41L13.41 12l4.89-4.89a1 1 0 000-1.41z"></path>
              </svg>
            </button>
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'hidden',
              }}
            >
              <img
                src={imageUrls[currentImgIndex]}
                alt="Fullscreen"
                style={{ maxHeight: '80vh', objectFit: 'contain', width: 'auto', height: 'auto' }}
              />
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1))
                }
                style={{
                  position: 'absolute',
                  left: 50,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'<'}
              </button>
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0))
                }
                style={{
                  position: 'absolute',
                  right: 50,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'>'}
              </button>
            </div>
          </div>,
          document.getElementById('root') as HTMLElement
        )}
    </>
  );
};

export default ServiceCard;
