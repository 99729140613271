import { useState } from 'react';
import getAxiosPrivate from '../API/axiosPrivate';

const useAxiosPrivate = () => {
  const [axiosPrivate] = useState(() => getAxiosPrivate());

  return axiosPrivate;
};

export default useAxiosPrivate;
