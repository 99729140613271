import React, { useLayoutEffect } from 'react';
import { Main } from '../components/Main/Main';

export default function MainPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Main />;
}
