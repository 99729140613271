import { useCallback, useEffect, useState } from 'react';
import { useToggleVisible } from './useToggleVisible';

export const useShowCookies = (): [boolean, boolean, () => void] => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  useEffect(() => {
    const needShow = localStorage.getItem('isAcceptCookies') !== 'true';
    if (needShow) toggle();
  }, []);
  const setAccept = () => {
    localStorage.setItem('isAcceptCookies', 'true');
    toggle();
  };

  return [isOpen, isShow, setAccept];
};
