export const format = (str: string) => {
  const s = str.length;
  const chars = [...str];
  const strWithSpaces = chars.reduceRight((acc, char, i) => {
    const spaceOrNothing = (s - i) % 3 === 0 ? " " : "";
    return spaceOrNothing + char + acc;
  }, "");

  return strWithSpaces[0] === " " ? strWithSpaces.slice(1) : strWithSpaces;
};

export const getBalance = (num: number) => {
  return format(Math.floor(num).toString()) + "₽";
};
