import { ModalContainerProps } from '../Modal/Modal';
import { StoryWrapperModal } from '../StoryWrapperModal/StoryWrapperModal';
import { Stories, url } from '../Main/Stories/Slider/Slider';
import { Swiper } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import * as S from './styles';
import React, { useEffect, useState } from 'react';
import 'swiper/css/effect-coverflow';
import { Content } from './Content/Content';

type StoriesModalProps = {
  stories: Stories[];
  current?: number;
};
export const StoriesModal = ({ current, stories, isShow, toggle, isOpen }: ModalContainerProps & StoriesModalProps) => {
  const [localCurrent, setLocalCurrent] = useState<number>();
  useEffect(() => {
    setLocalCurrent(current);
  }, [current]);

  return isOpen && typeof localCurrent === 'number' ? (
    <StoryWrapperModal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <Swiper
          effect={'coverflow'}
          slidesPerView={'auto'}
          spaceBetween={0}
          grabCursor={true}
          centeredSlides={true}
          slideToClickedSlide={true}
          initialSlide={localCurrent}
          coverflowEffect={{
            rotate: 5,
            stretch: -30,
            depth: 300,
            modifier: 1,
            slideShadows: true,
            scale: 0.9,
          }}
          breakpoints={{
            576: {
              spaceBetween: 80,
            },
            930: {
              spaceBetween: 150,
            },
          }}
          modules={[Navigation, EffectCoverflow]}
          onActiveIndexChange={(swiper) => {
            setLocalCurrent(swiper.activeIndex);
          }}
        >
          {[...stories].map((story, index) => (
            <S.StyledSwiperSlide key={story.id}>
              <Content
                content={story.media.map(({ media, duration }) => ({ src: url + media, duration }))}
                title={story.title}
                description={story.description ?? ''}
                isActive={index === localCurrent}
                isLast={index === stories.length - 1}
                toggle={toggle}
              />
            </S.StyledSwiperSlide>
          ))}
        </Swiper>
      </S.Wrapper>
    </StoryWrapperModal>
  ) : null;
};
