import styled from 'styled-components';

export const ShadowDivider = styled.div`
  position: absolute;
  z-index: 3;
  bottom: -1px;
  left: -1px;
  right: -1px;
  height: 60%;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 85.9%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1.5rem;
  text-align: left;
`;
export const Description = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.revert};
`;
