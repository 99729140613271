import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Conversations from '../../components/Conversations';

export default function ConversationsPage() {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="conversations">
      <nav className="d-block d-lg-none mt-3 mb-3 mb-sm-5" aria-label="breadcrumb">
        <div
          onClick={() => (state?.fromChat ? navigation('/personal-account/profile') : navigation(-1))}
          className="gray-3"
          style={{ cursor: 'pointer' }}
        >
          &#10094; Назад
        </div>
      </nav>
      <h4 className="text-center color-1">Сообщения</h4>
      <Conversations />
    </div>
  );
}
