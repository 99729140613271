import { FormEvent, useState } from 'react';
import CustomModal from '../components/CustomModal';
import useIsMobile from '../hooks/useIsMobile';
import PageWithBackLayout from '../layouts/PageWithBack.Layout';
import { getQuestion } from '../API/question';
import alertActions from '../store/actions/alert';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

const Cirle = ({ procent, isBig }: any) => {
  return (
    <div className={`c100 p${procent * 100} ${isBig && 'big'}`}>
      <span>{`${procent * 100}%`}</span>
      <div className="slice">
        <div className="bar"></div>
        <div className="fill"></div>
      </div>
    </div>
  );
};

export default function AIAssistPage() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const [isShowQuestionModal, setIsShowQuestionModal] = useState(false);

  const [data, setData] = useState({
    name: '',
    email: '',
    question: '',
  });
  const [valid, setValid] = useState({
    isInValidName: false,
    isInValidEmail: false,
    isInValidQuestions: false,
  });
  const mailSample = Object.values(data).find((i) => i?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isInValidName = data?.name === undefined || data.name?.length < 1 || data.name?.length > 55;
    const isInValidEmail = data?.email === undefined || mailSample === undefined;
    const { question } = data;
    const isInValidQuestions = question?.length === undefined || question?.length < 5 || question?.length > 1024;

    if (isInValidName) {
      setValid({ ...valid, isInValidName: true });
    } else if (isInValidEmail) {
      setValid({ ...valid, isInValidEmail: true });
    } else if (isInValidQuestions) {
      setValid({ ...valid, isInValidQuestions: true });
    } else {
      const formData = new FormData();
      const req = { ...data };
      for (let key in req) {
        formData.append(key, req[key as keyof typeof req]);
      }
      getQuestion(formData)
        .then(() => {
          setData({
            name: '',
            email: '',
            question: '',
          });
          setIsShowQuestionModal(false);
          setAlert('success', true, 'Вопрос успешно отправлен нашим консультантам, ждите ответа');
        })
        .catch(() => {
          setAlert('danger', true, 'Произошла ошибка сервера');
        });
    }
  };

  const resetFieldVal = (field: string) => {
    setValid((prevValid) => ({ ...prevValid, [field]: false }));
  };

  return (
    <PageWithBackLayout>
      <div
        className="container"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 25,
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Cirle procent={0.25} isBig={!isMobile} />
        <span
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: isMobile ? '16px' : '32px',
            lineHeight: isMobile ? '20px' : '42px',
            /* or 131% */
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: '#198ACB',
          }}
        >
          К сожалению, наш виртуальный помощник находиться в разработке.Но вы всегда можете связаться с нами
        </span>
        <button
          style={{
            width: '300px',
            height: '41px',
            background: '#FFFFFF',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '50px',
            fontFamily: "'Montserrat'",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            color: '#198ACB',
          }}
          onClick={() => setIsShowQuestionModal(true)}
        >
          Задать вопрос
        </button>
      </div>

      <CustomModal isShow={isShowQuestionModal} setIsShow={setIsShowQuestionModal} closeButton={true} size="lg">
        <div>
          <h3 className="text-center">Задать вопрос</h3>
          <form className="message-form">
            <div className="text-center">
              <div>
                <div className="fs-11 fw-5">Вам ответит администратор</div>
              </div>
            </div>
            <div className="row align-items-center fs-11 mt-3">
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="name"
                  style={{
                    color: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                >
                  Ваше имя:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Имя"
                  value={data.name}
                  id="name"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    resetFieldVal('isInValidName');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="email"
                  style={{
                    color: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                >
                  Ваш Email:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Email"
                  value={data.email}
                  id="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    resetFieldVal('isInValidEmail');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="question"
                  style={{
                    color: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                >
                  Ваш вопрос:
                </label>
                <span className="fs-08 gray-3 mt-2">От 5 символов</span>
              </div>
              <div className="col-sm-9 mb-sm-3">
                <textarea
                  style={{
                    borderColor: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                  placeholder="Вопрос"
                  value={data.question}
                  id="question"
                  onChange={(e) => {
                    setData({ ...data, question: e.target.value });
                    resetFieldVal('isInValidQuestions');
                  }}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </CustomModal>
    </PageWithBackLayout>
  );
}
