import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteBlackMarker, getBMForUser, getRealEstatesForBM } from '../../API/blackMarker';
import { ReactComponent as BlackMArkerBigIconn } from '../../assets/icons/blackMarkerBig.svg';
import Loader from '../../components/Loader';
import { SliderAds } from '../../components/SliderAds';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useIsMobile from '../../hooks/useIsMobile';
import * as S from '../../layouts/styles';
import alertActions from '../../store/actions/alert';
import { useAccessToken, useCurrentUser } from '../../store/reducers';

export default function UserBlackMarker() {
  const isMobile = useIsMobile(768);

  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const token = useAccessToken();
  const currentUser = useCurrentUser();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const [isLoading, setIsLoading] = useState(true);
  const [blackMarkers, setBlackMarkers] = useState<any>();
  useEffect(() => {
    getBMForUser(axiosPrivate, currentUser.id, token).then((res) => {
      setBlackMarkers({
        meta: res.meta,
        last_page: res.meta.last_page,
        catalog: res.data,
        foundCount: res.meta.total,
      });
      // console.log(res);
      setIsLoading(false);
    });
  }, []);

  const [nextPage, setNextPage] = useState(2);
  const getFetchData = useCallback(async (nPage = nextPage) => {
    getBMForUser(axiosPrivate, currentUser.id, token, nPage)
      .then((response) => {
        // console.log({ response, nPage});
        const newState = {
          meta: response.meta,
          last_page: response.meta.last_page,
          catalog: [...blackMarkers.catalog, ...response.data],
          foundCount: response.meta.total,
        }
        // console.log({newState});
        setBlackMarkers(newState);
        setNextPage(nPage + 1);
      })
      .catch((err) => {
        return err.message;
      });
  }, [blackMarkers]);

  const onDeleteBlackMarker = (id: number) => {
    deleteBlackMarker(axiosPrivate, id, currentUser?.id, token)
      .then((res) => {
        setAlert('success', true, 'Метка успешно удалена!');
        const newBlackMarkers = JSON.parse(JSON.stringify(blackMarkers));
        newBlackMarkers.catalog.splice(
          newBlackMarkers.catalog.findIndex((i) => i.id === id),
          1
        );
        // console.log({ newBlackMarkers });
        setBlackMarkers(newBlackMarkers);
      })
      .catch((err) => {
        // console.log(err);
        setAlert('danger', true, 'Произошла ошибка сервера');
      });
  };

  const onGetAds = (id: number, address: string, count) => {
    // console.log({count});
    if (count > 0)
      getRealEstatesForBM(axiosPrivate, address, token).then((res) => {
        const BMs = JSON.parse(JSON.stringify(blackMarkers));
        BMs.catalog.forEach((bm) => {
          delete bm.realEstates;
        });
        BMs.catalog.find((bm) => bm.id === id).realEstates = res;
        setBlackMarkers(BMs);
      });
    else {
      setAlert('danger', true, 'По данной метке нет объявлений');
    }
  };

  return (
    <div style={{ maxWidth: 980, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 20 }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <S.CustomPTeg style={{ marginBottom: '0 !important' }}>Избранные адреса</S.CustomPTeg>
      </div>

      <InfiniteScroll
        dataLength={blackMarkers?.catalog?.length || 0}
        next={() => getFetchData?.()}
        hasMore={nextPage <= blackMarkers?.last_page}
        loader={
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        }
      >
        {isLoading ? (
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        ) : blackMarkers?.catalog?.length ? (
          blackMarkers?.catalog?.map?.((item) => {
            return (
              <React.Fragment key={item.id}>
                <div
                  className="frame"
                  style={{
                    borderRadius: 10,
                    padding: '30px 40px',
                    position: 'relative',
                    background: item?.realEstates ? '#E6F6FF' : '#FFF',
                    marginBottom: 25,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'flex-start' : 'center',
                      gap: 20,
                    }}
                  >
                    <BlackMArkerBigIconn
                      style={{
                        width: isMobile ? 20 : 50,
                        height: isMobile ? 28 : 80,
                      }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <span
                        style={{
                          color: '#44494C',
                          fontWeight: 600,
                          fontSize: isMobile ? 14 : 24,
                          lineHeight: isMobile ? '21px' : '36px',
                        }}
                      >
                        {item.blackMarker?.address}
                      </span>
                      <span
                        style={{
                          color: '#C59C7E',
                          fontWeight: 600,
                          fontSize: isMobile ? 14 : 24,
                          lineHeight: isMobile ? '21px' : '36px',
                        }}
                      >
                        {` По этому адресу найдено ${item.blackMarker?.adsCount} ${(function (number, titles) {
                          const cases = [2, 0, 1, 1, 1, 2];
                          return `${
                            titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
                          }`;
                        })(item.blackMarker?.adsCount, ['объявление', 'объявления', 'объявлений'])}`}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: isMobile ? '100%' : '',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        gap: 20,
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-1 fs-11 text-uppercase"
                        onClick={() => {
                          onGetAds(item.id, item.blackMarker?.address, item.blackMarker?.adsCount);
                        }}
                        style={{ borderRadius: 25 }}
                      >
                        Смотреть объявления
                      </button>
                    </div>

                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        onDeleteBlackMarker(item.id);
                      }}
                    >
                      <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.00006 1.18237L15 15.9049" />
                        <path d="M14.9999 1.18237L1.00001 15.9049" />
                      </svg>
                    </button>
                  </div>
                </div>
                {item?.realEstates ? (
                  <div className="row">
                    {item?.realEstates?.length ? (
                      <div className="position-relative">
                        <SliderAds isBlackMarker={true} userAds={item?.realEstates} />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <h6 className="m-auto p-5 text-center">Черных меток нет</h6>
        )}
      </InfiniteScroll>
    </div>
  );
}
