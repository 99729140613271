import styled from 'styled-components';
import { getTransition } from '../../../../Styles/utilites';
import { SwiperSlide } from 'swiper/react';

export const Wrapper = styled.div`
  // margin-top: 16px;

  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    width: 130px;
  }
  .swiper-4>.swiper-pagination {
    display: block;
    position: static;
    margin-top: 1rem;
    min-height: 16px;
  }
  .swiper-4>.swiper-pagination>.swiper-pagination-bullet {
    width: 70px;
    background: #fff;
    height: 10px;
    border-radius: 0.2rem;
    border: ${({ theme }) => `1px solid ${theme.colors.brandColor}`};
    ${getTransition(300, 'transform')}
  }
  .swiper-4>.swiper-pagination>.swiper-pagination-bullet-active {
    transform: scaleY(1.35);
    background-color: ${({ theme }) => theme.colors.brandColor};
  }

  .swiper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .swiper-pagination {
    display: flex !important;
    max-width: 640px;
  }

  .swiper-pagination .swiper-pagination-bullet {
    flex-grow: 1;
    // margin: 0px;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  background: black;
  // overflow: hidden;
  //width: 450px;
  // height: 444px;
  height: 73vw;
  max-height: 444px;
  border-radius: 1rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
