import styled, { css } from 'styled-components';
import { getTransition } from '../../../Styles/utilites';

export const Wrapper = styled.div`
  height: 100%;
  display: block;
  @media screen and ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const Nav = styled.nav`
  height: 100%;
`;

export const ListContainer = styled.ul`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 0;
`;

type ListEltProps = {
  $isAi?: boolean;
  $red?: boolean;
};
export const ListElt = styled.li<ListEltProps>`
  position: relative;
  cursor: pointer;
  svg {
    min-width: 20px;
    height: auto;
    path {
      fill: ${({ theme, $isAi, $red }) =>
        $isAi ? theme.colors.secondary : $red ? theme.colors.red : theme.colors.revert};
      ${getTransition(300, 'fill')}
    }
  }
  ${({ $isAi }) => $isAi && aiStyles}
`;

export const aiStyles = css`
  border-radius: 50%;
  box-shadow: 0 0 5px 5px hsla(${({ theme }) => theme.colors.revertValue}, 0.5);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.revert};
  &:hover {
    box-shadow: 0 0 8px 10px hsla(${({ theme }) => theme.colors.revertValue}, 0.5);
  }
  ${getTransition(300, 'box-shadow')}
`;
