import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUpdateSize from '../../../../hooks/useUpdateSize';
//import Card from '../../../../components/Card/Card';
import CardFav from '../../../../components/Card/CardFav';
import { getAdsWishlist, getServicesWishlist } from '../../../../API/wishlist';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useAccessToken, useCurrentUser } from '../../../../store/reducers';
import Loader from '../../../../components/Loader';
import { deleteWishList as deleteAdWishList } from '../../../../API/adspage';
import { deleteWishList as deleteServiceWishList } from '../../../../API/services';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../../../../store/actions/alert';
import usePagination from '../../../../hooks/pagination';
import * as S from './styles';
import { emitCreateWithServiceTopicMessage } from '../../../../API/socketConversations';
import CustomModal from '../../../../components/CustomModal';

//import UserCard from '../../../../components/UserCard/UserCard';
import UserCardFav from '../../../../components/UserCard/UserCardFav';
import favoriteCountAction from '../../../../store/actions/favoriteCount';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import ShowPhone from '../../../../components/ShowPhone';
import ServiceCard from '../../../../components/ServiceCard/ServiceCard';
import useIsMobile from '../../../../hooks/useIsMobile';

export default function Favorites({ routeName }) {
  const token = useAccessToken();
  const currentUser = useCurrentUser();
  const userId = currentUser?.id;
  const favs = useSelector((state) => state?.favoriteCount);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const { setFavoriteCount } = bindActionCreators(favoriteCountAction, dispatch);
  const view = useUpdateSize('1399px');
  const [nextPage, setNextPage] = useState(1);
  const tab = useSelector((state) => state.tab.tab);

  const adsPag = usePagination(4);
  const servicesPag = usePagination(4);

  const [adsWishlist, setAdsWishlist] = useState({
    isLoading: false,
    error: null,
    meta: null,
    items: [],
  });
  const [servicesWishlist, setServiceWishlist] = useState({
    isLoading: false,
    error: null,
    meta: null,
    items: [],
  });

  const getFetchData = async () => {
    if (tab === 'ads') {
      getAdsRequest(nextPage, 4);
    } else {
      getServicesRequest(nextPage, 4);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userId, token, tab]);

  // useEffect(() => {
  //  getServicesRequest(1, 4);
  //   window.scrollTo(0, 0);
  // }, [userId, token, servicesPag.currentPage]);

  useEffect(() => {
    getServicesRequest(1, 4);
    getAdsRequest(1, 4);
    setNextPage(2);
  }, []);

  useEffect(() => {
    if (tab === 'ads' && !!adsWishlist?.items?.length) {
      setNextPage(adsWishlist?.meta?.meta?.current_page + 1);
    } else if (!!servicesWishlist?.items?.length) {
      setNextPage(servicesWishlist?.meta?.meta?.current_page + 1);
    }
  }, [tab]);

  useEffect(() => {
    if (adsWishlist?.items?.length === 0 && tab === 'ads') {
      adsPag.setCurrentPage(1);
      adsPag.setStartingPage(1);
    }
  }, [tab]);

  useEffect(() => {
    if (servicesWishlist?.items?.length === 0 && tab === 'services') {
      servicesPag.setCurrentPage(1);
      servicesPag.setStartingPage(1);
    }
  }, [tab]);

  const getAdsRequest = (page, limit = 4) => {
    userId &&
      token &&
      page &&
      getAdsWishlist(userId, page, limit, axiosPrivate, token)
        .then((res) => {
          if (page !== 1) setNextPage(nextPage + 1);
          setAdsWishlist({
            isLoading: true,
            meta: res,
            items: [...adsWishlist?.items, ...res?.data],
          });
        })
        .catch((error) => setAdsWishlist((prev) => ({ ...prev, isLoading: true, error })));
  };

  const getAdsRequestFirst = (page, limit = 4) => {
    userId &&
      token &&
      page &&
      getAdsWishlist(userId, page, limit, axiosPrivate, token)
        .then((res) => {
          if (page !== 1) setNextPage(nextPage + 1);
          setAdsWishlist({
            isLoading: true,
            meta: res,
            items: [...res?.data],
          });
        })
        .catch((error) => setAdsWishlist((prev) => ({ ...prev, isLoading: true, error })));
  };
  const getServicesRequest = (page, limit) => {
    userId &&
      token &&
      page &&
      getServicesWishlist(userId, page, limit, axiosPrivate, token)
        .then((res) => {
          if (page !== 1) setNextPage(nextPage + 1);
          setServiceWishlist({
            isLoading: true,
            meta: res,
            items: [...servicesWishlist?.items, ...res?.data],
          });
        })
        .catch((error) => setServiceWishlist((prev) => ({ ...prev, isLoading: true, error })));
  };
  const getServicesRequestFirst = (page, limit) => {
    userId &&
      token &&
      page &&
      getServicesWishlist(userId, page, limit, axiosPrivate, token)
        .then((res) => {
          if (page !== 1) setNextPage(nextPage + 1);
          setServiceWishlist({
            isLoading: true,
            meta: res,
            items: [...res?.data],
          });
        })
        .catch((error) => setServiceWishlist((prev) => ({ ...prev, isLoading: true, error })));
  };
  const deleteAdFromWishList = async (realEstateId) => {
    deleteAdWishList({ userId, token, realEstateId }, axiosPrivate)
      .then(() => {
        setAdsWishlist({
          ...adsWishlist,
          meta: {
            ...adsWishlist.meta,
            meta: {
              ...adsWishlist.meta.meta,
              total: adsWishlist.meta.meta.total - 1,
            },
          },
          items: adsWishlist.items.filter(({ id }) => id !== realEstateId),
        });
        setFavoriteCount({
          ...favs,
          adsCount: favs.adsCount - 1,
        });
        setAlert('success', true, 'Объявление успешно удалено из избранных');
      })
      .catch(() => {
        setAlert('danger', true, 'Произошла ошибка сервера');
      });
  };

  const deleteServiceFromWishList = async (serviceId) => {
    deleteServiceWishList({ userId, token, serviceId }, axiosPrivate)
      .then(() => {
        setServiceWishlist({
          ...servicesWishlist,
          meta: {
            ...servicesWishlist.meta,
            meta: {
              ...servicesWishlist.meta.meta,
              total: servicesWishlist.meta.meta.total - 1,
            },
          },
          items: servicesWishlist.items.filter(({ id }) => id !== serviceId),
        });
        // getServicesWishlist(userId, servicesPag.currentPage, servicesPag.pageLimit, axiosPrivate, token).then((res) => {
        //   setServiceWishlist({
        //     isLoading: true,
        //     meta: res,
        //     items: res?.data,
        //   });
        // });
        setFavoriteCount({
          ...favs,
          servicesCount: favs.servicesCount - 1,
        });
        setAlert('success', true, 'Объявление успешно удалено из избранных');
      })
      .catch(() => {
        setAlert('danger', true, 'Произошла ошибка сервера');
      });
  };
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentDeletingId, setCurrentDeletingId] = useState(null);
  const [currentDeletingType, setCurrentDeletingType] = useState(null);
  const handleShowModal = (id, type) => {
    setCurrentDeletingId(id);
    setCurrentDeletingType(type);
    setShowModal(true);
  };
  const handleDeleteClick = (id) => {
    setCurrentDeletingId(id);
    setIsDeleteModalVisible(true);
  };

  const confirmDeletion = () => {
    if (currentDeletingId) {
      deleteAdFromWishList(currentDeletingId);
      setIsDeleteModalVisible(false);
    }
  };
  const handleDeleteConfirm = () => {
    if (currentDeletingId && currentDeletingType) {
      if (currentDeletingType === 'ad') {
        deleteAdFromWishList(currentDeletingId);
      } else if (currentDeletingType === 'service') {
        deleteServiceFromWishList(currentDeletingId);
      }
      setShowModal(false);
      setCurrentDeletingId(null);
      setCurrentDeletingType(null);
    }
  };
  // write message modal
  const initialSendMessagePayloads = {
    serviceId: null,
    userId: null,
  };
  const [sendMessagePayloads, setSendMessagePayloads] = useState(initialSendMessagePayloads);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setSendMessagePayloads(initialSendMessagePayloads);
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithServiceTopicMessage(sendMessagePayloads.userId, {
        text: messageInput,
        serviceId: sendMessagePayloads.serviceId,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  const isMobile = useIsMobile(768)

  return (
    <div className="pb-3 pb-sm-4 pb-xxl-5">
      {/* <nav className="d-block d-lg-none mt-3 mb-3 mb-sm-5" aria-label="breadcrumb">
        <Link to="/personal-account" className="gray-3">
          &#10094; Назад
        </Link>
      </nav> */}
      {/* <h4 className="text-center color-1 mb-3 mb-sm-4 mb-xl-5">Мое избранное</h4> */}
      {/* <ul className="tabs mb-4">
        <li>
          <button className={tab === 'ads' ? 'active' : ''} onClick={() => setTab('ads')}>
            Объявления ({favs.adsCount || 0})
          </button>
        </li>
        <li>
          <button className={tab === 'services' ? 'active' : ''} onClick={() => setTab('services')}>
            Услуги ({favs.servicesCount || 0})
          </button>
        </li>
      </ul> */}
      <div
      // className={
      //   tab === 'ads'
      //     ? 'row px-4 px-sm-0 row-cols-sm-2 row-cols-xxl-1 g-3 g-md-4'
      //     : 'row px-2 px-sm-0 row-cols-sm-2 row-cols-lg-3 row-cols-xl-2 row-cols-xxl-2 g-3'
      // }
      >
        {tab === 'ads' ? (
          adsWishlist.isLoading ? (
            adsWishlist?.items?.length ? (
              <InfiniteScroll
                dataLength={adsWishlist?.items?.length}
                next={() => getFetchData()}
                hasMore={nextPage <= adsWishlist?.meta?.meta?.last_page}
                loader={
                  <div className="p-5 w-100 d-flex justify-content-center">
                    <Loader color="#146492" />
                  </div>
                }
              >
                <S.GridDisplay className={view === 'as-a-list' ? '' : 'row row-cols-sm-2 gx-2 gx-md-4'}>
                  {adsWishlist.items.map((item) => {
                    return (
                      <S.NewStyledFavCard key={item.id}>
                        <CardFav
                          ads={item}
                          type={view}
                          isVip={item.isVip}
                          isHot={item.isHot}
                          title={item.title}
                          price={item.price}
                          transactionType={item.transactionType}
                          residentalComplex={item?.residentalComplex}
                          residentalComplexForUser={item?.residentalComplexForUser}
                          address={item.address}
                          metro={item.metro}
                          text={item.description}
                          date={item.createdAtForUser}
                          showUser={true}
                          isLargeFont={true}
                          id={item.id}
                          uuid={item.uuid}
                          user={item.user}
                          communalPrice={item.communalPrice}
                          pledge={item.pledge}
                          commissionForUser={item.commissionForUser}
                          prepaymentTypeForUser={item.prepaymentTypeForUser}
                          rentalTypeForUser={item.rentalTypeForUser}
                          wishlistStatus={item.wishlistStatus}
                          reportStatus={item.reportStatus}
                          userAvatar={item.user?.avatar}
                          routeName={routeName}
                          userId={item.user.id}
                          inWishlist={true}
                          withSide={true}
                          totalArea={item?.totalArea}
                          estateName={item?.estate?.name}
                          buildingTypeForUser={item?.buildingTypeForUser}
                          realEstateTypeForUser={item?.estate?.realEstateTypeForUser}
                          acres={item?.acres}
                          viewsCount={item.viewsCount}
                          controlButton={
                            <S.ButtonHolder2>
                              <S.ButtonRemoveRelocate
                                type="button"
                                className="color-1 d-flex align-items-center"
                                onClick={() => handleShowModal(item.id, 'ad')}
                              >
                                <span className="ms-2">Удалить из избранного</span>
                              </S.ButtonRemoveRelocate>
                            </S.ButtonHolder2>

                          }
                          callbackDeleteWish={() => { getAdsRequestFirst(1, 4) }}
                        />
                        {/* !!!! */}
                        {/* <div className="col-3">

                          <div className="author w-fit d-flex flex-column align-items-center ms-auto mt-4">
                            <NavLink to={`/user/${props?.user?.id}`}>
                              <img src={userAvatar ? userAvatar : '/img/img-photo.svg'} alt="Фото" />
                            </NavLink>
                            <div className="gray-2 fw-5 fs-09 mt-2">
                              <NavLink to={`/user/${props?.user?.id}`}>{props.user?.fullName}</NavLink>
                            </div>
                            {props.user?.createdAtForUser && (
                              <div className="gray-3 fs-08 mt-2">На сайте с {props.user?.createdAtForUser}</div>
                            )}
                            <ShowPhone className="fs-09 mt-3" phone={props?.user?.phone} />
                          </div>
                        </div> */}
                      </S.NewStyledFavCard>
                    );
                  })}
                </S.GridDisplay>
              </InfiniteScroll>
            ) : (
              <h6 className="m-auto p-5 text-center">Объявлений нет</h6>
            )
          ) : (
            <div className="d-flex justify-content-center p-5 w-100">
              <Loader color="#146492" />
            </div>
          )
        ) : servicesWishlist.isLoading ? (
          servicesWishlist?.items?.length ? (
            <InfiniteScroll
              dataLength={servicesWishlist?.items?.length}
              next={() => getFetchData()}
              hasMore={nextPage <= servicesWishlist?.meta?.meta?.last_page}
              loader={
                <div className="p-5 w-100 d-flex justify-content-center">
                  <Loader color="#146492" />
                </div>
              }
            >
              <S.GridDisplay
                className={
                  //'row px-4 px-sm-0 row-cols-sm-2 row-cols-xxl-1 g-3 g-md-4'
                  //row row-cols-sm-2 gx-2 gx-md-4
                  'row row-cols-sm-2 gx-2 gx-md-4'
                }
              >
                {servicesWishlist.items.map((item) => {
                  return (
                    <S.StyledCardWrapperZ key={item.id}>
                      <ServiceCard
                        service={item}
                        id={item.id}
                        type={!isMobile && 'as-a-list'}
                        wishlistStatus={item.wishlistStatus}
                        isAdView={false}
                        user={item.user}
                        description={item.description}
                        serviceTypeId={item.serviceTypeId}
                        labels={item.labels}
                        images={item.subServices[0].service.images}
                        subServices={item.subServices}
                        withSide={isMobile}
                        isLargeFont={true}
                        callbackDeleteWish={() => { getServicesRequestFirst(1, 4) }}

                        showUser={true}
                        // type={!isMobile && 'as-a-list'}
                        // inAddResponse={true}
                        // withSide={true}
                        // userId={item?.user?.id}
                        // userName={item.user?.fullName}
                        // link={`/user/${item?.userId}`}
                        // photo={item.user.avatar}
                        // description={item.description}
                        // labels={item.labels}
                        // phone={item.user.phone}
                        // email={item.user.email}
                        // rating={item.user.rating}
                        // viewsCount={item.user?.viewsCount}
                        // subServices={item.subServices}
                        // serviceId={item.id}
                        // serviceTypeId={item.serviceTypeId}
                        // setSendMessagePayloads={setSendMessagePayloads}
                        // isShowMessage={true}
                        inWishlist={true}
                        controlButton={
                          <div className="d-flex justify-content-center justify-content-xl-end mt-2">
                            <S.ButtonRemoveRelocate2
                              type="button"
                              className="ms-4 color-1 d-flex align-items-center"
                              onClick={() => handleShowModal(item.id, 'service')}
                            >
                              <span className="ms-2">Удалить из избранного</span>
                            </S.ButtonRemoveRelocate2>
                          </div>
                        }
                      />
                    </S.StyledCardWrapperZ>
                  );
                })}
              </S.GridDisplay>
            </InfiniteScroll>
          ) : (
            <h5 className="text-center p-5 w-100">Нет избранных</h5>
          )
        ) : (
          <div className="d-flex justify-content-center p-5 w-100">
            <Loader color="#146492" />
          </div>
        )}
      </div>
      {/*{tab === 'ads' && adsWishlist?.items?.length > 0 && (*/}
      {/*  <Pagination*/}
      {/*    pageLimit={adsPag.pageLimit}*/}
      {/*    currentPage={adsPag.currentPage}*/}
      {/*    setCurrentPage={adsPag.setCurrentPage}*/}
      {/*    pagesDisplayedLimit={3}*/}
      {/*    itemsAmount={adsWishlist?.meta?.meta?.total || 0}*/}
      {/*    startingPage={adsPag.startingPage}*/}
      {/*    className="mt-4 mt-sm-5"*/}
      {/*    setStartingPage={adsPag.setStartingPage}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{tab === 'services' && servicesWishlist?.items?.length > 0 && (*/}
      {/*  <Pagination*/}
      {/*    pageLimit={servicesPag.pageLimit}*/}
      {/*    currentPage={servicesPag.currentPage}*/}
      {/*    setCurrentPage={servicesPag.setCurrentPage}*/}
      {/*    pagesDisplayedLimit={3}*/}
      {/*    itemsAmount={servicesWishlist?.meta?.meta?.total || 0}*/}
      {/*    startingPage={servicesPag.startingPage}*/}
      {/*    className="mt-4 mt-sm-5"*/}
      {/*    setStartingPage={servicesPag.setStartingPage}*/}
      {/*  />*/}
      {/*)}*/}
      {showModal && (
        <div className="modal fade show" id="delete-ad" tabIndex="-1" style={{ display: "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                <div className="text-center fs-15 fw-6 title-font my-5">
                  Вы уверены что хотите удалить объявление?
                </div>
                <div className="row row-cols-2">
                  <div>
                    <button type="button" className="btn btn-2 w-100 fs-11 text-uppercase" onClick={handleCloseModal}>
                      Отмена
                    </button>
                  </div>
                  <div>
                    <button type="button" className="btn btn-1 w-100 fs-11 text-uppercase" onClick={handleDeleteConfirm}>
                      Удалить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomModal isShow={sendMessagePayloads.userId} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>
    </div>
  );
}
