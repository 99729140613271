import { useSwiper } from 'swiper/react';
import { useTimeout } from '../../../../hooks/useTimeout';
import React, { useEffect } from 'react';
import { DELAY, Media } from '../Content';
import * as S from './styles';

type ControlProps = {
  isActive: boolean;
  isLast: boolean;
  current: number;
  content: Media[];
  toggle: () => void;
  setNextSlide: () => void;
  setPrevSlide: () => void;
};
export const Control = ({ isActive, isLast, current, content, toggle, setNextSlide, setPrevSlide }: ControlProps) => {
  const swiper = useSwiper();
  const [setNext, clear] = useTimeout(() => {
    if (!isActive) return;
    if (current === content.length - 1) {
      if (isLast) toggle();
      else {
        swiper.slideTo(0);
        setNextSlide();
      }
    } else {
      swiper.slideNext();
    }
  });

  const onClick = (direction: 'prev' | 'next') => {
    clear();
    if (direction === 'prev') {
      if (current === 0) setPrevSlide();
      else swiper.slidePrev();
    } else {
      if (current === content.length - 1) {
        if (isLast) toggle();
        else setNextSlide();
      } else swiper.slideNext();
    }
  };

  useEffect(() => {
    const delay = !!content[current] && !!content[current].duration ? content[current]!.duration! + 0.5 : DELAY;
    if (isActive) setNext({ delay });
    else clear();
  }, [isActive]);

  useEffect(() => {
    const delay = !!content[current] && !!content[current].duration ? content[current]!.duration! + 0.5 : DELAY;
    clear();
    setNext({ delay });
  }, [current]);

  return (
    <S.Wrapper>
      <S.ControlBtn onClick={() => onClick('prev')} />
      <S.ControlBtn onClick={() => onClick('next')} />
    </S.Wrapper>
  );
};
