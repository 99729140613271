import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import useAlert from '../hooks/alert';
import { useShowCookies } from '../hooks/useShowCookies';
import { CookiesInfo } from '../components/CookiesInfo';

const AppLayout = () => {
  const { alertNode } = useAlert(2000);
  const [isOpen, isShow, setAccept] = useShowCookies();

  return (
    <div className="root-wrapper">
      <Header />
      <div className="root-wrapper__content">
        <Outlet />
      </div>
      {alertNode}
      <Footer />
      <CookiesInfo isOpen={isOpen} isShow={isShow} setAccept={setAccept} />
    </div>
  );
};

export default AppLayout;
