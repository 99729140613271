import apiRoutes from './config/apiRoutes';

export const deleteVideo = async (axiosPrivate, videoId, token) => {
  try {
    await axiosPrivate.delete(`${process.env.REACT_APP_BASE_URL}${apiRoutes.DELETE_VIDEO}/${videoId}`, {
      data: { token },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
