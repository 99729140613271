import React, { useEffect, useState } from 'react';
import CustomSelect from '../../CustomSelect/CustomSelect';
import useSelectedCity from '../../../hooks/useSelectedCity';
import useMapCenter from '../../../hooks/useMapCenter';
import defineMapCenter from '../../../API/defineMapCenter';
import { withYMaps, YMapsApi } from 'react-yandex-maps';
import SearchDropdown from '../../SearchDropdown';
import { getCities } from '../../../API/cities';

const CityContainerComponent = React.memo(({ ymaps, noView }: { ymaps?: YMapsApi }) => {
  const [isShowCities, setIsShowCities] = useState(false);
  const { city, setCity, isDefinedCity } = useSelectedCity();
  const { setMapCenter } = useMapCenter(ymaps!);
  const [cities, setCities] = useState<string[]>([]);

  useEffect(() => {
    getCities().then((res) => {
      const cities = res.body.sort();
      setCities(['Вся Россия', ...cities]);
    });
  }, []);

  const changeCity = (title: string) => {
    const localStorageUserCity = localStorage.getItem('userCity');
    const localStorageMapCenter = !!localStorage.getItem('mapCenter') && localStorage.getItem('mapCenter')!.split(',');

    if (title !== localStorageUserCity) {
      localStorage.setItem('userCity', title);
      setCity(title);
      ymaps &&
        defineMapCenter(ymaps, title === 'Вся Россия' ? 'Москва' : title).then((coords) => {
          localStorage.setItem('mapCenter', coords);
          setMapCenter(coords);
        });
    } else if (localStorageMapCenter) {
      setCity(localStorageUserCity);
      setMapCenter(localStorageMapCenter);
    }

    setIsShowCities(false);
  };

  if (noView) {
    return <></>;
  } else {
    return (
      <div className="city-container align-self-center">
        <CustomSelect
          btnClass={'color-2 text-uppercase'}
          modificator="city"
          isShow={isShowCities}
          checkedOptions={[city]}
          options={cities}
          callback={({ title }: { title: string }) => changeCity(title)}
          child={SearchDropdown}
          placeholder="Введите ваш город"
          align="left"
          initialCount="100"
        />
        {/*<CityPopup city={city} isDefinedCity={isDefinedCity} setIsShowCities={setIsShowCities} />*/}
      </div>
    );
  }
});

export default withYMaps(CityContainerComponent, true);
