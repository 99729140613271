import styled from 'styled-components';
export const ButtonHolder = styled.div`
  margin-top: -8.5rem !important;
  justify-content: flex-end !important;
  display: flex !important;
  margin-right: 25px;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: -9.5rem !important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: -9.5rem !important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-top: -11.5rem !important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: 4rem !important;
    justify-content: flex-end !important;
    display: flex !important;
    width: 100%;
  }
`;
export const ButtonHolder2 = styled.div`
  //margin-top: -8.5rem!important;
  justify-content: center !important;
  display: flex !important;
  //margin-right: 25px;
  margin-top: auto;
  margin-bottom: 10px;
  @media screen and ${({ theme }) => theme.media.md} {
    //margin-top: -9.5rem!important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    //margin-top: -9.5rem!important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    //margin-top: -11.5rem!important;
    justify-content: center !important;
    display: flex !important;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: 3rem !important;
    justify-content: flex-end !important;
    display: flex !important;
    width: 100%;
  }
`;
export const ButtonRemoveRelocate = styled.button`
  // position: absolute;
  // margin-top: -4.5rem;
  // margin-left: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 30px;
  border: 1px solid #198acb;
  border-radius: 50px;
  color: #b8c5cc;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  transition: 0.2s;
  &:hover {
    color: #ffffff;
    background: #fe6060;
    border: 1px solid #fe6060;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 36px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 16px;
    background: transparent;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 41px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 20px;
    background: transparent;
  }
`;
export const ButtonRemoveRelocate2 = styled.button`
  // position: absolute;
  // margin-top: -4.5rem;
  // margin-left: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 30px;
  border: 1px solid #198acb;
  border-radius: 50px;
  color: #b8c5cc;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  transition: 0.2s;
  &:hover {
    color: #ffffff;
    background: #fe6060;
    border: 1px solid #fe6060;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 36px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 16px;
    background: transparent;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 41px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 20px;
    background: transparent;
    margin-left: -20px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 41px;
    border: 1px solid #198acb;
    border-radius: 50px;
    color: #b8c5cc;
    font-weight: bold;
    font-size: 20px;
    background: transparent;
  }
`;

export const NewStyledFavCard = styled.div`
  display: flex;
  // flex-direction: column;
  // --bs-gutter-x: 1.5rem;
  // --bs-gutter-y: 0;
  // display: flex;
  // flex-wrap: wrap;
  // margin-top: calc(-1* var(--bs-gutter-y));
  // margin-right: calc(-.5* var(--bs-gutter-x));
  // //margin-left: calc(-.5* var(--bs-gutter-x));

  // gap: 20px;
  padding-bottom: 10px;

  @media screen and ${({ theme }) => theme.media.sm} {
    width: 45%;
    max-width: 50%;
    flex-grow: 1;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 100%;
    //margin-bottom: 20px !important;
    max-width: 100%;
  }
`;

export const GridDisplay = styled.div`
  @media screen and ${({ theme }) => theme.media.sm} {
    > div:nth-child(odd) {
      margin-right: 0.5rem;
    }
    > div:nth-child(edd) {
      margin-left: 0.5rem;
    }
  }
`;

export const NoWrappingDiw = styled.div`
  flex-wrap: nowrap !important;
`;
export const StyledCardWrapperZ = styled.div`
  // max-height: 910px;
  height: 100%;
  //width: 100%;
  position: relative;
  // background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  display: flex;
  //flex-wrap: wrap;
  //margin-left: calc(-.5* var(--bs-gutter-x));
  //margin-left: 20px;
  margin-bottom: 20px;
  width: 100%;
  @media screen and ${({ theme }) => theme.media.sm} {
    // max-height: 750px;
    //height: 750px;
    width: 45%;
    flex-grow: 1;
    max-width: 50%;
    //margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    // max-height: 820px;
    //height: 820px;
    //margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    // max-height: 800px;
    //height: 800px;
    //margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    //width: 100%;
    // max-height: 840px;
    //height: 840px;
    position: relative;
    // background-color: #fff;
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
    border-radius: 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px !important;
    margin-left: 10px;
    //max-height: 530px;
  }
`;
