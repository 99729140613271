import { NavLink } from 'react-router-dom';
import { ReactComponent as Ai } from '../../../assets/icons/nav/ai.svg';
import { ReactComponent as Heart } from '../../../assets/icons/nav/favorite.svg';
import { ReactComponent as Main } from '../../../assets/icons/nav/main.svg';
import { ReactComponent as Email } from '../../../assets/icons/nav/email.svg';
import { ReactComponent as Jingle } from '../../../assets/icons/nav/jingle.svg';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useAccessToken } from '../../../store/reducers';
import { bindActionCreators } from 'redux';
import favoriteCountActions from '../../../store/actions/favoriteCount';
import { getAdsWishlist, getServicesWishlist } from '../../../API/wishlist';
import * as S from './styles';
import { Counter } from '../../Header/HeaderComponent/styles';

type MobPanelProps = {};

export const MobPanel = ({}: MobPanelProps) => {
  const { conversationCount, user, favs } = useSelector((state: RootState) => ({
    conversationCount: state.conversationCount,
    user: state.currentUser,
    favs: state.favoriteCount,
  }));
  const favsCount = Object.values(favs)
    .map((val) => Number(val))
    .reduce((val, acc) => val + acc);

  const axiosPrivate = useAxiosPrivate();
  const token = useAccessToken();
  const dispatch = useDispatch();
  const { setFavoriteCount } = bindActionCreators(favoriteCountActions, dispatch);

  const getFavData = async () => {
    if (user && user.id && token) {
      const adsData = await getAdsWishlist(user.id, 1, 1, axiosPrivate, token);
      const adsCount = adsData?.meta?.total || 0;
      const servicesData = await getServicesWishlist(user.id, 1, 1, axiosPrivate, token);
      const servicesCount = servicesData?.meta?.total || 0;
      setFavoriteCount({ servicesCount, adsCount });
    }
  };

  useEffect(() => {
    getFavData();
  }, [user]);

  return (
    <S.Wrapper>
      <S.Nav>
        <S.ListContainer>
          <S.ListElt>
            <NavLink to="/">
              <Main />
            </NavLink>
          </S.ListElt>
          <S.ListElt $red={favsCount > 0}>
            <NavLink to="/personal-account/favorites" className={`heart`}>
              <Heart />
              {user && !!favsCount && favsCount > 1 && <MobCounter>{favsCount}</MobCounter>}
            </NavLink>
          </S.ListElt>
          <S.ListElt $isAi={true}>
            <NavLink to="/ai">
              <Ai />
            </NavLink>
          </S.ListElt>
          <S.ListElt>
            <NavLink to="/personal-account/my-messages">
              <Email />
              {user && conversationCount > 0 && <MobCounter>{conversationCount}</MobCounter>}
            </NavLink>
          </S.ListElt>
          <S.ListElt>
            <div>
              <Jingle />
              {user && <MobCounter>2</MobCounter>}
            </div>
          </S.ListElt>
        </S.ListContainer>
      </S.Nav>
    </S.Wrapper>
  );
};

const MobCounter = ({ children }: PropsWithChildren) => {
  return <Counter $bottom={-5}>{children}</Counter>;
};
