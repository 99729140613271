import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { ReactComponent as EMail } from '../../../assets/icons/card/mail.svg';
import { ReactComponent as Share } from '../../../assets/icons/card/share.svg';
import { ReactComponent as Whatsapp } from '../../../assets/icons/card/whatsapp.svg';
import { ReactComponent as Mail } from '../../../assets/icons/nav/email.svg';
import { ReactComponent as Tg } from '../../../assets/icons/footer/tg.svg';
import * as S from './styles';
import { bindActionCreators } from 'redux';
import alertActions from '../../../store/actions/alert';
import { useDispatch } from 'react-redux';
import BtnRep from '../../BtnRep';

type ContactsPanelProps = {
  isReport?: boolean;
  isHorizontalAll?: boolean;
  isHoz?: boolean;
  phone: string;
  mail: string;
  link: string;
  onClickMail: () => void;
  isHorizontalPC: boolean;
  realEstateId: number;
  reportStatus: any;
  type: string;
  reportTitle: any;
  reportOnClick: any;
};
export const ContactsPanel = ({
  isReport = true,
  isHorizontalAll,
  isHoz,
  mail,
  isHorizontalPC,
  onClickMail,
  phone,
  reportTitle,
  reportOnClick
}: ContactsPanelProps) => {
  return (
    <>
      <S.Wrapper isHorizontalPC={isHorizontalPC} isHorizontalAll={!!isHorizontalAll} isHoz={!!isHoz}>
        <div
          onClick={onClickMail}
          style={Object.assign({ display: 'flex', alignItems: 'center' }, isHoz ? {} : { height: 50 })}
        >
          <Mail style={{ margin: 0 }} />
        </div>
        <div
          onClick={() => (window.location.href = `https://t.me/+${phone}`)}
          style={Object.assign({ display: 'flex', alignItems: 'center' }, isHoz ? {} : { height: 50 })}
        >
          <Tg />
        </div>
        <div
          onClick={() => (window.location.href = `https://wa.me/${phone}`)}
          style={Object.assign({ display: 'flex', alignItems: 'center' }, isHoz ? {} : { height: 50 })}
        >
          <Whatsapp />
        </div>
        <div
          onClick={() => (window.location.href = `mailto:${mail}`)}
          style={Object.assign({ display: 'flex', alignItems: 'center' }, isHoz ? {} : { height: 50 })}
        >
          <EMail />
        </div>
        {isReport ? (
          <BtnRep isHoz={isHoz} title={reportTitle} onClick={reportOnClick} />
        ) : null}
      </S.Wrapper>
    </>
  );
};
