import React from 'react'

export default function ImgPreview(props) {
    const urls = props.urls;
    return (
        <div className="img-preview" onClick={props.onClick}>
            {
                urls &&
                urls.map(function (item, index, arr) {
                    if (index === 1 && urls?.find(item => item?.split('.')?.at(-1) === 'mp4')) {
                        return (
                            <div key={index.toString()} className="wrap">
                                <img src="/img/icons/card/video-library-svgrepo-com.svg" style={{ 'object-fit': 'inherit' }} />
                            </div>
                        )
                    } else if (index < 2) {
                        return (
                            <div key={index.toString()} className="wrap">
                                <img src={item} alt="фото" style={{ borderRadius: '10px' }} />                            </div>
                        )
                    } else if (index === 2) {
                        return (
                            <div key={index.toString()} className="wrap">
                                <img src={item} alt="фото" style={{ borderRadius: '10px' }} />
                                <div className="extra" style={{ borderRadius: '10px' }}>Еще {arr.length - 3} фото</div>
                            </div>
                        )
                    } else {
                        return false;
                    }
                })
            }
        </div>
    )
}
