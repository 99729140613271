import { useCallback, useEffect, useRef, useState } from 'react';
import Rating from 'react-rating';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { emitCreateWithoutTopicMessage } from '../API/socketConversations';
import {
  addReportReview,
  addReportUser,
  CreateReview,
  deleteReportReview,
  deleteReportUser,
  getReviewsInUsersPage,
  userInfoInUserPage,
} from '../API/userspage';
import CustomModal from '../components/CustomModal';
import ShowPhone from '../components/ShowPhone';
import { SliderAds } from '../components/SliderAds';
import { checkPhotoPath } from '../helpers/photo';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import alertActions from '../store/actions/alert';
import { useAccessToken, useCurrentUser } from '../store/reducers';

import ReactDOM from 'react-dom';
import { ContactsPanel } from '../components/Card/ContactsPanel/ContactsPanel';
import ReportModal from '../components/ReportModal';
import { SliderReviews } from '../components/SliderReviews';
import { SliderServices } from '../components/SliderServices';
import useIsMobile from '../hooks/useIsMobile';
import PageWithBackLayout from '../layouts/PageWithBack.Layout';
import * as S from './PersonalAccount/UserProfile/styles';
import { ReactComponent as PartnerIcon } from '../assets/icons/profile/parther.svg';

const StarRatingWithNumber = ({ rating }) => {
  const roundedRating = Math.round(rating);
  const isMobile = useIsMobile(768);
  return (
    <S.UserPageRatingWrapper isMobile={isMobile}>
      <img src="/img/icons/star-blue.svg" alt="Рейтинг" />
      <S.UserPageRatingSpan isMobile={isMobile}>{roundedRating}</S.UserPageRatingSpan>
    </S.UserPageRatingWrapper>
  );
};

const UserHeader = ({ userInformation, setAlert, setIsShowWriteMessageModal, isShowWriteMessageModal }) => {
  const isMobile = useIsMobile(768);

  const currentUser = useCurrentUser();

  const onClickMail = useCallback(() => {
    if (currentUser) {
      setIsShowWriteMessageModal(true);
    } else {
      setAlert('danger', true, 'Функция доступна авторизованным пользователям');
    }
  }, [currentUser, isShowWriteMessageModal]);

  //REPORT
  //UTILS
  const axiosPrivate = useAxiosPrivate();

  //REPORT
  //ALERTS
  const onlyAuth = () => setAlert('danger', true, 'Функция доступна авторизованным пользователям');
  const alreadySent = () => setAlert('danger', true, 'Жалоба уже отправлена');

  //DATE
  const currentToken = useAccessToken();
  const [data, setData] = useState({
    token: currentToken,
    fromId: currentUser?.id,
    toId: userInformation?.id,
  });
  useEffect(() => {
    setData({
      token: currentToken,
      fromId: currentUser?.id,
      toId: userInformation?.id,
    });
  }, [currentToken, currentUser?.id, userInformation?.id]);

  //REPORT STATUS
  const [isExistUserReport, setIsExistUserReport] = useState(userInformation?.reportStatus);
  useEffect(() => {
    setIsExistUserReport(userInformation?.reportStatus);
  }, [userInformation?.reportStatus]);

  //USER ACTIONS
  const [isReportOpen, setIsReportOpen] = useState(false);
  const reportAdAction = async (payload: any) => {
    if (!currentUser) {
      onlyAuth();
    } else {
      setIsReportOpen(false);
      await addReportUser(axiosPrivate, payload)
        .then(() => {
          setIsExistUserReport((reportAdStatus) => !reportAdStatus);
          setAlert('success', true, 'Жалоба успешно добавлена');
        })
        .catch((e) => {
          setAlert('danger', true, 'Произошла ошибка');
        });
    }
  };

  const reportTitle = isExistUserReport ? 'Жалоба отправлена' : 'Пожаловаться';
  const reportOnClick = () => {
    !currentUser ? onlyAuth() : isExistUserReport ? alreadySent() : setIsReportOpen(true);
  };

  return (
    <div style={{ width: '100%', background: '#F3F3F3' }}>
      <div className="container">
        <div
          style={{
            paddingTop: isMobile ? '1rem' : '2rem',
            paddingBottom: isMobile ? '1rem' : '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile && 'space-between',
            // maxWidth:500
          }}
        >
          <div style={{ display: 'flex' }}>
            <div className="user-photo" style={{position:'relative'}}>
              <img
                style={{
                  'object-fit': 'cover',
                  width: isMobile ? '5rem' : '10rem',
                  height: isMobile ? '5rem' : '10rem',
                  borderRadius: '50%',
                }}
                src={checkPhotoPath(userInformation?.avatar)}
                alt={userInformation?.fullName}
              />

              {userInformation?.isPartner ? (
                <PartnerIcon
                  style={{
                    width: '35%',
                    height: '35%',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(0%, 0%)',
                  }}
                />
              ) : null}
              {/*<div className="indicator online"/>*/}
            </div>
            <S.RowAvatartitle>
              <S.SkeletonMargin2>
                <S.AvatarTitle>
                  {userInformation?.firstName} {userInformation?.lastName}
                </S.AvatarTitle>
                <S.AvatarUnder>На сайте с {userInformation?.createdAtForUser}</S.AvatarUnder>
                {(!isMobile && !currentUser) || userInformation?.id !== currentUser?.id ? (
                  <ContactsPanel
                    phone={userInformation?.phone}
                    mail={userInformation?.email}
                    link={`https://dakvartira.ru/user/${userInformation?.id}`}
                    onClickMail={onClickMail}
                    isHorizontalPC={true}
                    isHorizontalAll={true}
                    reportOnClick={reportOnClick}
                    reportTitle={reportTitle}
                  />
                ) : null}
              </S.SkeletonMargin2>
            </S.RowAvatartitle>
          </div>
          <StarRatingWithNumber rating={userInformation?.rating || 0} />
        </div>
      </div>

      {/* REPORT MODAL */}
      {isReportOpen &&
        ReactDOM.createPortal(
          <ReportModal isShow={isReportOpen} setIsShow={setIsReportOpen} apiAction={reportAdAction} pndData={data} />,
          document.getElementById('root') as HTMLElement
        )}
    </div>
  );
};

const CarouselReviews = ({ setAlert, userId, page, userInformation }) => {
  const axiosPrivate = useAxiosPrivate();
  const currentToken = useAccessToken();

  const currentUser = useCurrentUser();

  const [limit, setLimit] = useState(2);

  const [reviews, setReviews] = useState<any>([]);
  const [reviewStatus, setReviewStatus] = useState(false);

  const createReview = (e) => {
    e.preventDefault();
    CreateReview(axiosPrivate, data).then(() => {
      setReviewStatus(true);
      getReviewsInUsersPage(axiosPrivate, userId, page, currentUser.id).then((res) => setReviews(res));
    });
  };

  const addReportForReview = async (payload) => {
    await addReportReview(axiosPrivate, payload);
    getReviewsInUsersPage(axiosPrivate, userId, page, currentUser.id).then((res) => setReviews(res));
  };

  const deleteReportForReview = async (usersReviewId) => {
    await deleteReportReview(axiosPrivate, currentToken, usersReviewId, currentUser.id);
    getReviewsInUsersPage(axiosPrivate, userId, page, currentUser.id).then((res) => setReviews(res));
  };

  useEffect(() => {
    getReviewsInUsersPage(axiosPrivate, userId, page, currentUser?.id).then((res) => setReviews(res));
  }, [currentUser, userId]);

  const cuterArray = (arr) => {
    const result = [];
    for (let i = 0; i < Math.ceil(arr?.length / limit); i++) {
      result.push(arr.slice(i * limit, i * limit + limit));
    }
    return result;
  };

  const [data, setData] = useState({});
  useEffect(() => {
    if (userId && currentUser?.id) {
      setData((data) => ({
        ...data,
        toId: userId,
        fromId: currentUser?.id,
        token: currentToken,
        userReport: userInformation?.reportStatus,
      }));
    }
  }, [userId, currentUser?.id, currentToken, userInformation]);

  return (
    <>
      <section className="container">
        <div className="row">
          <h4
            className="text-center text-md-start"
            style={{
              fontFamily: "'Montserrat'",
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '30px',
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              color: '#198ACB',
            }}
          >
            Отзывы
          </h4>

          {!reviews?.data?.length ? (
            <>
              <div className="fs-11 text-center text-md-start mb-4">Нет отзывов</div>
            </>
          ) : (
            <>
              <SliderReviews
                reviews={reviews?.data}
                // reviews={cuterArray(reviews?.data)[0]}
                addReportForReview={addReportForReview}
                deleteReportForReview={deleteReportForReview}
                pageUser={userInformation}
              />

              {/* {cuterArray(reviews?.data)[0]?.map((i) => (
                  <div className="review mb-3" key={i.id}>
                    <img
                      src={checkPhotoPath(i?.from?.avatar)}
                      alt={i.from?.fullName}
                      className="photo d-none d-sm-block"
                    />
                    <div className="ms-sm-4">
                      <div className="d-flex align-items-center d-sm-block mb-2 mb-sm-0">
                        <div className="ms-3 ms-sm-0">
                          <h4>{i.from?.fullName}</h4>
                          <div className="rating mb-sm-3">
                            <span className="fs-12 ms-0">Оценка:</span>
                            <Rating
                              readonly={true}
                              initialRating={i?.rating}
                              fractions={2}
                              emptySymbol={<img src="/img/icons/star-gray.svg" alt="1" />}
                              fullSymbol={<img src="/img/icons/star-blue.svg" alt="1" />}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p>{i?.description}</p>
                      </div>
                      <div className="date fs-11 gray-3">{i?.createdAtForUser}</div>
                      <button
                        type="button"
                        className="claiming color-1 fs-09"
                        onClick={() => {
                          i?.reportStatus ? deleteReportForReview(i?.id) : addReportForReview(i?.id);
                        }}
                      >
                        {i?.reportStatus ? 'Разжаловать' : 'Пожаловаться'}
                      </button>
                    </div>
                  </div>
                ))} */}
              {/* {reviews?.meta?.total > limit && (
                  <button type="button" className="fs-12 fw-5 color-1 mx-auto bb-1" onClick={() => setLimit(limit + 2)}>
                    Показать еще
                  </button>
                )} */}
            </>
          )}
          <button
            disabled={userId === String(currentUser?.id) || userInformation?.reviewStatus || reviewStatus}
            type="button"
            data-bs-toggle={!!currentUser ? 'modal' : undefined}
            data-bs-target={!!currentUser ? '#write-review' : undefined}
            className="btn btn-1 fs-11 mx-auto mx-md-0 mb-4"
            onClick={(e) => {
              if (!currentUser) {
                e.preventDefault();
                e.stopPropagation();
                setAlert('danger', true, 'Функция доступна авторизованным пользователям');
              }
            }}
          >
            Добавить отзыв
          </button>
        </div>
      </section>
      <div className="modal fade" id="write-review" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal">
                <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.00006 1.18237L15 15.9049" />
                  <path d="M14.9999 1.18237L1.00001 15.9049" />
                </svg>
              </button>
              <h4 className="text-center color-1 mb-2 mb-sm-4">Оставьте отзыв</h4>
              <form>
                <div className="row">
                  <div className="col-lg-4 d-flex flex-lg-column align-items-center mb-2 mb-sm-4 mb-lg-0">
                    <div className="photo me-3 me-lg-0 mb-lg-3">
                      <img src={checkPhotoPath(userInformation?.avatar)} alt={userInformation?.fullName} />
                      {/*<div className="indicator online"/>*/}
                    </div>
                    <div className="text-lg-center">
                      <div className="fs-11 fw-5 mb-sm-2">{userInformation?.fullName}</div>
                      <div className="fs-11 fw-5">{userInformation?.ownerTypeForUser}</div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex align-items-center">
                      <span className="fs-11 me-4">Ваша оценка:</span>
                      <Rating
                        initialRating={data?.rating || 0}
                        start={0}
                        stop={5}
                        fractions={2}
                        emptySymbol={<img src="/img/icons/star-gray.svg" alt="1" />}
                        fullSymbol={<img src="/img/icons/star-blue.svg" alt="5" />}
                        onClick={(rate) => setData((prevState) => ({ ...prevState, rating: rate }))}
                      />
                    </div>
                    <textarea
                      className="mt-3"
                      rows="6"
                      value={data?.description || ''}
                      placeholder="Напишите отзыв"
                      onChange={(e) => setData((prevState) => ({ ...prevState, description: e.target.value }))}
                    />
                    <button
                      disabled={userInformation?.reviewStatus || reviewStatus}
                      data-bs-toggle={!!currentUser ? 'modal' : undefined}
                      type="submit"
                      className="btn btn-1 fs-12 ms-auto mt-3"
                      onClick={(e) => createReview(e)}
                    >
                      ОТПРАВИТЬ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CarouselServices = ({ userInformation }) => {
  return userInformation?.services?.length ? (
    <section className="container">
      <div className="row">
        <h4
          className="text-center text-md-start mb-2 mb-sm-4"
          style={{
            fontFamily: "'Montserrat'",
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '30px',
            lineHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            color: '#198ACB',
          }}
        >
          Услуги
        </h4>
        {userInformation?.services?.length === 0 ? (
          <div className="fs-11 text-center text-md-start">Пользователь не предоставляет услуг</div>
        ) : (
          <div className="position-relative">
            <SliderServices services={userInformation?.services} reportStatus={userInformation?.reportStatus} />
          </div>
        )}
      </div>
    </section>
  ) : null;
};

const CarouselAds = ({ adRef, userInformation, routeName }) => {
  return userInformation?.realEstates?.length ? (
    <section className="container">
      <div className="row">
        <h4
          className="text-center text-md-start"
          ref={adRef}
          style={{
            fontFamily: "'Montserrat'",
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '30px',
            lineHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            color: '#198ACB',
          }}
        >
          Объявления
        </h4>
        {userInformation?.realEstates?.length === 0 ? (
          <div className="fs-11 text-center text-md-start">Нет актуальных объявлений</div>
        ) : (
          <div className="position-relative">
            <SliderAds userAds={userInformation?.realEstates} routeName={routeName} />
          </div>
        )}
      </div>
    </section>
  ) : null;
};

export default function UserPage({ routeName }) {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();

  const { userId, page } = useParams();
  const loc = useLocation();

  const [userInformation, setUserInformation] = useState([]);
  useEffect(() => {
    userInfoInUserPage(userId, currentUser?.id).then((res) => {
      setUserInformation(res?.body);
    });
  }, [userId]);

  const adRef = useRef(null);
  useEffect(() => {
    if (adRef !== null) {
      if (loc?.state?.fromAd) {
        setTimeout(() => {
          window.scrollTo({
            left: 0,
            top: adRef.current?.offsetTop,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  }, [loc?.state?.fromAd]);

  // write message modal
  const initialSendMessagePayloads = {
    userId: null,
  };
  const [sendMessagePayloads, setSendMessagePayloads] = useState(initialSendMessagePayloads);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');

  const [isShowWriteMessageModal, setIsShowWriteMessageModal] = useState(false);

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setSendMessagePayloads(initialSendMessagePayloads);
    setIsShowWriteMessageModal(false);
  };
  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithoutTopicMessage(sendMessagePayloads.userId, {
        conversationId: 0,
        text: messageInput,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  // alert actions
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  //MAIN
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWithBackLayout>
      <UserHeader
        userInformation={userInformation}
        setAlert={setAlert}
        isShowWriteMessageModal={isShowWriteMessageModal}
        setIsShowWriteMessageModal={setIsShowWriteMessageModal}
      />
      <CarouselReviews setAlert={setAlert} userId={userId} userInformation={userInformation} page={page} />
      <CarouselServices userInformation={userInformation} />
      <CarouselAds adRef={adRef} userInformation={userInformation} routeName={routeName} />

      {/* MODALS */}
      <div className="mobile-btns d-block d-md-none py-2 py-sm-3">
        <div className="container">
          <div className="row row-cols-2 gx-2 gx-sm-3">
            <div>
              <ShowPhone className="fs-12" phone={userInformation?.phoneForUser} />
            </div>
            <div>
              <button
                type="button"
                className="fs-12 btn btn-1 w-100 px-2"
                onClick={() => {
                  if (!currentUser) {
                    setAlert('danger', true, 'Функция доступна авторизованным пользователям');
                  } else if (!!userInformation?.id) {
                    setSendMessagePayloads((prev) => ({
                      ...prev,
                      userId: userInformation?.id,
                    }));
                  }
                }}
              >
                Написать сообщение
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isShow={sendMessagePayloads.userId} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <div className="d-flex align-items-center">
            <div className="photo me-2 me-sm-4">
              <img src={checkPhotoPath(userInformation?.avatar)} alt="Колесникова Ирина" />
              {/*<div className="indicator online"/>*/}
            </div>
            <div>
              <h4>{userInformation?.fullName || 'Не известный'}</h4>
              {/*<div className="gray-2 fs-09">Сейчас онлайн</div>*/}
            </div>
          </div>
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>
      <CustomModal isShow={isShowWriteMessageModal} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <div className="d-flex align-items-center">
            <div className="photo me-2 me-sm-4">
              <img src={checkPhotoPath(userInformation?.avatar)} alt={userInformation?.fullName} />
            </div>
            <div>
              <h4>{userInformation?.fullName}</h4>
            </div>
          </div>
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>
    </PageWithBackLayout>
  );
}
