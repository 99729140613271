import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getAttributesTypes, getServicesUsers, getSubServicesTypes } from '../API/services';
import { emitCreateWithServiceTopicMessage } from '../API/socketConversations';
import CustomModal from '../components/CustomModal';
import CustomSelect from '../components/CustomSelect/CustomSelect';
import Loader from '../components/Loader';
import { onMultiCheckboxHandler } from '../helpers/collectDataFromForm';
import { servicesTypesLocal } from '../helpers/services';
import useDebounce from '../hooks/debounce';
import usePagination from '../hooks/pagination';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import alertActions from '../store/actions/alert';

import { ReactComponent as SearchSVG } from '../assets/icons/search.svg';

import styled from 'styled-components';

import ServiceCard from '../components/ServiceCard/ServiceCard';
import useIsMobile from '../hooks/useIsMobile';
import PageWithBackLayout from '../layouts/PageWithBack.Layout';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useCurrentUser } from '../store/reducers';

const miniFilter = [
  { value: 'desc', title: 'сначала новые' },
  { value: 'desc', title: 'по рейтингу(от высокого)' },
  { value: 'asc', title: 'по рейтингу(от низкого)' },
];

const Form = styled.form`
  display: flex;
  border: 1.13468px solid #bdbdbd;
  border-radius: 5.6734px;
  div {
    width: 100%;
  }
  input {
    border: none;
  }
`;

const ScrollFilterContent = styled.div`
  label {
    margin-left: 10px;
  }
  #categories div {
    display: flex;
    margin-bottom: 10px;
  }
  fieldset {
    margin-bottom: 10px;
  }
`;

const ServiceHeader = ({ findService, imgURL }) => {
  return (
    <h1 className="catalog__title" style={{ textAlign: 'center' }}>
      <img src={imgURL} /> {findService()}
    </h1>
  );
};

const ServiceFiltersPanel = ({
  selectMiniFilter,
  setSelectMiniFilter,
  miniFilter,
  isMobile,
  isFilterView,
  setIsFilterView,
  slug,
  payload,
  setPayload,
  filterSubs,
  find,
  setNextPage,
  users,
}) => {
  const { height: WinHeight } = useWindowDimensions();
  const navigate = useNavigate();
  return (
    <div
      className="d-flex flex-column justify-content-between align-items-start gap-2 mb-4"
      style={{ width: '100%', maxWidth: isMobile ? '' : 337 }}
    >
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: isMobile ? 'row' : 'column', rowGap: 10, columnGap: 10, width: '100%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            columnGap: 10,
            justifyContent: isMobile ? 'space-between' : 'initial',
          }}
        >
          <div
            className="d-flex gap-2"
            style={{
              padding: 10,
              flex: isMobile ? 1 : 0,
              width: isMobile ? 'auto' : '217px',
              height: '33px',
              background: '#FFFFFF',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
            }}
          >
            <CustomSelect
              className="gray-2"
              btnClass="fs-11"
              align="right"
              checkedOptions={[selectMiniFilter.title]}
              options={miniFilter}
              callback={({ value, title }) => {
                setSelectMiniFilter({ value, title });
              }}
              placeholder={'Сортировать'}
            />
          </div>
          <button
            type="button"
            className="d-flex align-items-center"
            onClick={() => setIsFilterView((prev) => !prev)}
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
              padding: 5,
            }}
          >
            <img src="/img/icons/filter.svg" alt="filter" />
            <span className="ms-2 fs-11 fw-5 color-1">Фильтр</span>
          </button>
          {/* <div
            style={{
              width: isMobile ? '100%' : 340,
              height: isMobile ? WinHeight - 100 - 50 - 64 - 25 * 2 - 41 : '60vh',
              position: 'absolute',
              top: isMobile ? 100 : 120,
              left: isMobile && 0,
              background: '#FFF',
              display: isFilterView ? 'block' : 'none',
              'z-index': '100',
              fontSize: 'large',
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 5px',
            }}
            onClick={(e) => e.stopPropagation()}
          > */}
          <CustomModal
            isShow={isFilterView}
            setIsShow={setIsFilterView}
            closeButton={true}
            className="modal__map-filters  filter-modal"
            centered={true}
            isMobile={isMobile}
          >
            <div
              style={{
                padding: 10,
                height: 60,
                background: '#FFFFFF',
                'box-shadow': '0px 1px 5px rgba(0, 0, 0, 0.25)',
              }}
            >
              <Form>
                <button type="submit" style={{ marginLeft: 10 }}>
                  <SearchSVG />
                </button>
                <input
                  type="text"
                  value={payload?.query || ''}
                  onChange={(e) => {
                    setPayload((prevState) => ({
                      ...prevState,
                      query: e.target.value,
                    }));
                  }}
                  placeholder="Поиск по ФИО"
                />
              </Form>
            </div>
            <ScrollFilterContent style={{ overflow: 'scroll', height: `calc(100% - ${60 + 100}px)` }}>
              <div style={{ padding: 10 }}>
                {/* <fieldset id="categories">
                  <legend>Услуга:</legend>
                  {servicesTypesLocal.map((item) => {
                      return (
                        <div>
                          <input
                            type="radio"
                            id={`service-${item.value}`}
                            name="service"
                            value={item.slug}
                            checked={slug == item.slug}
                            onChange={() => navigate(`/service/${item.slug}`)}
                          />
                          <label for={`service-${item.value}`}>{item.name}</label>
                        </div>
                      );
                  })}
                </fieldset> */}
                {filterSubs?.data?.length > 0 ? (
                  <fieldset>
                    <legend className="title-font text-left fs-12 fw-6 mb-3">Вид услуг:</legend>
                    {filterSubs?.data?.map((sub) => (
                      <label className="mb-3" key={sub.id}>
                        <input
                          type="checkbox"
                          value={sub.id}
                          onClick={() => setNextPage(2)}
                          checked={payload?.subServicesTypes?.find((i) => i == sub.id)}
                          onChange={(e) => {
                            onMultiCheckboxHandler('subServicesTypes', e.target.value, setPayload);
                          }}
                        />
                        <span className="fs-11 ms-3">{sub.name}</span>
                      </label>
                    ))}
                  </fieldset>
                ) : null}
              </div>
            </ScrollFilterContent>
            <div className="offcanvas-footer">
              <div className="d-flex  align-items-baseline align-items-lg-start justify-content-between mb-3">
                <button className="btn btn-1  color-1   fw-5">Найдено {users?.meta?.total} исполнителей</button>
                <button
                  type="button"
                  onClick={() => {
                    setPayload({
                      page: 1,
                      limit: 6,
                      serviceTypeId: find(),
                    });
                  }}
                  className="btn btn-1  color-1   fw-5"
                >
                  Очистить фильтр
                </button>
              </div>
            </div>
            {/* <div
              className={`${isMobile ? 'fs-09' : 'fs-11'} d-flex justify-content-between align-items-center`}
              style={{
                'flex-direction': 'column',
                background: '#FFF',
                padding: 10,
                height: 100,
                gap: 10,
              }}
            >
              <button
                type="reset"
                className="btn btn-rad2 w-100"
                style={{
                  color: payload?.subServicesTypes?.length ? '#FFF' : '#000',
                  background: payload?.subServicesTypes?.length ? '#146492' : 'rgb(204, 204, 204)',
                }}
                onClick={() =>
                  setPayload({
                    page: 1,
                    limit: 6,
                    serviceTypeId: find(),
                  })
                }
              >
                Сбросить фильтры
              </button>
              <div className="fs-11">Найдено {users?.meta?.total} исполнителей</div>
            </div> */}
          </CustomModal>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

const ServicesTiles = ({ services, getFetchData, nextPage, setSendMessagePayloads, isLoading }) => {
  const loc = useLocation();
  const isMobile = useIsMobile(768);

  if (isLoading)
    return (
      <div className="p-5 w-100 d-flex justify-content-center">
        <Loader color="#146492" />
      </div>
    );

  if (!services || !services.data || !services.data.length)
    return <h6 className="m-auto p-5 text-center">Исполнители не найдены</h6>;
  return (
    <InfiniteScroll
      dataLength={services?.data?.length}
      next={() => getFetchData(false)}
      hasMore={nextPage <= services?.meta?.last_page}
      loader={
        <div className="p-5 w-100 d-flex justify-content-center">
          <Loader color="#146492" />
        </div>
      }
    >
      <div className={'row row-cols-sm-1 row-cols-lg-2 g-2 g-md-3 g-lg-4'}>
        {services?.data?.map((service, idx) => {
          // console.log({ service });
          return (
            <div key={service.id}>
              <ServiceCard
                service={service}
                reportStatus={service.reportStatus}
                id={service.id}
                type={!isMobile && 'as-a-list'}
                wishlistStatus={service.wishlistStatus}
                isAdView={false}
                user={service.user}
                description={service.description}
                serviceTypeId={service.serviceTypeId}
                labels={service.labels}
                images={service.subServices[0].service.images}
                subServices={service.subServices}
                withSide={isMobile}
                isLargeFont={true}
                date={service.subServices[0].service.createdAtForUser}
                isLast={services?.data?.length - 1 === idx}
                showUser={true}
              />
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default function Services() {
  const [isLoading, setIsLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const { slug } = useParams();
  const services = usePagination(6);

  const currentUser = useCurrentUser();

  const [searchFIO, setSearchFIO] = useState({});
  const [firstBlockFilters, setFirstBlockFilters] = useState([]);
  const [secondBlockFilters, setSecondBlockFilters] = useState([]);
  const [selectMiniFilter, setSelectMiniFilter] = useState({});
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const [filterSubs, setFilterSubs] = useState({
    isLoading: false,
    data: [],
  });
  const city = useSelector((state) => state?.selectedCity);
  const [users, setUsers] = useState({
    isLoading: false,
    data: [],
    meta: {},
    last_page: 0,
  });
  const [nextPage, setNextPage] = useState(2);

  const initialSendMessagePayloads = {
    serviceId: null,
    userId: null,
  };
  const [sendMessagePayloads, setSendMessagePayloads] = useState(initialSendMessagePayloads);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');

  const find = () => {
    let serviceId;
    servicesTypesLocal.find((i) => {
      if (i.slug === slug) {
        return (serviceId = i.value);
      }
    });
    return serviceId;
  };

  const [payload, setPayload] = useState({
    page: 1,
    limit: services.pageLimit,
    serviceTypeId: find(),
  });

  const delayPayload = useDebounce(payload, 500);

  const getFetchData = async (isLoadi = true) => {
    isLoadi && setIsLoading(true);
    getServicesUsers(
      axiosPrivate,
      { ...delayPayload, page: nextPage },
      city === 'Вся Россия' ? '' : city,
      currentUser?.id
    )
      .then((res) => {
        setNextPage(nextPage + 1);
        setUsers({
          isLoading: true,
          data: [...users.data, ...res?.body?.data],
          meta: res?.body?.meta,
          last_page: res?.body?.meta?.last_page,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        return err.message;
      });
  };

  useEffect(() => {
    setPayload((prevState) => ({
      ...prevState,
      serviceTypeId: find(),
      page: nextPage - 1,
    }));
  }, [find(), nextPage]);

  useEffect(() => {

    if (city !== null && nextPage === 2) {
      setIsLoading(true);
      getServicesUsers(axiosPrivate, delayPayload, city === 'Вся Россия' ? '' : city, currentUser?.id)
        .then((res) => {
          setUsers({
            isLoading: true,
            data: res?.body?.data,
            meta: res?.body?.meta,
            last_page: res?.body?.meta?.last_page,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          return err.message;
        });
    }
  }, [delayPayload, city]);

  useEffect(() => {
    getSubServicesTypes(axiosPrivate, payload.serviceTypeId).then((res) =>
      setFilterSubs({ isLoading: true, data: res })
    );
    services.setCurrentPage(1);
    services.setStartingPage(1);
    setNextPage(2);
  }, [payload.serviceTypeId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const acceptFilters = (e) => {
    e.preventDefault();
    setPayload((prevState) => ({
      ...prevState,
      ...firstBlockFilters,
      ...secondBlockFilters,
      ...searchFIO,
    }));
    services.setStartingPage(1);
    services.setCurrentPage(1);
    setNextPage(2);
  };

  useEffect(() => {
    switch (selectMiniFilter.title) {
      case 'сначала новые':
        setPayload({
          ...payload,
          orderBy: selectMiniFilter.value,
          rating: null,
        });
        break;
      case 'по рейтингу(от высокого)':
        setPayload({
          ...payload,
          rating: selectMiniFilter.value,
          orderBy: null,
        });
        break;
      case 'по рейтингу(от низкого)':
        setPayload({
          ...payload,
          rating: selectMiniFilter.value,
          orderBy: null,
        });
        break;
    }
  }, [selectMiniFilter, payload?.orderBy]);

  const findService = () => {
    let nameMenu;
    servicesTypesLocal.find((i) => {
      if (i.slug === slug) {
        return (nameMenu = i.name);
      }
    });
    return nameMenu;
  };

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setSendMessagePayloads(initialSendMessagePayloads);
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithServiceTopicMessage(sendMessagePayloads.userId, {
        conversationId: 0,
        serviceId: sendMessagePayloads.serviceId,
        text: messageInput,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  const isMobile = useIsMobile(768);
  const [isFilterView, setIsFilterView] = useState(false);

  return (
    <PageWithBackLayout onClick={() => isFilterView && setIsFilterView(false)}>
      <main style={{ width: '100%' }} className="container">
        <ServiceHeader findService={findService} imgURL={servicesTypesLocal.find((i) => i.slug === slug)?.imageLocal} />
        <ServiceFiltersPanel
          selectMiniFilter={selectMiniFilter}
          setSelectMiniFilter={setSelectMiniFilter}
          miniFilter={miniFilter}
          isMobile={isMobile}
          isFilterView={isFilterView}
          setIsFilterView={setIsFilterView}
          slug={slug}
          payload={payload}
          setPayload={setPayload}
          filterSubs={filterSubs}
          find={find}
          setNextPage={setNextPage}
          users={users}
        />
        <div className="row">
          <ServicesTiles
            isLoading={isLoading}
            services={users}
            getFetchData={getFetchData}
            nextPage={nextPage}
            setSendMessagePayloads={setSendMessagePayloads}
          />
        </div>

        <CustomModal isShow={sendMessagePayloads.userId} hideModal={() => resetMessage()} closeButton>
          <form className="message-form">
            <textarea
              className="mt-3"
              rows="4"
              placeholder="Введите сообщение"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
            />
            {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
            <div className="d-flex align-items-center mt-3">
              <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
                ОТПРАВИТЬ
              </button>
            </div>
          </form>
        </CustomModal>
      </main>
    </PageWithBackLayout>
  );
}
