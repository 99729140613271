import React from 'react';
import { MainPanel } from './MainPanel/MainPanel';
import { MobPanel } from './MobPanel/MobPanel';
import * as S from './styles';

const Footer = () => {
  return (
    <S.Footer>
      <MainPanel />
      <MobPanel />
    </S.Footer>
  );
};

export default Footer;
