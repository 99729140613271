import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import * as S from '../styles';
import { Swiper, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Progress } from './Progress/Progress';
import { Description } from './Description/Description';
import { Control } from './Control/Control';

export type Media = { src: string; duration?: number };
type ContentProps = {
  content: Media[];
  title: string;
  description: string;
  isActive: boolean;
  isLast: boolean;
  toggle: () => void;
};

export const DELAY = 5000;
export const Content = ({ title, description, content, isActive, isLast, toggle }: ContentProps) => {
  const swiper = useSwiper();
  const [current, setCurrent] = useState(0);

  const setNext = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);
  const setPrev = useCallback(() => {
    swiper.slidePrev();
  }, [swiper]);

  return (
    <S.SlideContentDivider>
      {isActive && <Progress content={content} current={current} />}
      <Swiper
        grabCursor={false}
        centeredSlides={false}
        allowTouchMove={false}
        navigation={true}
        controller={{
          by: 'container',
        }}
        modules={[Navigation]}
        onActiveIndexChange={(swiper) => {
          setCurrent(swiper.activeIndex);
        }}
      >
        {content.map(({ src, duration }, i) => (
          <S.SlideWithContent key={i}>
            {!duration ? <img src={src} alt={title} /> : <VideoComponent src={src} isPlay={current === i} />}
          </S.SlideWithContent>
        ))}
        {!!description.length && <Description description={description} />}
        <Control
          isActive={isActive}
          current={current}
          content={content}
          isLast={isLast}
          toggle={toggle}
          setNextSlide={setNext}
          setPrevSlide={setPrev}
        />
      </Swiper>
    </S.SlideContentDivider>
  );
};

type VideoComponentProps = {
  src: string;
  isPlay: boolean;
};
const VideoComponent = ({ src, isPlay }: VideoComponentProps) => {
  const vidRef = useRef() as MutableRefObject<HTMLVideoElement>;

  useEffect(() => {
    if (isPlay && vidRef.current) {
      vidRef.current.play();
    } else if (vidRef.current) {
      vidRef.current.pause();
    }
  }, [isPlay]);

  return isPlay ? (
    <video ref={vidRef} preload={'auto'}>
      <source src={src} />
    </video>
  ) : null;
};
