import styled from 'styled-components';

export const Star = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    //width: auto;
    height: auto;
  }
`;

type RatingProps = {
  $isSingle: boolean;
  $fontSize?: number;
};
export const Rating = styled.div<RatingProps>`
  position: absolute;
  font-size: ${({ $isSingle, $fontSize }) => {
    const base = $fontSize || 1.35;
    return $isSingle ? base : Math.floor((base * 100) / 1.17) / 100;
  }}rem;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.75);
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  transform: translate3d(0, 15%, 0);
  color: ${({ theme }) => theme.colors.revert};
`;
