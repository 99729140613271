import styled, { css } from 'styled-components';
import linkImage from './assets/link.svg';
import fullwidth from './assets/fullwidth.svg';
export const SidePanelWrapper = styled.div`
  // position: absolute;
  // right: 1rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scale: ${({ isMobile }) => (isMobile ? '1.5' : '1.25')};
  margin-right: ${({ isOffcanvas }) => (isOffcanvas ? '0' : '0.3rem')};
`;
export const SidePanelWrapper2 = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.sm} {
    bottom: 7rem;
  }
`;

type SideEltProps = {
  $isRating?: boolean;
};
export const SideElt = styled.div<SideEltProps>`
  margin-bottom: 0.5rem;
  svg {
    width: ${({ $isRating }) => ($isRating ? 26 : 18)}px;
    height: auto;
  }
  ${({ $isRating }) =>
    !$isRating &&
    css`
      cursor: pointer;
      svg {
        circle,
        path {
          fill: ${({ theme }) => theme.colors.brandColor};
        }
      }
    `}
  @media screen and ${({ theme }) => theme.media.xs} {
    margin-bottom: 0.75rem;
    svg {
      width: ${({ $isRating }) => ($isRating ? 32 : 22)}px;
    }
  }
`;

export const Eye = styled.div`
  // margin-right: 0.25rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 24px;
    height: 17px;
  }
  svg {
    width: 24px;
    height: 17px;
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    // margin-right: 0.45rem;
  }
`;

export const RoundedBordersCard = styled.div`
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
`;

export const CustomGridCol1 = styled.div`
  flex: 0 0 auto;
  width: 61.66666667%;
`;

export const CustomButtons = styled.div`
  @media screen and ${({ theme }) => theme.media.xxl} {
    position: absolute;
    margin-left: -20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    position: absolute;
    margin-left: -80px;
    //left: 5%;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
`;

export const CopyLinkButton = styled.button`
  position: absolute;
  z-index: 110;
  display: flex;
  right: 10px;
  top: 10px;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${linkImage});
  background-size: cover;
  background-position: center;
`;

export const CustomButtons2 = styled.div`
  z-index: 110;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 10px;
  padding: 0 10px;
`;
export const CopyLinkButton2 = styled.button`
  z-index: 110;
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url(${linkImage});
  background-size: cover;
  background-position: center;

  @media screen and ${({ theme }) => theme.media.xl} {
    width: 40px;
    height: 40px;
  }
`;
export const ItemContainerTotal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: auto;
  justify-content: center;
`;
export const FullScreenImgButt = styled.button`
  //position: absolute;
  z-index: 120;
  display: flex;
  //left: 87%;
  //top: 1%;
  //margin-top: 20px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  //background-color: #FFFFFF;
  background-image: url(${fullwidth});
  background-size: cover;
  // background-size: 24px 24px;
  // background-repeat: no-repeat;
  background-position: center;
  @media screen and ${({ theme }) => theme.media.sm} {
    //left: 87%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    //left: 87%;
    width: 33px;
    height: 33px;
  }
  @media screen and ${({ theme }) => theme.media.xxxl} {
    //left: 85%;
    width: 33px;
    height: 33px;
  }
`;
export const StyledCardMini = styled.div`
  max-height: 580px;
  //height: 560px;
  height: fit-content;
  width: 100%;
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  border-radius: 0.5rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    // max-height: 450px;
    //height: 650px;
    // margin-left: 24px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    // max-height: 520px;
    //height: 650px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 100%;
    // max-height: 520px;
    //height: 690px;
    position: relative;
    background-color: #fff;
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
    border-radius: 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    // max-height: 490px;
  }
`;
export const StyledCardMiniFav = styled.div`
  // max-height: 620px;
  //height: 620px;
  height:fit-content;
  width: 100%;
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  border-radius: 0.5rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    // max-height: 650px;

    //height: 650px;
    gap: 10px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    // max-height: 650px;
    //height: 650px;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    // max-height: 670px;
    //height: 670px;
    width: 100%;
    //margin-top: 24px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 100%;
    // max-height: 680px;
    //height: 680px;
    position: relative;
    background-color: #fff;
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
    border-radius: 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    // max-height: 490px;
  }
`;
export const AuthorSkeletonBetweem = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AuthorSkeletonBetweemFav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  @media screen and ${({ theme }) => theme.media.lg} {
    padding: 0 0 10px 0;
  }
`;
export const AuthorSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and ${({ theme }) => theme.media.xl} {
    display: flex;
    flex-direction: row;
  }
`;
export const ImgHolder = styled.div`
  width: fit-content;
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
  border-radius: 50%;
  object-fit: cover;
  img {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    img {
      width: 105px;
      height: 105px;
      border-radius: 50%;
    }
  }
`;
export const ImgHolder2 = styled.div`
  width: fit-content;
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
  border-radius: 50%;
  object-fit: cover;
  img {
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    img {
      width: 105px;
      height: 105px;
      border-radius: 50%;
    }
  }
`;
export const AuthorSkeletonCunstruct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`;
export const NameText = styled.div`
  margin-top: 0.5rem !important;
  font-family: Montserrat;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
`;
export const NameTextFav = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  color: #000000;
  font-weight: 700;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 16px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 22px;
  }
`;

export const FavCardTitle = styled.div`
  font-size: 1.5em;
  font-weight: 800;
  color: #146492;
  font-family: Montserrat;
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1.3em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 1.5em;
  }
`;
export const DataRegistrationText = styled.div`
  margin-top: 0.5rem !important;
  font-family: Montserrat;
  color: #7da3b9;
  font-size: 22px;
  font-weight: 400;
`;
export const DataRegistrationTextFav = styled.div`
  font-size: 14px;
  margin-top: 0.5rem !important;
  font-family: Montserrat;
  color: #7da3b9;
  font-weight: 400;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 10px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 16px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 22px;
  }
`;
export const CardTilteColorizer = styled.div`
  color: #198acb;
`;

export const CustomRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  img {
    width: 56px;
    height: 54px;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      width: 44px;
      height: 43px;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      width: 56px;
      height: 54px;
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    img {
      width: 86px;
      height: 84px;
    }
  }
`;
export const CustomRatingSpan = styled.span`
  position: absolute;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 24px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 36px;
  }
`;

export const FontTitleScaler = styled.div<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) => (isLarge ? '1.6em' : '1em')};
  font-weight: 800;
  color: #146492;
  font-family: Montserrat;
  color: #198acb;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '1.1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1.2em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: ${({ isLarge }) => (isLarge ? '1.7em' : '1em')};
  }
`;
export const FontSubTitleScaler = styled.div<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) => (isLarge ? '1.5em' : '0.9em')};
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1.1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: ${({ isLarge }) => (isLarge ? '1.7em' : '0.9em')};
  }
`;
export const FontPriceScaler = styled.div<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) => (isLarge ? '1.4em' : '0.8em')};
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1.1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: ${({ isLarge }) => (isLarge ? '1.7em' : '0.8em')};
  }
`;
export const FontPositionScaler = styled.div<{ isLarge?: boolean }>`
  font-size: ${({ isLarge }) => (isLarge ? '1.4em' : '0.8em')};
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1.2em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: ${({ isLarge }) => (isLarge ? '1.6em' : '0.8em')};
  }
`;
export const OrangeTextD = styled.div<{ isLarge?: boolean }>`
  color: #c59c7e;
  font-size: ${({ isLarge }) => (isLarge ? '1.4em' : '0.8em')};
  font-weight: 500;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '0.8em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    color: #c59c7e;
    font-size: ${({ isLarge }) => (isLarge ? '1.7em' : '0.8em')};
    font-weight: 500;
  }
`;
export const OrangeTextS = styled.span<{ isLarge?: boolean }>`
  color: #c59c7e;
  font-size: ${({ isLarge }) => (isLarge ? '1.4em' : '0.8em')};
  font-weight: 500;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: ${({ isLarge }) => (isLarge ? '0.8em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ isLarge }) => (isLarge ? '1em' : '0.9em')};
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    color: #c59c7e;
    font-size: ${({ isLarge }) => (isLarge ? '1.7em' : '0.8em')};
    font-weight: 500;
  }
`;
export const VisibleMargin = styled.div`
  margin-top: auto;
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: auto;
  }
`;
export const VisibleMargin2 = styled.div`
  margin-top: auto;
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: 0px;
  }
`;

export const OrangeGroup = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  @media screen and ${({ theme }) => theme.media.xxl} {
    // margin-top: 50px;
  }
`;
