import React, { useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import accessTokenActions from '../../../store/actions/accessToken';
import currentUserActions from '../../../store/actions/currentUser';
import { bindActionCreators } from 'redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import apiRoutes from '../../../API/config/apiRoutes';
import CustomModal from '../../../components/CustomModal';
import { useAccessToken } from '../../../store/reducers';
import favoriteCount from '../../../store/actions/favoriteCount';
import * as S from './styles';
import { HeartLink } from '../../../components/Header/HeaderComponent/styles';
// import { ReactComponent as Favorite } from '../../../assets/icons/nav/favorite.svg';
import { items } from '../../../layouts/PersonalAccountLayout';
import { getQuestion } from '../../../API/question';
import alertActions from '../../../store/actions/alert';

export default function AccountMenu({ onAskQuestion }) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const { resetToken } = bindActionCreators(accessTokenActions, dispatch);
  const { resetCurrentUser } = bindActionCreators(currentUserActions, dispatch);
  const { setFavoriteCount } = bindActionCreators(favoriteCount, dispatch);
  const [isShowModalExit, setIsShowModalExit] = useState(false);
  const [isShowQuestionModal, setIsShowQuestionModal] = useState(false);
  const conversationCount = useSelector((state) => state?.conversationCount);
  const favs = useSelector((state) => state?.favoriteCount);
  const currentUser = useSelector((state) => state?.currentUser);
  const token = useAccessToken();
  const favsCount = Object.values(favs)
    .map((val) => Number(val))
    .reduce((val, acc) => val + acc);

  const handleLogout = async () => {
    resetToken();
    resetCurrentUser();
    setFavoriteCount({
      adsCount: 0,
      servicesCount: 0,
    });
    navigate('/');
    setIsShowModalExit(false);
    await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.LOGOUT}`, { token: token });
  };
  const [data, setData] = useState({
    name: '',
    email: '',
    question: '',
  });
  const [valid, setValid] = useState({
    isInValidName: false,
    isInValidEmail: false,
    isInValidQuestions: false,
  });
  const mailSample = Object.values(data).find((i) => i?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isInValidName = data?.name === undefined || data.name?.length < 1 || data.name?.length > 55;
    const isInValidEmail = data?.email === undefined || mailSample === undefined;
    const { question } = data;
    const isInValidQuestions = question?.length === undefined || question?.length < 5 || question?.length > 1024;

    if (isInValidName) {
      setValid({ ...valid, isInValidName: true });
    } else if (isInValidEmail) {
      setValid({ ...valid, isInValidEmail: true });
    } else if (isInValidQuestions) {
      setValid({ ...valid, isInValidQuestions: true });
    } else {
      const formData = new FormData();
      const req = { ...data };
      for (let key in req) {
        formData.append(key, req[key as keyof typeof req]);
      }
      getQuestion(formData)
        .then(() => {
          setData({
            name: '',
            email: '',
            question: '',
          });
          setIsShowQuestionModal(false);
          setAlert('success', true, 'Вопрос успешно отправлен нашим консультантам, ждите ответа');
        })
        .catch(() => {
          setAlert('danger', true, 'Произошла ошибка сервера');
        });
    }
  };

  const resetFieldVal = (field) => {
    setValid((prevValid) => ({ ...prevValid, [field]: false }));
  };
  return (
    <div>
      <nav className="d-block d-lg-none mt-3 mb-4 mb-sm-5" aria-label="breadcrumb">
        <Link to="/" className="gray-3">
          &#10094; На главную
        </Link>
      </nav>
      <S.CustomH1 className="d-block d-lg-none text-center mb-4">Личный кабинет</S.CustomH1>
      <nav className="menu">
        <ul>
          {items
            .filter(({ navigate }) => navigate !== 'skipped')
            .map(({ navigate, icon, content }) =>
              navigate === 'favorites' ? (
                <li key={navigate}>
                  <HeartLink to={navigate} $red={favsCount > 0} style={{ position: 'relative' }}>
                    {icon}
                    {!!currentUser && !!favsCount && favsCount > 0 && <S.CounterMenuFav>{favsCount}</S.CounterMenuFav>}
                  </HeartLink>
                  <NavLink to={navigate}>{content}</NavLink>
                </li>
              ) : navigate === 'my-messages' ? (
                <li key={navigate}>
                  {icon}
                  <NavLink to={navigate}>{content}</NavLink>
                  {conversationCount > 0 && <S.CounterMenuEm>{conversationCount}</S.CounterMenuEm>}
                </li>
              ) : (
                <li key={navigate}>
                  {icon}
                  <NavLink to={navigate}>{content}</NavLink>
                </li>
              )
            )}
          <li>
            <S.MyQuestion />
            <button type="button" onClick={() => setIsShowQuestionModal(true)}>
              Задать вопрос
            </button>
          </li>
          <li>
            <S.MyLeave />
            <button onClick={() => setIsShowModalExit(true)} type="button">
              Выйти
            </button>
          </li>

        </ul>
      </nav>
      <CustomModal
        isShow={isShowModalExit}
        setIsShow={setIsShowModalExit}
        closeButton={true}
        className="modal__exit-with-account"
      >
        <div className="text-center fs-15 fw-6 title-font my-5">Вы действительно хотите выйти?</div>
        <div className="row row-cols-2">
          <div>
            <button
              type="button"
              className="btn btn-2 w-100 fs-11 text-uppercase"
              onClick={() => setIsShowModalExit(false)}
            >
              Отмена
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-1 w-100 fs-11 text-uppercase" onClick={handleLogout}>
              Выйти
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal isShow={isShowQuestionModal} setIsShow={setIsShowQuestionModal} closeButton={true} size="lg">
        <div>
          <h3 className="text-center">Задать вопрос</h3>
          <form className="message-form">
            <div className="text-center">
              <div>
                <div className="fs-11 fw-5">Вам ответит администратор</div>
              </div>
            </div>
            <div className="row align-items-center fs-11 mt-3">
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="name"
                  style={{
                    color: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                >
                  Ваше имя:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidName ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Имя"
                  value={data.name}
                  id="name"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    resetFieldVal(e, 'isInValidName');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="email"
                  style={{
                    color: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                >
                  Ваш Email:
                </label>
              </div>
              <div className="col-sm-9 mb-3">
                <input
                  style={{
                    borderColor: valid.isInValidEmail ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Email"
                  value={data.email}
                  id="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    resetFieldVal(e, 'isInValidEmail');
                  }}
                />
              </div>
              <div className="col-sm-3 mb-1 mb-sm-3">
                <label
                  className="gray-3"
                  htmlFor="question"
                  style={{
                    color: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                >
                  Ваш вопрос:
                </label>
                <span className="fs-08 gray-3 mt-2">От 5 символов</span>
              </div>
              <div className="col-sm-9 mb-sm-3">
                <textarea
                  style={{
                    borderColor: valid.isInValidQuestions ? '#DA1E2A' : '',
                  }}
                  type="text"
                  placeholder="Вопрос"
                  value={data.question}
                  id="question"
                  onChange={(e) => {
                    setData({ ...data, question: e.target.value });
                    resetFieldVal(e, 'isInValidQuestions');
                  }}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-1 mx-auto fs-12 mt-4" onClick={handleSubmit}>
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
