import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 398px;
  height: 270px;
  display: flex;
  flex-direction: column;
`;
export const StyledWrapperImg = styled.div`
  width: 396px;
  height: 89px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 785px) {
    img {
      width: 100px;
    }
  }
`;

export const ImgPreviewWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 785px) {
    gap: 5px;
  }
`;

export const TextUpper = styled.div`
  position: absolute;
  margin-top: -40px;
  margin-left: 15px;
  color: #fff;
  @media screen and (max-width: 785px) {
    margin-top: -35px;
    margin-left: 17px;
  }
`;

export const BlackSq = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 60px;
  width: 110px;
  margin-top: -61px;
  border-radius: 10px;
  @media screen and (max-width: 785px) {
    width: 100px;
    height: 55px;
    margin-top: -55px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.xl} {
    display: flex;
    flex-direction: row;
  }
`;

export const DisplayButton = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.xl} {
    justify-content: flex-start;
  }
`;
export const UnderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and ${({ theme }) => theme.media.md} {
    padding-left: 20px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 60%;
  }
`;

export const CardNamer = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 1.8em;
  color: #198acb;
  font-family: Montserrat;
  font-weight: bold;
`;

export const CardServ = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const CardServ2 = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: -20px;
  padding-bottom: 10px;
`;
interface DescriptUnderProps {
  isExpanded: boolean;
  contentHeight?: number;
}
export const DescriptUnder = styled.span<DescriptUnderProps>`
  display: -webkit-box;
  -webkit-line-clamp:  ${({ isExpanded, contentHeight }) => (isExpanded ? `0` : '2')};
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-family: Montserrat;
  font-weight: 600;
  color: #6f6f6f;
  // max-width: 574px;
  transition: all 0.3s ease;
  cursor: pointer;
  // height: ${({ isExpanded, contentHeight }) => (isExpanded ? `${contentHeight}px` : '39px')};
  // @media screen and ${({ theme }) => theme.media.md} {
  //   height: ${({ isExpanded, contentHeight }) => (isExpanded ? `${contentHeight}px` : '60px')};
  // }
  // @media screen and ${({ theme }) => theme.media.xxl} {

  //   max-width: 574px;
  //   font-size: 16px;
  //   display: inline-block;
  //   font-family: Montserrat;
  //   font-weight: 600;
  //   color: #6f6f6f;
  //   max-width: 574px;
  // }
`;

/* Стили для раскрытого состояния */
export const ExpandedDescriptUnder = styled(DescriptUnder)`
  max-height: 600px;
`;

export const OrangeGroup = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: 50px;
`;
export const Eye = styled.div`
  margin-right: 0.25rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 24px;
    height: 17px;
  }
  svg {
    width: 24px;
    height: 17px;
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    margin-right: 0.45rem;
  }
`;

export const EditButton = styled.div`
    width: 140px;
    height: 25px;
    border-radius: 50px;
    background: #198ACB;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    @media screen and ${({ theme }) => theme.media.md} {
        width: 200px;
        height: 31px;
        font-size: 20px;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        width: 220px;
        height: 41px;
        border-radius: 50px;
        background: #198ACB;
        align-items: center;
        display: flex;
        justify-content: center;
        align-content: center;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 18px;
    }
    @media screen and ${({ theme }) => theme.media.xxl} {
      width: 260px;
      height: 41px;
      border-radius: 50px;
      background: #198ACB;
      align-items: center;
      display: flex;
      justify-content: center;
      align-content: center;
      color: #FFFFFF;
      font-family: Montserrat;
      font-size: 20px;
  }
}
`;

export const RemoveButton = styled.button`
  width: 140px;
  height: 25px;
  border-radius: 50px;
  border: 1px solid #198acb;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #198acb;
  font-family: Montserrat;
  font-size: 12px;
  align-items: center;
  background: transparent;
  transition: 0.3s;
  &:hover {
    border: 1px solid #fe6060;
    background: #fe6060;
    color: #fff;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 200px;
    height: 31px;
    font-size: 20px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 220px;
    height: 41px;
    border-radius: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #198acb;
    font-family: Montserrat;
    font-size: 18px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 260px;
    height: 41px;
    border-radius: 50px;
    border: 1px solid #198acb;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #198acb;
    font-family: Montserrat;
    font-size: 20px;
    align-items: center;
    background: transparent;
  }
`;

export const ServShield = styled.div`
  background: #d0e0e9;
  color: #165d85;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px 3px 10px;
  border-radius: 20px;
  text-align: center;
  text-wrap: nowrap
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and ${({ theme }) => theme.media.xl} {
    background: #d0e0e9;
    color: #165d85;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    padding: 3px 10px 3px 10px;
  }
`;

export const TextUnderShield = styled.span`
  color: #6f6f6f;
  font-family: Montserrat;
  font-style: normal;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  margin-right: 0.35rem;
  margin-bottom: 0.5rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    color: #6f6f6f;
    font-family: Montserrat;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%;
    margin-right: 0.35rem;
    margin-bottom: 0.5rem;
  }
`;

export const ShieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // align-self: flex-start;
  //justify-items: start;
  justify-items: start;
  align-items: start;
  justify-content: start;
  align-content: normal;
  @media screen and ${({ theme }) => theme.media.xl} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  max-width: 100vw;
  overflow-x: scroll;
  padding: 0.5rem 0.75rem 0px;
  gap: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const GridCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  //margin-left: 60px;
`;

export const NormalizedCol = styled.div`
  flex: 1 0;
  @media screen and ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 33%;
  }
`;

export const WidthScaler = styled.div`
  width: 100%;
  cursor: pointer;
  @media screen and ${({ theme }) => theme.media.lg} {
    width: 100%;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 90%;
  }
`;
export const ReadMoreButton = styled.span`
  // display: flex;
  // border-radius: 15px;
  // background: #198ACB;
  // color: #fff;
  // align-self: center;
  // justify-content: center;
  // align-items: center;
  // align-content: center;
  font-family: Montserrat;
    font-weight: 600;
  // font-size: 1.2em;
  // padding: 3px 8px 3px 8px;
  // border: 1px solid #198ACB;
  cursor: pointer;
  transition:
    color 300ms,
    background 300ms;
  color: #198acb;
  @media screen and ${({ theme }) => theme.media.md} {
    // font-size: 1.2em;
    // padding: 5px 10px 5px 10px;
    // border: 1px solid #198ACB;
  }
  &:hover {
    // background: #fff;
    color: #198acb;
  }
`;
