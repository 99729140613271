import apiRoutes from "./config/apiRoutes";

export async function getAdsWishlist(userId, page = 1, limit = 4, axiosPrivate, token) {
    try {
        const response = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.USER_ADS_WISHLIST}/${userId}`, {page, limit, token, orderBy:'desc'})
        return response.data.body;
    } catch(err) {
        console.error(err)
    }
}
export async function getServicesWishlist(userId, page = 1, limit = 4, axiosPrivate, token) {
    try {
        const response = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.USER_SERVICES_WISHLIST}/${userId}`, {page, limit, token, orderBy:'desc'})
        return response.data.body;
    } catch(err) {
        console.error(err)
    }
}
