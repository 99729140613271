import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPopular, getRecommend } from '../../API/mainpagereq';
import { SliderAds } from '../SliderAds';
import { RootState } from '../../store/store';
import { Stories } from './Stories/Stories';
import { Categories } from './Categories/Categories';
import { MiniProfile } from './MiniProfile/MiniProfile';
import { MapSection } from './Map/Map';
import CityPopup from '../CityPopup';
import useSelectedCity from '../../hooks/useSelectedCity';
import { Articles } from './Articles/Articles';

export const Main = () => {
  const { currentUser, city } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    city: state.selectedCity,
  }));
  const userId = currentUser?.id;
  const { page } = useParams();
  const [recommend, setRecommend] = useState([]);
  const [popular, setPopular] = useState([]);
  //const [hotAds, setHotAds] = useState([]);

  const { isDefinedCity } = useSelectedCity();

  useEffect(() => {
    if (userId && city) {
      getRecommend(userId, 6, city).then((data) => setRecommend(data));
    }
  }, [userId, city]);

  useEffect(() => {
    if (userId && city) {
      getPopular(Number(page), 6, userId, city).then((data) => setPopular(data));
    }
  }, [page, userId, city]);

  // useEffect(() => {
  //   if (userId && city && typesEstate) {
  //     getCatalog(1, 6, "", city, {
  //       isHot: true,
  //       estateId: typesEstate[0]?.estates[0]?.id,
  //     }).then((data) => setHotAds(data?.body?.data));
  //   }
  // }, [city, typesEstate]);

  // const findPhoto = (name) => {
  //     let photo;
  //     servicesTypesLocal.find((i) => {
  //         if (i.name === name) {
  //             return (photo = i.imageLocal);
  //         }
  //     });
  //     return photo;
  // };

  return (
    <main>
      <CityPopup city={city} isDefinedCity={isDefinedCity} setIsShowCities={() => {}} />

      <Stories />
      <MiniProfile />
      <Categories />
      <MapSection />
      {/*{!(hotAds === undefined || hotAds.length === 0) && (*/}
      {/*  <section className="sec-4 container mb-6">*/}
      {/*    <h3>Срочная продажа</h3>*/}
      {/*    <div className="position-relative">*/}
      {/*      <Slider1 hotAds={hotAds} />*/}
      {/*    </div>*/}
      {/*  </section>*/}
      {/*)}*/}

      {!(popular === undefined || popular?.length === 0) && (
        <section className="sec-4 container mb-6">
          <h3>Часто просматриваемые</h3>
          <div className="position-relative">
            <SliderAds popular={popular} />
          </div>
        </section>
      )}

      {!(recommend === undefined || recommend?.length === 0) && (
        <section className="sec-4 container mb-6">
          <h3>Рекомендованные Вам</h3>
          <div className="position-relative">
            <SliderAds recommend={recommend} />
          </div>
        </section>
      )}

      <Articles />
    </main>
  );
};
