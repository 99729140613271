import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
    background:#FFF;
`;

export const TextDivider = styled.div`
  // background: transparent;
  // height: 50%;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // padding: 0 0.25rem;
  // @media screen and ${({ theme }) => theme.media.xs} {
  //   padding: 0 0.5rem;
  // }
  // @media screen and ${({ theme }) => theme.media.md} {
  //   padding: 0 0.75rem;
  // }
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: ${({isAd}) => isAd ? 'linear-gradient(to bottom, rgba(255 255 255 /100%) 0%, rgba(255,255,255, .5) 6%, rgba(255,255,255, 0) 10%, rgba(255,255,255, 0) 50%, rgba(255,255,255,0) 50%,rgba(0 0 0 /77%) 100%);' : 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%,rgba(0 0 0 /57%) 100%);' }
  //.swiper-slide-active & {
  //  opacity: 1;
  //}
`;

export const Text = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // flex-direction: column;
  // position: relative;
  // z-index: 2;
  // color: ${({ theme }) => theme.colors.revert};
  // overflow: hidden;
  // text-overflow: ellipsis;
`;
export const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-align: left;

  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  @media screen and ${({ theme }) => theme.media.xs} {
    margin-bottom: 0.25rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1.1rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;
export const Description = styled.div`
  font-size: 0.75rem;
  text-align:left;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and ${({ theme }) => theme.media.xs} {
    -webkit-line-clamp: 3;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    -webkit-line-clamp: 3;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    -webkit-line-clamp: 3;
  }
`;
