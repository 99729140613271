import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { emitCreateWithRealEstateTopicMessage } from '../../API/socketConversations';
import { ReactComponent as Cross } from '../../assets/icons/card/cross.svg';
import { ReactComponent as Dots } from '../../assets/icons/card/dots.svg';
import { ReactComponent as Phone } from '../../assets/icons/card/phone.svg';
import { formatAddress } from '../../helpers/formatingDate';
import { localEstates } from '../../helpers/localEstates';
import { checkPhotoPath } from '../../helpers/photo';
import { useClickOutside } from '../../hooks/useClickOutside';
import { getEstateTypeByEstateId } from '../../pages/Ad.List.Page';
import alertActions from '../../store/actions/alert';
import { useCurrentUser } from '../../store/reducers';
import BtnFav from '../BtnFav';
import CustomModal from '../CustomModal';
import HoverSlider from '../HoverSlider/HoverSlider';
import ImgPreview from '../ImgPreview';
import { Popover } from '../Popover/Popover';
import { RatingStar } from '../RatingStar/RatingStar';
import { ContactsPanel } from './ContactsPanel/ContactsPanel';
import * as S from './styles';

import useIsMobile from '../../hooks/useIsMobile';
import ReportModal from '../ReportModal';
import UserHeader from '../User/UserHeader';

import { reportAd } from '../../API/adspage';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useAccessToken } from '../../store/reducers';

const sait = `${process.env.REACT_APP_PHOTO_URL}/uploads/`;

const CardTitle = ({ ads, ...props }: any) => {
  return [3, 4, 5].includes(ads?.estateId) ? (
    <span>
      {props?.estateName}
      {ads?.totalArea ? (
        <span>
          {` ${ads?.totalArea} м`}
          <sup>2</sup>
        </span>
      ) : null}

      {ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.dom
        ? `${ads.areaType ? ', ' + ['СНТ', 'ИЖС', 'ЛПХ'][ads.areaType] : ''}`
        : null}

      {ads.landArea ? (
        <span>{` ${ads?.landArea} сот.`}</span>
      ) : ads?.acres ? (
        <span>{` ${ads?.acres} сот.`}</span>
      ) : null}
    </span>
  ) : (
    <span>
      {props?.estateName}
      {ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.kvartiri
        ? `${ads.estateType ? ', ' + ['Новостройка', 'Вторичка'][ads.estateType] : ''}`
        : ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.dom
        ? `${ads.areaType ? ', ' + ['СНТ', 'ИЖС', 'ЛПХ'][ads.areaType] : ''}`
        : ads?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.commer
        ? `${
            props?.buildingTypeForUser && props?.buildingTypeForUser?.toLowerCase() !== 'не установлено'
              ? ', ' + props?.buildingTypeForUser
              : ''
          }`
        : null}

      {ads?.landArea ? (
        <span>{`, ${ads?.landArea} сот.`}</span>
      ) : ads?.acres ? (
        <span>{`, ${ads?.acres} сот.`}</span>
      ) : ads?.totalArea ? (
        <span>
          {`, ${ads?.totalArea} м`}
          <sup>2</sup>
        </span>
      ) : null}
    </span>
  );
};

const Card = ({ ads, isOffcanvas, isAdView, type, showUser, link, isLargeFont, isLast, ...props }) => {
  const pictures: any[] = useMemo(
    () =>
      []
        .concat(ads?.image, ads?.images?.map((i) => i?.image))
        .map((i) => (i ? { type: 'img', src: `${sait}${i}` } : { type: 'img', src: '/img/nophoto.jpg' }))
        .concat(ads?.videos?.map((v) => ({ type: 'video', src: process.env.REACT_APP_PHOTO_URL + v?.video })))
        .filter((i) => i),
    [ads]
  );

  const onlyIMGS = useMemo(
    () => pictures.map((item) => (item.type === 'img' ? item.src : null)).filter((i) => i),
    [pictures]
  );
  const onlyVIDEOS = useMemo(
    () => pictures.map((item) => (item.type === 'video' ? item.src : null)).filter((i) => i),
    [pictures]
  );

  const urls = useMemo(
    () => [onlyIMGS[0], onlyVIDEOS[0], ...onlyIMGS.slice(1)].filter((i) => i),
    [onlyIMGS, onlyVIDEOS]
  );

  // const type = props.type;
  // const showUser = props.showUser;
  const user = useCurrentUser();
  const [isOpenContacts, setOpenContacts] = useState(false);
  const [isShowWriteMessageModal, setIsShowWriteMessageModal] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [messageInputError, setMessageInputError] = useState('');
  const { pathname } = useLocation();
  const { search } = useLocation();

  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const ref = useRef();
  useClickOutside(() => setOpenContacts(false), ref);

  const resetMessage = () => {
    setMessageInput('');
    setMessageInputError('');
    setIsShowWriteMessageModal(false);
  };

  const onCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard
      .writeText(`https://dakvartira.ru/card-page/${props?.uuid}`)
      .then(() => setAlert('success', true, 'Ссылка скопирована в буфер обмена'));
  };
  const onSendMessage = (e) => {
    e.preventDefault();
    const message = messageInput.trim();

    if (message.length) {
      emitCreateWithRealEstateTopicMessage(props?.user?.id, {
        conversationId: 0,
        realEstateId: props?.id,
        text: messageInput,
      })
        .then(() => {
          setAlert('success', true, 'Сообщение отправлено');
          resetMessage();
        })
        .catch(() => setAlert('danger', true, 'Что-то пошло не так, не удалось отправить сообщение'));
    } else {
      setMessageInputError('Сообщение не должно быть пустым');
    }
  };

  const onClickMail = useCallback(() => {
    if (user) {
      setIsShowWriteMessageModal(true);
    } else {
      setAlert('danger', true, 'Функция доступна авторизованным пользователям');
    }
  }, [user, isShowWriteMessageModal]);

  const formattedpropsaddres = formatAddress(props.address);

  const isMobile = useIsMobile(768);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState<number>();
  const openModalWithImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (pictures && pictures.length > 0) {
      setCurrentImgIndex(0);
      setIsModalOpen(true);
    }
  };

  const [toogleZIDX, setToogleZIDX] = useState(false);

  //REPORT
  //UTILS
  const axiosPrivate = useAxiosPrivate();

  //ALERTS
  const onlyAuth = () => setAlert('danger', true, 'Функция доступна авторизованным пользователям');
  const alreadySent = () => setAlert('danger', true, 'Жалоба уже отправлена');

  //DATE
  const currentUser = useCurrentUser();
  const currentToken = useAccessToken();

  //REPORT STATUS
  // const [isExistUserReport, setIsExistUserReport] = useState(false);
  const [isExistRealEstateReport, setIsExistRealEstateReport] = useState(false);
  useEffect(() => {
    if (props?.reportStatus) {
      setIsExistRealEstateReport(props?.reportStatus);
    }
  }, [props?.reportStatus]);

  const [isReportOpen, setIsReportOpen] = useState(false);
  //REAL ESTATE ACTIONS
  const reportAdAction = async (payload: any) => {
    if (!currentUser) {
      onlyAuth();
    } else {
      setIsReportOpen(false);
      await reportAd(axiosPrivate, payload)
        .then(() => {
          setIsExistRealEstateReport((reportAdStatus) => !reportAdStatus);
          setAlert('success', true, 'Жалоба успешно добавлена');
        })
        .catch((e) => {
          setAlert('danger', true, 'Произошла ошибка');
        });
    }
  };

  const reportTitle = isExistRealEstateReport ? 'Жалоба отправлена' : 'Пожаловаться';
  const reportOnClick = () => {
    !currentUser ? onlyAuth() : isExistRealEstateReport ? alreadySent() : setIsReportOpen(true);
  };

  return (
    <>
      {type === 'as-a-list' ? (
        <S.RoundedBordersCard
          className={`card-midi${props.className || ''}`}
          style={{
            background: props?.isTransparent ? 'transparent' : '',
            boxShadow: props?.isTransparent ? 'none' : 'initial',
          }}
        >
          {showUser && (
            <NavLink to={`/user/${props?.user?.id}`}>
              <UserHeader user={props.user} />
            </NavLink>
          )}
          <div className={`${isAdView ? '' : 'h-100'} row `}>
            <div className={`${isAdView ? 'col-4' : 'col-6'}`}>
              <NavLink
                to={`/card-page/${props?.uuid}`}
                state={{
                  prevRoute: pathname,
                  routeName: props?.routeName,
                  prevSearch: search,
                }}
                style={{ position: 'relative' }}
              >
                <HoverSlider urls={urls} />
                <ImgPreview urls={urls} />
                <S.CustomButtons2>
                  <S.ItemContainerTotal>
                    <S.FullScreenImgButt onClick={openModalWithImage} />
                    <S.ItemContainerRight>
                      <S.CopyLinkButton2 onClick={onCopy} style={{ marginRight: 10 }} />
                      <BtnFav
                        id={props?.id}
                        wishlistStatus={props?.wishlistStatus}
                        onFav={props.onFav}
                        type={'realEstate'}
                        position={'static'}
                        callbackDeleteWish={props.callbackDeleteWish}
                      />
                    </S.ItemContainerRight>
                  </S.ItemContainerTotal>
                </S.CustomButtons2>
              </NavLink>
            </div>

            <div
              className={`${isAdView ? 'col-8' : 'col-6'} d-flex flex-column justify-content-between align-items-start`}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <S.FontTitleScaler isLarge={isLargeFont} className="title-font fw-7 mb-3 mb-xxl-4">
                    <NavLink
                      to={`/card-page/${props?.uuid}`}
                      state={{
                        prevRoute: pathname,
                        routeName: props?.routeName,
                        prevSearch: search,
                      }}
                    >
                      {props?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.commer ? (
                        <>{props?.buildingTypeForUser}</>
                      ) : (
                        <CardTitle ads={ads} {...props} />
                      )}
                    </NavLink>
                  </S.FontTitleScaler>
                  <div className="mb-3">
                    {props?.transactionType === 1 ? (
                      <S.FontSubTitleScaler
                        isLarge={isLargeFont}
                        className="text-start title-font black fw-8 font-weight-bold"
                      >
                        {props.price} ₽
                      </S.FontSubTitleScaler>
                    ) : (
                      <>
                        <S.FontSubTitleScaler
                          isLarge={isLargeFont}
                          className="text-start title-font black fw-7 font-weight-bold"
                        >{`${
                          Math.round((Number(props?.price) / Number(props?.totalArea)) * 10) / 10
                        } ₽ за м`}</S.FontSubTitleScaler>
                        {/* <S.FontSubTitleScaler isLarge={isLargeFont} className="gray-3 text-start mt-2">
                      {props.communalPrice
                        ? `Коммунальные платежи: ${props.communalPrice} ₽`
                        : 'Коммунальные платежи не указаны'}
                      <br />
                      {props.pledge ? `Залог: ${props?.pledge} ₽` : 'не указан'},
                      {props.commissionForUser ? ` комиссия: ${props.commissionForUser}` : `не указана`}
                      <br />
                      {props.prepaymentTypeForUser
                        ? `Предоплата: ${props.prepaymentTypeForUser}`
                        : 'предоплата не указана'}
                      ,{props.rentalTypeForUser ? ` аренда: ${props.rentalTypeForUser}` : 'срок аренды не указан'}
                    </S.FontSubTitleScaler> */}
                      </>
                    )}
                  </div>

                  <div className="d-flex align-items-center mb-3 mb-xxl-3">
                    {/* <img src="/img/icons/pin.svg" alt="адрес" /> */}
                    <S.FontPriceScaler isLarge={isLargeFont}>
                      <div>
                        {props?.residentalComplex &&
                        props?.residentalComplex !== 'undefined' &&
                        props?.residentalComplex !== 'null'
                          ? `ЖК: ${props?.residentalComplexForUser}`
                          : ''}
                      </div>
                      <div>{formattedpropsaddres}</div>
                    </S.FontPriceScaler>
                  </div>
                  {!currentUser || ads?.user?.id !== currentUser?.id ? (
                    <ContactsPanel
                      phone={props?.user?.phone}
                      mail={props?.user?.email}
                      link={`https://dakvartira.ru/card-page/${props?.uuid}`}
                      onClickMail={onClickMail}
                      isHorizontalPC={true}
                      isHorizontalAll={true}
                      realEstateId={ads?.id}
                      reportStatus={ads?.reportStatus}
                      reportOnClick={reportOnClick}
                      reportTitle={reportTitle}
                    />
                  ) : null}
                </div>

                <S.OrangeGroup>
                  <S.OrangeTextD isLarge={isLargeFont}>{props.date}</S.OrangeTextD>
                  <S.Eye className="d-flex color-2 ms-4">
                    <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
                    <S.OrangeTextS isLarge={isLargeFont}>
                      {ads.viewsCount} {ads.todayViewsCount > 0 && `(${ads.todayViewsCount})`}
                    </S.OrangeTextS>
                  </S.Eye>
                </S.OrangeGroup>
                {!!props.controlButton && <span> {props.controlButton}</span>}
              </div>
            </div>
          </div>
        </S.RoundedBordersCard>
      ) : (
        <S.StyledCardMiniFav
          className={`card-mini ${props.className || ''}`}
          style={{
            'z-index': toogleZIDX ? '1' : '0',
            background: props?.isTransparent ? 'transparent' : '',
            boxShadow: props?.isTransparent ? 'none' : 'initial',
          }}
        >
          {showUser && (
            <NavLink to={`/user/${props?.user?.id}`}>
              <UserHeader user={props.user} />
            </NavLink>
          )}
          <NavLink
            className={'d-flex flex-column'}
            to={`/card-page/${props?.uuid}`}
            state={{
              prevRoute: pathname,
              routeName: props?.routeName,
              prevSearch: search,
              transactionType: props?.transactionType,
              estateType: getEstateTypeByEstateId(props?.estateId),
              estateId: props?.estateId,
            }}
          >
            <div style={{ position: 'relative' }}>
              <HoverSlider urls={urls} />
              <S.CustomButtons2>
                <S.ItemContainerTotal>
                  <S.FullScreenImgButt onClick={openModalWithImage} />
                  <S.ItemContainerRight>
                    <S.CopyLinkButton2 onClick={onCopy} style={{ marginRight: 10 }} />
                    <BtnFav
                      id={props?.id}
                      wishlistStatus={props?.wishlistStatus}
                      onFav={props.onFav}
                      type={'realEstate'}
                      position={'static'}
                      callbackDeleteWish={props.callbackDeleteWish}
                    />
                  </S.ItemContainerRight>
                </S.ItemContainerTotal>
              </S.CustomButtons2>
            </div>

            <div className="p-3 d-flex flex-column" style={{ 'z-index': '20' }}>
              <div className="d-flex flex-column justify-content-between mb-2 gap-1">
                <S.FontTitleScaler isLarge={isLargeFont}>
                  {props?.estate?.realEstateTypeForUser?.toLowerCase() === localEstates.commer ? (
                    <>{props?.buildingTypeForUser}</>
                  ) : (
                    <CardTitle ads={ads} {...props} />
                  )}
                </S.FontTitleScaler>
                <S.FontSubTitleScaler isLarge={isLargeFont} className="title-font black fw-7">
                  {props?.price}
                  {props?.transactionType === 0 ? ' ₽/мес' : ' ₽'}
                </S.FontSubTitleScaler>
                <S.FontPriceScaler isLarge={isLargeFont} className="title-font black fw-7">
                  {`${Math.round((Number(props?.price) / Number(props?.totalArea)) * 10) / 10} ₽ за м`}
                  <sup>2</sup>
                </S.FontPriceScaler>
              </div>
              <div className="address d-flex align-items-center mb-3" style={{ 'justify-content': 'space-between' }}>
                <S.FontPositionScaler isLarge={isLargeFont} className="d-flex flex-column  ms-1 fw-7">
                  <div className="mb-1">
                    {props?.residentalComplex &&
                    props?.residentalComplex !== 'undefined' &&
                    props?.residentalComplex !== 'null'
                      ? `ЖК: "${props?.residentalComplexForUser}"`
                      : ''}
                  </div>
                  <div>{formattedpropsaddres}</div>
                </S.FontPositionScaler>
                {props?.withSide && (
                  <S.SidePanelWrapper
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    isMobile={isMobile}
                    isOffcanvas={isOffcanvas}
                  >
                    {!currentUser || props?.user?.id !== currentUser?.id ? (
                      <>
                        <S.SideElt as={'a'} href={'tel:+' + props?.user?.phone} onClick={(e) => e.stopPropagation()}>
                          <Phone />
                        </S.SideElt>
                        <Popover
                          widthInRem={2}
                          direction={'left'}
                          action={'click'}
                          tooltip={
                            <ContactsPanel
                              phone={props?.user?.phone}
                              mail={props?.user?.email}
                              onClickMail={onClickMail}
                              isHoz={isMobile}
                              realEstateId={props?.id}
                              reportStatus={props?.reportStatus}
                              reportOnClick={reportOnClick}
                              reportTitle={reportTitle}
                            />
                          }
                          toogleZidx={(isOutside) =>
                            setToogleZIDX((prev) => (isOutside !== undefined ? isOutside : !prev))
                          }
                          isLast={isLast}
                          isOpen={isOpenContacts}
                        >
                          <S.SideElt
                            ref={ref}
                            onClick={(e) => {
                              setOpenContacts((prev) => !prev);
                            }}
                          >
                            {isOpenContacts ? <Cross /> : <Dots />}
                          </S.SideElt>
                        </Popover>
                      </>
                    ) : null}
                    {isOffcanvas && (
                      <S.SideElt $isRating={true}>
                        <RatingStar rating={props?.user?.rating} fontSize={1} />
                      </S.SideElt>
                    )}
                  </S.SidePanelWrapper>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <S.OrangeTextD isLarge={isLargeFont}>{props.date}</S.OrangeTextD>
                <S.Eye className="d-flex color-2">
                  <S.OrangeTextS isLarge={isLargeFont}>
                    {ads.viewsCount} {ads.todayViewsCount > 0 && `(${ads.todayViewsCount})`}
                  </S.OrangeTextS>
                  <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
                </S.Eye>
              </div>
            </div>
          </NavLink>

          <span> {props.controlButton}</span>
        </S.StyledCardMiniFav>
      )}

      {/* MSG SEND */}
      <CustomModal isShow={isShowWriteMessageModal} hideModal={() => resetMessage()} closeButton>
        <form className="message-form">
          <div className="d-flex align-items-center">
            <div className="photo me-2 me-sm-4">
              <img src={checkPhotoPath(props?.user?.avatar)} alt={props?.user?.fullName} />
            </div>
            <div>
              <h4>{props?.user?.fullName}</h4>
            </div>
          </div>
          <textarea
            className="mt-3"
            rows="4"
            placeholder="Введите сообщение"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          {messageInputError && <span className="message-form__error w-100 text-danger">{messageInputError}</span>}
          <div className="d-flex align-items-center mt-3">
            <button type="submit" className="btn btn-1 w-100 flex-1 fs-12 ms-4" onClick={onSendMessage}>
              ОТПРАВИТЬ
            </button>
          </div>
        </form>
      </CustomModal>

      {/* REPORT MODAL */}
      {isReportOpen &&
        ReactDOM.createPortal(
          <ReportModal
            isShow={isReportOpen}
            setIsShow={setIsReportOpen}
            apiAction={reportAdAction}
            pndData={{ token: currentToken, userId: currentUser?.id, realEstateId: ads?.id }}
          />,
          document.getElementById('root') as HTMLElement
        )}

      {/* IMAGE VIEWER */}
      {isModalOpen &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 3000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            onClick={() => setIsModalOpen(false)}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" viewBox="0 0 24 24">
                <path d="M18.3 5.71a1 1 0 00-1.41 0L12 10.59l-4.89-4.88a1 1 0 00-1.41 1.41L10.59 12l-4.89 4.89a1 1 0 101.41 1.41L12 13.41l4.89 4.89a1 1 0 001.41-1.41L13.41 12l4.89-4.89a1 1 0 000-1.41z"></path>
              </svg>
            </button>
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'hidden',
              }}
            >
              {typeof currentImgIndex === 'number' &&
              Number.isFinite(currentImgIndex) &&
              pictures[currentImgIndex]?.type === 'video' ? (
                <video
                  controls
                  src={
                    typeof currentImgIndex === 'number' && Number.isFinite(currentImgIndex)
                      ? pictures[currentImgIndex].src
                      : undefined
                  }
                  style={{ width: '80%' }}
                />
              ) : (
                <img
                  src={
                    typeof currentImgIndex === 'number' && Number.isFinite(currentImgIndex)
                      ? pictures[currentImgIndex].src
                      : undefined
                  }
                  alt="Fullscreen"
                  style={{ maxHeight: '80vh', objectFit: 'contain', width: 'auto', height: 'auto' }}
                />
              )}
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) =>
                    typeof prevIndex === 'number' && Number.isFinite(prevIndex)
                      ? prevIndex > 0
                        ? prevIndex - 1
                        : pictures.length - 1
                      : 0
                  )
                }
                style={{
                  position: 'absolute',
                  left: 20,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'<'}
              </button>
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) =>
                    typeof prevIndex === 'number' && Number.isFinite(prevIndex)
                      ? prevIndex < pictures.length - 1
                        ? prevIndex + 1
                        : 0
                      : 0
                  )
                }
                style={{
                  position: 'absolute',
                  right: 20,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'>'}
              </button>
            </div>
          </div>,
          document.getElementById('root') as HTMLElement
        )}
    </>
  );
};

export default memo(Card);
