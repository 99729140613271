import styled from 'styled-components';
import { getTransition } from '../../../Styles/utilites';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  position: fixed;
  > div {
    height: 100%;
  }
`;
export const LinksWrapper = styled.div`
  display: flex;
  > a {
    display: none;
  }
  > div {
    display: flex;
  }
  > a,
  > div {
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    > div:not(:last-child) {
      ${getTransition(300, ['transform', 'color'])}
    }
  }
  > a:not(:first-child),
  > div:not(:first-child) {
    margin-left: 2rem;
  }
  > a:last-child {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }
    div {
      color: ${({ theme }) => theme.colors.brandColor};
      margin-left: 0.5rem;
      font-weight: 500;
      ${getTransition()}
    }
    &:hover {
      div {
        color: ${({ theme }) => theme.colors.darkBrand};
      }
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    svg {
      min-width: 20px;
      height: auto;
      path {
        ${getTransition(300, ['fill', 'stroke'])}
      }
      ${getTransition(300, 'transform')}
    }
    svg.header_icon {
      path {
        fill: ${({ theme }) => theme.colors.brandColor};
        stroke: ${({ theme }) => theme.colors.brandColor};
        ${getTransition(300, ['fill', 'stroke'])}
      }
    }
    > a,
    > div {
      display: flex;

      &:hover {
        > div:not(:last-child) {
          //transform: scale(1.15);
          color: ${({ theme }) => theme.colors.darkBrand};
        }
        > svg {
          transform: scale(1.15) translate3d(0, -1px, 0);
        }

        header_icon path {
          fill: ${({ theme }) => theme.colors.darkBrand};
          stroke: ${({ theme }) => theme.colors.darkBrand};
        }
      }
    }
  }
`;

type HeartLinkProps = {
  $red: boolean;
};
export const HeartLink = styled(Link)<HeartLinkProps>`
  > svg {
    path {
      fill: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.brandColor)};
      stroke: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.brandColor)};
      ${getTransition(300, ['fill', 'stroke'])}
    }
  }
  &:hover {
    > svg {
      path {
        fill: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.darkBrand)};
        stroke: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.darkBrand)};
      }
    }
  }
`;

export const CalendarIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.brandColor};
  svg {
    width: 21px;
    height: auto;
    rect {
      fill: ${({ theme }) => theme.colors.brandColor};
    }
    path {
      fill: white;
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: auto;
    height: auto;
    background-color: transparent;
    svg {
      width: auto;
      rect {
        fill: white;
      }
      path {
        fill: ${({ theme }) => theme.colors.brandColor};
      }
    }
  }
`;

type CalendarWrapperProps = {
  $show: boolean;
};
export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  top: 49px;
  right: 0;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};

  ${getTransition(300, ['opacity', 'visibility'])};
  @media screen and (max-width: 575px) {
    width: calc(100vw - 1.5rem);
    > div {
      width: 100%;
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    right: -10px;
    top: 48px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    top: 42px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    top: 50px;
  }
`;
export const RemoveHoverEffect = styled.div`
  font-size: 1em;
`;
type CounterProps = {
  $right?: number;
  $bottom?: number;
};
export const Counter = styled.span<CounterProps>`
  position: absolute;
  right: ${({ $right }) => (typeof $right === 'number' ? $right : -10)}px;
  top: ${({ $bottom }) => (typeof $bottom === 'number' ? $bottom : -2)}px;
  width: 19px;
  height: 19px;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.revert};
  color: ${({ theme }) => theme.colors.grey33};
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  z-index: 100;
`;

export const Day = styled.div`
  user-select: none;
  position: absolute;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.colors.revert};
  font-weight: bold;
  font-size: 10px;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.brandColor};
    font-size: 12px;
  }
`;

export const CustomRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  img {
    width: 56px;
    height: 54px;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      width: 44px;
      height: 43px;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      width: 56px;
      height: 54px;
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    img {
      width: 86px;
      height: 84px;
    }
  }
`;
export const CustomRatingSpan = styled.span`
  position: absolute;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: bold;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.9);
  @media screen and ${({ theme }) => theme.media.xxl} {
    font-size: 0.9em;
  }
`;
