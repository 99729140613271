import React, { useState } from 'react';
import * as S from './styles';
import { useTimeout } from '../../hooks/useTimeout';
import { useToggleVisible } from '../../hooks/useToggleVisible';
import { number } from 'joi';

type DropdownItem = {
  content: string;
  action: () => void;
  icon: JSX.Element;
  count?: number;
  highlight?: boolean;
};

type DropdownProps = {
  isOpen: boolean;
  array: DropdownItem[];
  widthInRem?: number;
  direction?: 'top' | 'bottom';
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  favsCount: number;
};

export const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  array,
  widthInRem,
  direction = 'top',
  setActiveTab,
  favsCount,
  activeTab
}) => {
  return (
    <S.Wrapper
      $isOpen={isOpen}
      $direction={direction}
      $widthInRem={widthInRem}
      $isJsxIn={true}
      data-testid={'dropdown'}
    >
      <S.Container>
      {array.map((item, index) => {
        const modifiedIcon = item.highlight ? (
          <S.HeartLink to="/favorites-route" $red={true}>
            {item.icon}
          </S.HeartLink>
        ) : item.icon;

        return (
          <li
            key={index}
            onClick={() => {
              item.action();
              setActiveTab(item.content);
            }}
            data-testid={'dropdown-elt'}
          >
            {modifiedIcon}
            {item.content}
            {item.count != null && item.count > 0 && <S.CounterMenuFav>{item.count}</S.CounterMenuFav>}
          </li>
        );
        })}
      </S.Container>
    </S.Wrapper>
  );
};
