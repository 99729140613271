import styled, { css } from 'styled-components';
import { getTransition } from '../../Styles/utilites';

type ContentProps = {
  $isShow: boolean;
  $isSpecial?: boolean;
  $width?: number;
  $overVisible?: boolean;
};
export const Content = styled.div<ContentProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.25em;
  min-height: 30vh;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  max-width: ${({ $width }) => ($width ? $width : 900)}px;
  max-height: 90vh;
  margin: 0 1rem 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.darkBrand};
  opacity: 0;
  transform: rotateY(90deg) translate3d(0, -100px, 0);
  overflow: ${({ $overVisible }) => ($overVisible ? 'initial' : 'hidden')};
  ${({ $isShow }) =>
    $isShow &&
    css`
      opacity: 1;
      transform: rotateY(0) translate3d(0, 0, 0);
    `}
  ${getTransition(300, ['opacity', 'transform'], 'ease-out')}
  @media screen and ${({ theme }) => theme.media.sm} {
    max-height: 100vh;
    margin: 0 3rem 0;
    padding: 1em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    min-height: 50vh;
    padding: 1em 2em;
  }
  @media screen and (min-width: 2100px) {
    min-height: 40vh;
  }
  ${({ $isSpecial }) =>
    $isSpecial &&
    css`
      max-width: initial;
      min-width: initial;
      max-height: initial;
      min-height: initial;
      width: 300px;
      height: 280px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: initial;
      @media screen and ${({ theme }) => theme.media.sm} {
        width: 400px;
        height: 380px;
      }
      @media screen and ${({ theme }) => theme.media.md} {
        max-width: initial;
        min-width: initial;
        max-height: initial;
        min-height: initial;
      }
      @media screen and ${({ theme }) => theme.media.sm} {
        width: 500px;
        height: 480px;
      }
    `}
`;
