import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';

type CustomModalProps = {
  setIsShow: (arg: boolean) => void;
  hideModal?: () => void;
  bsPrefix?: string;
  isShow: boolean;
  backdrop?: boolean | 'static';
  centre?: boolean;
  centered?: boolean;
  className?: string;
  size?: 'sm' | 'lg' | 'xl';
  closeButton?: boolean;
  isMobile: any;
};
const CustomModal = ({
  setIsShow,
  bsPrefix,
  isShow,
  hideModal,
  backdrop,
  centered,
  centre,
  className,
  size,
  closeButton,
  children,
  isMobile,
}: PropsWithChildren<CustomModalProps>) => {
  const handleClose = () => setIsShow(false);

  return (
    <Modal
      bsPrefix={bsPrefix}
      show={isShow}
      onHide={() => {
        !!hideModal ? hideModal() : handleClose();
      }}
      backdrop={backdrop || true}
      data-bs-backdrop={false}
      centered={centre || centered || false}
      dialogClassName="modal-90w"
      className={className ?? ''}
      size={size}
    >
      {closeButton ? (
        <Modal.Header         onClick={(e) => {
          // e.preventDefault();
          e.stopPropagation();
        }}>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              !!hideModal ? hideModal() : handleClose();
            }}
          >
            <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00006 1.18237L15 15.9049" />
              <path d="M14.9999 1.18237L1.00001 15.9049" />
            </svg>
          </button>
        </Modal.Header>
      ) : null}
      <Modal.Body
        style={{ marginTop: isMobile ? 0 : 30 }}
        onClick={(e) => {
          // e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
