import * as S from './styles';
import React from 'react';

type DescriptionProps = {
  description: string;
};

export const Description = ({ description }: DescriptionProps) => {
  return (
    <S.ShadowDivider>
      <S.Description>{description}</S.Description>
    </S.ShadowDivider>
  );
};
