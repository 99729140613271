import React, { useEffect, useState } from 'react';
import Card from './Card/CardFav';
import CustomOffcanvas from './CustomOffcanvas';
import useWindowDimensions from '../hooks/useWindowDimensions';

const OffcanvasCards = (props) => {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    width < 991 ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  return (
    <CustomOffcanvas
      className={props.className ?? ''}
      placement="start"
      isShow={props?.cards?.length}
      setIsShow={() => props.hideOffcanvas && props.hideOffcanvas()}
      backdrop={false}
      closeButton={true}
    >
      {props?.cards?.length &&
        props.cards.map((card) => (
          <div key={card.id} className={'d-flex'}>
            <Card
              ads={card}
              isOffcanvas={true}
              className="mb-4"
              isLargeFont={isMobile}
              withSide={true}
              pictures={[card.image, card.images]}
              isVip={card.isVip}
              isHot={card.isHot}
              title={card.title}
              price={card.price}
              reportStatus={card.reportStatus}
              totalArea={card.totalArea}
              transactionType={card.transactionType}
              estateId={card.estateId}
              residentalComplex={card?.residentalComplex}
              residentalComplexForUser={card?.residentalComplexForUser}
              address={card.address}
              metro={card.metro}
              text={card.description}
              date={card.createdAtForUser}
              id={card.id}
              uuid={card.uuid}
              user={card.user}
              communalPrice={card.communalPrice}
              pledge={card.pledge}
              commissionForUser={card.commissionForUser}
              prepaymentTypeForUser={card.prepaymentTypeForUser}
              rentalTypeForUser={card.rentalTypeForUser}
              wishlistStatus={card.wishlistStatus}
              userAvatar={card.user?.avatar}
              estateName={card?.estate?.name}
              buildingTypeForUser={card?.buildingTypeForUser}
              realEstateTypeForUser={card?.estate?.realEstateTypeForUser}
              acres={card?.acres}
              onFav={props.onFav}
              routeName={'Каталог'}
            />
          </div>
        ))}
    </CustomOffcanvas>
  );
};

export default OffcanvasCards;
