import styled from 'styled-components';
interface WrapperProps {
  isHorizontalPC: boolean;
  isHorizontalAll?: boolean;
  isHoz?:boolean;
  scale: number;
}
export const Wrapper = styled.div<WrapperProps>`
  scale: ${(props) => props.scale || 1};
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: ${(props) => {
    return props.isHorizontalAll ? 'row' : 'column';
  }};
  justify-content: flex-start;
  gap: 5px;
  align-items: ${({ isHoz }) => {
    return isHoz ? 'center' : 'flex-end';
  }};


  a,
  svg {
    width: 32px;
    height: ${({ isHoz }) => {
      return isHoz ? '32px' : 'auto';
    }};
  }

  div {
    cursor: pointer;
  }
  div:first-child {
    svg {
      path {
        fill: #d46b25;
      }
    }
  }
  div:not(:first-child),
  a {
    svg {
      margin-top: ${({ isHoz }) => {
        return isHoz ? '0px' : '0rem';
      }};
    }
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    flex-direction: ${(props) => (props.isHorizontalPC ? 'row' : 'column')};
    justify-content: flex-start;
    gap: 5px;
    align-items: flex-end;
  }
`;
