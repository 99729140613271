import { Route, Routes } from 'react-router-dom';

import ArticleList from '../pages/Article.List';
import AppLayout from '../layouts/AppLayout';
import AIAssistPage from '../pages/AIAssist.Page';
import AdEditPage from '../pages/Ad.Edit.Page';
import AdListPage from '../pages/Ad.List.Page';
import AdViewPage from '../pages/Ad.View.Page';
import AllServices from '../pages/AllServices';
import ArticlePage from '../pages/Article.Page';
import CatalogList from '../pages/CatalogList';
import Hypothec from '../pages/Hypothec';
import Insurance from '../pages/Insurance';
import Login from '../pages/Login';
import MainPage from '../pages/MainPage';
import NotFound from '../pages/NotFound';
import Password1 from '../pages/Password1';
import Password2 from '../pages/Password2';
import PersonalAccount from '../pages/PersonalAccount';
import Policy from '../pages/Policy';
import Registration from '../pages/Registration';
import Services from '../pages/Services.List.Page';
import UserPage from '../pages/User.Page';
import WaitAccountActivation from '../pages/WaitAccountActivation';
import AuthProtector from './AuthProtector';

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<AppLayout />}>
        {/* DOMAIN */}
        <Route path="catalog" element={<AdListPage routeName="Объявления" />} />
        <Route path="catalog-list" element={<CatalogList />} />
        <Route path="card-page/:uuid" element={<AdViewPage />} />
        <Route path="services" element={<AllServices routeName="Услуги" />} />
        <Route path="service/:slug" element={<Services />} />
        <Route path="hypothec" element={<Hypothec routeName="Ипотека" />} />
        <Route path="insurance" element={<Insurance routeName="Страхование" />} />

        <Route path="user/:userId" element={<UserPage routeName="Пользователь" />} />

        <Route path="ai" element={<AIAssistPage />} />

        {/* CIAM */}
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="password-1" element={<Password1 />} />
        <Route path="password-2/:token" element={<Password2 />} />
        <Route path="waitAccountActivation/:uuid" element={<WaitAccountActivation />} />

        <Route element={<AuthProtector />}>
          <Route path="advertise" element={<AdEditPage />} >
            <Route path=":uuid" element={<AdEditPage />} />
          </Route>

          <Route path="personal-account/*" element={<PersonalAccount />} />
        </Route>

        {/* SHARED */}
        <Route index path="/" element={<MainPage />} />
        <Route path="articles" element={<ArticleList routeName="Статьи" />} />
        <Route path="articles/:slug" element={<ArticlePage routeName="Название статьи" />} />
        <Route path="policy" element={<Policy />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
