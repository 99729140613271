import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { getTransition } from '../../../../Styles/utilites';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  padding: 0 1rem;
  overflow: hidden;
  max-width: 2000px;
  .swiper {
    height: 100%;
    //   width: auto;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    height: 70px;
  }
  .swiper-wrapper,
  .swiper-slide-active {
    height: 80px;
  }

  .swiper-pagination {
    display: none;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    .swiper-slide {
      height: 100px;
    }
    .swiper-wrapper,
    .swiper-slide-active {
      height: 110px;
    }
  }
  @media screen and (max-width: 768px) {
    .swiper-slide-active {
      > div:first-child > div:first-child {
        opacity: 0;
      }
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 5rem 0;
    padding: 0 3rem;
    .swiper-wrapper {
      height: 240px;
      align-items: flex-start;
    }
    .swiper-slide {
      height: 220px;
    }
    .swiper-slide-active {
      height: 240px;
    }
    .swiper-pagination {
      display: block;
      position: static;
      margin-top: 0.5rem;
      min-height: 16px;
    }
    .swiper-pagination-bullet {
      width: 70px;
      height: 10px;
      border-radius: 0.3rem;
      background-color: ${({ theme }) => theme.colors.brandColor};
      ${getTransition(300, 'transform')}
    }
    .swiper-pagination-bullet-active {
      transform: scaleY(1.35);
    }
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 0 5rem;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  background: black;
  overflow: hidden;
  width: 450px;
  height: 220px;
  border-radius: 0.5rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${getTransition(300, 'height')}
`;
