import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import Card from './Card/CardFav';
import { useEffect } from 'react';
import * as S from './Main/Articles/Slider/styles';
import useIsMobile from '../hooks/useIsMobile';

export const SliderAds = (props) => {
  const [popular, setPopular] = useState([]);
  const [recommend, setRecommend] = useState([]);
  const [hotAds, setHotAds] = useState([]);
  const [userAds, setUserAds] = useState([]);

  useEffect(() => {
    if (props.recommend) {
      setRecommend(props.recommend);
    }
  }, [props.recommend]);

  useEffect(() => {
    if (props.userAds) {
      setUserAds(props.userAds);
    }
  }, [props.userAds]);

  useEffect(() => {
    if (props.popular) {
      setPopular(props.popular);
    }
  }, [props.popular]);

  useEffect(() => {
    if (props.hotAds) {
      setHotAds(props.hotAds);
    }
  }, [hotAds]);

  const isMobile = useIsMobile(768)

  return (
    <S.Wrapper>
      <Swiper
        className="swiper-4"
        spaceBetween={12}
        slidesPerView={props?.isBlackMarker ? 1 : isMobile ? 1 : 1.5}
        touchStartPreventDefault={true}
        grabCursor={true}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: props?.isBlackMarker ? 1 : 2,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: props?.isBlackMarker ? 1: 2,
            spaceBetween: 16,
          },
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation, Pagination]}
      >
        {popular?.map((popular) => (
          <SwiperSlide key={popular.id}>
            <Card
              ads={popular}
              user={popular.user}
              type={!isMobile && 'as-a-list'}
              id={popular.id}
              wishlistStatus={popular.wishlistStatus}
              uuid={popular.uuid}
              pictures={[popular.image, popular.images]}
              isVip={popular.isVip}
              isHot={popular.isHot}
              title={popular.title}
              price={popular.price}
              residentalComplex={popular?.residentalComplex}
              residentalComplexForUser={popular?.residentalComplexForUser}
              address={popular.address}
              metro={popular.metro}
              text={popular.description}
              date={popular.createdAtForUser}
              totalArea={popular?.totalArea}
              estateName={popular?.estate?.name}
              buildingTypeForUser={popular?.buildingTypeForUser}
              realEstateTypeForUser={popular?.estate?.realEstateTypeForUser}
              acres={popular?.acres}
              withSide={isMobile}
              isLargeFont={true}
              todayViewsCount={popular.todayViewsCount}
            />
          </SwiperSlide>
        ))}
        {recommend?.map((recommend) => (
          <SwiperSlide key={recommend.id}>
            <Card
              ads={recommend}
              user={recommend.user}
              type={!isMobile && 'as-a-list'}
              id={recommend.id}
              wishlistStatus={recommend.wishlistStatus}
              uuid={recommend.uuid}
              pictures={[recommend.image, recommend.images]}
              isVip={recommend.isVip}
              isHot={recommend.isHot}
              title={recommend.title}
              price={recommend.price}
              residentalComplex={recommend?.residentalComplex}
              residentalComplexForUser={recommend?.residentalComplexForUser}
              address={recommend.address}
              metro={recommend.metro}
              text={recommend.description}
              date={recommend.createdAtForUser}
              totalArea={recommend?.totalArea}
              estateName={recommend?.estate?.name}
              buildingTypeForUser={recommend?.buildingTypeForUser}
              realEstateTypeForUser={recommend?.estate?.realEstateTypeForUser}
              acres={recommend?.acres}
              withSide={isMobile}
              isLargeFont={true}
              todayViewsCount={recommend.todayViewsCount}
            />
          </SwiperSlide>
        ))}
        {userAds?.map((userAds) => (
          <SwiperSlide key={userAds.id}>
            <Card
              ads={userAds}
              user={userAds.user}
              type={!isMobile && 'as-a-list'}
              id={userAds.id}
              wishlistStatus={userAds.wishlistStatus}
              uuid={userAds.uuid}
              pictures={[userAds.image, userAds.images]}
              isVip={userAds.isVip}
              isHot={userAds.isHot}
              title={userAds.title}
              price={userAds.price}
              residentalComplex={userAds?.residentalComplex}
              residentalComplexForUser={userAds?.residentalComplexForUser}
              address={userAds.address}
              metro={userAds.metro}
              text={userAds.description}
              date={userAds.createdAtForUser}
              routeName={props?.routeName}
              totalArea={userAds?.totalArea}
              estateName={userAds?.estate?.name}
              buildingTypeForUser={userAds?.buildingTypeForUser}
              realEstateTypeForUser={userAds?.estate?.realEstateTypeForUser}
              acres={userAds?.acres}
              viewsCount={userAds.viewsCount}
              withSide={isMobile}
              isLargeFont={true}
              todayViewsCount={userAds.todayViewsCount}
            />
          </SwiperSlide>
        ))}
        {hotAds?.map((hotAd) => (
          <SwiperSlide key={hotAd.id}>
            <Card
              ads={hotAd}
              user={hotAd.user}
              type={!isMobile && 'as-a-list'}
              id={hotAd.id}
              wishlistStatus={hotAd.wishlistStatus}
              uuid={hotAd.uuid}
              pictures={[hotAd.image, hotAd.images]}
              isVip={hotAd.isVip}
              isHot={hotAd.isHot}
              title={hotAd.title}
              price={hotAd.price}
              residentalComplex={hotAd?.residentalComplex}
              residentalComplexForUser={hotAd?.residentalComplexForUser}
              address={hotAd.address}
              metro={hotAd.metro}
              text={hotAd.description}
              date={hotAd.createdAtForUser}
              totalArea={hotAd?.totalArea}
              estateName={hotAd?.estate?.name}
              buildingTypeForUser={hotAd?.buildingTypeForUser}
              realEstateTypeForUser={hotAd?.estate?.realEstateTypeForUser}
              acres={hotAd?.acres}
              withSide={isMobile}
              isLargeFont={true}
              todayViewsCount={hotAd.todayViewsCount}
            />
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev">
          <img src="/img/icons/prev.svg" alt="предыдущий" className="w-100" />
        </div>
        <div className="swiper-button-next">
          <img src="/img/icons/next.svg" alt="следующий" className="w-100" />
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </S.Wrapper>
  );
};
