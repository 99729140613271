import * as S from './styles';
import { Title } from './styles';
import one from '../../../assets/imgs/categories/first.png';
import two from '../../../assets/imgs/categories/second.png';
import three from '../../../assets/imgs/categories/third.png';
import four from '../../../assets/imgs/categories/fourth.png';
import five from '../../../assets/imgs/categories/fiveth.png';
import six from '../../../assets/imgs/categories/sixth.png';
import seven from '../../../assets/imgs/categories/seventh.png';
import eight from '../../../assets/imgs/categories/eightth.png';
import nine from '../../../assets/imgs/categories/nineth.png';
import ten from '../../../assets/imgs/categories/tenth.png';
import eleven from '../../../assets/imgs/categories/eleventh.png';
import twelve from '../../../assets/imgs/categories/twelveth.png';
import { ReactComponent as Close } from '../../../assets/icons/modal/close.svg';
import React, { PropsWithChildren, useState } from 'react';
import { useTimeout } from '../../../hooks/useTimeout';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

type CategoriesProps = {};

type ActionType = 'all' | 'link';
type Item = {
  img: string;
  titles: string[];
  actionType: ActionType;
  link: string;
};

const actions = ['Купить', 'Продать', 'Снять', 'Сдать'];

const items: Item[] = [
  {
    img: one,
    titles: ['Квартиры', 'Комнаты'],
    actionType: 'all',
    link: '1,1;0,1',
  },
  {
    img: two,
    titles: ['Дома', 'Дачи'],
    actionType: 'all',
    link: '1,2;0,2',
  },
  {
    img: three,
    titles: ['Гараж', 'Паркинг'],
    actionType: 'all',
    link: '1,4;0,4',
  },
  {
    img: four,
    titles: ['Земельные участки'],
    actionType: 'all',
    link: '1,3;0,3',
  },
  {
    img: five,
    titles: ['Коммерческая недвижимость'],
    actionType: 'all',
    link: '1,5;0,5',
  },
  {
    img: six,
    titles: ['Продажа товара'],
    actionType: 'link',
    link: '/',
  },
  {
    img: seven,
    titles: ['Дизайн'],
    actionType: 'link',
    link: '/service/dizain',
  },
  {
    img: eight,
    titles: ['Ремонт'],
    actionType: 'link',
    link: '/service/remontStroitelstvo',
  },
  {
    img: nine,
    titles: ['Грузоперевозки'],
    actionType: 'link',
    link: '/service/gruzoperevozki',
  },
  {
    img: ten,
    titles: ['Услуги риелторов'],
    actionType: 'link',
    link: '/service/uslugiRieltora',
  },
  {
    img: eleven,
    titles: ['Ипотека и кредитование'],
    actionType: 'link',
    link: '/hypothec',
  },
  {
    img: twelve,
    titles: ['Страхование'],
    actionType: 'link',
    link: '/insurance',
  },
];

const getToLink = (link: string, isAdv: boolean, order: number) => {
  const [transaction, estate] = link.split(';')[order].split(',');
  return `/${isAdv ? 'advertise' : 'catalog'}?transactionType=${transaction}&typesEstate=${estate}`;
};

export const Categories = ({}: CategoriesProps) => {
  const { width } = useWindowDimensions();
  const [isShow, setShow] = useState(false);
  const [set, clear] = useTimeout(() => setShow(true), 10);
  const [setClose, clearClose] = useTimeout(() => setActiveItem(undefined), 500);
  const [activeItem, setActiveItem] = useState<number>();

  const onClick = (id: number) => {
    if (id === activeItem) return;
    if (width < 768) {
      setShow(true);
      setActiveItem(id);
    } else {
      setShow(false);
      setActiveItem(id);
      set();
    }
    // if (activeItem === id) setActiveItem(undefined);
    // else setActiveItem(id);
  };
  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShow(false);
    setClose();
  };

  return (
    <S.Section id="sec-2">
      <S.Wrapper className="container">
        {items.map(({ img, titles, actionType, link }, i) => (
          <S.Item to={link} as={i < 5 ? 'div' : Link} key={i} onClick={() => (actionType === 'all' ? onClick(i) : {})}>
            <S.ItemContent>
              <S.TitleDivider>
                {titles.map((title, i) => (
                  <Title key={i} $isSecond={i === 1}>
                    {title}
                  </Title>
                ))}
              </S.TitleDivider>
              <img src={img} alt={titles.join(' ')} />
            </S.ItemContent>
            {i === activeItem && (
              <S.Item $active={true} $index={i} $isShow={isShow} onClick={() => onClick(i)}>
                <S.ItemContent $active={true}>
                  <S.ActiveImgWrapper $isActive={isShow}>
                    <S.TitleDivider $isActive={isShow}>
                      {titles.map((title, i) => (
                        <Title key={i} $isSecond={i === 1} $isActive={true}>
                          {title}
                        </Title>
                      ))}
                    </S.TitleDivider>
                    <img src={img} alt={titles.join(' ')} />
                  </S.ActiveImgWrapper>
                  <S.Close onClick={onClose}>
                    <Close />
                  </S.Close>
                  <S.LinksDivider>
                    <S.ActionDivider>
                      {actions.slice(0, 2).map((action, j) => (
                        <S.Action to={getToLink(link, j === 1, 0)} key={j}>
                          {action}
                        </S.Action>
                      ))}
                    </S.ActionDivider>
                    <S.ActionDivider>
                      {actions.slice(2).map((action, j) => (
                        <S.Action to={getToLink(link, j === 1, 1)} key={j}>
                          {action}
                        </S.Action>
                      ))}
                    </S.ActionDivider>
                  </S.LinksDivider>
                </S.ItemContent>
              </S.Item>
            )}
          </S.Item>
        ))}
      </S.Wrapper>
    </S.Section>
  );
};
