import React, { useEffect, useState } from 'react';
import YMap from './YMap';
import OffcanvasCards from './OffcanvasCards';
import OffcanvasFilters from './OffcanvasFilters';
import { animateScroll as scroll } from 'react-scroll';
import getForMap from '../API/ymap';
import { useSelector } from 'react-redux';
import { right } from '@popperjs/core';
import { ReactComponent as CloseSVG } from '../assets/icons/map/close.svg';
import CityContainer from './Main//CityContainer/CityContainer';

const YMapContainer = ({
  isShowMap,
  filters,
  setFilters,
  onResetFilters,
  onApplyFilters,
  foundCount,
  searchParams,
  searchParamsTransactionType,
  closeAction
}) => {
  const city = useSelector((state) => state.selectedCity);
  const [ids, setIds] = useState([]);
  const [cards, setCards] = useState([]);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    getForMap(city, '', filters).then((items) => setMapData(items));
  }, [city, filters]);

  useEffect(() => {
    if (isShowMap) {
      setIsShowFilters(true);
    } else {
      setIsShowFilters(false);
    }
  }, [isShowMap]);

  useEffect(() => {
    if (ids?.length) {
      const result = [];

      mapData.forEach((item) => {
        ids.forEach((id) => {
          if (id === item.id) {
            result.push(item);
          }
        });
      });

      setCards(result);
    }
  }, [ids]);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <div className="catalog__ymaps-container" style={{ position: 'relative' }}>
      <CityContainer noView={true} />
      <YMap items={mapData}
        className="catalog__ymaps"
        callback={(ids) => setIds(ids)} isAdList={true} />
      <OffcanvasCards
        someProp={'true'}
        className="main-page__offcanvas-cards"
        cards={cards}
        hideOffcanvas={() => {
          setCards([]);
          setIds([]);
        }}
      />
      <button style={{
        position: 'absolute',
        right: 20,
        top: 20,
        'z-index': '100',
      }}
        onClick={() => { closeAction(false) }}
      >
        <CloseSVG />
      </button>
    </div >
  );
};

export default YMapContainer;
