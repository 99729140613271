import { combineReducers } from 'redux';
import accessToken from './accessToken';
import currentUser from './currentUser';
import selectedCity from './selectedCity';
import mapCenter from './mapCenter';
import { useSelector } from 'react-redux';
import alert from './alert';
import conversationCount from './conversationCount';
import favoriteCount from './favoriteCount';
import tabReducer from './favswitchReducer';

const reducers = combineReducers({
  accessToken,
  currentUser,
  selectedCity,
  mapCenter,
  alert,
  conversationCount,
  favoriteCount,
  tab: tabReducer,
});

export function useCurrentUser() {
  return useSelector((state: { currentUser?: any }) => state.currentUser);
}

export function useAccessToken() {
  return useSelector((state: { accessToken?: string }) => {
    return state.accessToken;
  });
}

export default reducers;
