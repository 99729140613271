import { useEffect, useLayoutEffect, useState } from 'react';

import { useCurrentUser } from '../store/reducers';

import '../assets/styles/WelcomeScreen.css';

function WelcomeScreen() {
  const currentUser = useCurrentUser();

  const [userName, setUserName] = useState('');
  const [timeType, setTimeType] = useState<number>();

  const welcomeTexts = ['Доброе утро', 'Добрый день', 'Добрый вечер', 'Доброй ночи'];

  const containerClasses = ['morning', 'day', 'evening', 'night'];

  useEffect(() => {
    setUserName(currentUser?.firstName);
  }, [currentUser]);

  useEffect(() => {
    setUserName(currentUser?.firstName);
  }, [timeType]);

  useLayoutEffect(() => {
    let date = new Date();
    let h = Math.round(date.getHours());

    if (h >= 6 && h < 12) {
      setTimeType(0);
    }
    if (h >= 12 && h < 18) {
      setTimeType(1);
    }
    if (h >= 18 && h < 24) {
      setTimeType(2);
    }
    if (h >= 0 && h < 6) {
      setTimeType(3);
    }
  }, []);

  return (
    <div className={`welcomeContainer ${typeof timeType === 'number' && containerClasses[timeType]}`}>
      <div className={'welcomeContainerHideBlock'} />
      <div className="welcomeText">
        <p>
          <span>{`${typeof timeType === 'number' && welcomeTexts[timeType]}${userName ? ',' : ''}`}</span>
          <br />
          {!!userName && <span className="userNameText">{userName}!</span>}
        </p>
      </div>
    </div>
  );
}

export default WelcomeScreen;
