import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Breadcrumbs = ({ currentRouteName }) => {
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    if (!state || !state.estateId) return;
    const filters = sessionStorage.getItem('filters');

    const filtersObj = !!filters ? JSON.parse(filters) : {};
    sessionStorage.setItem(
      'filters',
      JSON.stringify({
        ...filtersObj,
        districts: filtersObj.districts || [],
        orderBy: filtersObj.orderBy || 'asc',
        estateId: state.estateId,
        transactionType: state.transactionType,
        typesEstate: state.estateType,
      })
    );
  }, [state]);

  return (
    <nav aria-label="breadcrumb">
      <div className="container py-3 py-sm-4 py-lg-5">
        {/* <NavLink
                    to={
                        state?.routeName
                            ? `${state?.prevRoute}${state?.prevSearch}`
                            : state?.prevRoute
                    }
                    className="d-block d-md-none gray-3 mb-4"
                >
                    &#10094; Назад
                </NavLink> */}
        <ol className="d-md-flex breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Главная</NavLink>
          </li>
          {state?.prevRoute && state?.prevRoute?.length > 1 && (
            <li className="breadcrumb-item">
              <NavLink
                to={
                  state?.routeName
                    ? `${state?.prevRoute}${state?.prevSearch ? state?.prevSearch : ''}`
                    : state?.prevRoute
                }
              >
                {state?.routeName}
              </NavLink>
            </li>
          )}
          {state?.routeName === 'Каталог' && state?.prevRoute?.length < 2 && (
            <li className="breadcrumb-item">
              <NavLink
                to={`/catalog?transactionType=${state.transactionType || 1}&typesEstate=${state.estateType || 0}`}
                // to={`/catalog${
                //   !!sessionStorage.getItem('filters') &&
                //   !!JSON.parse(sessionStorage.getItem('filters')).transactionType &&
                //   !!JSON.parse(sessionStorage.getItem('filters')).typesEstate
                //     ? '?transactionType=' +
                //       JSON.parse(sessionStorage.getItem('filters')).transactionType +
                //       '&typesEstate=' +
                //       JSON.parse(sessionStorage.getItem('filters')).typesEstate
                //     : '?transactionType=1&typesEstate=1'
                // }`}
              >
                {state?.routeName}
              </NavLink>
            </li>
          )}
          {currentRouteName && <li className="breadcrumb-item breadcrumb-item_active">{currentRouteName}</li>}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
