import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';
import { getServiceType, getSubServicesTypes } from '../../API/services';
import { checkPhotoPath } from '../../helpers/photo';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ShowPhone from '../ShowPhone';
import HoverSlider from '../HoverSlider/HoverSlider';

import '../../assets/styles/userCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../../store/actions/alert';
import BtnFav from '../BtnFav';
import { format } from '../../helpers/numFormat';
import { Price } from '../ServiceCard/ServiceCard';

function UserCard(props) {
  const axiosPrivate = useAxiosPrivate();
  const [serviceType, setServiceType] = useState({});
  const [subServicesTypes, setSubServicesTypes] = useState([]);
  const userId = useSelector((state) => state.currentUser?.id);
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const setSendMessagePayloads = () => {
    if (props?.setSendMessagePayloads && props?.serviceId && props?.userId) {
      props.setSendMessagePayloads((prev) => ({
        ...prev,
        serviceId: props?.serviceId,
        userId: props?.userId,
      }));
    }
  };

  const onSendMsg = () => {
    if (userId) {
      setSendMessagePayloads();
    } else {
      setAlert('danger', true, 'Функция доступна авторизованным пользователям');
    }
  };

  useEffect(() => {
    getServiceType(axiosPrivate, props.serviceTypeId).then((serviceTypes) => {
      setServiceType(serviceTypes);
    });
  }, []);

  useEffect(() => {
    getSubServicesTypes(axiosPrivate, props.serviceTypeId).then((servicesTypes) => {
      setSubServicesTypes(
        servicesTypes
          ?.filter((type) => props.subServices?.find((subService) => type?.id === subService?.serviceTypeSubServiceId))
          .map((subType) => {
            const data = props.subServices.find((subService) => subType?.id === subService?.serviceTypeSubServiceId);
            return {
              ...subType,
              price: data.price,
              isFromPrice: data.isFromPrice,
            };
          })
      );
    });
  }, []);

  const serviceTypeLabels = ['Услуги риелтора', 'Дизайн', 'Ремонт/Строительство', 'Грузоперевозки'];

  const imgsUrl = `${process.env.REACT_APP_PHOTO_URL}/uploads`;

  if (!props.subServices.length) return <div>LOOSE DATA</div>;
  return (
    <>
      {isMobile ? (
        <div className="user-card" style={{ padding: 0 }}>
          <div className="title" style={{ marginBottom: '10px' }}>
            <div className="d-xxl-flex">
              <div className="photo">
                <Link to={props.link}>
                  <img
                    style={{ width: '50px', height: '50px', marginBottom: '10px', marginTop: '10px' }}
                    src={checkPhotoPath(props.photo)}
                    alt={props.userName}
                  />
                </Link>
              </div>
              <div className="rating mb-2 mb-xl-2 ms-xxl-4">
                <Rating
                  readonly={true}
                  initialRating={props?.rating}
                  fractions={2}
                  emptySymbol={<img src="/img/icons/star-gray.svg" alt="1" />}
                  fullSymbol={<img src="/img/icons/star-blue.svg" alt="1" />}
                />
              </div>
              <h5 className="text-center fw-bold mb-xl-2 mb-xxl-0" style={{ margin: 0, fontSize: '20px' }}>
                <Link to={props.link}>{serviceTypeLabels[props.serviceTypeId - 1]}</Link>
              </h5>
              <h5 className="text-center fw-bold color-1 mb-1 mb-xl-2 mb-xxl-0" style={{ margin: 0, fontSize: '20px' }}>
                <Link to={props.link}>{props.userName}</Link>
              </h5>
            </div>
          </div>
          {props.subServices[0].service.images && (
            <HoverSlider
              isMobile={isMobile}
              serviceId={props?.serviceId}
              wishlistStatus={props?.wishlistStatus}
              urls={props.subServices[0].service.images
                .sort(function (a, b) {
                  return a.order - b.order;
                })
                .map((serviceImage) => `${imgsUrl}/${serviceImage.image}`)}
              to={props.link}
            />

          )}
          <div className="serv-list mt-2 mt-md-3 mt-xxl-0">
            {props?.labels?.map((i) => (
              <div className="serv" key={i.id}>
                {i.name}
              </div>
            ))}
          </div>
          <div className="btns mt-2 mt-md-3 mt-xxl-0" style={{ padding: '1rem' }}>
            <ShowPhone phone={props.phone} userId={userId ?? 'notExist'} />
            {props?.isShowMessage && (
              <button type="button" className="d-xxl-block btn btn-1 w-100 px-1 mt-2" onClick={onSendMsg}>
                Написать сообщение
              </button>
            )}
            {props.inAddResponse && (
              <Link
                to={`/personal-account add/${props.userId}`}
                className="btn btn-2 w-100 px-1 mt-2 mt-xxl-0"
                state={{
                  labels: props.labels,
                  exp: props.exp,
                  subServices: props.subServices,
                  phone: props.phone,
                  description: props.description,
                  serviceId: props.serviceId,
                  prevUrl: props.prevUrl,
                }}
              >
                Откликнуться
              </Link>
            )}
          </div>
          <div className="d-flex" style={{ justifyContent: 'space-between', margin: '0 20px 20px' }}>
            <div className="d-flex color-0">
              <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
              <span className="d-none d-md-block ms-2">Просмотры:</span>
              <span className="w-100 ms-1">{props.viewsCount}</span>
            </div>
            <div className="d-flex color-2 ms-4">{props.subServices[0].service.createdAtForUser}</div>
          </div>
        </div>
      ) : (
        <div className="user-card d-flex flex-column align-items-center">
          <div className={`person ${props?.inWishlist ? 'favs' : ''}`}>
            <div className="photo mt-2 mt-md-3 mt-xxl-0">
              <Link to={props.link}>
                <img src={checkPhotoPath(props.photo)} alt={props.userName} />
              </Link>
            </div>
            <div className="title">
              <div className="">
                <div className="d-flex align-items-center rating mb-1 mb-xl-2 mt-xxl-2">
                  <Rating
                    readonly={true}
                    initialRating={props?.rating}
                    fractions={2}
                    emptySymbol={<img src="/img/icons/star-gray.svg" alt="1" />}
                    fullSymbol={<img src="/img/icons/star-blue.svg" alt="1" />}
                  />
                  <span className="ms-2">({props.rating})</span>
                </div>
                <h4 className="text-center color-0 mb-1 mb-xl-2 mb-xxl-0">
                  {serviceTypeLabels[props.serviceTypeId - 1]}
                </h4>
                <h4 className="text-center color-1 mb-1 mb-xl-2 mb-xxl-0">
                  <Link to={props.link}>{props.userName}</Link>
                </h4>
              </div>
            </div>
          </div>

          {!props?.inWishlist && (
            <div className="favoriteContainer">
              <BtnFav id={props?.serviceId} wishlistStatus={props?.wishlistStatus} type={'service'} />
            </div>
          )}

          {Boolean(props.subServices[0].service?.images.length) && (

            <HoverSlider
              urls={props.subServices[0].service.images
                .sort(function (a, b) {
                  return a.order - b.order;
                })
                .map((serviceImage) => `${imgsUrl}/${serviceImage.image}`)}
              to={props.link}
            />


          )}


          <div className="desc fs-11 mt-2 mt-md-3 mt-xxl-0">
            <div className="text">
              <p>{props.description}</p>
            </div>
          </div>
          <div className="serv-list mt-2 mt-md-3 mt-xxl-0">
            {subServicesTypes?.map((i) => (
              <div key={i.id} className={'d-flex flex-column align-items-center justify-content-center'}>
                <div className="serv">{i.name}</div>
                <Price>
                  {i.isFromPrice && 'от '}
                  {format(i.price.toString())} ₽
                </Price>
              </div>
            ))}
          </div>
          <div className="w-100 btns mt-2 mt-md-3 mt-xxl-2 g-4">
            <ShowPhone phone={props.phone} className="fs-11" userId={userId ?? 'notExist'} />
            {props?.isShowMessage && (
              <button type="button" className="d-xxl-block btn btn-1 fs-11 w-100 px-1 mt-2" onClick={onSendMsg}>
                Написать сообщение
              </button>
            )}
            {props.inAddResponse && (
              <Link
                to={`/personal-account/responses/add/${props.userId}`}
                className="btn btn-2 w-100 fs-11 px-1 mt-2"
                state={{
                  labels: props.labels,
                  exp: props.exp,
                  subServices: props.subServices,
                  phone: props.phone,
                  description: props.description,
                  serviceId: props.serviceId,
                  prevUrl: props.prevUrl,
                }}
              >
                Откликнуться
              </Link>
            )}
          </div>
          <div className="d-flex" style={{ justifyContent: 'space-between', width: '100%' }}>
            <div className="d-flex color-0">
              <img src="/img/icons/eye-fill.svg" alt="Просмотры" />
              <span className="d-none d-md-block ms-2">Просмотры:</span>
              <span className="w-100 ms-1">{props.viewsCount}</span>
            </div>
            <div className="d-flex color-2 ms-4">{props.subServices[0].service.createdAtForUser}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserCard;
