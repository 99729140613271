import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getCatalog, getDistricts } from '../API/catalog';
import { getTypesEstate } from '../API/typesEstate';
import CustomSelect from '../components/CustomSelect/CustomSelect';
import Loader from '../components/Loader';
import YMapContainer from '../components/YMapContainer';
import env from '../config/env';
import { onSelectHandler } from '../helpers/collectDataFromForm';
import useDebounce from '../hooks/debounce';
import usePagination from '../hooks/pagination';
import useSearchForm from '../hooks/useSearchForm';
import useUpdateSizeSecond from '../hooks/useUpdateSizeSecond';

import { isEmpty } from '../helpers/isEmpty';

import CardFav from '../components/Card/CardFav';
import useIsMobile from '../hooks/useIsMobile';
import PageWithBackLayout from '../layouts/PageWithBack.Layout';

import { ReactComponent as MarkSVG } from '../assets/icons/map/mark.svg';
import { ReactComponent as SearchSVG } from '../assets/icons/search.svg';

import styled from 'styled-components';

import { onCheckboxHandler, onInputHandler, onMultiCheckboxHandler } from '../helpers/collectDataFromForm';

import useWindowDimensions from '../hooks/useWindowDimensions';
import { typesEstateElts } from '../components/EstateFilters';
import CustomModal from '../components/CustomModal';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useAccessToken, useCurrentUser } from '../store/reducers';
import { ReactComponent as BlackMarkerBigIcon } from '../assets/icons/blackMarkerBig.svg';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertActions from '../store/actions/alert';
import { createBlackMarker } from '../API/blackMarker';
import { Tooltip } from 'bootstrap';

export const getEstateTypeByEstateId = (id) => {
  if (id < 3) return 1;
  else if (id < 6) return 2;
  else if (id < 11) return 3;
  else if (id < 13) return 4;
  else return 5;
};
export const estateTypeToEstateId = {
  1: [1, 2],
  2: [3, 4, 5],
  3: [6, 7, 8, 9, 10],
  4: [11, 12],
  5: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
};

const Form = styled.form`
  display: flex;
  border: 1.13468px solid #bdbdbd;
  border-radius: 5.6734px;
  div {
    width: 100%;
  }
  input {
    border: none;
  }
`;

const ScrollFilterContent = styled.div`
  label {
    margin-left: 10px;
  }
  fieldset div {
    display: flex;
    margin-bottom: 10px;
  }
  fieldset {
    margin-bottom: 10px;
  }
`;

const CatalogHeader = ({ isMobile }) => {
  return (
    <h1 className="catalog__title" style={{ textAlign: isMobile ? 'center' : 'left' }}>
      Каталог объявлений
    </h1>
  );
};

const CatalogFiltersPanel = ({
  setIsShowMap,
  onSelectHandler,
  filters,
  setFilters,
  isMobile,
  isFilterView,
  setIsFilterView,
  setSearch,
  search,
  sugData,
  onSearch,
  foundCount,
  onResetFilters,
  onApplyFilters,
  callback,
  searchParams,
  searchParamsTransactionType,
  additionalFilters,
  setAdditionalFilters,
  catalogPag,
  setEstateIds,
}) => {
  const { height: WinHeight } = useWindowDimensions();

  const setAction = (e) => {
    const value = e.target.value;
    onSelectHandler(value, 'transactionType', setFilters);
    catalogPag.setStartingPage(1);
    catalogPag.setCurrentPage(1);
  };

  const setCategory = (e) => {
    const value = Number(e.target.value);
    onSelectHandler(value, 'typesEstate', setFilters);
    onSelectHandler(estateTypeToEstateId[value], 'estateIds', setFilters);
    getTypesEstate()
      .then((response) => response.find((type) => type.id === value))
      .then((result) => result && result.estates.map((item) => ({ title: item.name, value: item.id })))
      .then((estateIds) => {
        setEstateIds(estateIds);
      });
    catalogPag.setStartingPage(1);
    catalogPag.setCurrentPage(1);
  };

  const update = (e) => {
    // e.preventDefault(e);
    // onApplyFilters();
    // callback();
    // setIsFilterView(false);
  };

  // const [isShowModalFilters, setIsShowModalFilters] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const token = useAccessToken();
  const currentUser = useCurrentUser();

  const tooltip = useRef();
  const tooltipRef = useRef();
  useEffect(() => {
    if (!tooltip.current && tooltipRef.current) {
      tooltip.current = new Tooltip(tooltipRef.current, {
        title: `Функционал позволяет уведомлять об интересующих объектах по определенному адресу`,
        placement: 'top',
        trigger: 'hover',
        delay: { show: 300, hide: 0 },
      });
    }
    if (!search) {
      tooltip.current?.enable();
    } else {
      tooltip.current?.disable();
    }
  }, [search]);

  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);
  const onBlackMarker = () => {
    if (currentUser?.id) {
      if (search) {
        createBlackMarker(axiosPrivate, search, currentUser?.id, token)
          .then((res) => {
            if (res?.isExist) setAlert('success', true, 'Черная метка уже была создана');
            else setAlert('success', true, 'Черная метка успешно создана');
          })
          .catch(() => {
            setAlert('danger', true, 'Произошла ошибка сервера!');
          });
      } else {
        setAlert('danger', true, 'Введите адрес для создания черной метки');
      }
    } else {
      setAlert('danger', true, 'Функция доступна только зарегистрированным пользователям');
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-between align-items-start gap-2 mb-4"
      style={{ width: '100%', maxWidth: isMobile ? '' : 337 }}
    >
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: isMobile ? 'row' : 'column', rowGap: 10, columnGap: 10, width: '100%' }}
      >
        <div style={{ width: isMobile ? 'auto' : '100%', height: 33 }}>
          <button
            type="button"
            onClick={() => {
              setIsShowMap(true);
            }}
            style={{
              width: isMobile ? '' : '100%',
              height: '100%',

              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
              background: "url('/img/ydx_map.jpg')",
            }}
          >
            <div
              className="d-flex"
              style={{
                width: '100%',
                minWidth: 100,
                padding: 5,
                borderRadius: '10px',
                justifyContent: isMobile ? 'center' : 'left',
                alignItems: 'center',
              }}
            >
              <MarkSVG />
              <span className="ms-2 fs-11 fw-5 white">{isMobile ? 'На карте' : 'Показать на карте'}</span>
            </div>
          </button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: 10 }}>
          <div
            className="d-flex gap-2"
            style={{
              width: '100%',
              padding: 10,
              height: '33px',
              background: '#FFFFFF',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
            }}
          >
            <CustomSelect
              modificator="orderby"
              btnClass="fs-11"
              checkedOptions={[filters.orderBy]}
              options={[
                { title: 'Сначала новые', value: 'desc' },
                { title: 'Сначала старые', value: 'asc' },
              ]}
              mode="values"
              callback={({ value }) => onSelectHandler(value, 'orderBy', setFilters)}
              placeholder={'Сортировать'}
            />
          </div>

          <button
            type="button"
            className="d-flex align-items-center"
            onClick={() => setIsFilterView((prev) => !prev)}
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
              padding: 5,
            }}
          >
            <img src="/img/icons/filter.svg" alt="filter" />
            <span className="ms-2 fs-11 fw-5 color-1">Фильтр</span>
          </button>

          <CustomModal
            isShow={isFilterView}
            setIsShow={setIsFilterView}
            closeButton={true}
            className="modal__map-filters  filter-modal"
            centered={true}
            isMobile={isMobile}
          >
            <div
              style={{
                // width: '100%',
                padding: 10,
                height: 60,
                background: '#FFFFFF',
                // 'box-shadow': '0px 1px 5px rgba(0, 0, 0, 0.25)',
              }}
            >
              <Form
                onSubmit={(e) => {
                  onSearch(e, 'addressOrResidentalComplex', setFilters);
                }}
              >
                <button
                  type="submit"
                  // className="btn btn-1"
                  onClick={(e) => {
                    onSearch(e, 'addressOrResidentalComplex', setFilters);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <SearchSVG />
                </button>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: 1, height: 35 }}>
                    <AddressSuggestions
                      token={env.DADATA_TOKEN}
                      onChange={(e) => {
                        setSearch(e.value);
                      }}
                      // containerClassName="catalog__search"
                      inputProps={{
                        placeholder: 'Адрес или ЖК',
                        onChange: (e) => {
                          setSearch(sugData.current.textInput.value);
                        },
                        onKeyDown: (e) => {
                          e.keyCode === 13 && onSearch(e, 'addressOrResidentalComplex', setFilters);
                        },
                      }}
                      delay={1000}
                      ref={sugData}
                    />
                  </div>
                  <BlackMarkerBigIcon
                    style={{ height: 22, cursor: 'pointer', opacity: !search ? 0.5 : 1 }}
                    ref={tooltipRef}
                    onClick={onBlackMarker}
                  />
                </div>
              </Form>
            </div>
            <ScrollFilterContent style={{ overflow: 'scroll', height: '100%', maxHeight: 600 - 60 - 80 }}>
              <div style={{ padding: 10 }}>
                <fieldset>
                  <legend>Действие:</legend>
                  <div>
                    <input
                      type="radio"
                      id="buy"
                      name="action"
                      value="1"
                      checked={filters.transactionType == 1}
                      onChange={setAction}
                    />
                    <label for="buy">Купить</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="rent"
                      name="action"
                      value="0"
                      checked={filters.transactionType == 0}
                      onChange={setAction}
                    />
                    <label for="rent">Снять</label>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Категория:</legend>
                  {typesEstateElts.map((item) => {
                    return (
                      <div key={`catalog-category-${item.id}`}>
                        <input
                          type="radio"
                          id={`catalog-category-${item.id}`}
                          name="category"
                          value={item.id}
                          checked={filters.typesEstate == item.id}
                          onChange={setCategory}
                        />
                        <label for={`catalog-category-${item.id}`}>{item.name}</label>
                      </div>
                    );
                  })}
                </fieldset>

                {searchParams === 1 && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Объект:</legend>
                      {[
                        { title: 'Квартира', id: 1 },
                        { title: 'Комната', id: 2 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="estateIds"
                              value="1"
                              checked={additionalFilters?.estateIds?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('estateIds', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Тип объекта:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="1room"
                          checked={additionalFilters?.estateTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('estateTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Новостройка</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="2room"
                          checked={additionalFilters?.estateTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('estateTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Вторичка</span>
                      </label>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Тип жилья:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="1room"
                          checked={additionalFilters?.houseTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Квартира</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="2room"
                          checked={additionalFilters?.houseTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Апартаменты</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Количество комнат:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="1room"
                          checked={additionalFilters?.roomTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">1 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="2room"
                          checked={additionalFilters?.roomTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">2 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="3room"
                          checked={additionalFilters?.roomTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">3 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="4room"
                          checked={additionalFilters?.roomTypes?.includes(4) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 4, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">4 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="5room"
                          checked={additionalFilters?.roomTypes?.includes(5) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 5, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">5 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="6room"
                          checked={additionalFilters?.roomTypes?.includes(6) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 6, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">5+ комнат</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="6room"
                          checked={additionalFilters?.roomTypes?.includes(7) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 7, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Свободная планировка</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="studio"
                          checked={additionalFilters?.roomTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Студия</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Цена:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 price me-3"
                          value={additionalFilters?.startPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startPrice', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 price"
                          value={additionalFilters?.endPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endPrice', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    {searchParamsTransactionType === 0 && (
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Срок аренды:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lease"
                            checked={additionalFilters?.rentalTypes?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('rentalTypes', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Посуточно</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lease"
                            checked={additionalFilters?.rentalTypes?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('rentalTypes', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Краткосрочно</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lease"
                            checked={additionalFilters?.rentalTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('rentalTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Длительная аренда</span>
                        </label>
                      </fieldset>
                    )}
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Ремонт:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Без ремонта</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Косметический</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Евроремонт</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Дизайнерский</span>
                      </label>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Дополнительные характеристики:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasKitchenFurniture"
                          checked={additionalFilters?.hasKitchenFurniture || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Кухонная мебель</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasFurniture"
                          checked={additionalFilters?.hasFurniture || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Мебель в комнатах</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasRefrigerator"
                          checked={additionalFilters?.hasRefrigerator || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Холодильник</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasWashingMachine"
                          checked={additionalFilters?.hasWashingMachine || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Стиральная машина</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasDishWasher"
                          checked={additionalFilters?.hasDishWasher || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Посудомоечная машина</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasTv"
                          checked={additionalFilters?.hasTv || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Телевизор</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasConditioner"
                          checked={additionalFilters?.hasConditioner || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Кондиционер</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasInternet"
                          checked={additionalFilters?.hasInternet || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Интернет</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasBathroom"
                          checked={additionalFilters?.hasBathroom || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Ванна</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasShowerCabin"
                          checked={additionalFilters?.hasShowerCabin || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Душевая кабина</span>
                      </label>
                      {searchParamsTransactionType === 0 && (
                        <>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="withKids"
                              checked={additionalFilters?.withKids || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Можно с детьми</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="withPets"
                              checked={additionalFilters?.withPets || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Можно с животными</span>
                          </label>
                        </>
                      )}
                    </fieldset>
                    <div className="collapse" id="advanced-filter">
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Общая площадь:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-2">От</div>
                          <input
                            type="number"
                            className="w-100 area me-3"
                            value={additionalFilters?.startTotalArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'startTotalArea', true, setAdditionalFilters);
                            }}
                          />
                          <div className="fs-11 me-2">До</div>
                          <input
                            type="number"
                            className="w-100 area"
                            value={additionalFilters?.endTotalArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'endTotalArea', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Жилая площадь:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-2">От</div>
                          <input
                            type="number"
                            className="w-100 area me-3"
                            value={additionalFilters?.startLivingArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'startLivingArea', true, setAdditionalFilters);
                            }}
                          />
                          <div className="fs-11 me-2">До</div>
                          <input
                            type="number"
                            className="w-100 area"
                            value={additionalFilters?.endLivingArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'endLivingArea', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Площадь кухни:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-2">От</div>
                          <input
                            type="number"
                            className="w-100 area me-3"
                            value={additionalFilters?.startKitchenArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'startKitchenArea', true, setAdditionalFilters);
                            }}
                          />
                          <div className="fs-11 me-2">До</div>
                          <input
                            type="number"
                            className="w-100 area"
                            value={additionalFilters?.endKitchenArea || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'endKitchenArea', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Планировка:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="layout"
                            value="Изолированная"
                            checked={additionalFilters?.layoutTypes?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('layoutTypes', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Изолированная</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="layout"
                            value="Смежная"
                            checked={additionalFilters?.layoutTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('layoutTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Смежная</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="layout"
                            value="Свободная"
                            checked={additionalFilters?.layoutTypes?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('layoutTypes', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Свободная</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="layout"
                            value="Смежно-изолированная"
                            checked={additionalFilters.layoutTypes?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('layoutTypes', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Смежно-изолированная</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Санузел:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="bathroom"
                            value="Раздельный"
                            checked={additionalFilters?.WCTypes?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('WCTypes', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Раздельный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="bathroom"
                            value="Совмещенный"
                            checked={additionalFilters?.WCTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('WCTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Совмещенный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="bathroom"
                            value="2 и более"
                            checked={additionalFilters?.WCTypes?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('WCTypes', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">2 и более</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Этаж:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-2">От</div>
                          <input
                            type="number"
                            className="w-100 me-3"
                            value={additionalFilters?.startFloor || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'startFloor', true, setAdditionalFilters);
                            }}
                          />
                          <div className="fs-11 me-2">До</div>
                          <input
                            type="number"
                            className="w-100"
                            value={additionalFilters?.endFloor || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'endFloor', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-baseline mt-2">
                          <label className="ps-2">
                            <input
                              type="checkbox"
                              name="notFirstFloor"
                              checked={additionalFilters?.notFirstFloor || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Не первый</span>
                          </label>
                          <label className="ms-5">
                            <input
                              type="checkbox"
                              name="notLastFloor"
                              checked={additionalFilters?.notLastFloor || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Не последний</span>
                          </label>
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Этажей в доме:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-2">От</div>
                          <input
                            type="number"
                            className="w-100 me-3"
                            value={additionalFilters?.startMaxFloor || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'startMaxFloor', true, setAdditionalFilters);
                            }}
                          />
                          <div className="fs-11 me-2">До</div>
                          <input
                            type="number"
                            className="w-100"
                            value={additionalFilters?.endMaxFloor || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'endMaxFloor', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Балкон/Лоджия:</legend>
                        <label className="ps-2 mb-2">
                          <input
                            type="checkbox"
                            name="balcony"
                            value="Балкон"
                            checked={additionalFilters?.balconyTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('balconyTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Балкон</span>
                        </label>
                        <label className="ps-2 mb-2">
                          <input
                            type="checkbox"
                            name="balcony"
                            value="Лоджия"
                            checked={additionalFilters?.balconyTypes?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('balconyTypes', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Лоджия</span>
                        </label>
                        <label className="ps-2 mb-2">
                          <input
                            type="checkbox"
                            name="balcony"
                            value="Несколько"
                            checked={additionalFilters?.balconyTypes?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('balconyTypes', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Несколько</span>
                        </label>
                        <label className="ps-2 mb-2">
                          <input
                            type="checkbox"
                            name="balcony"
                            value="Нет"
                            checked={additionalFilters?.balconyTypes?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('balconyTypes', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Нет</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Тип дома:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Кирпичный"
                            checked={additionalFilters?.houseBuildingTypes?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Кирпичный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Панельный"
                            checked={additionalFilters?.houseBuildingTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Панельный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Монолитный"
                            checked={additionalFilters?.houseBuildingTypes?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Монолитный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Блочный"
                            checked={additionalFilters?.houseBuildingTypes?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Блочный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Деревянный"
                            checked={additionalFilters?.houseBuildingTypes?.includes(4) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 4, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Деревянный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Кирпично-монолитный"
                            checked={additionalFilters.houseBuildingTypes?.includes(5) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 5, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Кирпично-монолитный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Керамзитный"
                            checked={additionalFilters.houseBuildingTypes?.includes(6) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 6, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Керамзитный</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Газоблок"
                            checked={additionalFilters.houseBuildingTypes?.includes(7) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 7, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Газоблок</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Пеноблок"
                            checked={additionalFilters.houseBuildingTypes?.includes(8) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 8, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Пеноблок</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Армолитовые блоки"
                            checked={additionalFilters.houseBuildingTypes?.includes(9) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 9, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Армолитовые блоки</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Сип-панели"
                            checked={additionalFilters.houseBuildingTypes?.includes(10) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 10, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Сип-панели</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="house-type"
                            value="Смешанные"
                            checked={additionalFilters.houseBuildingTypes?.includes(11) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('houseBuildingTypes', 11, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Смешанные</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Год постройки:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-3">От</div>
                          <input
                            type="number"
                            className="me-3"
                            pattern="[0-9]{4}"
                            value={additionalFilters?.yearOfConstruction || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'yearOfConstruction', setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Лифт:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lift"
                            value="Пассажирский"
                            checked={additionalFilters?.elevatorTypes?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('elevatorTypes', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Пассажирский</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lift"
                            value="Грузовой"
                            checked={additionalFilters?.elevatorTypes?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('elevatorTypes', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Грузовой</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="lift"
                            value="Пассажирский/Грузовой"
                            checked={additionalFilters.elevatorTypes?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('elevatorTypes', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Пассажирский/Грузовой</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Высота потолков:</legend>
                        <div className="d-flex align-items-baseline">
                          <div className="fs-11 me-3">От</div>
                          <input
                            type="number"
                            className="length me-3"
                            value={additionalFilters?.ceilingHeight || ''}
                            onChange={(e) => {
                              onInputHandler(e, 'ceilingHeight', true, setAdditionalFilters);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Окна:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windows"
                            value="Во двор"
                            checked={additionalFilters.window?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('window', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Во двор</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windows"
                            value="На улицу"
                            checked={additionalFilters.window?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('window', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">На улицу</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windows"
                            value="На солнечную сторону"
                            checked={additionalFilters.window?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('window', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">На солнечную сторону</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windows"
                            value="На две стороны"
                            checked={additionalFilters.window?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('window', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">На две стороны</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Тип окон:</legend>

                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Обычное прямоугольное"
                            checked={additionalFilters.windowType?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Обычное прямоугольное</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="С вращающейся рамой"
                            checked={additionalFilters.windowType?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">С вращающейся рамой</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="В нише"
                            checked={additionalFilters.windowType?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">В нише</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Панорамное"
                            checked={additionalFilters.windowType?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Панорамное</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Французское"
                            checked={additionalFilters.windowType?.includes(4) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 4, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Французское</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Эркер"
                            checked={additionalFilters.windowType?.includes(5) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 5, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Эркер</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="С изогнутым верхом"
                            checked={additionalFilters.windowType?.includes(6) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 6, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">С изогнутым верхом</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Выгнутое"
                            checked={additionalFilters.windowType?.includes(7) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 7, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Выгнутое</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Со скользящей рамой"
                            checked={additionalFilters.windowType?.includes(8) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 8, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Со скользящей рамой</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windowType"
                            value="Со створным переплетом"
                            checked={additionalFilters.windowType?.includes(9) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windowType', 9, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Со створным переплетом</span>
                        </label>
                      </fieldset>

                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Направление по Розе ветров:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Север"
                            checked={additionalFilters.windRoseDirectionType?.includes(0) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 0, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Север</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Юг"
                            checked={additionalFilters.windRoseDirectionType?.includes(1) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 1, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Юг</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Запад"
                            checked={additionalFilters.windRoseDirectionType?.includes(2) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 2, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Запад</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Восток"
                            checked={additionalFilters.windRoseDirectionType?.includes(3) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 3, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Восток</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Северо-восток"
                            checked={additionalFilters.windRoseDirectionType?.includes(4) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 4, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Северо-восток</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Юго-восток"
                            checked={additionalFilters.windRoseDirectionType?.includes(5) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 5, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Юго-восток</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Северо-запад"
                            checked={additionalFilters.windRoseDirectionType?.includes(6) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 6, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Северо-запад</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="windRoseDirectionType"
                            value="Юго-запад"
                            checked={additionalFilters.windRoseDirectionType?.includes(7) || false}
                            onChange={(e) => {
                              onMultiCheckboxHandler('windRoseDirectionType', 7, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-3">Юго-запад</span>
                        </label>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Дополнительно:</legend>
                        <div className="d-flex align-items-baseline flex-wrap">
                          <label className="ps-2">
                            <input
                              type="checkbox"
                              name="hasRamp"
                              checked={additionalFilters?.hasRamp || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Пандус</span>
                          </label>
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Мусоропровод:</legend>
                        <div className="d-flex align-items-baseline flex-wrap">
                          <label className="ps-2">
                            <input
                              type="checkbox"
                              name="hasGarbageСhute"
                              checked={additionalFilters?.hasGarbageСhute || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Есть</span>
                          </label>
                        </div>
                      </fieldset>
                      <fieldset className="mb-4">
                        <legend className="title-font fs-12 fw-6 mb-3">Парковка:</legend>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="hasGroundParking"
                            checked={additionalFilters?.hasGroundParking || false}
                            onChange={(e) => {
                              onCheckboxHandler(e, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Наземная</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="hasUnderGroundParking"
                            checked={additionalFilters?.hasUnderGroundParking || false}
                            onChange={(e) => {
                              onCheckboxHandler(e, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Подземная</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="hasMoreLayerParking"
                            checked={additionalFilters?.hasMoreLayerParking || false}
                            onChange={(e) => {
                              onCheckboxHandler(e, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Многоуровневая</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="hasYardParking"
                            checked={additionalFilters.hasYardParking || false}
                            onChange={(e) => {
                              onCheckboxHandler(e, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Открытая во дворе</span>
                        </label>
                        <label className="ps-2 mb-3">
                          <input
                            type="checkbox"
                            name="hasBarrierParking"
                            checked={additionalFilters.hasBarrierParking || false}
                            onChange={(e) => {
                              onCheckboxHandler(e, setAdditionalFilters);
                            }}
                          />
                          <span className="fs-11 ms-2">Во дворе за шлагбаумом</span>
                        </label>
                      </fieldset>
                    </div>
                  </>
                )}

                {searchParams === 2 && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Объект:</legend>
                      {[
                        { title: 'Дом', id: 3 },
                        { title: 'Дача', id: 4 },
                        { title: 'Таунхаус', id: 5 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="estateIds"
                              value="1"
                              checked={additionalFilters?.estateIds?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('estateIds', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Тип участка:</legend>
                      {[
                        { title: 'СНТ', id: 0 },
                        { title: 'ИЖС', id: 1 },
                        { title: 'ЛПХ', id: 2 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="areaTypeForUser"
                              value="1"
                              checked={additionalFilters?.areaTypes?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('areaTypes', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Цена:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 price me-3"
                          value={additionalFilters?.startPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startPrice', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 price"
                          value={additionalFilters?.endPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endPrice', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Площадь:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 me-3"
                          value={additionalFilters?.startTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startTotalArea', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100"
                          value={additionalFilters?.endTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endTotalArea', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Количество комнат:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="1room"
                          checked={additionalFilters?.roomTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">1 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="2room"
                          checked={additionalFilters?.roomTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">2 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="3room"
                          checked={additionalFilters?.roomTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">3 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="4room"
                          checked={additionalFilters?.roomTypes?.includes(4) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 4, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">4 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="5room"
                          checked={additionalFilters?.roomTypes?.includes(5) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 5, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">5 комнатная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="6room"
                          checked={additionalFilters?.roomTypes?.includes(6) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 6, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">5+ комнат</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="6room"
                          checked={additionalFilters?.roomTypes?.includes(7) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 7, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Свободная планировка</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="rooms"
                          value="studio"
                          checked={additionalFilters?.roomTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('roomTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Студия</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Планировка:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="layout"
                          value="Изолированная"
                          checked={additionalFilters?.layoutTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('layoutTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Изолированная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="layout"
                          value="Смежная"
                          checked={additionalFilters?.layoutTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('layoutTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Смежная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="layout"
                          value="Свободная"
                          checked={additionalFilters?.layoutTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('layoutTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Свободная</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="layout"
                          value="Смежно-изолированная"
                          checked={additionalFilters.layoutTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('layoutTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Смежно-изолированная</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Год постройки:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="me-3"
                          value={additionalFilters?.startCityDistance || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startCityDistance', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Площадь, соток:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 me-3"
                          value={additionalFilters?.startAcres || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startAcres', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100"
                          value={additionalFilters?.endAcres || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endAcres', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Этажей в доме:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 me-3"
                          value={additionalFilters?.startMaxFloor || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startMaxFloor', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100"
                          value={additionalFilters?.endMaxFloor || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endMaxFloor', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Санузел:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="bathroom"
                          value="Раздельный"
                          checked={additionalFilters?.WCTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('WCTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Раздельный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="bathroom"
                          value="Совмещенный"
                          checked={additionalFilters?.WCTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('WCTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Совмещенный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="bathroom"
                          value="2 и более"
                          checked={additionalFilters?.WCTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('WCTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">2 и более</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Балкон/Лоджия:</legend>
                      <label className="ps-2 mb-2">
                        <input
                          type="checkbox"
                          name="balcony"
                          value="Балкон"
                          checked={additionalFilters?.balconyTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('balconyTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Балкон</span>
                      </label>
                      <label className="ps-2 mb-2">
                        <input
                          type="checkbox"
                          name="balcony"
                          value="Лоджия"
                          checked={additionalFilters?.balconyTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('balconyTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Лоджия</span>
                      </label>
                      <label className="ps-2 mb-2">
                        <input
                          type="checkbox"
                          name="balcony"
                          value="Несколько"
                          checked={additionalFilters?.balconyTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('balconyTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Несколько</span>
                      </label>
                      <label className="ps-2 mb-2">
                        <input
                          type="checkbox"
                          name="balcony"
                          value="Нет"
                          checked={additionalFilters?.balconyTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('balconyTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Нет</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Ремонт:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Без ремонта</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Косметический</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Евроремонт</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          checked={additionalFilters?.repairTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('repairTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Дизайнерский</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Хозпостройки:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="outBuildingTypes"
                          value="Гараж"
                          checked={additionalFilters.outBuildingTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('outBuildingTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Гараж</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="outBuildingTypes"
                          value="Баня"
                          checked={additionalFilters.outBuildingTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('outBuildingTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Баня</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="outBuildingTypes"
                          value="Хозпостройки"
                          checked={additionalFilters.outBuildingTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('outBuildingTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Хозпостройки</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Подвал:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasBasements"
                          value="Нет"
                          checked={additionalFilters.hasBasements?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('hasBasements', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Нет</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasBasements"
                          value="Есть"
                          checked={additionalFilters.hasBasements?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('hasBasements', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Есть</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Дополнительно:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasKitchenFurniture"
                          checked={additionalFilters?.hasKitchenFurniture || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Кухонная мебель</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasFurniture"
                          checked={additionalFilters?.hasFurniture || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Мебель в комнатах</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasRefrigerator"
                          checked={additionalFilters?.hasRefrigerator || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Холодильник</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasWashingMachine"
                          checked={additionalFilters?.hasWashingMachine || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Стиральная машина</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasDishWasher"
                          checked={additionalFilters?.hasDishWasher || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Посудомоечная машина</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasTv"
                          checked={additionalFilters?.hasTv || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Телевизор</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasConditioner"
                          checked={additionalFilters?.hasConditioner || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Кондиционер</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasInternet"
                          checked={additionalFilters?.hasInternet || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Интернет</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasBathroom"
                          checked={additionalFilters?.hasBathroom || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Ванна</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasShowerCabin"
                          checked={additionalFilters?.hasShowerCabin || false}
                          onChange={(e) => {
                            onCheckboxHandler(e, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Душевая кабина</span>
                      </label>
                      {searchParamsTransactionType === 0 && (
                        <>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="withKids"
                              checked={additionalFilters?.withKids || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Можно с детьми</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="withPets"
                              checked={additionalFilters?.withPets || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">Можно с животными</span>
                          </label>
                        </>
                      )}
                    </fieldset>
                  </>
                )}

                {searchParams === 3 && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Объект:</legend>
                      {[
                        { title: 'ИЖС', id: 6 },
                        { title: 'ЛПХ', id: 7 },
                        { title: 'СНТ', id: 8 },
                        { title: 'ДНП', id: 9 },
                        { title: 'Промназначения', id: 10 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="estateIds"
                              value="1"
                              checked={additionalFilters?.estateIds?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('estateIds', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Цена:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 price me-3"
                          value={additionalFilters?.startPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startPrice', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 price"
                          value={additionalFilters?.endPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endPrice', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Площадь, соток:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 me-3"
                          value={additionalFilters?.startAcres || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startAcres', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100"
                          value={additionalFilters?.endAcres || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endAcres', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Расстояние до города:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 me-3"
                          value={additionalFilters?.startCityDistance || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startCityDistance', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100"
                          value={additionalFilters?.endCityDistance || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endCityDistance', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>
                  </>
                )}

                {searchParams === 4 && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Объект:</legend>
                      {[
                        { title: 'Паркинг', id: 11 },
                        { title: 'Гараж', id: 12 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="estateIds"
                              value="1"
                              checked={additionalFilters?.estateIds?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('estateIds', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Цена:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 price me-3"
                          value={additionalFilters?.startPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startPrice', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 price"
                          value={additionalFilters?.endPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endPrice', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Общая площадь:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 area me-3"
                          value={additionalFilters?.startTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startTotalArea', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 area"
                          value={additionalFilters?.endTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endTotalArea', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Год постройки</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="me-3"
                          value={additionalFilters?.yearOfConstruction || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'yearOfConstruction', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Охрана:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasSecurity"
                          value="Нет"
                          checked={additionalFilters.hasSecuritys?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('hasSecuritys', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Нет</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="hasSecuritys"
                          value="Есть"
                          checked={additionalFilters.hasSecuritys?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('hasSecuritys', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Есть</span>
                      </label>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Парковка:</legend>
                      <div className="col-9">
                        <div className="d-flex align-items-baseline flex-wrap">
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasGroundParking"
                              checked={additionalFilters.hasGroundParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Наземная</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasUnderGroundParking"
                              checked={additionalFilters.hasUnderGroundParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Подземная</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasMoreLayerParking"
                              checked={additionalFilters.hasMoreLayerParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Многоуровневая</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasBarrierParking"
                              checked={additionalFilters.hasBarrierParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Во дворе за шлагбаумом</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasYardParking"
                              checked={additionalFilters.hasYardParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Открытая во дворе</span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    {/* </div> */}
                  </>
                )}
                {searchParams === 5 && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Объект:</legend>
                      {[
                        { title: 'Апартаменты', id: 13 },
                        { title: 'Офис', id: 14 },
                        { title: 'Склад', id: 15 },
                        { title: 'Гостиница', id: 16 },
                        { title: 'Медицинский центр', id: 17 },
                        { title: 'Свободного назначения', id: 18 },
                        { title: 'Производство', id: 19 },
                        { title: 'Автосервис', id: 20 },
                        { title: 'Розничный магазин', id: 21 },
                        { title: 'Готовый бизнес', id: 22 },
                        { title: 'Торговая площадь', id: 23 },
                        { title: 'Общепит', id: 24 },
                        { title: 'Здание целиком', id: 25 },
                        { title: 'Многоквартирный жилой дом', id: 26 },
                      ].map((input) => {
                        return (
                          <label className="ps-2 mb-3" key={input.id}>
                            <input
                              type="checkbox"
                              name="estateIds"
                              value="1"
                              checked={additionalFilters?.estateIds?.includes(input.id) || false}
                              onChange={(e) => {
                                onMultiCheckboxHandler('estateIds', input.id, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-3">{input.title}</span>
                          </label>
                        );
                      })}
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Цена:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 price me-3"
                          value={additionalFilters?.startPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startPrice', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 price"
                          value={additionalFilters?.endPrice || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endPrice', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Общая площадь:</legend>
                      <div className="d-flex align-items-baseline">
                        <div className="fs-11 me-2">От</div>
                        <input
                          type="number"
                          className="w-100 area me-3"
                          value={additionalFilters?.startTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'startTotalArea', true, setAdditionalFilters);
                          }}
                        />
                        <div className="fs-11 me-2">До</div>
                        <input
                          type="number"
                          className="w-100 area"
                          value={additionalFilters?.endTotalArea || ''}
                          onChange={(e) => {
                            onInputHandler(e, 'endTotalArea', true, setAdditionalFilters);
                          }}
                        />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Тип здания:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          value="no repair"
                          checked={additionalFilters?.buildingTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('buildingTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Бизнес-центр</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          value="repair 1"
                          checked={additionalFilters?.buildingTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('buildingTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Торговый центр</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          value="repair 2"
                          checked={additionalFilters?.buildingTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('buildingTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Административное здание</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          value="repair 3"
                          checked={additionalFilters?.buildingTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('buildingTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Жилой дом</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="repair"
                          value="repair 3"
                          checked={additionalFilters?.buildingTypes?.includes(4) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('buildingTypes', 4, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-3">Другое</span>
                      </label>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Тип дома:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Кирпичный"
                          checked={additionalFilters?.houseBuildingTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Кирпичный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Панельный"
                          checked={additionalFilters?.houseBuildingTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Панельный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Монолитный"
                          checked={additionalFilters?.houseBuildingTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Монолитный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Блочный"
                          checked={additionalFilters?.houseBuildingTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Блочный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Деревянный"
                          checked={additionalFilters?.houseBuildingTypes?.includes(4) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 4, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Деревянный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Кирпично-монолитный"
                          checked={additionalFilters.houseBuildingTypes?.includes(5) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 5, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Кирпично-монолитный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Керамзитный"
                          checked={additionalFilters.houseBuildingTypes?.includes(6) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 6, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Керамзитный</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Газоблок"
                          checked={additionalFilters.houseBuildingTypes?.includes(7) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 7, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Газоблок</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Пеноблок"
                          checked={additionalFilters.houseBuildingTypes?.includes(8) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 8, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Пеноблок</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Армолитовые блоки"
                          checked={additionalFilters.houseBuildingTypes?.includes(9) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 9, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Армолитовые блоки</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Сип-панели"
                          checked={additionalFilters.houseBuildingTypes?.includes(10) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 10, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Сип-панели</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="house-type"
                          value="Смешанные"
                          checked={additionalFilters.houseBuildingTypes?.includes(11) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('houseBuildingTypes', 11, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Смешанные</span>
                      </label>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Лифт:</legend>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="lift"
                          value="Нет"
                          checked={additionalFilters.elevatorTypes?.includes(0) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('elevatorTypes', 0, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Нет</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="lift"
                          value="Пассажирский"
                          checked={additionalFilters.elevatorTypes?.includes(1) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('elevatorTypes', 1, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Пассажирский</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="lift"
                          value="Грузовой"
                          checked={additionalFilters.elevatorTypes?.includes(2) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('elevatorTypes', 2, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Грузовой</span>
                      </label>
                      <label className="ps-2 mb-3">
                        <input
                          type="checkbox"
                          name="lift"
                          value="Пассажирский/грузовой"
                          checked={additionalFilters.elevatorTypes?.includes(3) || false}
                          onChange={(e) => {
                            onMultiCheckboxHandler('elevatorTypes', 3, setAdditionalFilters);
                          }}
                        />
                        <span className="fs-11 ms-2">Пассажирский/грузовой</span>
                      </label>
                    </fieldset>
                    <fieldset className="mb-4">
                      <legend className="title-font fs-12 fw-6 mb-3">Парковка:</legend>
                      <div className="col-9">
                        <div className="d-flex align-items-baseline flex-wrap">
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasGroundParking"
                              checked={additionalFilters.hasGroundParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Наземная</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasUnderGroundParking"
                              checked={additionalFilters.hasUnderGroundParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Подземная</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasMoreLayerParking"
                              checked={additionalFilters.hasMoreLayerParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Многоуровневая</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasBarrierParking"
                              checked={additionalFilters.hasBarrierParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Во дворе за шлагбаумом</span>
                          </label>
                          <label className="ps-2 mb-3">
                            <input
                              type="checkbox"
                              name="hasYardParking"
                              checked={additionalFilters.hasYardParking || false}
                              onChange={(e) => {
                                onCheckboxHandler(e, setAdditionalFilters);
                              }}
                            />
                            <span className="fs-11 ms-2">Открытая во дворе</span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </>
                )}

                {searchParams === 1 && (
                  <button
                    className="btn-filter color-1 mx-auto btn-collapse mb-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#advanced-filter"
                    aria-expanded="false"
                  >
                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.4286 -3.0664e-08L9 6.25L2.57143 -1.83984e-07L1.04343e-07 1.25L9 10L18 1.25L15.4286 -3.0664e-08Z"
                        fill="#146492"
                      />
                    </svg>
                    <span className="ms-3 fs-11 fw-5" />
                  </button>
                )}
              </div>
            </ScrollFilterContent>
            <div style={{ paddingTop: 10 }}>
              <div className="d-flex  align-items-baseline align-items-lg-start justify-content-between mb-3">
                <button
                  className="btn btn-1  color-1   fw-5"
                  onClick={(e) => {
                    e.preventDefault();
                    onApplyFilters();
                  }}
                >
                  Найденно {foundCount} объявлений
                </button>
                <button type="button" onClick={onResetFilters} className="color-1   fw-5">
                  Очистить фильтр
                </button>
              </div>
            </div>

            {/* <div
              className={`${isMobile ? 'fs-09' : 'fs-11'} d-flex justify-content-between align-items-center`}
              style={{
                'flex-direction': `${isMobile ? 'row' : 'column'}`,
                background: '#FFF',
                padding: 10,
                gap: 10,
                height: 100,
              }}
            >
              <button
                type="reset"
                className="btn btn-rad2 w-100"
                style={{
                  color: Object.keys(additionalFilters)?.length ? '#FFF' : '#000',
                  background: Object.keys(additionalFilters)?.length ? '#146492' : 'rgb(204, 204, 204)',
                }}
                onClick={onResetFilters}
              >
                Сбросить фильтры
              </button>
              <div className="fs-11 mt-1">Найденно {foundCount} объявлений</div>
            </div> */}
          </CustomModal>
          {/* <div
            style={{
              width: isMobile ? '100%' : 340,
              height: isMobile ? WinHeight - 100 - 50 - 64 - 25 * 2 - 41 : '60vh',
              // maxHeight:400,
              position: 'absolute',
              top: isMobile ? 100 : 150,
              left: isMobile && 0,
              background: '#FFF',
              display: isFilterView ? 'block' : 'none',
              'z-index': '100',
              fontSize: 'large',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            }}
            onClick={(e) => e.stopPropagation()}
          >

          </div> */}
        </div>
      </div>
    </div>
  );
};

const CatalogTiles = ({ catalogData, nextPage, view, routeName, getFetchData, isMobile, isLoading }) => {
  return (
    <div className="row">
      <InfiniteScroll
        dataLength={catalogData.catalog.length}
        next={() => getFetchData()}
        hasMore={nextPage <= catalogData.last_page}
        loader={
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        }
      >
        <div
          className={view === 'tiled' ? 'row row-cols-sm-1 row-cols-lg-2 g-2 g-md-3 g-lg-4' : 'row g-2 g-md-3 g-lg-4'}
        >
          {isLoading ? (
            <div className="p-5 w-100 d-flex justify-content-center">
              <Loader color="#146492" />
            </div>
          ) : catalogData?.catalog?.length ? (
            catalogData?.catalog?.map((catalogItem, i) => {
              return (
                <div key={catalogItem.id} className="d-flex">
                  <CardFav
                    ads={catalogItem}
                    reportStatus={catalogItem.reportStatus}
                    type={!isMobile && 'as-a-list'}
                    isVip={catalogItem.isVip}
                    totalArea={catalogItem.totalArea}
                    isHot={catalogItem.isHot}
                    title={catalogItem.title}
                    price={catalogItem.price}
                    transactionType={catalogItem.transactionType}
                    residentalComplex={catalogItem.residentalComplex}
                    residentalComplexForUser={catalogItem.residentalComplexForUser}
                    address={catalogItem.address}
                    metro={catalogItem.metro}
                    text={catalogItem.description}
                    date={catalogItem.createdAtForUser}
                    id={catalogItem.id}
                    uuid={catalogItem.uuid}
                    user={catalogItem.user}
                    communalPrice={catalogItem.communalPrice}
                    pledge={catalogItem.pledge}
                    commissionForUser={catalogItem.commissionForUser}
                    prepaymentTypeForUser={catalogItem.prepaymentTypeForUser}
                    rentalTypeForUser={catalogItem.rentalTypeForUser}
                    wishlistStatus={catalogItem.wishlistStatus}
                    userAvatar={catalogItem.user?.avatar}
                    routeName={routeName}
                    estateName={catalogItem?.estate?.name}
                    buildingTypeForUser={catalogItem?.buildingTypeForUser}
                    realEstateTypeForUser={catalogItem?.estate?.realEstateTypeForUser}
                    acres={catalogItem?.acres}
                    viewsCount={catalogItem.viewsCount}
                    showUser={isMobile}
                    withSide={isMobile}
                    link={undefined}
                    isLargeFont={true}
                    isLast={catalogData?.catalog?.length - 1 === i}
                  />
                </div>
              );
            })
          ) : (
            <h6 className="m-auto p-5 text-center">Объявлений нет</h6>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

const Catalog = ({ routeName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useUpdateSizeSecond('991px');
  const [searchParams, setSearchParams] = useSearchParams();
  const [districts, setDistricts] = useState([]);
  const initialFilters = {
    transactionType: +searchParams.get('transactionType'),
    typesEstate: +searchParams.get('typesEstate'),
    districts: [],
    orderBy: 'asc',
    estateIds: estateTypeToEstateId[+searchParams.get('typesEstate')],
  };
  const filtersStorage = !!sessionStorage.getItem('filters') ? JSON.parse(sessionStorage.getItem('filters')) : null;
  const [filters, setFilters] = useState(
    !!filtersStorage &&
      +searchParams.get('transactionType') === filtersStorage.transactionType &&
      +searchParams.get('typesEstate') === filtersStorage.typesEstate
      ? JSON.parse(sessionStorage.getItem('filters'))
      : initialFilters
  );
  const [additionalFilters, setAdditionalFilters] = useState({});

  const [estateIds, setEstateIds] = useState([]);
  const [catalogData, setCatalogData] = useState({
    isLoaded: false,
    foundCount: 0,
    catalog: [],
    last_page: 0,
  });
  const [isShowMap, setIsShowMap] = useState(false);
  const userId = useSelector((state) => state.currentUser?.id);
  const { search, setSearch, onSearch } = useSearchForm('');
  const selectedCity = useSelector((state) => state?.selectedCity);
  const debouncedFilters = useDebounce(filters, 500);
  const sugData = useRef(null);
  const [nextPage, setNextPage] = useState(2);
  const catalogPag = usePagination(6);

  useEffect(() => {
    const filters = sessionStorage.getItem('filters');
    if (!!filters) {
      const filtersObj = JSON.parse(filters);
      if (filtersObj.typesEstate === +searchParams.get('typesEstate')) setFilters(filtersObj);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [catalogPag.currentPage]);

  useLayoutEffect(() => {
    getTypesEstate()
      .then((response) => response.find((type) => type.id === filters.typesEstate))
      .then((result) => result && result.estates.map((item) => ({ title: item.name, value: item.id })))
      .then((estateIds) => {
        const filters = filtersStorage;
        console.log({ estateIds });
        setEstateIds(estateIds.map((item) => item.value));
        estateIds?.length &&
          onSelectHandler(
            filters && !!filters.estateIds && filters.typesEstate === +searchParams.get('typesEstate')
              ? filters.estateIds
              : estateIds.map((item) => item.value),
            'estateIds',
            setFilters
          );
      });
  }, []);

  const getFetchData = async () => {
    // setIsLoading(true);
    getCatalog(nextPage, 6, userId, selectedCity === 'Вся Россия' ? '' : selectedCity, debouncedFilters)
      .then((response) => {
        setNextPage(nextPage + 1);
        setCatalogData({
          isLoaded: true,
          meta: response.body,
          last_page: response.body.meta.last_page,
          catalog: [...catalogData.catalog, ...response.body.data],
          foundCount: response.body.meta.total,
        });
      })
      .catch((err) => {
        return err.message;
      });
    // setIsLoading(false);
  };
  //selectedCity === 'Вся Россия' ? '' : selectedCity
  useEffect(() => {
    setIsLoading(true);
    selectedCity &&
      catalogPag.currentPage &&
      getCatalog(
        catalogPag.currentPage,
        6,
        userId,
        selectedCity === 'Вся Россия' ? '' : selectedCity,
        debouncedFilters
        // catalogData.catalog.length
        //   ? debouncedFilters
        //   : {
        //       ...debouncedFilters,
        //       estateId: 1,
        //     }
      )
        .then((response) => {
          setNextPage(2);
          setCatalogData({
            isLoaded: true,
            meta: response.body,
            catalog: response.body.data,
            foundCount: response.body.meta.total,
            last_page: response.body.meta.last_page,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          return err.message;
        });
  }, [userId, selectedCity, debouncedFilters]);

  useEffect(
    () =>
      setSearchParams({
        transactionType: filters.transactionType,
        typesEstate: filters.typesEstate,
      }),
    [filters.transactionType, filters.typesEstate]
  );

  const onResetFilters = () => {
    sessionStorage.removeItem('filters');
    setNextPage(2);
    setFilters(initialFilters);
    setAdditionalFilters({});
  };

  const onApplyFilters = () => {
    console.log('onApplyFilters', additionalFilters, filters);
    if (additionalFilters?.estateIds?.length < 1)
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...additionalFilters,
        estateIds: estateTypeToEstateId[filters.typesEstate],
      }));
    else setFilters((prevFilters) => ({ ...prevFilters, ...additionalFilters }));
  };

  useEffect(() => {
    if (!isEmpty(filters)) sessionStorage.setItem('filters', JSON.stringify(filters));
    // setAdditionalFilters((prevAdditionalFilters) => ({
    //   ...prevAdditionalFilters,
    //   ...filters,
    // }));
  }, [filters]);

  useEffect(() => {
    onApplyFilters();
  }, [additionalFilters]);

  useEffect(() => {
    setIsShowMap(false);

    selectedCity &&
      getDistricts(selectedCity)
        .then((result) => result && result.map((item) => ({ title: item.name, value: item.id })))
        .then((result) => setDistricts(result));
  }, [selectedCity]);

  const searchButton = useCallback(() => {
    catalogPag.setStartingPage(1);
    catalogPag.setCurrentPage(1);
  }, []);

  const isMobile = useIsMobile(768);

  const [isFilterView, setIsFilterView] = useState(false);

  return (
    <PageWithBackLayout onClick={() => isFilterView && setIsFilterView(false)}>
      <div className={`container catalog`} style={{ width: '100%' }}>
        <CatalogHeader isMobile={isMobile} />
        <CatalogFiltersPanel
          setIsShowMap={setIsShowMap}
          onSelectHandler={onSelectHandler}
          isMobile={isMobile}
          filters={filters}
          setFilters={setFilters}
          isFilterView={isFilterView}
          setIsFilterView={setIsFilterView}
          setSearch={setSearch}
          search={search}
          onSearch={onSearch}
          sugData={sugData}
          foundCount={catalogData.foundCount}
          onResetFilters={onResetFilters}
          onApplyFilters={onApplyFilters}
          callback={searchButton}
          searchParams={+searchParams.get('typesEstate')}
          searchParamsTransactionType={+searchParams.get('transactionType')}
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilters}
          catalogPag={catalogPag}
          setEstateIds={setEstateIds}
        />
      </div>
      {isShowMap && (
        <YMapContainer
          // catalog={catalogData.catalog}
          isShowMap={isShowMap}
          filters={filters}
          setFilters={setFilters}
          onResetFilters={onResetFilters}
          onApplyFilters={onApplyFilters}
          foundCount={catalogData.foundCount}
          searchParams={+searchParams.get('typesEstate')}
          searchParamsTransactionType={+searchParams.get('transactionType')}
          closeAction={setIsShowMap}
        />
      )}
      <div className={`container catalog`} style={{ width: '100%' }}>
        <CatalogTiles
          getFetchData={getFetchData}
          catalogData={catalogData}
          nextPage={nextPage}
          view={view}
          routeName={routeName}
          isMobile={isMobile}
          isLoading={isLoading}
        />
      </div>
    </PageWithBackLayout>
  );
};
export default Catalog;
