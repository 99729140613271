import styled, { css, keyframes } from 'styled-components';
import { getTransition } from '../../../../Styles/utilites';
import { DELAY } from '../Content';

const fadeIn = keyframes`
0%{
    transform: translate3d(0, -100%, 0);
    opacity: 0;
}
    100%{
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`;
export const ProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  padding: 10px 16px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  gap: 5px;
  background: linear-gradient(to bottom, hsla(0, 0%, 0%, 0.8) 0%, hsla(0, 0%, 0%, 0) 100%);
  animation: ${fadeIn} 0.3s forwards ease;
`;

type ProgressBarProps = {
  $isWhite?: boolean;
  $isGrey?: boolean;
};
export const ProgressBar = styled.div<ProgressBarProps>`
  flex-grow: 1;
  height: 2px;
  border-radius: 0.5rem;
  background-color: ${({ theme, $isWhite }) => ($isWhite ? theme.colors.revert : theme.colors.grey58)};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  ${({ $isGrey }) =>
    $isGrey &&
    css`
      > div {
        opacity: 0;
      }
    `}
`;

type ProgressFillLineProps = {
  $width: number;
  $transitionDelay?: number;
};
export const ProgressFillLine = styled.div<ProgressFillLineProps>`
  width: ${({ $width }) => $width * 100}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.revert};
  ${({ $transitionDelay }) =>
    (typeof $transitionDelay === 'undefined' || $transitionDelay > 0) &&
    css`
      ${getTransition($transitionDelay ? $transitionDelay : DELAY, 'width', 'linear')}
    `}
`;
