import React, { useEffect, useRef, useState } from 'react';
import { useTimeout } from '../../../../hooks/useTimeout';
import * as S from './styles';
import { Media } from '../Content';

type ProgressProps = {
  content: Media[];
  current: number;
};
export const Progress = ({ content, current }: ProgressProps) => {
  const [back, setBack] = useState(false);
  const reset = useRef(false);
  const [local, setLocal] = useState(-1);
  const [setTimeToLocal, clearLocal] = useTimeout(() => setLocal(current), 10);
  const [set, clear] = useTimeout(() => {
    setBack(false);
    reset.current = false;
    setLocal(current);
  }, 100);

  useEffect(() => {
    if (current === 0 && local > 0 && !back) {
      setBack(true);
      reset.current = true;
      set();
    } else if (current < local) {
      setBack(true);
      reset.current = true;
      set();
    } else setTimeToLocal();
  }, [current]);

  return (
    <S.ProgressWrapper>
      {[...Array(content.length)].map((_, i) => (
        <S.ProgressBar key={i} $isWhite={i < local} $isGrey={i > local}>
          <S.ProgressFillLine
            $width={i <= local && !reset.current ? 1 : 0}
            $transitionDelay={i < local || i > local || reset.current ? 0 : content[local].duration || undefined}
          />
        </S.ProgressBar>
      ))}
    </S.ProgressWrapper>
  );
};
