export const fields = {
  isInValidEstateId: false,
  isInValidTransactionType: false,
  isInValidRentalTypes: false,
  isInValidAddress: false,
  isInValidHouseType: false,
  isInValidRoomType: false,
  isInValidTotalArea: false,
  isInValidLivingArea: false,
  isInValidKitchenArea: false,
  isInValidFloor: false,
  isInValidMaxFloor: false,
  isInValidDescription: false,
  isInValidImage: false,
  isInvalidVideo: false,
  isInValidPrice: false,
  isInValidEstateTypeId: false,
  isInValidYear: false,
  isInValidCeilingHeight: false,
  isInValidPrepayment: false,
  isInValidCommission: false,
  isInValidCadastralNumber: false,
  isInValidLandCadastralNumber: false,
  isInValidAcres: false,
  isInValidLocationType: false,
  isInValidBuildingType: false,
  isInValidParkingType: false,
};
