import React, { MutableRefObject, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/nav/calendar.svg';
import { ReactComponent as Email } from '../../../assets/icons/nav/email.svg';
import { ReactComponent as Favorite } from '../../../assets/icons/nav/favorite.svg';
import { ReactComponent as Jingle } from '../../../assets/icons/nav/jingle.svg';
import { ReactComponent as Main } from '../../../assets/icons/nav/main.svg';
import { ReactComponent as MobLogo } from '../../../assets/icons/nav/mobLogo.svg';
import { ReactComponent as PartnerIcon } from '../../../assets/icons/profile/parther.svg';
import ava from '../../../assets/imgs/ava.png';
import { useClickOutside } from '../../../hooks/useClickOutside';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { RootState } from '../../../store/store';
import * as S from './styles';

interface StarRatingWithNumberProps {
  rating: number;
}
const StarRatingWithNumber: React.FC<StarRatingWithNumberProps> = ({ rating }) => {
  const roundedRating = Math.round(rating);
  return (
    <S.CustomRatingWrapper>
      <img src="/img/icons/star-blue.svg" alt="Рейтинг" />
      <S.CustomRatingSpan>{roundedRating}</S.CustomRatingSpan>
    </S.CustomRatingWrapper>
  );
};
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
type HeaderComponentProps = {};
export const HeaderComponent = ({}: HeaderComponentProps) => {
  const { conversationCount, favs, currentUser } = useSelector((state: RootState) => ({
    conversationCount: state.conversationCount,
    favs: state.favoriteCount,
    currentUser: state.currentUser,
  }));
  const favsCount = Object.values(favs)
    .map((val) => Number(val))
    .reduce((val, acc) => val + acc);
  const { width } = useWindowDimensions();
  const [value, onChange] = useState<Value>(new Date());
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const refCalendar = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(() => setOpenCalendar(false), refCalendar);

  return (
    <S.Header>
      <div className="container">
        <Link to="/" className="me-lg-auto">
          {width < 768 ? <MobLogo /> : <img src={'/img/icons/logo.svg'} alt="Логотип сайта" className="logo" />}
        </Link>
        <S.LinksWrapper>
          <Link to={'/'}>
            <Main />
          </Link>
          <S.HeartLink to="/personal-account/favorites" $red={favsCount > 0}>
            <Favorite  />
            {!!currentUser && !!favsCount && favsCount > 0 && <S.Counter>{favsCount}</S.Counter>}
            {/*<img src="/img/icons/favorite.svg" alt="избранное" />*/}
          </S.HeartLink>
          <Link to="/personal-account/my-messages" className="header_icon"  >
            <Email className="header_icon"  />
            {!!currentUser && !!conversationCount && conversationCount > 0 && (
              <S.Counter>{conversationCount}</S.Counter>
            )}
          </Link>
          <Link to={'/'} className="header_icon" >
            <Jingle className="header_icon"  />
            {!!currentUser && <S.Counter>{2}</S.Counter>}
          </Link>
          <S.CalendarIconWrapper onClick={() => setOpenCalendar((prev) => !prev)} ref={refCalendar}>
            <CalendarIcon className="header_icon" />
            <S.Day>{new Date().getDate()}</S.Day>
            <S.CalendarWrapper $show={isOpenCalendar} onClick={(e) => e.stopPropagation()}>
              <Calendar onChange={onChange} value={value} locale={'ru'} />
            </S.CalendarWrapper>
          </S.CalendarIconWrapper>

          <Link to="/personal-account/profile">
            <span style={{position:'relative'}}>
              <img
                src={
                  !!currentUser && currentUser.avatar
                    ? `${process.env.REACT_APP_PHOTO_URL}/uploads/${currentUser.avatar}`
                    : ava
                }
                alt="Аватар"
              />
              {currentUser?.isPartner ? (
                <PartnerIcon
                  style={{
                    width: '50%',
                    height: '50%',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(20%, 20%)',
                  }}
                />
              ) : null}
            </span>

            <S.RemoveHoverEffect>
              {currentUser ? currentUser.firstName + ' ' + currentUser.lastName : 'Личный кабинет'}
            </S.RemoveHoverEffect>
            {currentUser && <StarRatingWithNumber rating={currentUser?.rating || 0} />}
          </Link>
        </S.LinksWrapper>
      </div>
    </S.Header>
  );
};
