import React from 'react';
import * as S from './styles';
import { ReactComponent as Vk } from '../../../assets/icons/footer/vk.svg';
import { ReactComponent as Tg } from '../../../assets/icons/footer/tg.svg';
import { ReactComponent as QR } from '../../../assets/icons/footer/qr.svg';
import { ReactComponent as AppStore } from '../../../assets/icons/footer/gplay.svg';
import { ReactComponent as Co } from '../../../assets/icons/footer/co.svg';
import { ReactComponent as User } from '../../../assets/icons/footer/user.svg';
import { ReactComponent as Heart } from '../../../assets/icons/nav/favorite.svg';
import { ReactComponent as Email } from '../../../assets/icons/nav/email.svg';
import { ReactComponent as Jingle } from '../../../assets/icons/nav/jingle.svg';
import { Link } from 'react-router-dom';
import { IconCalendar } from '../../IconCalendar/IconCalendar';
import { ReactComponent as Ai } from '../../../assets/icons/nav/ai.svg';

type MainPanelProps = {};

const items = [
  {
    to: '/personal-account/profile',
    icon: <User />,
    name: 'Личный кабинет',
  },
  {
    to: '/personal-account/favorites',
    icon: <Heart />,
    name: 'Избранное',
  },
  {
    to: '/personal-account/my-messages',
    icon: <Email />,
    name: 'Сообщения',
  },
  {
    to: '/',
    icon: <Jingle />,
    name: 'Уведомления',
  },
  {
    to: '/',
    icon: <IconCalendar />,
    name: 'Календарь',
  },
];
const markets = [
  {
    img: <AppStore />,
    name: 'Google Play',
  },
  {
    img: <AppStore />,
    name: 'Google Play',
  },
];
export const MainPanel = ({}: MainPanelProps) => {
  return (
    <S.Wrapper className={'container'}>
      <div className={'d-flex'}>
        <S.FirstBlock>
          <img src={'/img/icons/logo.svg'} alt="Логотип сайта" />
          {/*<S.FlexIcons>*/}
          {/*  <Link to={'/'}>*/}
          {/*    <Vk />*/}
          {/*  </Link>*/}
          {/*  <Link to={'/'}>*/}
          {/*    <Tg />*/}
          {/*  </Link>*/}
          {/*</S.FlexIcons>*/}
          <S.MetaSite>
            <Co /> Все права защищены
            <div>
              <Link to={'policy'}>Политика конфиденциальности</Link>
            </div>
          </S.MetaSite>
        </S.FirstBlock>
      </div>
      <S.Middle>
        <S.Title>Профиль</S.Title>
        <S.ListContainer>
          {items.map(({ icon, name, to }, i) => (
            <S.ListElt to={to} key={i}>
              <div>{icon}</div> <div>{name}</div>
            </S.ListElt>
          ))}
        </S.ListContainer>
      </S.Middle>
      <div>
        <S.Title $align={'left'}>Открыть на телефоне:</S.Title>
        <S.AppsDivider>
          <QR />
          {/*<S.MarketsDivider>*/}
          {/*  {markets.map(({ img, name }, i) => (*/}
          {/*    <S.Market key={i}>*/}
          {/*      {img} <S.MarketTitle>{name}</S.MarketTitle>*/}
          {/*    </S.Market>*/}
          {/*  ))}*/}
          {/*</S.MarketsDivider>*/}
        </S.AppsDivider>
      </div>
      <Link to="/ai">
        <S.AI>
          <Ai />
        </S.AI>
      </Link>
    </S.Wrapper>
  );
};
