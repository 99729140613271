import { useEffect, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';


import useIsMobile from '../hooks/useIsMobile';
import UserHeader from "./User/UserHeader";
import HoverSlider from './HoverSlider/HoverSlider';
import * as SS from './Card/styles';
import ReactDOM from 'react-dom';
import * as S from './Main/Articles/Slider/styles';

const SliderResponces = (props) => {
  const [responses, setResponses] = useState({
    items: [],
    meta: {},
  });

  useEffect(() => {
    props.responses &&
      setResponses({
        items: props.responses.data,
        meta: props.responses.meta,
      });
  }, [props.responses]);

  const isMobile = useIsMobile(768)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const openModalWithImage = (e, imageUrlsLocal) => {
    e.preventDefault();
    e.stopPropagation();
    setImageUrls(imageUrlsLocal)
    if (imageUrlsLocal && imageUrlsLocal.length > 0) {
      setCurrentImgIndex(0);
      setIsModalOpen(true);
    }
  };


  return (
    <S.Wrapper>
      <Swiper
        className="swiper-4"
        spaceBetween={10}
        slidesPerView={isMobile ? 1 : 2.5}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation, Pagination]}
      >
        {responses?.items?.map((response) => {
          const imgsUrl = `${process.env.REACT_APP_PHOTO_URL}`
          const sortedImages = response.images.sort((a, b) => a.order - b.order);
          const urls = sortedImages.map((serviceImage) => `${imgsUrl}${serviceImage.image}`);
          return (
            <SwiperSlide key={response.id}>
              <div
                className="review"
                style={{
                  "border-radius": 10,
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 10,
                  background: 'rgb(243, 243, 243)'
                }}>

                <NavLink to={`/user/${response?.user?.id}`} style={{width:'100%', maxWidth:550}}>
                  <UserHeader user={response.user}  />
                </NavLink>

                <div style={{
                  width: '100%'
                }}>
                  <div style={{
                    padding: 10,
                    marginBottom: 20
                  }}>

                    <div style={{ display: 'flex', flexDirection:'column', gap: 20 }}>
                      <div style={{ position: 'relative', height: 'calc(50% - 10px)' }}>
                        <HoverSlider urls={urls} onClick={(e) => openModalWithImage(e, urls)} />
                        <SS.CustomButtons2>
                          <SS.ItemContainerTotal>
                            <SS.FullScreenImgButt onClick={(e) => openModalWithImage(e, urls)} />
                          </SS.ItemContainerTotal>
                        </SS.CustomButtons2>
                      </div>
                      <div style={{ height: 'calc(50% - 10px)', overflow:'auto' }}>
                        <h3
                          className="text-center text-md-start"
                          style={{
                            fontFamily: "'Montserrat'",
                            fontStyle: 'normal',
                            fontWeight: '600',
                            // fontSize: 20,
                            lineHeight: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            // color: '#198ACB',
                            color: '#000',
                            marginBottom: 20
                          }}
                        >
                          Комментарий
                        </h3>
                        <p style={{
                          fontFamily: "'Montserrat'",
                          fontStyle: 'normal',
                          color: '#6F6F6F',
                          fontSize: 14,
                          fontWeight: '600',
                          marginTop: 20
                        }}>
                          {response?.description}
                        </p>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
        <div className="swiper-button-prev">
          <img src="/img/icons/prev.svg" alt="предыдущий" className="w-100" />
        </div>
        <div className="swiper-button-next">
          <img src="/img/icons/next.svg" alt="следующий" className="w-100" />
        </div>
        <div className="swiper-pagination"></div>
      </Swiper >
      {
        isModalOpen &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 3000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            onClick={() => setIsModalOpen(false)}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" viewBox="0 0 24 24">
                <path d="M18.3 5.71a1 1 0 00-1.41 0L12 10.59l-4.89-4.88a1 1 0 00-1.41 1.41L10.59 12l-4.89 4.89a1 1 0 101.41 1.41L12 13.41l4.89 4.89a1 1 0 001.41-1.41L13.41 12l4.89-4.89a1 1 0 000-1.41z"></path>
              </svg>
            </button>
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'hidden',
              }}
            >
              <img
                src={imageUrls[currentImgIndex]}
                alt="Fullscreen"
                style={{ maxHeight: '80vh', objectFit: 'contain', width: 'auto', height: 'auto' }}
              />
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1))
                }
                style={{
                  position: 'absolute',
                  left: 50,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'<'}
              </button>
              <button
                onClick={() =>
                  setCurrentImgIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0))
                }
                style={{
                  position: 'absolute',
                  right: 50,
                  top: '50%',
                  transform: 'translateY(-50%) scale(2)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '24px',
                }}
              >
                {'>'}
              </button>
            </div>
          </div>,
          document.getElementById('root')
        )
      }</S.Wrapper>
  );
};

export default SliderResponces;
