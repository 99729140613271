import styled from 'styled-components';
import { getTransition } from '../../../Styles/utilites';
import { Link } from 'react-router-dom';
import { aiStyles } from '../MobPanel/styles';

export const Wrapper = styled.div`
  display: none;
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.font};
  }
`;

export const FirstBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  img {
    height: 40px;
    width: auto;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      height: 60px;
    }
  }
`;

export const FlexIcons = styled.div`
  display: flex;
  margin: 2rem 0 2rem;
  a:first-child {
    margin-right: 1rem;
  }
  svg {
    width: 36px;
    height: auto;
    opacity: 1;
    &:hover {
      opacity: 0.6;
    }
    ${getTransition(300, 'opacity')}
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    svg {
      width: 42px;
    }
    a:first-child {
      margin-right: 1.5rem;
    }
  }
`;

export const MetaSite = styled.div`
  color: ${({ theme }) => theme.colors.brandColor};
  text-align: left;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.6;
  margin-top: auto;
  svg {
    width: 18px;
    vertical-align: -5px;
  }
`;

export const Middle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  display: block;
  margin-top: 0.75rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 1.5rem;
  }
`;

export const ListElt = styled(Link)`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brandColor};
  > div:first-child {
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: 21px;
      text,
      path {
        fill: ${({ theme }) => theme.colors.brandColor};
        ${getTransition(300, ['fill'])}
      }
    }
  }
  > div:last-child {
    font-size: 1.15rem;
    font-family: Lato;
  }
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.darkBrand};
    div {
      svg {
        text,
        path {
          fill: ${({ theme }) => theme.colors.darkBrand};
        }
      }
    }
  }
  ${getTransition()}
`;

type TitleProps = {
  $align?: string;
};
export const Title = styled.div<TitleProps>`
  font-size: 1.25rem;
  text-align: ${({ $align }) => ($align ? $align : 'center')};
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

export const AppsDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.75rem;
  flex-direction: column;
  > svg {
    width: 150px;
    height: auto;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 1.5rem;
    flex-direction: row;
  }
`;

export const MarketsDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media screen and ${({ theme }) => theme.media.lg} {
    height: 120px;
    margin-left: 2.5rem;
  }
`;

export const Market = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem 0.75rem;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 0.75rem;
  > svg {
    width: 18px;
    height: auto;
    margin-right: 0.5rem;
  }
  &:hover {
    box-shadow: 0 1px 15px 5px rgba(0, 0, 0, 0.25);
  }
  ${getTransition(300, 'box-shadow')}
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    padding: 0.5rem 0.75rem;
    > svg {
      width: 24px;
    }
  }
`;

export const MarketTitle = styled.div`
  text-align: center;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;

  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 2px;
    font-size: 1.2rem;
  }
`;

export const AI = styled.div`
  ${aiStyles};
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 2rem;
  right: 1.5rem;
  box-shadow: 0 0 5px 5px hsla(${({ theme }) => theme.colors.secondaryValue}, 0.5);
  svg {
    width: 36px;
    height: auto;
    path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
  &:hover {
    box-shadow: 0 0 8px 10px hsla(${({ theme }) => theme.colors.secondaryValue}, 0.5);
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    bottom: 3rem;
    right: 2rem;
  }
`;
