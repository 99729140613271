import actionTypes from './actionTypes';

const setToken = (token: string) => {
  return (dispatch: (arg: any) => void) => {
    dispatch({ type: actionTypes.setToken, payload: token });
  };
};

const resetToken = () => {
  return (dispatch: (arg: any) => void) => {
    dispatch({ type: actionTypes.resetToken });
  };
};

export default { setToken, resetToken };
