'use client';
import styled from 'styled-components';
import { getTransition } from './utilites';

export const PageWrapperSection = styled.section`
  margin: 0 0.75rem;
`;

export const MarginWrapper = styled.div`
  margin-top: 2rem;
`;

export const PageHeading = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-bottom: 2rem;
  }
`;

export const WrapperModal = styled.div`
  text-align: center;
  margin: 3rem 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: auto 1rem;
  }
`;
export const ModalHeading = styled.h5`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
`;

export const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey45};
  font-weight: 500;
  line-height: 1.4;
`;

export const FormInput = styled.input`
  position: relative;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  padding: 0.5em 1.5em;
  background: linear-gradient(270deg, #19172d 29.73%, #36244d 100%);
  &:focus-visible {
    outline: none;
  }
`;

type ModalProps = {
  $isShow: boolean;
};
export const FullScreenModalWrapper = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.order.top};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(${({ theme }) => theme.colors.fontValue}, 0.95);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  opacity: ${({ $isShow }) => ($isShow ? 1 : 0)};
  ${getTransition(300, 'opacity', 'ease-out')}
`;

type CloseProps = {
  $top?: boolean;
};
export const Close = styled.button<CloseProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 1rem;
  top: ${({ $top }) => ($top ? -10 : 3)}px;
  right: -10px;
  z-index: ${({ theme }) => theme.order.two};
  color: ${({ theme }) => theme.colors.revert};
  font-size: 25px;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  width: 28px;
  max-width: 28px;
  height: 28px;
  max-height: 28px;
  border: none;
  padding: 0;
  background: transparent;
  svg {
    width: 20px;
    height: 20px;
    transform: rotateZ(0);
    path {
      fill: ${({ theme }) => theme.colors.revert};
      ${getTransition(300, 'fill')}
    }
  }
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.grey58};
      }
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    svg {
      width: 30px;
      height: 30px;
    }
    top: ${({ $top }) => ($top ? -6 : 0)}px;
    right: 5px;
    width: 34px;
    height: 34px;
  }
`;
