import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import useCustomSelect from '../../hooks/useCustomSelect';
import DefaultDropdown from '../DefaultDropdown';
import * as S from './styles';

// mode = titles(default) -> если в checkedOptions передаются titles(значения отображаемые в dropdown items)
// mode = values -> если в checkedOptions передаются values(значения которые нужны для вычислений и логики, прикрепляются к dropdown items, но не отображаются в UI)
// child -> принимает что будет использовано в качестве дочерней компоненты dropdown
// callback -> сработает в случает события клика по dropdown item, возвращает объект с title и value кликнутого элемента
type CustomSelectProps = {
  mode?: string;
  options: any[];
  [key: string]: any;
};
type DropdownItem = {
  title: string;
  value: number;
};
const CustomSelect = React.memo(
  ({
    mode = 'titles',
    options = [],
    checkedOptions,
    btnClass,
    className,
    title,
    isShow,
    modificator,
    callback,
    child,
    placeholder,
    align,
    initialCount,
  }: CustomSelectProps) => {
    const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
    const [checkedValue, setCheckedValue] = useState<number | null>(null);
    const [checkedTitle, setCheckedTitle] = useState<string | null>(null);
    const { current: DropdownChild } = useRef(child ?? DefaultDropdown);
    const { isShowDropdown, toggleDropdown, closeDropdown, ref } = useCustomSelect(isShow);

    const onSelectItem = (title: any, value: any) => {
      callback && callback({ title, value });
    };

    useEffect(() => {
      options.length &&
        setDropdownItems(
          options.map((option, index) =>
            option.value
              ? option
              : {
                  title: option,
                  value: index,
                }
          )
        );
    }, [options]);

    useEffect(() => {
      if (dropdownItems?.length && checkedOptions?.length === 1) {
        if (mode === 'titles') {
          const title = checkedOptions[0];
          setCheckedTitle(title);
          const foundValue = dropdownItems.find((item) => item.title === title);
          foundValue && setCheckedValue(foundValue.value);
        } else {
          const value = checkedOptions[0];
          setCheckedValue(value);
          const foundTitle = dropdownItems.find((item) => item.value === value);
          foundTitle && setCheckedTitle(foundTitle.title);
        }
      }
    }, [dropdownItems, checkedOptions]);

    return (
      <div
        className={`custom-select ${modificator ? 'custom-select_' + modificator : ''} ${className ?? ''} ${
          isShowDropdown ? 'show' : ''
        }`}
        ref={ref}
      >
        <S.Button
          type="button"
          className={`custom-select__toggle ${modificator ? 'custom-select__toggle_' + modificator : ''} ${
            btnClass ? btnClass : ''
          }`}
          $isShow={isShowDropdown}
          onClick={() => toggleDropdown()}
        >
          {dropdownItems.length ? <div>{title || checkedTitle || placeholder || 'Выберите значение'}</div> : <div>Пусто</div>}
          <Arrow />
        </S.Button>
        <div
          className={`dropdown-list ${modificator ? 'dropdown-list_' + modificator : ''} options`}
          data-alignment={align}
        >
          <DropdownChild
            isShow={isShowDropdown}
            options={dropdownItems}
            onSelectItem={onSelectItem}
            closeDropdown={closeDropdown}
            checkedOptions={checkedOptions}
            mode={mode}
            placeholder={placeholder}
            initialCount={+initialCount}
          />
        </div>
      </div>
    );
  }
);

export default CustomSelect;
