import { NavLink } from 'react-router-dom';
import * as S from './styles';
import { Shadow } from './styles';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import alertActions from '../../../../../store/actions/alert';
import * as SAds from '../../../../Card/styles';

type ArticleProps = {
  imgUrl: string;
  title: string;
  text: string;
  articleUrl: string;
  pathname?: string;
  routeName?: string;
  adRequesites?: string;
};
export default function ArticleTopCard(props: ArticleProps) {
  const dispatch = useDispatch();
  const { setAlert } = bindActionCreators(alertActions, dispatch);

  const onCopy = (e, articleUrl) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard
      .writeText(`https://dakvartira.ru/articles/${articleUrl}`)
      .then(() => setAlert('success', true, 'Ссылка скопирована в буфер обмена'));
  };

  return (
    <NavLink
      className="d-flex"
      style={{ borderRadius: '1rem', overflow: 'hidden', width: '100%', boxShadow: '0px 4px 10px rgb(0 0 0 / 22%)' }}
      to={`/articles/${props.articleUrl}`}
      state={{ prevRoute: props?.pathname, routeName: props?.routeName }}
    >
      <S.Wrapper>
        <div style={{ height: '100%', position: 'relative' }}>
          {/* <img src={props.imgUrl} alt={props.title} /> */}
          <div
            style={{
              background: `url(${props.imgUrl})`,
              width: '100%',
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          ></div>
          <SAds.CopyLinkButton2 onClick={(e) => onCopy(e, props.articleUrl)} style={{ top:10, right: 10, position:'absolute', zIndex:1 }} />
          <Shadow isAd={!!props.adRequesites}>
            {props.adRequesites !== 'null' && props.adRequesites?.trim()?.length ? (
              <S.Title
                style={{
                  position: 'absolute',
                  left: 0,
                  fontSize: 12,
                  padding: 10,
                  textAlign: 'left',
                  top: 0,
                  width: '100%',
                  color: '#44494C',
                  fontWeight: 600,
                }}
              >
                {`Реклама ${props.adRequesites}`}
              </S.Title>
            ) : null}
            <S.Title
              style={{
                position: 'absolute',
                left: 0,
                padding: 10,
                bottom: 0,
                width: '100%',
                color: '#FFF',
                textAlign: 'center',
              }}
            >
              {props.title}
            </S.Title>
          </Shadow>
        </div>
        {/* <div style={{ height: '35%', padding: 20 }}>

          <S.Description dangerouslySetInnerHTML={{ __html: props.text }}></S.Description>
        </div> */}
      </S.Wrapper>
    </NavLink>
  );
}
