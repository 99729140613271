import React from 'react';
import * as S from './styles';

type SlideStoriesProps = {
  image: string;
  title: string;
};
export const SlideStories = ({ title, image }: SlideStoriesProps) => {
  return (
    <S.Wrapper>
      <S.Bg />
      <img src={image} alt={title} />
      <S.Title>{title}</S.Title>
    </S.Wrapper>
  );
};
