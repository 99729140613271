import InfiniteScroll from 'react-infinite-scroll-component';
import { checkPhotoPath } from '../../../helpers/photo';
import Loader from '../../Loader';
import ArticleTopCard from './Slider/Article/ArticleTopCard';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import useIsMobile from '../../../hooks/useIsMobile';

function groupByCount(array: any[], count: number) {
  const newArray = [];
  for (let index = 0; index < array.length; index += count) {
    console.log(index);
    newArray.push(array.slice(index, index + count));
  }
  console.log({ newArray });
  return newArray;
}

const BricksGroup = ({ containerWidth, isMobile, catalogItemGroup, catalogItemGroupIDX }) => {
  const normalCards = catalogItemGroup.slice(0, isMobile ? 2 : 4);

  useEffect(() => {
    console.log(containerWidth);
  }, []);

  return (
    <div
      class="catalogGroupRow"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: catalogItemGroupIDX % 2 === 0 ? 'row' : 'row-reverse',
        marginTop: 0,
      }}
    >
      <div
        style={{
          width:
            catalogItemGroup?.length < 5
              ? isMobile
                ? catalogItemGroup?.length < 3 ? '100%' :'50%'
                : '100%'
              : (containerWidth / (isMobile ? 2 : 3)) * (isMobile ? 1 : 2),
          display: 'flex',
          flexWrap: 'wrap',
        }}
        key={catalogItemGroup.id}
      >
        {normalCards?.map((catalogItem: any, idx) => {
          return (
            <div
              // ref={catalogItemGroupIDX === 0 && idx === 0 ? firstCardRef : null}
              key={catalogItem.id}
              className="d-flex"
              style={{
                height: (containerWidth / (isMobile ? 2 : 3)) * 0.8,
                width: containerWidth / (isMobile ? 2 : 3),
                padding: isMobile ? 6 : 12,
                // height: containerWidth * 0.8,
                // // padding: '1%',
                // width: isMobile
                //   ? catalogItemGroup?.length === 3
                //     ? '100%'
                //     : '50%'
                //   : catalogItemGroupIDX === 0 && idx === 0
                //   ? catalogItemGroup?.length === 5
                //     ? 'calc(50% - 1px)'
                //     : 'calc(33% - 1px)'
                //   : containerWidth,
              }}
            >
              <ArticleTopCard
                imgUrl={checkPhotoPath(catalogItem.image)}
                title={catalogItem.title}
                text={catalogItem.description}
                articleUrl={catalogItem.slug}
                adRequesites={catalogItem.adRequesites}
              />
            </div>
          );
        })}
      </div>
      {catalogItemGroup[isMobile ? 2 : 4] ? (
        <div
          key={catalogItemGroup[isMobile ? 2 : 4].id}
          className="d-flex"
          style={{
            height: (containerWidth / (isMobile ? 2 : 3)) * 0.8 * 2,
            width: containerWidth / (isMobile ? 2 : 3),
            padding: isMobile ? 6 : 12,
          }}
        >
          <ArticleTopCard
            imgUrl={checkPhotoPath(catalogItemGroup[isMobile ? 2 : 4].image)}
            title={catalogItemGroup[isMobile ? 2 : 4].title}
            text={catalogItemGroup[isMobile ? 2 : 4].description}
            articleUrl={catalogItemGroup[isMobile ? 2 : 4].slug}
            adRequesites={catalogItemGroup[isMobile ? 2 : 4].adRequesites}
          />
        </div>
      ) : null}
    </div>
  );
};

type ArticlesProps = {
  catalogData?: any;
  nextPage: number;
  view?: any;
  getFetchData?: any;
  isLoading?: boolean;
};
export default React.memo(function ArticleTiles({
  catalogData,
  nextPage,
  view,
  getFetchData,
  isLoading,
}: ArticlesProps) {
  const isMobile = useIsMobile(768);
  const containerRef = useRef(null);

  return (
    <div
      ref={containerRef}
      className="row"
      style={
        {
          // paddingRight: 'calc(var(--bs-gutter-x)* .5)',
          // paddingLeft: 'calc(var(--bs-gutter-x)* .5)',
          // marginTop: 'var(--bs-gutter-y)',
        }
      }
    >
      <InfiniteScroll
        dataLength={catalogData?.catalog?.length || 0}
        next={() => getFetchData?.()}
        hasMore={nextPage <= catalogData?.last_page}
        loader={
          <div className="p-5 w-100 d-flex justify-content-center">
            <Loader color="#146492" />
          </div>
        }
      >
        <div
          className={view === 'tiled' ? 'row row-cols-sm-1 row-cols-lg-2 g-2 g-md-3 g-lg-4' : 'row g-2 g-md-3 g-lg-4'}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {isLoading ? (
            <div className="p-5 w-100 d-flex justify-content-center">
              <Loader color="#146492" />
            </div>
          ) : catalogData?.catalog?.length ? (
            groupByCount(catalogData?.catalog, isMobile ? 3 : 5)?.map?.(
              (catalogItemGroup: any, catalogItemGroupIDX: number) => {
                return (
                  <BricksGroup
                    key={catalogItemGroup.id}
                    containerWidth={containerRef?.current?.getBoundingClientRect?.()?.width - 12 * 2}
                    isMobile={isMobile}
                    catalogItemGroup={catalogItemGroup}
                    catalogItemGroupIDX={catalogItemGroupIDX}
                  />
                );
              }
            )
          ) : (
            <h6 className="m-auto p-5 text-center">Статей нет</h6>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
});
