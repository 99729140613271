import styled from 'styled-components';
import { ReactComponent as Favorite } from './img/favorite.svg';
import { ReactComponent as Profile } from './img/pa-1.svg';
import { ReactComponent as Announcement } from './img/pa-2.svg';
import { ReactComponent as Touch } from './img/pa-11.svg';
import { ReactComponent as Services } from './img/pa-3.svg';
import { ReactComponent as Bag } from './img/pa-12.svg';
import { ReactComponent as Emails } from './img/email.svg';
import { ReactComponent as Review } from './img/star.svg';
import { ReactComponent as Leave } from './img/leave-icon.svg';
import { ReactComponent as Quest } from './img/question.svg';
import { ReactComponent as Arror } from './img/arror.svg';

export const RoundedCustom = styled.div`
  border-radius: 10px;
`;

export const CustomContentWrapper = styled.div`
  background: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  padding-top: 0px !important;
  margin-left: 0px;
`;

export const CustomWidthDivider = styled.div`
  //flex: 0 0 auto;
  //width: 100%;
  // @media screen and ${({ theme }) => theme.media.md} {
  //   width: 75%;
  // }
  // @media screen and ${({ theme }) => theme.media.lg} {
  //   width: auto;
  //   flex-shrink: 1;
  // }
`;

export const CustomSkeleton = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: -10px;
  width: 100%;
  align-items: center;
  gap: 20px;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    width: 95%;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 70%;
  }
`;

export const ProfilePathDivider = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const DropOpener = styled.button`
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 101;
  width: 100%;
  padding-left: 15px;
  display: flex;
  font-size: 16px;
  color: #198acb;
  padding-bottom: 5px;
  padding-top: 10px;
  box-shadow: 0px 7px 7px 1px rgba(0, 0, 0, 0.1);
`;

export const CustomPTeg = styled.p`
  font-family: Montserrat;
  font-size: 28px;
  color: #198acb;
  text-align: left; //center
  font-weight: 600;
  margin-bottom: 1em;
`;

export const CustomCreateButton = styled.button`
  width: 260px;
  height: 41px;
  background-color: #198acb;
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
  border-radius: 50px;
`;

export const HeartMenu = styled(Favorite)`
  margin-right: 0.75em;
  width: 23px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyProfile = styled(Profile)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyAnnouncement = styled(Announcement)`
  margin-right: 1em;
  width: 22.5px;
  height: 22.5px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyTouch = styled(Touch)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyServices = styled(Services)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyBag = styled(Bag)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyEmails = styled(Emails)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyReviews = styled(Review)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyLeave = styled(Leave)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;

export const MyQuestion = styled(Quest)`
  margin-right: 1em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const MyArror = styled(Arror)`
  margin-right: 13em;
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.colors.brandColor};
  }
`;
export const CustomH1 = styled.h1`
  color: #198acb !important;
`;

// export const NavLinkCustom = styled.a`
//     font-family: Montserrat!important;
//     font-size: 24px;
//     font-weight: 400;
//     color: #44494C;
// `
type CounterProps = {
  $right?: number;
  $bottom?: number;
};
export const CounterMenuFav = styled.span<CounterProps>`
  position: absolute;
  // right: ${({ $right }) => (typeof $right === 'number' ? $right : -10)}px;
  // top: ${({ $bottom }) => (typeof $bottom === 'number' ? $bottom : -2)}px;
  width: 19px;
  height: 19px;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.revert};
  color: ${({ theme }) => theme.colors.grey33};
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  z-index: 100;
  margin-top: -8px;
  margin-left: -110px;
`;

export const SpaceDivider = styled.div`
  width: 15px;
  height: 1px;
  background: transparent;
`;

export const AdsButton = styled.button`
  width: 148px;
  height: 36px;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #198acb !important;
  color: #198acb !important;
  font-weight: 600 !important;
  font-family: Montserrat;
  font-size: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.md} {
    width: 178px;
    height: 41px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:active {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &:hover {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &:focus {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &.focusedbut {
    background: #198acb;
    color: #fff !important;
    border: 1px solid #198acb !important;
  }
`;

export const ServButton = styled.div`
  width: 148px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #198acb;
  color: #198acb;
  font-family: Montserrat;
  font-size: 1.25em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and ${({ theme }) => theme.media.md} {
    width: 178px;
    height: 41px;
    border-radius: 50px;
    border: 1px solid #198acb;
    color: #198acb;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &:active {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &:hover {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &:focus {
    background: #198acb;
    color: #fff !important;
    font-weight: 600 !important;
    border: 1px solid #198acb !important;
  }
  &.focusedbut {
    background: #198acb;
    color: #fff !important;
    border: 1px solid #198acb !important;
  }
`;

export const UpperCounter = styled.span`
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background: white;
  border: 1px solid #198acb;
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  margin-top: -10px;
`;

export const CustomLi = styled.li`
  display: flex;
`;
