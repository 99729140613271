import apiRoutes from './config/apiRoutes';

export async function getBMForUser(axiosPrivate, userId, token, page = 1, limit = 5) {
  try {
    const response3 = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.PAGINATE_BLACK_MARKER}`, {
      page,
      limit,
      token,
      userId,
    });
    return response3.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function getRealEstatesForBM(axiosPrivate, address, token) {
  try {
    const response3 = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_REALESTATES_FROM_BM}`, {
      address,
      token
    });
    return response3.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function createBlackMarker(axiosPrivate, address, userId, token) {
  try {
    const res = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.CREATE_BLACK_MARKER}`, {
      address,
      userId,
      token,
    });
    return res.data.body;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteBlackMarker(axiosPrivate, blackMarkerUserId, userId, token) {
  try {
    const res = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.DELETE_BLACK_MARKER}`, {
      userId,
      blackMarkerUserId,
      token,
    });
    return res.data.body;
  } catch (error) {
    console.error(error);
  }
}
