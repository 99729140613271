import useIsMobile from '../hooks/useIsMobile';

interface IProps {
  children?:any;
  onClick?:any;
}
export default function PageWithBackLayout({ children, onClick }: IProps) {
  const isMobile = useIsMobile(768);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
        onClick={onClick}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: 25,
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <div
            className="container"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'left',
            }}
          >
            <button
              onClick={() => {
                window.history.back();
              }}
              style={{
                width: '147px',
                height: '41px',
                background: '#198ACB',
                borderRadius: '50px',
                color: '#FFF',
                fontSize: '1.5em',
              }}
            >
              Назад
            </button>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
