import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ServiceCard from '../components/ServiceCard/ServiceCard';
import * as S from './Main/Articles/Slider/styles';
import useIsMobile from '../hooks/useIsMobile';

export const SliderServices = ({ services, viewsCount, reportStatus }) => {
  const isMobile = useIsMobile(768);

  return (
    <S.Wrapper>
      <Swiper
        className="swiper-4"
        spaceBetween={0}
        slidesPerView={isMobile ? 1 : 1.5}
        touchStartPreventDefault={true}
        grabCursor={true}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation, Pagination]}
      >
        {services?.map((service) => {
          return (
            <SwiperSlide key={service.id}>
              <ServiceCard
                service={service}
                reportStatus={reportStatus}
                description={service.description}
                serviceTypeId={service.serviceTypeId}
                key={service.id}
                id={service.id}
                labels={service.labels}
                images={service.images}
                subServices={service.subServices}
                withSide={isMobile}
                isLargeFont={true}
                type={!isMobile && 'as-a-list'}
                isAdView={false}
                isUserPage={true}
                user={service.user}
                userId={service.userId}
                date={service.subServices[0].service.createdAtForUser}
              />
            </SwiperSlide>
          )
        })}
        <div className="swiper-button-prev">
          <img src="/img/icons/prev.svg" alt="предыдущий" className="w-100" />
        </div>
        <div className="swiper-button-next">
          <img src="/img/icons/next.svg" alt="следующий" className="w-100" />
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </S.Wrapper>
  );
};
