import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import * as S from './styles';
import { SlideStories } from './SliderStories/SliderStories';
import { StoriesModal } from '../../../StoriesModal/StoriesModal';
import { useToggleVisible } from '../../../../hooks/useToggleVisible';

export type Stories = {
  id: string;
  title: string;
  image: string;
  description?: string;
  media: {
    id: number;
    media: string;
    duration?: number;
  }[];
};
type SliderProps = {
  stories: Stories[];
};
export const url = `${process.env.REACT_APP_PHOTO_URL}`;
export const Slider = ({ stories }: SliderProps) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [current, setCurrent] = useState<number>();

  const onClick = (i: number) => {
    setCurrent(i);
    toggle();
  };

  useEffect(() => {
    if (!isOpen) setCurrent(undefined);
  }, [isOpen]);

  return (
    <S.Wrapper>
      <Swiper
        loop={false}
        slidesPerView={3.5}
        spaceBetween={8}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        slideToClickedSlide={true}
        breakpoints={{
          500: {
            slidesPerView: 4,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          1400: {
            slidesPerView: 4.5,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
        }}
      >
        {[...stories].map((story, index) => (
          <S.StyledSwiperSlide key={story.id} onClick={() => onClick(index)}>
            <SlideStories image={url + story.image} title={story.title} />
          </S.StyledSwiperSlide>
        ))}
      </Swiper>
      <StoriesModal isOpen={isOpen} isShow={isShow} toggle={toggle} current={current} stories={stories} />
    </S.Wrapper>
  );
};
