import { useCallback, useEffect, useState } from 'react';
import { getNormalNews, getTopNews } from '../API/news';
import useDebounce from '../hooks/debounce';
import Loader from '../components/Loader';
import ArticleTiles from '../components/Main/Articles/ArticleTiles';
import { Slider } from '../components/Main/Articles/Slider/Slider';
import * as S from '../components/Main/Articles/styles';
import useIsMobile from '../hooks/useIsMobile';
import { Link } from 'react-router-dom';

export default function Articles({ routeName }) {
  const [newsFilter, setNewsFilter] = useState('');
  const delayNewsFilter = useDebounce(newsFilter, 500);

  //TOP
  const [isLoadingTop, setIsLoadingTop] = useState(true);
  const [allTopArticles, setAllTopArticles] = useState<any>();
  const [filteredTopArticles, setFilteredTopArticles] = useState();
  useEffect(() => {
    getTopNews().then((result) => {
      setAllTopArticles(result);
      setFilteredTopArticles(result);
      setIsLoadingTop(false);
    });
  }, []);
  useEffect(() => {
    if (delayNewsFilter)
      setFilteredTopArticles(
        allTopArticles?.filter(
          (article) =>
            article.title.toLowerCase().includes(delayNewsFilter.toLowerCase()) ||
            article.description.toLowerCase().includes(delayNewsFilter.toLowerCase())
        )
      );
    else setFilteredTopArticles(allTopArticles);
  }, [delayNewsFilter]);

  //NORMAL
  const [normarArticles, setNormarArticles] = useState<any>();
  const [isLoadingNormal, setIsLoadingNormal] = useState(true);
  useEffect(() => {
    getNormalNews(1, 6, newsFilter).then((result) => {
      setNormarArticles({ catalog: result?.data, last_page: result?.meta?.last_page });
      setIsLoadingNormal(false);
    });
  }, []);

  const [nextPage, setNextPage] = useState(2);
  const getFetchData = useCallback(
    async (nPage = nextPage) => {
      getNormalNews(nPage, 6, delayNewsFilter)
        .then((response) => {
          console.log({ response });
          setNextPage(nPage + 1);
          setNormarArticles({
            isLoaded: true,
            meta: response.meta,
            last_page: response.meta.last_page,
            catalog: [...normarArticles.catalog, ...response.data],
            foundCount: response.meta.total,
          });
        })
        .catch((err) => {
          return err.message;
        });
    },
    [delayNewsFilter, normarArticles]
  );

  useEffect(() => {
    setIsLoadingNormal(true)
    getNormalNews(1, 6, newsFilter).then((result) => {
      setNormarArticles({ catalog: result?.data, last_page: result?.meta?.last_page });
      setIsLoadingNormal(false);
    });
  }, [delayNewsFilter]);

  const isMobile = useIsMobile(768);

  return (
    <main>
      <div className="container py-3 py-sm-4 py-lg-5">
        <nav aria-label="breadcrumb">
          <Link to="/" className="d-block d-md-none gray-3">
            &#10094; Назад
          </Link>
          <ol className="d-none d-md-flex breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Главная</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Статьи
            </li>
          </ol>
        </nav>
      </div>

      <div className="container pb-4 pb-sm-5">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 20,
            marginBottom: 30,
          }}
        >
          <h1 className="text-center text-md-start g-4 m-0">Статьи</h1>
          <input
            className={`articleInput ${isMobile ? 'w-100' : 'w-25'} fs-11`}
            value={newsFilter}
            onChange={(event) => setNewsFilter(event.target.value)}
            placeholder="Поиск"
          />
        </div>

        <S.Wrapper className="container pb-5">
          {isLoadingTop ? (
            <div className="p-5 w-100 d-flex justify-content-center">
              <Loader color="#146492" />
            </div>
          ) : allTopArticles.length ? (
            <div className="position-relative">
              <Slider data={filteredTopArticles} />
            </div>
          ) : null}
        </S.Wrapper>
        <ArticleTiles
          isLoading={isLoadingNormal}
          catalogData={normarArticles}
          getFetchData={getFetchData}
          nextPage={nextPage}
          view={'tiled'}
        />
      </div>
    </main>
  );
}
