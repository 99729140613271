import { PropsWithChildren, useEffect, useState } from 'react';
import { createContainer, Portal } from '../Portal/Portal';
import * as S from './Styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/modal/close.svg';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { Close, FullScreenModalWrapper } from '../../Styles/components';
import { MODAL_CONTAINER_ID, ModalProps } from '../Modal/Modal';

export type StoryWrapperModalProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};
export const StoryWrapperModal = ({ onClose, isShow, width, overVisible, children }: PropsWithChildren<ModalProps>) => {
  const [isMounted, setMounted] = useState(false);

  useLockBodyScroll();

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID });
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, [onClose]);

  return isMounted ? (
    <Portal id={MODAL_CONTAINER_ID}>
      <FullScreenModalWrapper $isShow={isShow} data-testid={'wrap'}>
        <S.Title>Истории</S.Title>
        <Close onClick={onClose}>
          <CloseIcon />
        </Close>
        <div onClick={(e) => e.stopPropagation()} data-testid={'modal-content'}>
          <>{children}</>
        </div>
      </FullScreenModalWrapper>
    </Portal>
  ) : null;
};
