import apiRoutes from './config/apiRoutes';

export const getServicesTypes = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_SERVICE_TYPES}`);
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
};

export const getServiceType = async (axiosPrivate, id) => {
  try {
    const response = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_SERVICE_TYPE}/${id}`);
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
};

export const getSubServicesTypes = async (axiosPrivate, serviceId) => {
  try {
    const response = await axiosPrivate.post(
      `${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_SERVICE_SUB_TYPES}/${serviceId}`
    );
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
};

export const getAttributesTypes = async (axiosPrivate, serviceId) => {
  try {
    const response = await axiosPrivate.post(
      `${process.env.REACT_APP_BASE_URL}${apiRoutes.GET_SERVICE_ATTRIBUTES_TYPES}/${serviceId}`
    );
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
};

export const getServicesUsers = async (axiosPrivate, payload, city = '', currentUserId = '') => {
  try {
    const response = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS}/${currentUserId}`, {
      ...payload,
      city: city,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createService = async (axiosPrivate, payloads) => {
  try {
    await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.ADD_SERVICES}`, payloads);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteService = async (axiosPrivate, serviceId, token) => {
  try {
    await axiosPrivate.delete(`${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS}/${serviceId}`, {
      data: { token },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getService = async (axiosPrivate, serviceId) => {
  try {
    const response = await axiosPrivate.get(
      `${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS}/${serviceId}`
    );
    return response.data.body;
  } catch (error) {
    console.error(error);
  }
};

export const updateService = async (axiosPrivate, payload, id) => {
  try {
    await axiosPrivate.patch(`${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS}/${id}`, payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const incrementTodayViewsCount = async (axiosPrivate, id) => {
  try {
    const res = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS}/incrementTodayViewsCount/${id}`);
    return res.data.body
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function addWishList(data, axiosPrivate) {
  try {
    const response = await axiosPrivate.post(
      `${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS_WISHLIST}`,
      { ...data }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteWishList(data, axiosPrivate) {
  try {
    const response = await axiosPrivate.delete(
      `${process.env.REACT_APP_BASE_URL}${apiRoutes.SERVICES_ACTIONS_WISHLIST}`,
      { data: data }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
