import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { getTransition } from '../../../Styles/utilites';

export const Section = styled.section`
  background: ${({ theme }) => theme.colors.bgColor};
  padding: 0.75rem 0 0.25rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 1.5rem 0 1rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    padding: 3rem 0 2rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

type ItemProps = {
  $active?: boolean;
  $isShow?: boolean;
  $index?: number;
};

export const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: ${({ $active }) => ($active ? 'absolute' : 'relative')};
  flex-grow: 1;
  //width: 80px;
  width: 80px;
  height: auto;
  &:not(:nth-child(even)) {
    margin-right: 0.5rem;
  }

  margin-bottom: 0.5rem;
  img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${getTransition(500, 'transform')};
  }
  ${({ $active, $isShow }) =>
    $active
      ? css`
          display: ${$active && !$isShow ? 'none' : 'flex'};
          margin-right: 0;
          &:not(:nth-child(even)) {
            margin-right: 0;
          }
          img {
            transform: ${$isShow ? 'translate3d(0, 50px, 0)' : 'translate3d(0, 0, 0)'};
          }
          top: 0;
          z-index: 4;
          width: calc(200% + 0.5rem);
          &:hover {
            img {
              transform: scale(1.1) translate3d(0, ${$isShow ? '50px' : '0'}, 0);
            }
          }
        `
      : css`
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        `};
  ${({ $index }) =>
    $index !== undefined && $index % 2 !== 0
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
  @media screen and (min-width: 287px) {
    &:not(:nth-child(even)) {
      margin-right: 0;
    }
    &:not(:nth-child(3n)) {
      margin-right: ${({ $active }) => ($active ? 0 : '0.5rem')};
    }
    ${({ $index }) =>
      $index !== undefined && ($index + 1) % 3 === 0
        ? css`
            right: 0;
            left: auto;
          `
        : css`
            left: 0;
          `};
  }
  @media screen and (min-width: 370px) {
    width: 96px;
    ${({ $active }) =>
      $active &&
      css`
        width: calc(200% + 0.5rem);
      `};
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    width: 120px;
    ${({ $active }) =>
      $active &&
      css`
        width: calc(200% + 0.5rem);
      `};
  }
  @media screen and (min-width: 519px) {
    width: 140px;
    ${({ $active }) =>
      $active &&
      css`
        width: calc(200% + 0.5rem);
      `};
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    //position: relative;
    width: 100px;
    &:not(:nth-child(3n)) {
      margin-right: 0;
    }
    &:not(:nth-child(4n)) {
      margin-right: 0.5rem;
    }
    ${({ $active }) =>
      $active &&
      css`
        &:not(:nth-child(4n)) {
          margin-right: 0;
        }
        width: calc(200% + 0.5rem);
      `};
    border-radius: 0.8rem;

    ${({ $index }) =>
      $index !== undefined && ($index + 1) % 4 === 0
        ? css`
            right: 0;
            left: auto;
          `
        : css`
            left: 0;
          `};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
    width: 160px;
    ${({ $active }) =>
      $active &&
      css`
        width: 100%;
      `};
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    width: 200px;
    ${({ $active }) =>
      $active &&
      css`
        width: 100%;
      `};
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 160px;
    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
    &:not(:nth-child(6n)) {
      margin-right: 1rem;
    }
    ${({ $active }) =>
      $active &&
      css`
        width: 100%;
        &:not(:nth-child(6n)) {
          margin-right: 0;
        }
      `};

    margin-bottom: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 200px;
    ${({ $active }) =>
      $active &&
      css`
        width: 100%;
      `};
  }
  @media screen and ${({ theme }) => theme.media.xxxl} {
    width: 220px;
    ${({ $active }) =>
      $active &&
      css`
        width: 100%;
      `};
  }
`;

type ItemContentProps = {
  $active?: boolean;
};
export const ItemContent = styled.div<ItemContentProps>`
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-shrink: 1;
  border-radius: ${({ $active }) => ($active ? 'calc(0.5rem - 1px)' : '0.5rem')};
  overflow: hidden;

  ${({ $active }) =>
    $active
      ? css`
          &:hover {
            > div:first-child > div > div {
              transform: translate3d(0, -8px, 0) scale(1.1);
            }
          }
        `
      : css`
          &:hover {
            > div:first-child > div {
              transform: translate3d(0, -8px, 0) scale(1.1);
            }
          }
        `}
  @media screen and ${({ theme }) => theme.media.sm} {
    border-radius: ${({ $active }) => ($active ? 'calc(0.8rem - 2px)' : '0.8rem')};
  }
`;

export const LinksDivider = styled.div`
  position: absolute;
  width: 100%;
  height: 52%;
  overflow: hidden;
  background: white;
  z-index: 1;
  bottom: 0;
  box-shadow: inset 0 10px 5px -5px hsla(0, 0%, 0%, 0.25);
  padding: 1.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div:first-child {
    margin-bottom: 0.75rem;
  }
  @media screen and (min-width: 340px) {
    padding: 1.5rem 1rem;
    > div:first-child {
      margin-bottom: 1rem;
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 1.5rem 0.5rem;
    > div:first-child {
      margin-bottom: 0.75rem;
    }
  }
`;

export const ActionDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > a:first-child {
    margin-right: 0.25rem;
  }
  > a:last-child {
    margin-left: 0.25rem;
  }
  @media screen and (min-width: 340px) {
    > a:first-child {
      margin-right: 0.5rem;
    }
    > a:last-child {
      margin-left: 0.5rem;
    }
  }
  @media screen and ${({ theme }) => theme.media.md} {
    > a:first-child {
      margin-right: 0.25rem;
    }
    > a:last-child {
      margin-left: 0.25rem;
    }
  }
`;

export const Action = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0.35rem;
  color: ${({ theme }) => theme.colors.brandColor};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  &:hover {
    color: ${({ theme }) => theme.colors.darkBrand};
    background-color: hsla(${({ theme }) => theme.colors.lightBlueValue}, 0.6);
  }
  ${getTransition()};
  @media screen and (min-width: 360px) {
    font-size: 0.85rem;
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    font-size: 0.95rem;
  }
`;

type ActiveImgWrapperProps = {
  $isActive?: boolean;
};
export const ActiveImgWrapper = styled.div<ActiveImgWrapperProps>`
  overflow: hidden;
  position: relative;
  z-index: 3;
  transform: ${({ $isActive }) => ($isActive ? 'translate3d(0, -52%, 0)' : 'translate3d(0, 0, 0)')};
  @media screen and ${({ theme }) => theme.media.md} {
    ${getTransition(500, 'transform')};
  }
`;

type TitleDividerProps = {
  $isActive?: boolean;
};
export const TitleDivider = styled.div<TitleDividerProps>`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 0%, 0) 100%);
  height: ${({ $isActive }) => ($isActive ? 25 : 50)}%;
  display: flex;
  align-items: ${({ $isActive }) => ($isActive ? 'flex-end' : 'center')};
  justify-content: ${({ $isActive }) => ($isActive ? 'center' : 'flex-end')};
  flex-direction: ${({ $isActive }) => ($isActive ? 'row' : 'column')};
  padding-bottom: 0.5rem;

  @media screen and (min-width: 287px) {
    padding-bottom: 0.25rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    ${getTransition(500, 'bottom')};
    padding-bottom: 0.5rem;
  }
`;

type TitleProps = {
  $isSecond?: boolean;
  $isActive?: boolean;
};
export const Title = styled.div<TitleProps>`
  color: ${({ theme }) => theme.colors.revert};
  font-size: 0.85rem;
  font-weight: 700;
  font-family: Lato;
  text-align: center;
  white-space: pre-wrap;
  width: 90%;
  ${getTransition(300, 'transform')};
  ${({ $isSecond }) =>
    $isSecond &&
    css`
      transition-delay: 0.1s;
    `};

  @media screen and (min-width: 287px) {
    font-size: 0.6rem;
  }
  @media screen and (min-width: 336px) {
    font-size: 0.7rem;
  }
  @media screen and ${({ theme }) => theme.media.xs} {
    font-size: 0.85rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 80%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: ${({ $isActive }) => ($isActive ? 0.85 : 1)}rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: ${({ $isActive }) => ($isActive ? 1 : 1.2)}rem;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  opacity: 0.6;
  svg {
    width: 16px;
    height: auto;
  }
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  ${getTransition(300, ['transform', 'opacity'])}
`;
