import React, { useEffect, useState } from 'react';
import { getStories } from '../../../API/mainpagereq';
import { Slider } from './Slider/Slider';
import { useSelector } from 'react-redux';

type StoriesProps = {};
export const Stories = ({}: StoriesProps) => {
  const [stories, setStories] = useState([]);
  const selectedCity = useSelector((state) => state?.selectedCity);
  useEffect(() => {
    getStories().then((data) => {
      const filtered = data.filter((story) => story.cities === null ? true : story.cities.includes(selectedCity));
      setStories(filtered);
    });
  }, []);

  return (
    <section className={'d-flex align-items-center justify-content-center'}>
      <Slider stories={stories} />
    </section>
  );
};
