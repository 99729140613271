import styled from 'styled-components';
import { getTransition } from '../../../../../Styles/utilites';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:hover {
    > div:first-child {
      opacity: 1;
    }
  }
`;

export const Bg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.35);
  opacity: 1;
  ${getTransition(300, 'opacity')}
  @media screen and ${({ theme }) => theme.media.md} {
    opacity: 0;
  }
`;
export const Title = styled.div`
  position: absolute;
  font-family: Lato;
  z-index: 2;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.revert};
  font-weight: 600;
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1.5rem;
  }
`;
