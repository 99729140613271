import styled from 'styled-components';
import {ReactComponent as Done } from './assets/Done.svg'
import PhoneInput from 'react-phone-input-2';

export const FieldTypeTitle = styled.div`
    font-family: Montserrat;
    font-size: 1.2em;
    color: #198ACB;
    font-weight: 600;
    @media screen and ${({ theme }) => theme.media.md} {
        font-size: 20px;
    }
`;

export const StyledInput = styled.input`
    border-radius: 10px;
    background: #fff;
    border: 1px solid #fff!important;
    color: #000!important;
    font-family: Montserrat;
    font-size: 1.2em;
    font-weight: 600;
    @media screen and ${({ theme }) => theme.media.md} {
        font-size: 20px;
    }
    &:active {
        border: 1px solid #198ACB;
    }
    &:hover {
        border: 1px solid #198ACB;
    }
`;

export const StyledRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StyledDone = styled(Done)`
    margin-left: -40px;
`;

export const BannerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    @media screen and ${({ theme }) => theme.media.xl} {
        flex-direction: row;
        gap: 0px;
    }
`;
export const RowAvatartitle = styled.div`
    display: flex;
`;
export const AvatarWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    align-items: center;

    width: 100%;
    border-radius: 50%;
    img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
    }
    @media screen and ${({ theme }) => theme.media.md} {
        margin-bottom: 0.75em;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        justify-content: flex-start;
        img {
            width: 105px;
            height: 105px;
            border-radius: 50%;
        }
    }
`;
export const SkeletonMargin = styled.div`
    @media screen and ${({ theme }) => theme.media.md} {
        margin-left: 24px;
    }
`;
export const SkeletonMargin2 = styled.div`
    margin-left: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and ${({ theme }) => theme.media.xl} {
        margin-left: 24px;
    }
`;
export const AvatarTitle = styled.div`
    font-size: 1.4em;
    color: #000;
    font-family: Montserrat;
    font-weight: 600;
    text-align: start;
    @media screen and ${({ theme }) => theme.media.md} {
        font-size: 1.7em;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        text-align: start;
        font-size: 26px;
    }
`;
export const AvatarUnder = styled.div`
    font-size: 1.1em;
    color: #7DA3B9;
    font-family: Montserrat;
    font-weight: 500;
    //width: 300px;
    text-align: start;
    @media screen and ${({ theme }) => theme.media.md} {
        font-size: 1.2em;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        text-align: start;
        font-size: 22px;
        width: 300px;
    }
`;


export const CustomRatingWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-left: 24px;
    //margin-top: 50px;
    img {
        width: 57px;
        height: 58px;
    }
    @media screen and ${({ theme }) => theme.media.lg} {
        width: 86px;
        height: 84px;
        margin-left: 0px;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        position: relative;
        margin-left: 0px;
        margin-top: 0px;
    }
`;

export const UserPageRatingWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    // margin-left: 24px;
    //margin-top: 50px;
    img {
        width: ${({isMobile} )=> isMobile ? '55px' : '122px' };
        height: ${({isMobile}) => isMobile ? '55px' : '122px'};
    }
    @media screen and ${({ theme }) => theme.media.lg} {
        width: ${({isMobile} )=> isMobile ? '55px' : '122px' };
        height: ${({isMobile}) => isMobile ? '55px' : '122px'};
        margin-left: 0px;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        position: relative;
        margin-left: 0px;
        margin-top: 0px;
    }
`;

export const UserPageRatingSpan = styled.span`
    position: absolute;
    color: #ffffff;
    font-size: ${({isMobile} )=> isMobile ? '25px' : '50px' };
    font-weight: bold;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
`;

export const CustomRatingSpan = styled.span`
    position: absolute;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
`;

export const StyledPhoneField = styled(PhoneInput)`
    .form-control {
        border-radius: 10px;
        background: #fff;
        border: 1px solid #fff!important;
        color: #000!important;
        font-family: Montserrat;
        font-size: 1.2em;
        font-weight: 600;
        @media screen and ${({ theme }) => theme.media.md} {
            font-size: 20px;
        }
        &:active {
            border: 1px solid #198ACB;
        }
        &:hover {
            border: 1px solid #198ACB;
        }
    }
`;
export const EditorButton = styled.button`
    width: 260px;
    height: 41px;
    background: #fff;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 3px 1px -1px rgba(34, 60, 80, 0.2);
    border-radius: 50px;
    color: #198ACB;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: 25px;
    @media screen and ${({ theme }) => theme.media.md} {
        //margin-left: 200px;
    }
    @media screen and ${({ theme }) => theme.media.xl} {
        box-shadow: 0px 12px 8px -1px rgba(34, 60, 80, 0);
        width: 260px;
        height: 41px;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 50px;
        color: #198ACB;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        //margin-left: 300px;
    }
`;

export const ButtonHolder = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
