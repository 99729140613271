import actionTypes from './actionTypes';

export type FavoriteCountType = {
  servicesCount: number;
  adsCount: number;
};
const setFavoriteCount = (payload: FavoriteCountType) => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.setFavoriteCount, payload });
  };
};

export default { setFavoriteCount };
