
import actionTypes from '../actions/actionTypes';
type TabAction = {
    type: string;
    payload: string;
  };

const initialState = {
    tab: 'ads', 
};
  
const tabReducer = (state = initialState, action: TabAction) => {
    switch (action.type) {
      case 'SET_TAB':
        return {
          ...state,
          tab: action.payload,
        };
      default:
        return state;
    }
};
  
export default tabReducer;