import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import BtnFav from '../BtnFav';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import * as S from './styles';
import { Link } from 'react-router-dom';

type HoverSliderProps = {
  urls?: string[];
  isMobile?: boolean;
  serviceId?: string;
  wishlistStatus?: boolean;
  onClick:any;
};
export default function HoverSlider(props: HoverSliderProps) {
  const urls = props.urls;

  return (
    <div
      className="hover-slider"
      style={props.isMobile ? { height: '300px', width: '330px' } : { borderRadius: '10px' }}
      onClick={props.onClick}
    >
      {urls && (
        <Swiper
          spaceBetween={0}
          loop={true}
          effect="fade"
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: false,
          }}
          modules={[Pagination, Navigation]}
        >
          {urls.map((slide, i) => (
            <SwiperSlide key={i}>
              {slide?.split('.')?.at(-1) === 'mp4' ? (
                <div style={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center', height: '90%'}}>
                  <video controls src={slide} style={{ height: '80%', zIndex:'0' }} />
                </div>
              ) : (
                <img src={slide} alt="фото" style={{ borderRadius: '10px' }} />
              )}
            </SwiperSlide>
          ))}

          <div className="swiper-pagination" />
        </Swiper>
      )}

      {props.isMobile && props.serviceId && (
        <div className="favoriteContainer">
          {/*<BtnFavService serviceId={props.serviceId} wishlistStatus={props.wishlistStatus} />*/}
          <BtnFav id={props.serviceId} wishlistStatus={!!props.wishlistStatus} type={'service'} />
        </div>
      )}
    </div>
  );
}

// old component with :hover css slide swipe behavior

// function OldHoverSlider(props: any) {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const urls = props.urls;
//
//   return (
//     <div className="hover-slider" style={props.isMobile ? { height: '200px' } : {}}>
//       {urls &&
//         urls.map(function (item: any, index: number, arr: any[]) {
//           if (arr.length < 7) {
//             return (
//               <div key={index.toString()} className={index === activeIndex ? 'sld active' : 'sld'}>
//                 <img src={item} alt="фото" />
//               </div>
//             );
//           } else {
//             if (index < 5) {
//               return (
//                 <div key={index.toString()} className={index === activeIndex ? 'sld active' : 'sld'}>
//                   <img src={item} alt="фото" />
//                 </div>
//               );
//             } else if (index === 5) {
//               return (
//                 <div key={index.toString()} className={index === activeIndex ? 'sld active' : 'sld'}>
//                   <img src={item} alt="фото" />
//                   <div className="extra">Еще {arr.length - 6} фото</div>
//                 </div>
//               );
//             } else {
//               return false;
//             }
//           }
//         })}
//       <div className="box">
//         {urls &&
//           urls.length > 1 &&
//           urls.map(function (item: any, index: number, arr: any[]) {
//             if (index < 6) {
//               return (
//                 <div
//                   key={index.toString()}
//                   className={index === activeIndex ? 'section active' : 'section'}
//                   onMouseEnter={() => setActiveIndex(index)}
//                 ></div>
//               );
//             } else {
//               return false;
//             }
//           })}
//       </div>
//       {props.isMobile && (
//         <div className="favoriteContainer">
//           <BtnFavService serviceId={props?.serviceId} wishlistStatus={props?.wishlistStatus} />
//         </div>
//       )}
//     </div>
//   );
// }
